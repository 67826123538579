import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import _ from 'lodash';
import { ReportHeader } from './common';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (Products) => {
  const productList = _.map(Products, (product, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: [
        { text: `${product?.name}\n` },
        { text: `${product?.about || ''}`, fontSize: 8 },
      ],
      alignment: 'left',
    },
    { text: `${product?.quantity} ${product?.unit}`, alignment: 'center' },
    {
      text: `${product?.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
      alignment: 'right',
    },
    {
      text: `${product?.discount
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
      alignment: 'right',
    },
    {
      text: `${(product?.price * product?.quantity - product?.discount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
      alignment: 'right',
    },
  ]);

  return productList;
};

const PoReport = async (data, information) => {
  const THBText = require('thai-baht-text');
  const sumPrice = _.sumBy(
    data.product,
    (each) => each?.price * each?.quantity
  );
  const sumDiscount = _.sumBy(data.product, (each) => parseInt(each?.discount));
  const sumAllPrice = sumPrice - sumDiscount;
  const taxPrice = () => {
    if (data.productVat) {
      return 0;
    }
    return sumAllPrice * 0.07;
  };
  const Price = sumAllPrice + taxPrice();
  const genItemBody = genBody(data.product);

  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 270, 20, 200],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    header: [
      ReportHeader(information),
      {
        alignment: 'center',
        margin: [20, -2, 20, 20],
        table: {
          alignment: 'center',

          widths: ['*'],
          body: [
            [
              {
                rowSpan: 2,
                colSpen: 4,
                text: 'ใบสั่งซื้อ/Purchase Order',
                margin: 10,
                style: 'header1',
                // fillColor: "#8b5cf6",
                border: [false, false, false, true],
              },
            ],
            [''],
          ],
        },
      },
      {
        margin: [20, -2, 20, 0],
        table: {
          alignment: '',

          widths: ['15%', '35%', '13%', '13%', '*'],
          body: [
            [
              {
                text: 'ชื่อผู้จำหน่าย',
                style: 'body',
                border: [true, true, false, false],
              },
              {
                text: `${data?.businessName || '-'}`,
                border: [false, true, true, false],
                style: 'body',
              },
              { text: '', border: [false, false, false, false] },
              { text: 'เลขที่' },
              { text: `${data.prefix}${data.running_number}` },
            ],

            [
              {
                text: 'เลขที่ผู้เสียภาษี ',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${data?.TaxId || '-'}`,
                border: [false, false, true, false],
              }, // texid
              { text: '', border: [false, false, false, false] },
              { text: 'วันที่', margin: [0, 4, 0, 0] },
              {
                text: `${dayjs(data.date).format('DD MMMM BBBB')}`,
                margin: [0, 4, 0, 0],
              },
            ],
            [
              {
                text: 'ชื่อผู้ติดต่อ',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${data?.name || '-'}`,
                style: 'body',
                border: [false, false, true, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: '',
                style: 'body',
                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'ที่อยู่',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${data?.address || '-'}`,
                style: 'body',
                border: [false, false, true, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: '',
                style: 'body',
                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'เบอร์โทร',
                style: 'body',
                border: [true, false, false, true],
              },
              {
                text: `${data?.tel || '-'}`,
                style: 'body',
                border: [false, false, true, true],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            // ...order
          ],
        },
      },
    ],
    content: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['5%', '45.01%', '13%', '13.33%', '10.33%', '13.33%'],
          body: [
            [
              {
                text: 'ลำดับ',
                alignment: 'center',
                border: [true, true, true, true],
                fillColor: '#facc15',
              },
              {
                text: 'รายการสินค้า',
                alignment: 'center',
                border: [true, true, true, true],
                fillColor: '#facc15',
              },
              {
                text: 'จำนวน',
                alignment: 'center',
                border: [true, true, true, true],
                fillColor: '#facc15',
              },
              {
                text: 'ราคาต่อหน่วย',
                alignment: 'center',
                border: [true, true, true, true],
                fillColor: '#facc15',
              },
              {
                text: 'ส่วนลด',
                alignment: 'center',
                border: [true, true, true, true],
                fillColor: '#facc15',
              },
              {
                text: 'จำนวนเงิน',
                alignment: 'center',
                border: [true, true, true, true],
                fillColor: '#facc15',
              },
            ],
            ...genItemBody,
          ],
        },
      },

      {},
      {
        // margin: [0, 12],
      },
    ],

    footer: [
      {
        margin: [20, -2, 20, 0],
        table: {
          alignment: '',

          widths: ['73%', '14.67%', '12.33%'],
          body: [
            [
              {
                text: [
                  { text: 'หมายเหตุ\n', bold: true },

                  `${data?.note || '-'}`,
                ],
                bold: true,
                rowSpan: 3,

                border: [false, false, false, false],
              },
              {
                text: 'รวมเงิน',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: `${sumPrice
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                border: [false, false, false, false],
                style: 'number',
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: 'ส่วนลดการค้า',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: `${
                  sumDiscount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                  '-'
                }`,
                style: 'number',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: 'เงินหลังหักส่วนลด',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: `${
                  sumAllPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                  '-'
                }`,
                border: [false, false, false, false],
                style: 'number',
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
                alignment: 'center',
                bold: true,
              },
              {
                text: 'ภาษีมูลค่าเพิ่ม 7%',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: taxPrice()
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                border: [false, false, false, false],
                bold: true,
                style: 'number',
              },
            ],
            [
              {
                fillColor: '#facc15',
                text: `${THBText(Price)}`,
                border: [false, false, false, false],
                alignment: 'center',
                style: 'number',
                bold: true,
              },
              {
                text: 'จำนวนเงินทั้งสิ้น',
                border: [false, false, false, false],
                bold: true,
              },
              {
                fillColor: '#facc15',
                text: Price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                border: [false, false, false, false],
                style: 'number',
                bold: true,
              },
            ],
          ],
        },
      },
      {
        margin: [20, 2, 20, 0],
        alignment: 'center',
        table: {
          alignment: '',
          heights: 70,
          widths: ['33.33%', '*', '33.33%'],
          body: [
            [
              {
                text: [
                  { text: '....................................\n' },
                  { text: 'ผู้สั่งซื้อ\n' },
                  { text: 'วันที่/date............................\n' },
                ],
                margin: [0, 35, 0, 0],
                alignment: 'center',

                border: [true, true, true, true],
              },
              {
                text: 'ตราบริษัท',
                alignment: 'center',
                margin: [0, 55, 0, 0],
                border: [true, true, true, true],
              },
              {
                text: [
                  { text: '....................................\n' },
                  { text: 'ผู้มีอนุมัติ\n' },
                  {
                    text: 'วันที่/date............................\n',
                  },
                ],
                margin: [0, 35, 0, 0],
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },
    ],

    styles: {
      header: {
        fontSize: 10,
        bold: true,
        alignment: 'right',
      },
      header1: {
        fontSize: 16,
        bold: true,
      },
      body: {
        fontSize: 9,
        alignment: 'left',
        bold: true,
      },
      body1: {
        alignment: 'right',
        fontSize: 10,
        bold: true,
      },
      body2: {
        alignment: 'right',
        fontSize: 8,
      },
      number: {
        alignment: 'right',
        fontSize: 10,
      },
    },
    images: {
      logo: information?.image?.url,
    },
  };
  pdfMake.createPdf(documentRef).open();
};
export default PoReport;
