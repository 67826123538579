import {
  SETTING_ALL,
  SETTING_GET,
  SETTING_POST,
  SETTING_PUT,
  SETTING_DELETE,
  SETTING_LOADING,
  SETTING_ERROR,
} from '../type';

import api from '../../utils/functions/api';

export const settingCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SETTING_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/SETTING`,
      {
        ...payload,
        // eslint-disable-next-line comma-dangle
      }
    );
    dispatch({ type: SETTING_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SETTING_ERROR });
  }
};

export const settingGetAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/iarc-setting?name=${name}&size=${size}&page=${page}`
    );
    if (status === 200) {
      dispatch({ type: SETTING_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SETTING_ERROR });
  }
};

export const settingGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/iarc-setting/${id}`
    );
    if (status === 200) {
      dispatch({ type: SETTING_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SETTING_ERROR });
  }
};

export const settingPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: SETTING_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/iarc-setting/${id}`,
      payload
    );
    dispatch({ type: SETTING_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SETTING_ERROR });
  }
};
export const settingDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: SETTING_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/iarc-setting/${id}`
    );
    dispatch({ type: SETTING_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SETTING_ERROR });
  }
};
