import {
  LEVEL_ALL,
  LEVEL_GET,
  LEVEL_POST,
  LEVEL_PUT,
  LEVEL_DELETE,
  LEVEL_LOADING,
  LEVEL_ERROR,
} from '../type';

import api from '../../utils/functions/api';

export const levelCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: LEVEL_LOADING });
    const { data } = await api.post(`${process.env.REACT_APP_API_URL}/level`, {
      ...payload,
      // eslint-disable-next-line comma-dangle
    });
    dispatch({ type: LEVEL_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: LEVEL_ERROR });
  }
};

export const levelGetAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/level?name=${name}&size=${size}&page=${page}`
    );
    if (status === 200) {
      dispatch({ type: LEVEL_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LEVEL_ERROR });
  }
};

export const levelGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/level/${id}`
    );
    if (status === 200) {
      dispatch({ type: LEVEL_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LEVEL_ERROR });
  }
};

export const levelPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: LEVEL_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/level/${id}`,
      payload
    );
    dispatch({ type: LEVEL_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: LEVEL_ERROR });
  }
};
export const levelDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: LEVEL_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/level/${id}`
    );
    dispatch({ type: LEVEL_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: LEVEL_ERROR });
  }
};
