/* eslint-disable default-param-last */
import {
  WORK_ALL,
  WORK_DELETE,
  WORK_GET,
  WORK_LOADING,
  WORK_POST,
  WORK_PUT,
} from '../type';

const initialState = {
  work: null,
  isLoading: true,
  isCompleted: true,
};

function WorkReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_LOADING:
      return { isLoading: true, isCompleted: false };
    case WORK_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case WORK_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case WORK_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case WORK_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case WORK_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default WorkReducer;
