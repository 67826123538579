import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/th';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const EmployeeSalaryReport2 = async (data, information) => {
  // console.log('data', data);
  console.log('information', information);
  const countTaxSSO = () => {
    const sso = data.salary * (data?.taxSSO / 100);
    if (data?.salary >= 15000) {
      return 15000 * (data?.taxSSO / 100);
    }
    return sso;
  };
  const yearRevenue = () =>
    data?.allSalary?.allsalary -
    data?.allSalary?.sumSSO -
    data?.allSalary?.sumEtc;
  const revenue = () => data?.salary + data?.ot + data?.extra + data?.allowance;
  const expenses = () => {
    const sso = countTaxSSO();
    return sso + data?.tax + data?.etc;
  };
  const sumSalary = () => revenue() - expenses();

  const documentRef = {
    pageSize: 'A4',
    pageOrientation: ' portrait',
    pageMargins: [20, 20, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },

    info: {
      title: `สลิปเงินเดือน_${data?.user?.name}_${dayjs(data?.date).format(
        'MMMM_BBBB'
      )}`,
    },

    content: [
      {
        margin: [0, 30],
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: ['20%', '20%', '*', '40%'],

          body: [
            [
              {
                text: 'ใบจ่ายเงินเดือน\nPAY SLIP',
                style: 'header',
                border: [false, false, false, true],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: '',
                rowSpan: 6,
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: `${information?.name}\n${information?.address} \nเลขประจำตัวผู้เสียภาษี ${information?.taxId}\n โทรศัพท์ ${information?.tel} `,
                rowSpan: 6,
                style: 'body2',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'เดือน\t' + `${dayjs(data?.date).format('MMMM BBBB')}`,
                style: 'body2',
                border: [false, false, false, false],
                margin: [0, 10],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: 'ชื่อ-สกุล',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: `${data?.user?.name}  ${data?.user?.surname}`,
                border: [false, false, false, false],
                style: 'body2',
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: 'เลขบัตรประจำตัวประชาชน',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: `${data?.user?.personalID || '-'}`,
                border: [false, false, false, false],
                style: 'body2',
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: 'ตำแหน่ง',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: `${data?.user?.position?.name || '-'}`,
                border: [false, false, false, false],
                style: 'body2',
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: 'วันที่จ่ายเงิน',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(data?.datePay).format('DD MMMM BBBB') || '-'}`,
                border: [false, false, false, false],
                style: 'body2',
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
          ],
        },
      },
      {
        margin: [0, 15],
        table: {
          headerRows: 1,
          widths: ['30%', '10%', '15%', '20%', '10%', '15%'],
          body: [
            [
              { text: 'รายได้', style: 'body' },
              { text: 'จำนวนวัน', style: 'body' },
              { text: 'จำนวนเงิน', style: 'body' },
              { text: 'รายการหัก', style: 'body' },
              { text: 'จำนวนวัน', style: 'body' },
              { text: 'จำนวนเงิน', style: 'body' },
            ],
            [
              { text: 'เงินเดือน', style: 'body1' },
              { text: '', style: 'body' },
              {
                text: `${
                  data?.salary
                    ?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-'
                }`,
                style: 'body4',
              },
              { text: 'หักลาป่วยเกินสิทธิ', style: 'body1' },
              { text: '', style: 'body1' },
              { text: '', style: 'body4' },
              // { text: 'รายได้สะสมต่อปี', style: 'body1', fillColor: '#d2d4d2' },
              // {
              //   text: `${data?.allSalary?.allsalary
              //     ?.toFixed(2)
              //     .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
              //   style: 'body1',
              // },
            ],
            [
              { text: 'ค่าตำแหน่ง', style: 'body1' },
              { text: '', style: 'body' },
              {
                text: `${
                  data?.ot?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                  '-'
                }`,
                style: 'body4',
              },
              { text: 'หักลากิจเกินสิทธิ', style: 'body1' },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
              // {
              //   text: countTaxSSO()
              //     ?.toFixed(2)
              //     .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
              //   style: 'body1',
              // },
              // { text: 'ภาษีสะสมต่อปี', style: 'body1', fillColor: '#d2d4d2' },
              // {
              //   text: `${data?.allSalary?.sumTax
              //     ?.toFixed(2)
              //     .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
              //   style: 'body1',
              // },
            ],
            [
              { text: 'OT 1.5', style: 'body1' },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
              // {
              //   text: `${
              //     data?.allowance
              //       ?.toFixed(2)
              //       .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-'
              //   }`,
              //   style: 'body1',
              // },
              { text: 'หักขาดงาน', style: 'body1' },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
              // {
              //   text: `${
              //     data?.etc?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
              //     '-'
              //   }`,
              //   style: 'body1',
              // },
              // {
              //   text: 'เงินประกันสังคมต่อปี',
              //   style: 'body1',
              //   fillColor: '#d2d4d2',
              // },
              // {
              //   text: `${data?.allSalary?.sumSSO
              //     ?.toFixed(2)
              //     .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
              //   style: 'body1',
              // },
            ],
            [
              { text: 'OT 1', style: 'body1' },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
              // {
              //   text: `${
              //     data?.extra?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
              //     '0.00'
              //   }`,
              //   style: 'body1',
              // },
              { text: 'หักสมทบประกันสังคม', style: 'body1' },
              { text: '', style: 'body' },
              {
                text: '',
                style: 'body4',
              },
              // {
              //   text: 'รายการหักอื่นๆ ต่อปี',
              //   style: 'body1',
              //   fillColor: '#d2d4d2',
              // },
              // {
              //   text: `${data?.allSalary?.sumEtc
              //     ?.toFixed(2)
              //     .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
              //   style: 'body1',
              // },
            ],
            [
              { text: 'OT 3', style: 'body1' },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
              // {
              //   text: `${data?.sumSalary
              //     ?.toFixed(2)
              //     .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
              //   style: 'body1',
              // },
              {
                text: 'หักเงินกู้ยืมกยศ',
                style: 'body1',
              },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
              // {
              //   text: expenses()
              //     ?.toFixed(2)
              //     .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
              //   style: 'body1',
              // },
              // { text: '', style: 'body1' },
              // { text: '' },
            ],
            [
              { text: 'เงินชดเชย', style: 'body1' },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
              {
                text: 'หักเงินกู้ยืม กรอ',
                style: 'body1',
              },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
            ],
            [
              { text: 'เบี้ยเลี้ยง', style: 'body1' },
              { text: '', style: 'body' },
              {
                text: `${
                  data?.allowance
                    ?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-'
                }`,
                style: 'body4',
              },
              {
                text: 'หักเงินค้ำประกันความเสียหายทรัพย์สินบริษัท	',
                style: 'body1',
              },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
            ],
            [
              { text: 'โบนัส', style: 'body1' },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
              {
                text: 'หักเงินกู้',
                style: 'body1',
              },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
            ],
            [
              { text: 'เงินได้อื่นๆ', style: 'body1' },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
              {
                text: 'หักภาษี ณ ที่จ่าย',
                style: 'body1',
              },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
            ],
            [
              { text: '', style: 'body1' },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
              {
                text: 'หักสวัสดิการอื่นๆ ระบุ',
                style: 'body1',
              },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
            ],
            [
              { text: '', style: 'body1' },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
              {
                text: ' หักอื่นๆ ระบุ ',
                style: 'body1',
              },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
            ],

            [
              { text: '', style: 'body1' },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
              {
                text: '   ',
                style: 'body1',
              },
              { text: '', style: 'body' },
              { text: '', style: 'body4' },
            ],
            [
              { text: 'รวมเงินได้', style: 'body1', fillColor: '#d2d4d2' },
              { text: '', style: 'body', fillColor: '#d2d4d2' },
              { text: '', style: 'body4', fillColor: '#d2d4d2' },
              {
                text: ' รวมรายการหัก  ',
                style: 'body1',
                fillColor: '#d2d4d2',
              },
              { text: '', style: 'body', fillColor: '#d2d4d2' },
              { text: '', style: 'body4', fillColor: '#d2d4d2' },
            ],
            [
              {
                text: '',
                style: 'body1',

                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body',

                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body',

                border: [false, false, false, false],
              },
              {
                text: ' ',
                style: 'body1',

                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body',

                border: [false, false, false, false],
              },
              {
                text: 'เงินได้สุทธิ',
                style: 'body2',
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: '',
                style: 'body1',

                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body',

                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body',

                border: [false, false, false, false],
              },
              {
                text: ' ',
                style: 'body1',

                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body',

                border: [false, false, false, false],
              },
              {
                text: 'Net to Pay	              ',
                style: 'body2',
                border: [true, false, true, true],
              },
            ],
            [
              {
                text: '',
                style: 'body1',

                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body',

                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body',

                border: [false, false, false, false],
              },
              {
                text: ' ',
                style: 'body1',

                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body',

                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body1',
                border: [true, true, true, true],
                margin: [0, 15, 0, 15],
              },
            ],

            // [
            //   {
            //     text: 'รวมรับสุทธิ',
            //     margin: [0, 8],
            //     style: 'footTb',
            //     colSpan: 3,
            //     fillColor: '#d2d4d2',
            //   },
            //   { text: '' },
            //   { text: '' },
            //   {
            //     text: sumSalary()
            //       ?.toFixed(2)
            //       .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            //     style: 'body3',
            //     margin: [0, 8],
            //   },

            //   // {
            //   //   text: 'รับสุทธิต่อปี',
            //   //   style: 'footTb',
            //   //   margin: [0, 8],
            //   //   fillColor: '#d2d4d2',
            //   // },
            //   // {
            //   //   text: yearRevenue()
            //   //     ?.toFixed(2)
            //   //     .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            //   //   style: 'body3',
            //   //   margin: [0, 8],
            //   // },
            // ],
            // [{ text: "รายการหัก" }, "", ""],
            // [{ text: "รายได้สะสมต่อปี" }, "", ""],
          ],
        },
      },
      {
        margin: [0, 90],
        table: {
          headerRows: 1,
          widths: ['15%', '5%', '15%', '5%', '15%', '5%', '15%', '5%', '20%'],
          body: [
            [
              {
                text: 'เงินได้สะสม',
                style: 'body2',
                border: [true, true, true, true],
              },
              {
                text: '',
                style: 'body2 ',
                border: [false, false, false, false],
              },
              {
                text: 'เงินหักสะสม',
                style: 'body2',
                border: [true, true, true, true],
              },
              {
                text: '',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: 'ภาษีสะสม',
                style: 'body2',
                border: [true, true, true, true],
              },
              {
                text: '',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: 'เงินประกันสะสม',
                style: 'body2',
                border: [true, true, true, true],
              },
              {
                text: '',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: 'ลงชื่อพนักงงาน',
                style: 'body2',
                border: [true, true, true, true],
              },
            ],
            [
              {
                text: '',
                style: 'body2',
                border: [true, true, true, true],
                margin: [0, 15, 0, 15],
              },
              {
                text: '',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body2',
                border: [true, true, true, true],
                margin: [0, 15, 0, 15],
              },
              {
                text: '   ',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body2',
                border: [true, true, true, true],
                margin: [0, 15, 0, 15],
              },
              {
                text: '',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body2',
                border: [true, true, true, true],
                margin: [0, 15, 0, 15],
              },
              {
                text: '',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body2',
                border: [true, true, true, true],
                margin: [0, 15, 0, 15],
              },
            ],
          ],
        },
      },
      // {
      //   margin: [0, 30],
      //   table: {
      //     // headers are automatically repeated if the table spans over multiple pages
      //     // you can declare how many rows should be treated as headers
      //     headerRows: 1,
      //     widths: ['*', '33%'],

      //     body: [
      //       [
      //         {
      //           text: '(ประทับตราบริษัท)',
      //           style: { alignment: 'right' },
      //           margin: [44, 0],
      //           border: [false, false, false, false],
      //         },
      //         {
      //           text: 'ผู้รับเงิน ................................................',
      //           border: [false, false, false, false],
      //           style: 'body2',
      //         },
      //       ],
      //       [
      //         {
      //           text: '',
      //           style: '',
      //           border: [false, false, false, false],
      //         },
      //         {
      //           margin: [0, 20],
      //           text: 'ผู้จ่ายเงิน ................................................',
      //           border: [false, false, false, false],
      //           style: 'body2',
      //         },
      //       ],
      //     ],
      //   },
      // },
    ],

    footer: [],

    styles: {
      header: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
      },
      body: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      body1: {
        fontSize: 10,
        bold: true,
        alignment: 'left',
      },
      body2: {
        fontSize: 10,
        bold: true,
        alignment: 'center',
      },
      body4: {
        fontSize: 10,
        bold: true,
        alignment: 'right',
      },

      body3: {
        fontSize: 12,
        bold: true,
        alignment: 'right',
        decoration: 'underline',
        decorationStyle: 'double',
      },

      footTb: {
        fontSize: 12,
        bold: true,
        alignment: 'right',
        alignment: 'center',
      },
      tab: {
        color: 'white',
      },
    },
    images: {},
  };
  pdfMake.createPdf(documentRef).open();
};
export default EmployeeSalaryReport2;
