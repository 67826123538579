import {
  POSITION_ALL,
  POSITION_GET,
  POSITION_POST,
  POSITION_PUT,
  POSITION_DELETE,
  POSITION_LOADING,
  POSITION_ERROR,
} from '../type';

import api from '../../utils/functions/api';

export const positionCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: POSITION_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/position`,
      {
        ...payload,
        // eslint-disable-next-line comma-dangle
      }
    );
    dispatch({ type: POSITION_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: POSITION_ERROR });
  }
};

export const positionGetAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/position?name=${name}&size=${size}&page=${page}`
    );
    if (status === 200) {
      dispatch({ type: POSITION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: POSITION_ERROR });
  }
};

export const positionNGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/position/${id}`
    );
    if (status === 200) {
      dispatch({ type: POSITION_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: POSITION_ERROR });
  }
};

export const positionPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: POSITION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/position/${id}`,
      payload
    );
    dispatch({ type: POSITION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: POSITION_ERROR });
  }
};
export const positionDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: POSITION_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/position/${id}`
    );
    dispatch({ type: POSITION_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: POSITION_ERROR });
  }
};
