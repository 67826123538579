/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { useParams, useHistory } from 'react-router';
import dayjs from 'dayjs';
import { BackButton } from 'components/Button';
import { ViewTitle } from 'components/ViewTitle';
import * as action from '../../redux/action';

function HrLeaveForm({ title, subtitle }) {
  const [note, setNote] = useState('-');
  const { handleSubmit } = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const leaveDocs = useSelector((state) => state.leaveDoc);
  const me = useSelector((state) => state.me);
  dayjs.extend(buddhistEra);
  // console.log("data leave", leaveDocs);

  useEffect(() => {
    dispatch(action.getOneLeaveDoc(params.id));

    return () => {};
  }, [leaveDocs?.name]);

  useEffect(() => () => {}, [leaveDocs, leaveDocs?.status]);
  const sumDay = () => {
    const date1 = dayjs(leaveDocs?.dateEnd).startOf('day');
    const date2 = dayjs(leaveDocs?.dateStart).startOf('day');
    const date = date1.toDate().getTime() - date2.toDate().getTime();
    const sumday = date / (1000 * 3600 * 24);

    return sumday + 1;
  };
  console.log('in leaveform', leaveDocs);

  // const sumOldDay = () => {
  //   const date1 = dayjs(leaveDocs?.lastdateEnd || 0).startOf('day');
  //   const date2 = dayjs(leaveDocs?.lastdateStart || 0).startOf('day');
  //   const date = date1.toDate().getTime() - date2.toDate().getTime();

  //   const sumday = date / (1000 * 3600 * 24);

  //   return sumday + 1;
  // };

  const renderBackButton = () => <BackButton />;

  const onApproveButton = async () => {
    try {
      const confirm = window.confirm('ยืนยันอนุมัติใบลา');
      if (confirm) {
        await dispatch(
          action.putLeaveDoc(params.id, {
            note,
            status: 'อนุมัติ',
            approver_by: me?.userData?._id,
            approve_status: true,
          })
        );
        await dispatch(action.getAllLeaveDoc({ name: '', page: 1, size: 5 }));
        history.goBack();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onRejectButton = async () => {
    const confirm = window.confirm('ยืนยันไม่อนุมัติใบลา');
    if (confirm) {
      await dispatch(
        action.putLeaveDoc(params.id, {
          note,
          status: 'ไม่อนุมัติ',
          approver_by: me?.userData?._id,
          approve_status: true,
        })
      );
      await dispatch(action.getAllLeaveDoc({ name: '', page: 1, size: 5 }));
      history.goBack();
    }
  };
  const onCancelButton = async () => {
    const confirm = window.confirm('ยืนยันยกเลิกใบลา');
    if (confirm) {
      await dispatch(
        action.putLeaveDoc(params.id, {
          note,
          status: 'ยกเลิก',
          approver_by: me?.userData?._id,
          approve_status: true,
        })
      );
      await dispatch(action.getAllLeaveDoc({ name: '', page: 1, size: 5 }));
      history.goBack();
    }
  };
  const onchangeNote = (e) => {
    setNote(e.target.value);
  };
  const renderViewTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  // const dataLeave = _.findIndex(leaveDocs?.arr,)
  return (
    <div className="min-h-screen">
      {renderViewTitle()}
      <form onSubmit={handleSubmit()}>
        <div className="pb-2">{renderBackButton()}</div>
        <div className=" ">
          <div>
            <Card>
              <CardContent>
                <div className="p-2">
                  <TableContainer>
                    <Table>
                      <colgroup>
                        <col width="30%" />
                        <col width="70%" />
                      </colgroup>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <h1 className=" font-bold  ">ชื่อ-นามสกุล</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l  ">
                              {leaveDocs?.name} {leaveDocs?.surname}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className=" font-bold   ">ตำแหน่ง</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l  ">{leaveDocs?.position}</h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">สังกัด</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l ">
                              {leaveDocs?.under || '-'}
                            </h1>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>{' '}
                </div>
                {/* <h1 className="font-bold pt-4 ">ข้อมูลการลาครั้งล่าสุด</h1>
                <div className="p-2">
                  <TableContainer>
                    <Table>
                      <colgroup>
                        <col width="30%" />
                        <col width="70%" />
                      </colgroup>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <h1 className=" font-bold  ">ลา</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l  ">
                              {' '}
                              {leaveDocs?.lastdateEnd
                                ? leaveDocs?.lastleaveType
                                : 'ลาครั้งแรก'}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className=" font-bold   ">วันที่</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l  ">
                              {leaveDocs?.lastdateEnd
                                ? dayjs(leaveDocs?.lastdateStart).format(
                                    'DD/MM/BB'
                                  )
                                : ''}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">ถึง</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l ">
                              {leaveDocs?.lastdateEnd
                                ? dayjs(leaveDocs?.lastdateEnd).format(
                                    'DD/MM/BB'
                                  )
                                : ''}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">จำนวน</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l ">
                              {leaveDocs?.lastdateEnd ? sumOldDay() : '-'}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">สาเหตุ</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l ">
                              {leaveDocs?.lastcause || '-'}
                            </h1>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>{' '}
                </div> */}
                <h1 className="font-bold pt-4 ">ข้อมูลการลา</h1>
                <div className="p-2">
                  <TableContainer>
                    <Table>
                      <colgroup>
                        <col width="30%" />
                        <col width="70%" />
                      </colgroup>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <h1 className=" font-bold  ">ลา</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l">
                              {leaveDocs?.leaveTypeID?.name}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className=" font-bold   ">วันที่</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l  ">
                              {dayjs(leaveDocs?.dateStart).format('DD/MM/BB')}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">ถึง</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l ">
                              {dayjs(leaveDocs?.dateEnd).format('DD/MM/BB')}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">จำนวน</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l ">{sumDay()}</h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">ช่วงเวลาการลา</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l ">
                              {leaveDocs?.statusAllDay === 'true' && (
                                <div>
                                  <h1>ทั้งวัน</h1>
                                </div>
                              )}
                              {leaveDocs?.statusHalfDay === 'true' && (
                                <div>
                                  <h1>ครึ่งวัน</h1>
                                </div>
                              )}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">สาเหตุ</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l ">
                              {leaveDocs?.leaveCause || '-'}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">เบอร์ที่ติดต่อได้</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l ">
                              {leaveDocs?.contact || '-'}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">
                              เอกสารการลาเพิ่มเติม
                            </h1>
                          </TableCell>
                          <TableCell>
                            <div>
                              <a href={leaveDocs?.file?.url} target="_blank">
                                <div className="text-blue-600 font-bold">
                                  {leaveDocs?.file?.file_name}
                                </div>
                              </a>
                            </div>
                          </TableCell>
                        </TableRow>
                        {/* <div>
                          <h1>เอกสารการลาเพิ่มเติม</h1>
                          <div>
                            <a href={leaveDocs?.file?.url} target="_blank">
                              <div className="text-blue-600 font-bold">
                                {leaveDocs?.file?.file_name}
                              </div>
                            </a>
                          </div>
                        </div> */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

                <div className="pt-8   ">
                  <div className="">
                    <div className="    ">
                      <h1 className="font-bold pt-4 ">ความเห็นผู้บริหาร</h1>
                      <div>
                        <TableContainer>
                          <Table>
                            <colgroup>
                              <col width="30%" />
                              <col width="70%" />
                            </colgroup>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <h1 className=" font-bold  ">ผลการพิจารณา</h1>
                                </TableCell>
                                <TableCell>
                                  {leaveDocs?.status === 'อนุมัติ' && (
                                    <Chip
                                      label={leaveDocs?.status}
                                      color="success"
                                    />
                                  )}
                                  {leaveDocs?.status === 'ไม่อนุมัติ' && (
                                    <Chip
                                      label={leaveDocs?.status}
                                      color="error"
                                    />
                                  )}
                                  {leaveDocs?.status === 'ยกเลิก' && (
                                    <Chip
                                      label={leaveDocs?.status}
                                      color="warning"
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <h1 className=" font-bold   ">ผู้พิจารณา</h1>
                                </TableCell>
                                <TableCell>
                                  <h1 className="text-l  ">
                                    {leaveDocs?.approver_by?.name}
                                  </h1>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <h1 className=" font-bold   ">หมายเหตุ</h1>
                                </TableCell>
                                <TableCell>
                                  <h1 className="text-l  ">
                                    {leaveDocs?.note}
                                  </h1>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                    <div className="  ">
                      {leaveDocs?.approve_status && (
                        <div>
                          <Button
                            color="warning"
                            variant="outlined"
                            onClick={() => onCancelButton()}
                          >
                            ยกเลิก
                          </Button>
                        </div>
                      )}
                      {/* {!leaveDocs?.approve_status && (
                        <div>
                          {' '}
                          <h1 className="font-bold  pt-4 ">หมายเหตุ</h1>
                          <div className="text-l pt-2">
                            <TextField
                              id="outlined-basic"
                              fullWidth
                              onChange={(e) => {
                                onchangeNote(e);
                              }}
                              label="หมายเหตุ"
                              defaultValue={leaveDocs?.note}
                              variant="outlined"
                              rows={4}
                            />
                          </div>
                          <div className="pt-6">
                            <Stack direction="row" spacing={2}>
                              <Button
                                variant="contained"
                                color="success"
                                onClick={() => {
                                  onApproveButton();
                                }}
                              >
                                อนุมัติ
                              </Button>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                  onRejectButton();
                                }}
                              >
                                ไม่อนุมัติ
                              </Button>
                            </Stack>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </form>
    </div>
  );
}
export default HrLeaveForm;
