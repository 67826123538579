/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-wrap-multilines */
import { Button, Card, FormControlLabel, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';

export default function FormPosition({ control, Controller, position }) {
  return (
    <div className="py-2">
      <Card>
        <div className="p-4">
          <div>
            <Controller
              name="name"
              defaultValue={position ? position?.name : ''}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อตำแหน่ง"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
            />
          </div>
          <div className="py-2 font-bold "> สิทธิ์ในการเข้าใช้งาน </div>
          <div className=" py-1 px-2 lg:grid grid-cols-2 gap-1">
            <div>
              <Controller
                name="access.SETTING"
                defaultValue={position ? position?.access?.SETTING : false}
                control={control}
                render={({ field }) => (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="ตั้งค่าภายในแผนก"
                    />
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                name="access.APPLOVE_LEAVEDOC"
                defaultValue={
                  position ? position?.access?.APPLOVE_LEAVEDOC : false
                }
                control={control}
                render={({ field }) => (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="อนุมัติใบลา"
                    />
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                name="access.APPLOVE_WORK"
                defaultValue={position ? position?.access?.APPLOVE_WORK : false}
                control={control}
                render={({ field }) => (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="อนุมัติงานในแผนก"
                    />
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                name="access.ADD_WORK"
                defaultValue={position ? position?.access?.ADD_WORK : false}
                control={control}
                render={({ field }) => (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="เพิ่มงานในแผนก"
                    />
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                name="access.TIMESTAMP_SETTING"
                defaultValue={
                  position ? position?.access?.TIMESTAMP_SETTING : false
                }
                control={control}
                render={({ field }) => (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="ตั้งค่าเวลางาน"
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
