import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { BackButton } from 'components/Button';
import Loading from 'components/Loading';
import * as actions from '../../redux/action';
import FormLevel from '../../components/Forms/FormLevel';

function EditLevel({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const level = useSelector((state) => state.level);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      level: level?.name,
    },
  });

  useEffect(() => {
    dispatch(actions.levelGet(id));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.levelPut(id, data));
        await dispatch(actions.levelGetAll({}));
        alert('บันทึกสำเร็จ');
        history.goBack();
      } catch (error) {
        console.log('error');
      }
    }
  };

  const renderForm = () => (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormLevel Controller={Controller} control={control} level={level} />
      </form>
    </div>
  );
  if (level.isLoading || !level.isCompleted) {
    return <Loading isLoading />;
  }
  if (!level.isLoading || level.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="py-2">{renderForm()}</div>
      </div>
    );
  }
}

EditLevel.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
EditLevel.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditLevel;
