import React from 'react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import thLocale from 'date-fns/locale/th';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MUIDatePicker from '@mui/lab/DatePicker';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

function MonthPicker({
  value,
  setValue,
  minDate = new Date('2012-03-01'),
  maxDate = new Date('2090-06-01'),
  label = 'เริ่มต้นค้นหา',
}) {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={thLocale}>
        <MUIDatePicker
          views={['year', 'month']}
          label={label}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            //   setDateChange(newValue);
          }}
          renderInput={(params) => (
            <TextField fullWidth {...params} size={'small'} />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}

MonthPicker.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  label: PropTypes.string,
};

export default MonthPicker;
