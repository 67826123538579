/* eslint-disable react/jsx-wrap-multilines */
import { Button, Card, TextField } from '@mui/material';
import React from 'react';

export default function FormGroupEmployee({ control, Controller, group }) {
  console.log('Group', group);
  return (
    <div className="py-2">
      <Card>
        <div className="p-4">
          <div>
            <Controller
              name="name"
              defaultValue={group ? group?.name : ''}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อฝ่าย"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
            />
          </div>
          <div className="flex justify-end">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
