import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Checkbox from '@mui/material/Checkbox';
import DatePicker from '@mui/lab/DatePicker';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Autocomplete,
  TextField,
} from '@mui/material';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import dayjs from 'dayjs';
import { format, isValid, parse } from 'date-fns';
import DateSelet from 'components/common/dateSelect/DateSelete';
import Loading from 'components/Loading';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import SumDiscount from 'components/Card/SumDiscount';
import Sumprice from 'components/Card/Sumprice';
import SumAllPrice from 'components/Card/SumAllPrice';
import ProductServe from '../../../components/Forms/FormProductArray';
import * as actions from '../../../redux/action';

function CreateQt({ title, subtitle }) {
  dayjs.extend(buddhistEra);
  const dispatch = useDispatch();
  const history = useHistory();
  const customer = useSelector((state) => state.customer);
  const product = useSelector((state) => state.product);
  const [findName, setFindName] = useState('');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState('');
  const [date, setDate] = React.useState(format(new Date(), 'y-MM-dd'));
  const [dueDate, setDueDate] = React.useState(format(new Date(), 'y-MM-dd'));
  const [productList, setProductList] = useState([]);
  const [customerData, setCustomerData] = useState();
  const [productVat, setProductVat] = useState(false);
  const [productVatUni, setProductVatUni] = useState(false);
  const { handleSubmit, control, setValue, watch } = useForm({});

  useEffect(() => {
    dispatch(actions.getAllCustomer({}));

    return () => {};
  }, [findName, page, size]);

  const handleCheckCustomer = (data) => {
    const customers = _.find(customer.arr, { _id: data?._id });
    setCustomerData(customers);
    setValue('taxId', customers?.TaxId);
    setValue('tel', customers?.tel);
    setValue('address', customers?.address);
    // setValue(customer?.statusTaxUni);
    // setValue(customer?.statusTax);
  };
  const onSubmitQuotation = async (data) => {
    if (data?.productArray?.length === 0) alert('กรุณาเพิ่มสินค้า');
    else {
      try {
        const ProcessData = await {
          date,
          prefix: `QT${dayjs(date).format('BB')}-`,
          dueDate,
          customer: customerData?._id,
          product: data?.productArray,
          allPrice: SumAllPrice(),
          statusTax: productVat,
          statusTaxUni: productVatUni,
        };
        console.log('dataSubmit', ProcessData);
        await dispatch(actions.addQuotation(ProcessData));
        await dispatch(actions.getAllQuotation({ findName, page, size }));
        history.push('/sale/quotation');
        console.log('ProcessData', data?.productArray);
      } catch (error) {}
    }
  };
  const tax = () => {
    if (!productVat) {
      return 0;
    }
    const price = SumAllPrice(productList);
    const taxAll = price * 0.03;
    return taxAll;
  };

  const taxUniversity = () => {
    if (!productVatUni) {
      return 0;
    }
    const priceUniversity = SumAllPrice(productList);
    const taxUniversity = priceUniversity * 0.01;
    return taxUniversity;
  };

  console.log('TaxUni', taxUniversity());
  const priceTax = () => SumAllPrice(productList) - tax();
  const priceTaxUni = () => SumAllPrice(productList) - taxUniversity();
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (customer.isloading && product.isloading) {
    return <Loading isloading />;
  }
  if (!customer.isloading && !product.isloading) {
    return (
      <div className="min-h-screen">
        <div>
          {renderTitle()}
          <BackButton />
        </div>
        <div className="py-2">
          <div>
            <div>
              <div className="flex">
                <Card>
                  <div className="p-2">
                    <h1 className="pb-4"> ข้อมูลลูกค้า</h1>
                    <div className="w-full">
                      <div className='"w-2/3'>
                        <Autocomplete
                          disablePortal
                          id="free-solo-demo"
                          freeSolo
                          options={customer?.arr || []}
                          getOptionLabel={(option) => option.businessname}
                          onChange={(e, newValue) =>
                            handleCheckCustomer(newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="ชื่อลูกค้า"
                              required
                            />
                          )}
                        />{' '}
                      </div>
                    </div>
                    <Controller
                      name="taxId"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          label="หมายเลขผู้เสียภาษี"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                    <Controller
                      name="tel"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          id="outlined-basic"
                          label="เบอร์โทร"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    <Controller
                      name="address"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          id="outlined-multiline-basic"
                          label="ที่อยู่"
                          variant="outlined"
                          fullWidth
                          multiline
                          margin="normal"
                          rows={4}
                        />
                      )}
                    />
                  </div>
                </Card>

                <div className="p-2 w-full">
                  <Card>
                    <CardContent>
                      {' '}
                      <form onSubmit={handleSubmit(onSubmitQuotation)}>
                        <div className="flex justify-between">
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="วันที่"
                                value={date}
                                onChange={(newValue) => {
                                  setDate(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>{' '}
                          </div>
                          <div>{/* <DateSelet /> */}</div>
                          <Button
                            variant="contained"
                            type="submit"
                            // onClick={onSubmitQuotation}
                          >
                            บันทึก
                          </Button>
                        </div>
                        {/* <div className="py-2">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="วันที่กำหนดส่ง"
                              value={dueDate}
                              onChange={(newValue) => {
                                setDueDate(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>{' '}
                        </div> */}
                        <Controller
                          name="credit"
                          control={control}
                          defaultValue="-"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              value={field.value}
                              label="จำนวนวันเครดิต"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                            />
                          )}
                        />
                        <Controller
                          name="payment"
                          control={control}
                          defaultValue="โอน/เงินสด"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              value={field.value}
                              label="วิธีการชำระเงิน"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />{' '}
                      </form>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
            <div>
              <div className="mt-2">
                <ProductServe
                  setProductList={setProductList}
                  setValue={setValue}
                  control={control}
                  watch={watch}
                  productVat={productVat}
                  setProductVat={setProductVat}
                  productVatUni={productVatUni}
                  setProductVatUni={setProductVatUni}
                  statusHide={true}
                  statusPickUp={false}
                />
              </div>
              <Card>
                <CardContent>
                  <div className="flex w-full ">
                    <div className="w-1/2">
                      {' '}
                      <Controller
                        name="note"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            id="outlined-multiline-basic"
                            label="หมายเหตุ"
                            variant="outlined"
                            fullWidth
                            multiline
                            margin="normal"
                            rows={4}
                          />
                        )}
                      />
                    </div>

                    <div className="flex justify-between  w-1/2 ml-2  ">
                      <div>
                        <div>รวมเงิน </div>
                        <div>ส่วนลดการค้า</div>
                        <div>เงินหลังหักส่วนลด</div>
                        {productVatUni === true ? (
                          <div>ภาษี ณ ที่จ่าย 1%</div>
                        ) : (
                          <div>ภาษี ณ ที่จ่าย 3%</div>
                        )}

                        <div className="font-bold ">จำนวนเงินทั้งสิ้น </div>
                      </div>
                      <div>
                        {productVatUni === true ? (
                          <div>
                            <div>
                              {Sumprice(productList)
                                ?.toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>

                            <div>
                              {SumDiscount(productList)
                                ?.toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>

                            <div>
                              {SumAllPrice(productList)
                                ?.toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>
                            <div>
                              <div>
                                {taxUniversity()
                                  ?.toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                              </div>
                            </div>

                            <div className="font-bold ">
                              {priceTaxUni()
                                ?.toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div>
                              {Sumprice(productList)
                                ?.toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>

                            <div>
                              {SumDiscount(productList)
                                ?.toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>

                            <div>
                              {SumAllPrice(productList)
                                ?.toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>
                            <div>
                              <div>
                                {tax()
                                  ?.toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                              </div>
                            </div>

                            <div className="font-bold ">
                              {priceTax()
                                ?.toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <>error</>;
}
export default CreateQt;
