/* eslint-disable import/no-extraneous-dependencies */
import { Button, Card } from '@mui/material';
import React from 'react';
import TextField from '@mui/material/TextField';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/lab';
import buddhistEra from 'dayjs/plugin/buddhistEra';

export default function CardMonthPrintFilltter({
  month,
  setMonth,
  name,
  setName,
  handleMonthlyPrint,
}) {
  const handleChange = (newValue) => {
    setMonth(new Date(newValue));
  };

  return (
    <div className="w-full ">
      <Card>
        <div className="p-4 lg:flex">
          <div className="w-full">
            <TextField
              id="outlined-basic"
              label="ค้นหาชื่อพนักงาน"
              size="small"
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </div>
          <div className="w-1/3 lg:px-2">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="เลือกเดือน"
                inputFormat="MM/yyyy"
                views={['year', 'month']}
                value={month}
                onChange={handleChange}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="px-1">
            <Button
              variant="contained"
              color="success"
              onClick={() => handleMonthlyPrint()}
            >
              {' '}
              พิมพ์
            </Button>
          </div>
        </div>
      </Card>{' '}
    </div>
  );
}
