import React, { useState, useEffect } from 'react';
import { Button, CardContent, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import { Paper } from '@mui/material';
import Add from '@mui/icons-material/Add';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { Controller, useForm } from 'react-hook-form';
import { Card } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../../redux/action';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useParams } from 'react-router-dom';

function User() {
  const { handleSubmit, control, reset } = useForm({ mode: 'onChange' });
  const [open, setOpen] = React.useState(false);
  const [role, setRole] = useState('');
  const axios = require('axios');
  const params = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    console.log(process.env.REACT_APP_API_URL);
    dispatch(action.getAllUser());
  }, []);
  console.log('user', user);
  const [password, setPassword] = useState({
    password: '',
    showPassword: false,
  });
  const defaultValue = {
    name: '',
    tel: '',
    email: '',
    username: '',
  };
  console.log('xxx', role);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (prop) => (event) => {
    setPassword({ password: event.target.value, showPassword: false });
  };

  const handleClickShowPassword = () => {
    setPassword({
      ...password,
      showPassword: !password.showPassword,
    });
  };
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  function AddUsers(data) {
    dispatch(action.addUser(data));
  }
  function getUser() {
    dispatch(action.getAllUser());
  }
  function deleteUser(id) {
    getUser();
    dispatch(action.deleteUser(id));
    getUser();
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function onSubmit(data) {
    const payload = {
      ...data,
      password: password?.password,
    };
    console.log(data);
    AddUsers(payload);
    reset(defaultValue);
    setPassword('');
    handleClose();
    getUser();
  }

  return (
    <div className="min-h-screen">
      <div className="pt-16">
        <Button
          variant="contained"
          startIcon={<Add />}
          color="primary"
          onClick={handleOpen}
        >
          เพิ่ม
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Button onClick={handleClose}>closed</Button>
              <Card>
                <CardContent>
                  <div>
                    <h1 className="text-xl text-gray-700 ">
                      สร้างบัญชีผู้ใช้งาน
                    </h1>
                  </div>
                  <div className="mt-8">
                    <Controller
                      name="name"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          id="outlined-basic"
                          label="name"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    <div className="flex">
                      {' '}
                      <Controller
                        name="email"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />{' '}
                      <Controller
                        name="tel"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            id="outlined-basic"
                            label="Phone"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />{' '}
                    </div>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={role}
                        label="role"
                        onChange={handleRoleChange}
                      >
                        <MenuItem>USER</MenuItem>
                        <MenuItem>ADMIN</MenuItem>
                      </Select>
                    </FormControl>
                    <Controller
                      name="username"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          id="outlined-basic"
                          label="Username"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />{' '}
                    <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                    <Controller
                      name="password"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <OutlinedInput
                          {...field}
                          value={password.password}
                          id="outlined-adornment-password"
                          type={password.showPassword ? 'text' : 'password'}
                          onChange={handleChange('password')}
                          fullWidth
                          label="Password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {password.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      )}
                    />{' '}
                  </div>

                  <Button type="submit">Save</Button>
                </CardContent>
              </Card>
            </Box>{' '}
          </form>
        </Modal>
        <Paper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p className="font-bold"></p>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <p className="font-bold">name</p>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <p className="font-bold">Tel.</p>
                  </TableCell>
                  <TableCell>
                    <p className="font-bold">Role</p>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user?.arr?.map((users) => (
                  <TableRow key={users.index}>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell>{users.name}</TableCell>
                    <TableCell>{users.tel}</TableCell>
                    <TableCell>{users.role}</TableCell>
                    <TableCell align="right">
                      <Stack
                        spacing={1}
                        direction="row"
                        className="justify-end"
                      >
                        {/* <Link to={`/sale/editquotation/${quotations?._id}`}>
                          <Button variant="contained" startIcon={<EditIcon />}>
                            แก้ไข
                          </Button>
                        </Link> */}
                        <Button
                          color="error"
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            deleteUser(users?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={quotation?.total}
            rowsPerPage={size}
            page={page - 1}
          /> */}
        </Paper>
      </div>
    </div>
  );
}
export default User;
