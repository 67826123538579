/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { useGeolocated } from 'react-geolocated';
import PropTypes from 'prop-types';
import { BackButton } from 'components/Button';
import Calhour from 'function/Calhour';
import Calmin from 'function/Calmin';
import CalOt from 'function/CalOt';
import CalOtMin from 'function/CalOtMin';
import _ from 'lodash';
import { ViewTitle } from 'components/ViewTitle';
// import haversine from 'haversine-distance';
import CalDistanceFunction from 'function/CalDistanceFunction';
import Loading from 'components/Loading';
import TimeStampCheckOutCard from '../../components/Card/TimeStampCheckOut';
import * as action from '../../redux/action';
import axios from 'axios';

export default function TimeStampCheckOut({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const checkIn = useSelector((state) => state.checkIn);
  const [imgSrc, setImgSrc] = useState();
  const timeStampSetting = useSelector((state) => state.TimeStampSetting);
  const { handleSubmit, control } = useForm({});
  const [selectedOutImage, setSelectedOutImage] = useState([]);
  const [date, setDate] = useState(new Date());
  const [place, setPlace] = useState();

  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();
  const [location, setLocation] = useState();
  const [noteCheckOut, setNoteCheckout] = useState();
  useEffect(() => {
    dispatch(action.getAllTimeStampSetting({ status: true }));
    dispatch(action.CheckInGet(params.id));
    return () => {};
  }, []);

  useEffect(() => {
    const time = axios.get(
      'https://timeapi.io/api/Time/current/zone?timeZone=Asia/Bangkok',
      {
        accept: 'application/json',
      }
    );
    console.log('time', time);
    return () => {};
  }, []);

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });
  // const handlePushBack = () => {
  //   history.goBack();
  // };

  console.log('Coord in  Check out ', coords);
  const locations = {
    longitude: coords?.longitude,
    latitude: coords?.latitude,
  };
  console.log('noteCheckOut', noteCheckOut);
  const sum_hours = Calhour(date, checkIn?.date);
  const sum_min = Calmin(date, checkIn?.date);
  const sum_ot_hours = CalOt(date, checkIn?.date);
  const sum_ot_min = CalOtMin(date, checkIn?.date);
  CalDistanceFunction(timeStampSetting?.arr, locations);

  useEffect(async () => {
    const result = await CalDistanceFunction(timeStampSetting?.arr, locations);
    setPlace(result);
    return () => {
      console.log('error ssss');
    };
  }, [timeStampSetting, locations]);
  console.log('Place  in Checkout', place);
  console.log(Location, locations);

  const timeOut = () => {
    const DateOut = new Date(checkIn?.date);
    const settingDate = new Date(place?.time_out);
    DateOut.setTime(settingDate.getTime());

    if (new Date() >= DateOut) {
      return new Date();
    }
    return DateOut;
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const onSubmitbill = async () => {
    if (place) {
      try {
        if (!imgSrc) {
          alert('กรุณาอัปโหลดรูป');
        } else {
          const confirm = window.confirm('ยืนยันบันทึกข้อมูล');
          if (confirm) {
            const payload = {
              // date_checkOut: timeOut(),
              image_checkOut: imgSrc,
              noteCheckOut: noteCheckOut,

              location_checkOut: {
                longitude: coords?.longitude,
                latitude: coords?.latitude,
              },
              checkOut_status: true,
              // sum_hours,
              // sum_min,
              // sum_ot_hours,
              // sum_ot_min,
              place_checkOut: place?._id,
            };
            await dispatch(action.CheckInPut(params.id, payload));
            await dispatch(action.CheckInGet(params.id));
            alert('ลงวลาออกสำเร็จ');
            history.goBack();
          }
        }
      } catch (error) {
        console.log('error', error);
        alert('บันทึกข้อมูลผิดพลาด');
      }
    } else {
      alert('คุณไม่อยู่ในระยะที่กำหนด');
    }
  };

  const renderCheck = () => (
    <form onSubmit={handleSubmit(onSubmitbill)}>
      <TimeStampCheckOutCard
        checkIn={checkIn}
        control={control}
        Controller={Controller}
        selectedOutImage={selectedOutImage}
        setSelectedOutImage={setSelectedOutImage}
        coords={coords}
        imgSrc={imgSrc}
        setImgSrc={setImgSrc}
        date={date}
        setDate={setDate}
        place={place}
        noteCheckOut={noteCheckOut}
        setNoteCheckout={setNoteCheckout}
      />
    </form>
  );

  if (
    !locations.latitude ||
    !locations.longitude ||
    !place ||
    !coords ||
    timeStampSetting.isLoading ||
    !timeStampSetting.isCompleted ||
    checkIn.isLoading ||
    !checkIn.isCompleted
  ) {
    return <Loading isLoading />;
  }
  if (
    !timeStampSetting.isLoading ||
    timeStampSetting.isCompleted ||
    !checkIn.isLoading ||
    checkIn.isCompleted
  ) {
    return (
      <div className="min-h-screen">
        <div>{renderTitle()}</div>
        <div className="p-2">
          <BackButton />
        </div>
        <div>{renderCheck()}</div>
      </div>
    );
  }
}
TimeStampCheckOut.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

TimeStampCheckOut.defaultProps = {
  title: '',
  subtitle: '',
};
