import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import * as actions from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Loading from 'components/Loading';

function LeaveTypeList({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const leaveType = useSelector((state) => state.leaveType);
  useEffect(() => {
    dispatch(actions.leaveTypeGetAll({}));
    return () => {};
  }, []);

  const handleDelete = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.leaveTypeDelete(id));
        await dispatch(actions.leaveTypeGetAll({ page, size }));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const renderButton = () => (
    <Button
      variant="contained"
      onClick={() => history.push('/hr/setting/leave-type/new')}
    >
      เพิ่ม
    </Button>
  );

  const renderTable = () => (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-aria-label="simple table">
            <colgroup>
              <col width="20%" />
              <col width="25%" />
              <col width="20%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h1 className="font-bold text-center "> ชื่อวันลา</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold text-center ">จำนวนโควตา</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">อายุงาน</h1>
                </TableCell>

                <TableCell>
                  <div> </div>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {_.isEmpty(leaveType?.arr) ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <h1 className=" text-center ">ไม่มีรายการ </h1>
                  </TableCell>
                </TableRow>
              ) : (
                leaveType?.arr?.map((e) => (
                  <TableRow key={e._id}>
                    <TableCell>
                      <h1 className=" text-center ">{e?.name}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">{e?.quantity} วัน</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">{e?.longevity} วัน</h1>
                    </TableCell>

                    <TableCell>
                      <div className="py-1 flex gap-2">
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={() =>
                            history.push(
                              '/hr/setting/leave-type/edit/' + e?._id
                            )
                          }
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleDelete(e?._id)}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 100]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={leaveType?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );

  if (leaveType.isLoading || !leaveType.isCompleted) {
    return <Loading isLoading={true} />;
  }
  if (!leaveType.isLoading || leaveType.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="py-4">{renderButton()}</div>
        </div>
        <div className="py-2">{renderTable()} </div>
      </div>
    );
  }
}

LeaveTypeList.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
LeaveTypeList.defaultProps = {
  title: '',
  subtitle: '',
};

export default LeaveTypeList;
