import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _ from 'lodash';
// components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardContent,
  Paper,
} from '@mui/material';

import * as action from '../../redux/action';
import { ViewTitle } from 'components/ViewTitle';
import OverAllCahrt from 'components/Chart/OverAllCahrt';

export default function Dashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const receipt = useSelector((state) => state.receipt);
  const invoice = useSelector((state) => state.invoice);
  const pickUpOrder = useSelector((state) => state.pickUpOrder);
  const salary = useSelector((state) => state.Salary);
  // const user = useSelector((state) => state.user);
  const invoiceFillter = _.filter(invoice?.arr, { status_receipt: false });

  useEffect(() => {
    dispatch(action.getAllReceipt({ name: '', page: 1, size: 100000000 }));
    dispatch(action.getAllInvoice({ name: '', page: 1, size: 100000000 }));
    dispatch(action.getAllUser({ name: '', page: 1, size: 100000000 }));
    dispatch(action.getAllPickUpOrder({ name: '', page: 1, size: 100000000 }));
    dispatch(action.getAllSalary({ name: '', page: 1, size: 100000000 }));
  }, []);
  const sumAllPrice = () => {
    if (receipt?.arr?.length === 0) {
      return 0;
    }
    const sumP = _.sumBy(receipt?.arr, (each) => each?.allPrice);
    return sumP;
  };
  const sumPriceFromIN = () => {
    if (receipt?.arr?.length === 0) {
      return 0;
    }
    const sumP = _.sumBy(invoiceFillter, (each) => each?.allPrice);
    return sumP;
  };

  const sumPriceFromRi = () => {
    if (pickUpOrder?.arr?.length === 0) {
      return 0;
    }
    const sumP = _.sumBy(pickUpOrder?.arr, (each) => each?.allPrice);
    return sumP + sumSalary();
  };
  console.log('salary', salary?.arr);
  const sumSalary = () => {
    if (salary?.arr?.length === 0) {
      return 0;
    }
    const sumP = _.sumBy(salary?.arr, (each) => each?.sumSalary);
    return sumP;
  };
  const sumSSO = () => {
    if (salary?.arr?.length === 0) {
      return 0;
    }
    const sumP = _.sumBy(salary?.arr, (each) => each?.sumSSO);
    return sumP;
  };
  const allSalary = sumSalary();
  const revenue = sumAllPrice() - sumPriceFromRi() - sumSalary();

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div className="min-h-screen ">
      <div>{renderTitle()}</div>
      <div className="bg-red-600 text-white ">
        <div className="p-2">โมดูลกำลังพัฒนา</div>
      </div>

      <div>
        <div>
          <div className="lg:flex pt-2">
            <div className="lg:w-1/2">
              <div className="lg:grid grid-cols-2 gap-2">
                <div>
                  <Card
                    sx={{
                      height: 200,
                      width: 350,
                    }}
                  >
                    <CardContent>
                      <div className="flex justify-between">
                        <h1 className="font-bold text-gray-600">
                          สรุปรายรับ-จ่าย
                        </h1>
                        <i class="fas fa-coins text-2xl text-gray-700 "></i>
                      </div>

                      <div className="flex">
                        <h1 className="text-5xl  font-bold  text-gray-700 mt-8">
                          {revenue < 0 ? (
                            <h1 className="text-5xl  font-bold text-center text-red-700 ">
                              {revenue
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </h1>
                          ) : (
                            <h1 className="text-5xl  font-bold text-center text-green-700 ">
                              {revenue
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </h1>
                          )}
                        </h1>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div>
                  <Card
                    sx={{
                      height: 200,
                      width: 350,
                    }}
                  >
                    <CardContent>
                      <div className="flex justify-between">
                        <h1 className="font-bold text-gray-600">รายได้รวม</h1>
                        <i class="fas fa-coins text-2xl text-gray-700 "></i>
                      </div>

                      <div className="flex">
                        <h1 className="text-5xl  font-bold  text-gray-700 mt-8">
                          {sumAllPrice()
                            ?.toFixed(2)
                            ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </h1>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div>
                  <Card
                    sx={{
                      height: 200,
                      width: 350,
                    }}
                  >
                    <CardContent>
                      <div className="flex justify-between">
                        <h1 className="font-bold text-gray-600">
                          รายได้ค้างรับ
                        </h1>
                        <i class="fas fa-coins text-2xl text-gray-700 "></i>
                      </div>

                      <div className="flex">
                        <h1 className="text-5xl  font-bold  text-gray-700 mt-8">
                          {sumPriceFromIN()
                            ?.toFixed(2)
                            ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </h1>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div>
                  <Card
                    sx={{
                      height: 200,
                      width: 350,
                    }}
                  >
                    <CardContent>
                      <div className="flex justify-between">
                        <h1 className="font-bold text-gray-600">รายจ่าย</h1>
                        <i class="fas fa-coins text-2xl text-gray-700 "></i>
                      </div>

                      <div className="flex">
                        <h1 className="text-5xl  font-bold  text-gray-700 mt-8">
                          {sumPriceFromRi()
                            ?.toFixed(2)
                            ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </h1>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
              <div className="pt-4">
                <Card>
                  <CardContent>
                    <div className="bg-indigo-700 ">
                      <h1 className="text-2xl p-2 text-white font-bold">
                        รายการเงินเดือน
                      </h1>
                    </div>
                    <div>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 50 }} aria-label="simple table">
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <h1 className="text-sm font-bold">
                                  ค่าประกันสังคมรวม
                                </h1>
                              </TableCell>
                              <TableCell align="left">
                                <div className="tex-left text-sm font-bold">
                                  {sumSSO()
                                    ?.toFixed(2)
                                    ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <h1 className=" text-xl font-bold">
                                  เงินเดือนพนักงานรวม
                                </h1>
                              </TableCell>
                              <TableCell>
                                <h1 className=" text-xl font-bold">
                                  {allSalary
                                    ?.toFixed(2)
                                    ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                </h1>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            <div className="w-1/2 pl-4">
              <Card>
                <CardContent>
                  <div className="  justify-center">
                    <OverAllCahrt
                      receipt={receipt}
                      pickUpOrder={pickUpOrder?.arr}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
