import _ from 'lodash';
import Sumprice from './Sumprice';
import SumDiscount from './SumDiscount';

export default function SumAllPrice(productList) {
  if (productList?.length === 0) {
    return 0;
  } else {
    return Sumprice(productList) - SumDiscount(productList);
  }
}
