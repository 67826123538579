import React from 'react';
import dayjs from 'dayjs';
export default function CalOtMin(date1, date2) {
  const ot = dayjs(date1).diff(dayjs(date2), 'h') - 8;
  const sum =
    dayjs(date1).diff(dayjs(date2), 'm') -
    dayjs(date1).diff(dayjs(date2), 'h') * 60;
  if (ot > 0) {
    return sum;
  }
  return 0;
}
