import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import * as action from '../../redux/action';
import EditWork from '../../components/Forms/EditWork';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';

export default function WorkEdit({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const user = useSelector((state) => state.user);
  const work = useSelector((state) => state.work);
  const { control, setValue, handleSubmit } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'workerArray',
  });
  const [date, setDate] = React.useState();
  useEffect(() => {
    dispatch(action.getOnework(params.id));
    dispatch(action.getAllUser({}));
    return () => {};
  }, []);
  const onSubmit = async (data) => {
    const confirm = window.confirm('ยืนยัน');
    if (confirm) {
      try {
        const statusBoolean = {
          allDay: false,
          workAllDay: false,
          halfDay: false,
          dayOff: false,
          dayOffHalfDay: false,
          laeveWork: false,
        };

        switch (data?.status) {
          case 'allDay':
            statusBoolean.allDay = true;
            break;
          case 'workAllDay':
            break;
          case 'halfDay':
            statusBoolean.halfDay = true;
            break;
          case 'dayOff':
            statusBoolean.dayOff = true;
            break;
          case 'dayOffHalfDay':
            statusBoolean.dayOffHalfDay = true;
            break;
          case 'laeveWork':
            statusBoolean.laeveWork = true;
            break;
          default:
            break;
        }
        console.log('data', data);
        const dataSubmit = {
          ...data,
          date,
          worker: data?.workerArray,
          name: data?.job,
          ...statusBoolean,
          allDay: statusBoolean?.allDay,
          halfDay: statusBoolean?.halfDay,
          dayOff: statusBoolean?.dayOff,
          dayOffHalfDay: statusBoolean?.dayOffHalfDay,
          laeveWork: statusBoolean?.laeveWork,
        };

        await dispatch(action.putwork(params.id, dataSubmit));
        await dispatch(action.getAllwork({}));
        history.goBack();
      } catch (error) {
        console.log(error);
      }
    }
  };
  const props = {
    handlButtonDelete: async (id) => {
      const confirm = window.confirm('ยืนยันลบข้อมูล');
      if (confirm) {
        try {
          await dispatch(action.deletework(id));
          await dispatch(action.getAllwork({}));
          history.push('/work/calendar');
        } catch (error) {
          console.log(error);
        }
      }
    },
  };
  const renderEdit = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditWork
        control={control}
        Controller={Controller}
        work={work}
        setValue={setValue}
        fields={fields}
        append={append}
        remove={remove}
        user={user}
        date={date}
        //  statusBoolean={statusBoolean}
        setDate={setDate}
        {...props}
      />
    </form>
  );
  const renderViewTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderViewTitle()}

      <BackButton />

      {renderEdit()}
    </div>
  );
}
