import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// components
import Sidebar from 'components/common/Sidebar/Sidebar';
import FooterAdmin from 'components/common/Footers/FooterAdmin.js';
// views
import Loading from 'components/Loading';
import * as actions from '../redux/action';
import { HomeNavbar } from 'components/Navbars';
import Project from '../views/Pm/Project';

export default function EmployeeLayout() {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  useEffect(() => {
    dispatch(actions.getMe());
    dispatch(actions.getAllInformation());
    return () => {};
  }, []);

  if (me.isLoading) {
    return <Loading isLoading />;
  }
  if (!me.isLoading) {
    return (
      <div className="min-h-screen h-full">
        <Sidebar />
        <div className="pt-4 hidden md:block ">
          <HomeNavbar onMobileNavOpen={() => {}} />
        </div>
        <div className="relative md:ml-64 bg-gray-200 min-h-screen  pt-6 md:px-8">
          <div className="px-4 md:px-10 mx-auto w-full min-h-screen ">
            <Switch>
              {/* <Route path="/project/home" exact>
                <Project title="จัดการโครงการ" />
              </Route> */}
            </Switch>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}
