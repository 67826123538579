import { Card, Button } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
// import ImageUpload from '../../components/ImageUpload/ImageUpload';
import Cameras from '../Camera/Cameras';
export default function TimeStampCard({
  me,
  selectedImage,
  setSelectedImage,
  coords,
  date,
  place,
  imgSrc,
  setImgSrc,
  noteCheckin,
  setNoteCheckin,
}) {
  const { handleSubmit, control, setValue, watch } = useForm({});
  const [ctime, setTime] = useState(new Date().toLocaleTimeString());
  const [note, setNote] = useState();
  const UpdateTime = () => {
    const time = new Date().toLocaleTimeString();
    setTime(time);
  };
  setInterval(UpdateTime);
  return (
    <div>
      <Card>
        <div className="mt-2 p-4 max-h-80 overflow-auto ">
          <div className="flex justify-center">
            <div className="bg-green-500 text-white p-2 rounded-full ">
              ลงเวลาเข้างาน
            </div>
          </div>
          <div className="w-full flex justify-center px-1 py-2 ">
            <h1 className="font-bold text-xl  ">
              ชื่อ : {me?.userData?.name} {me?.userData?.surname || ''}
            </h1>
          </div>
          <div className="w-full flex justify-center px-1 py-2 ">
            <h1 className="font-bold text-xl text-center ">
              สถานที่เข้างาน
              {place?.name ? (
                <div className="text-green-600">{place?.name}</div>
              ) : (
                <div className="text-red-500">คุณไม่อยู่ในระยะที่กำหนด</div>
              )}
            </h1>
          </div>
          <div className="w-full flex justify-center px-1 py-2">
            <div>
              <h1 className="font-bold text-xl ">
                วันที่ : {dayjs(date).format('DD/MM/BB ')}
              </h1>
              <div className="font-bold text-xl "> เวลา : {ctime}</div>
            </div>
          </div>
          <div className="flex justify-center ">
            <div className="m-12">
              <Cameras imgSrc={imgSrc} setImgSrc={setImgSrc} />
            </div>
          </div>
          <div className="px-12  mx-8">
            <div className="px-4 font-bold">หมายเหตุ</div>
            <div className="px-4  py-2">
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                onChange={(e) => setNoteCheckin(e.target.value)}
                fullWidth
              />
            </div>
          </div>
          <div className="px-4">
            <h1 className="text-red-700 px-12  mx-8">
              หากตรวจพบการทุจริต การเข้างานครั้งนี้จะถูกยกเลิกทันที
            </h1>
          </div>
          <div className=" flex justify-center ">
            <div className="px-4 py-4 w-1/2  ">
              <Button variant="contained" type="submit" fullWidth>
                บันทึก
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );

  /* <div className="w-full   px-1 py-2">
              <h1 className="font-bold">
                ตำแหน่ง: latitude {coords?.latitude},{coords?.longitude}{' '}
              </h1>
            </div> */
}
