import {
  PRODUCT_ALL,
  PRODUCT_DELETE,
  PRODUCT_GET,
  PRODUCT_LOADING,
  PRODUCT_POST,
  PRODUCT_PUT,
} from '../type';
const initialState = {
  Product: null,
  isLoading: true,
  isCompleted: true,
};

function ProductReducer(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_LOADING:
      return { isLoading: true, isCompleted: false };
    case PRODUCT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PRODUCT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PRODUCT_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PRODUCT_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case PRODUCT_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default ProductReducer;
