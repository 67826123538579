import { Button, Card } from '@mui/material';
import React from 'react';
import _ from 'lodash';

export default function NewsRender({ news, onDelete }) {
  return (
    <div>
      {_.isEmpty(news) ? (
        <Card className="text-center font-bold p-2 "> ไม่มีประกาศ </Card>
      ) : (
        <div>
          {_?.map(news, (each, index) => (
            <div className="py-2">
              <Card className="p-2 ">
                <div>
                  <b>{each?.head}</b>
                  <div className="text-gray-600">{each?.topic}</div>
                </div>
                <div className="flex justify-end py-2 ">
                  <div className="text-xs py-2 ">
                    สร้างโดย {each?.createdBy?.name} {each?.createdBy?.surname}{' '}
                  </div>
                  <Button
                    color="error"
                    onClick={() => onDelete(each?._id)}
                    size="small"
                  >
                    ลบ
                  </Button>
                </div>
              </Card>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
