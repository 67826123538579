import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import PrintIcon from '@mui/icons-material/Print';
import { Card, CardContent, TextField } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { useHistory } from 'react-router';
import { ViewTitle } from 'components/ViewTitle';
import Loading from 'components/Loading';
import * as action from '../../../redux/action';
import QtReport from '../../../components/Report/InReport';
import InReportUni from '../../../components/Report/InReportUni';
import AllReport from 'components/Report/AllReport';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function IndexQT({ title, subtitle }) {
  const dispatch = useDispatch();
  dayjs.extend(buddhistEra);
  const history = useHistory();
  const me = useSelector((state) => state.me);
  const invoice = useSelector((state) => state.invoice);
  const information = useSelector((state) => state.information);
  const [findName, setFindName] = useState('');
  const [selectInvoice, setSelectInvoice] = useState();
  const [checked, setChecked] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(5);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = React.useState(false);
  const me1 = me?.userData?.signature;
  //console.log('me1', me1);
  //console.log('me', me);
  useEffect(() => {
    dispatch(action.getMe());
    dispatch(action.getAllInformation());
    dispatch(action.getAllInvoice({ name: findName, page, size }));
    return () => {};
  }, [findName, page, size]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };

  const handlePrintQtReport = async (data) => {
    QtReport(me1, selectInvoice, information, checked);
  };
  const handlePrintQtReportUni = (data) => {
    InReportUni(me1, selectInvoice, information, checked);
    console.log('Select', selectInvoice);
  };
  const handlePrintQtReportAll = async (data) => {
    AllReport();
  };
  // console.log("information", information?.arr?.[0]);
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const DeleteProduct = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(action.deleteInvoice(id));
        // console.log("Delete Success");
        await dispatch(action.getAllInvoice({ findName, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };
  const onCreateRe = (id) => {
    history.push(`/sale/newre/${id}`);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderAddButton = () => (
    <Button
      variant="contained"
      onClick={() => history.push('/sale/newinvoice')}
    >
      เพิ่ม
    </Button>
  );
  const renderprintAll = () => (
    <Button
      variant="contained"
      onClick={() => {
        handlePrintQtReportAll();

        handleClose();
      }}
    >
      พิมพ์ใบเสร็จรับเงิน
    </Button>
  );

  const rendermodal = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="p-8">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              พิมพ์ใบแจ้งหนี้/ใบส่งของ
              <br />
              <br />
              <Checkbox
                label="มีลายเซ็นต์"
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              เพิ่มลายเซ็นอิเล็กทรอนิกส์
              <div className="text-center pt-8">
                <Button
                  size="large"
                  className="text-center"
                  variant="contained"
                  color="success"
                  onClick={() => {
                    if (selectInvoice?.statusTaxUni === true) {
                      handlePrintQtReportUni();
                    } else {
                      handlePrintQtReport();
                    }

                    handleClose();
                  }}
                >
                  พิมพ์
                </Button>
              </div>
            </Typography>
          </Box>
        </div>
      </Modal>
    </div>
  );

  if (invoice.isLoading) {
    return (
      <div>
        <Loading isLoading />
      </div>
    );
  }
  return (
    <div>
      {rendermodal()}
      <div className="flex justify-between">
        {renderTitle()}
        <div className="py-4">{renderAddButton()}</div>
      </div>
      <Card className="mb-2">
        <CardContent>
          <div>
            <div className="flex gap-1 justify-between">
              <div className="w-3/4">
                <TextField
                  id="outlined-basic"
                  label="ค้นหา"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setFindName(e.target.value)}
                  fullWidth
                />
              </div>

              {/* <div className="flex justify-center">{renderprintAll()}</div> */}
            </div>
          </div>
        </CardContent>
      </Card>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <p className="font-bold">เลขที่</p>
                </TableCell>
                <TableCell align="center">
                  <p className="font-bold">วันที่</p>
                </TableCell>
                <TableCell align="center">
                  <p className="font-bold">ชื่อลูกค้า</p>
                </TableCell>
                <TableCell align="center">
                  <p className="font-bold">เบอร์โทร</p>
                </TableCell>
                <TableCell align="center">
                  <p className="font-bold">ดำเนินการ</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoice.arr?.map((invoices) => (
                <TableRow key={invoices._id}>
                  <TableCell component="th" scope="row" align="center">
                    <Link to={`/sale/editinvoice/${invoices?._id}`}>
                      {invoices.prefix + invoices.running_number}{' '}
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    <Link to={`/sale/editinvoice/${invoices?._id}`}>
                      {dayjs(invoices.date).format('DD/MM/BB')}
                    </Link>{' '}
                  </TableCell>
                  <TableCell align="center">
                    <Link to={`/sale/editinvoice/${invoices?._id}`}>
                      {invoices.customer?.businessname}
                    </Link>{' '}
                  </TableCell>
                  <TableCell align="center">{invoices.customer?.tel}</TableCell>
                  <TableCell align="center">
                    <Stack
                      spacing={1}
                      direction="row"
                      className="justify-center"
                    >
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<PrintIcon />}
                        // onClick={() => {
                        //   // console.log("print", invoices);
                        //   handlePrintQtReport(invoices);
                        // }}
                        onClick={() => {
                          handleOpen();
                          setSelectInvoice(invoices);
                        }}
                      >
                        พิมพ์
                      </Button>

                      <Button
                        variant="contained"
                        startIcon={<DescriptionIcon />}
                        onClick={() => {
                          onCreateRe(invoices?._id);
                        }}
                      >
                        สร้างใบเสร็จรับเงิน
                      </Button>

                      <Button
                        color="error"
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          DeleteProduct(invoices?._id);
                        }}
                      >
                        ลบ
                      </Button>
                    </Stack>
                  </TableCell>{' '}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={invoice?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );
}
export default IndexQT;
