import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/index.css';
//PrivateRoute Contexts
// import Auth from './layouts/Auth.js';
import Sale from './layouts/Sale.js';
import Customer from './layouts/Customer.js';
import Product from 'layouts/Product';
import Employee from 'layouts/employee';
import Hr from 'layouts/Hr';
import Purchase from 'layouts/Purchase';
import Salary from 'layouts/Salary';
import Work from './layouts/Work';
import Home from './views/Home.js';
import Project from 'layouts/Project';
//AuthProvider Contexts
// layouts
import Admin from './layouts/Admin.js';

// views without layouts
import Landing from './views/Landing.js';
import Profile from './views/Profile.js';
import Login from './views/Login.js';
import PrivateRoute from './contexts/PrivateRoute.js';

import configurStore from './redux/configurStore';

export default function App() {
  const store = configurStore();

  return (
    <Router>
      <Provider store={store}>
        <Switch>
          {/* add routes with layouts */}
          <Route path="/" exact component={Login} />
          <PrivateRoute>
            {/* add routes without layouts */}
            <Route exact path="/home" component={Home} />
            <Route path="/landing" exact component={Landing} />
            <Route path="/profile" exact component={Profile} />
            <Route path="/admin" component={Admin} />
            <Route path="/sale" component={Sale} />
            <Route path="/salary" component={Salary} />
            <Route path="/purchase" component={Purchase} />
            <Route path="/hr" component={Hr} />
            <Route path="/employee" component={Employee} />
            <Route path="/customer" component={Customer} />
            <Route path="/project" component={Project} />
            <Route path="/product" component={Product} />
            <Route path="/work" component={Work} />
          </PrivateRoute>
          {/* add redirect for NotFound page */}
          <Redirect from="*" to="/" />
        </Switch>
      </Provider>
    </Router>
  );
}
