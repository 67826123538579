import {
  RECEIPT_ALL,
  RECEIPT_DELETE,
  RECEIPT_GET,
  RECEIPT_LOADING,
  RECEIPT_POST,
  RECEIPT_PUT,
} from '../type';
const initialState = {
  reciept: null,
  isLoading: true,
  isCompleted: true,
};

function ReceiptReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIPT_LOADING:
      return { isLoading: true, isCompleted: false };
    case RECEIPT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case RECEIPT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case RECEIPT_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case RECEIPT_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case RECEIPT_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default ReceiptReducer;
