import dayjs from 'dayjs';
export default function Calmin(date1, date2) {
  const sum =
    dayjs(date1).diff(dayjs(date2), 'm') -
    dayjs(date1).diff(dayjs(date2), 'h') * 60;
  const hours = dayjs(date1).diff(dayjs(date2), 'h');
  if (hours <= 8) {
    return sum;
  }
  return 0;
}
