import { Card, CardContent, TextField, Button } from '@mui/material';
import React, { Children } from 'react';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';

const defaultValue = {
  productName: '',
  productQuality: '',
  productUnit: '',
  productPrice: '',
  productDiscount: '0',
  productabout: '',
};

function FormProduct({
  children,
  setProductList,
  setReload,
  productList,
  reload,
  setOpen,
  product,
}) {
  const { control, setValue, getValues, reset } = useForm({});

  const handleAddProductList = (data) => {
    if (
      getValues('name') === '' ||
      getValues('quantity') === '' ||
      getValues('unit') === '' ||
      getValues('price') === ''
    ) {
      alert('กรุณากรอกข้อมูลให้ครบ');
    } else {
      const productObj = {
        name: getValues('name'),
        quantity: getValues('quantity'),
        unit: getValues('unit'),
        price: getValues('price'),
        about: getValues('about'),
        discount: getValues('discount'),
      };

      productList.push(productObj);
      console.log('productList in submit', productList);
      setProductList(productList);
      setOpen(false);
      reset(defaultValue);
      setReload(!reload);
    }
  };
  return (
    <>
      <div>{children}</div>
      <Card className="">
        <CardContent>
          <Controller
            name="name"
            control={control}
            // defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="outlined-basic"
                label="ชื่อสินค้า"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          />{' '}
          <div className="flex">
            <Controller
              name="quantity"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="จำนวน"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              )}
            />{' '}
            <Controller
              name="unit"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="หน่วย"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              )}
            />{' '}
            <Controller
              name="price"
              control={control}
              required
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="ราคาต่อหน่วย"
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                />
              )}
            />{' '}
            <Controller
              name="discount"
              control={control}
              required
              defaultValue="0"
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="ส่วนลด"
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                />
              )}
            />{' '}
          </div>
          <Controller
            name="about"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="outlined-multiline-basic"
                label="รายละเอียดสินค้า"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
              />
            )}
          />{' '}
          <div className="w-full">
            <Button onClick={() => handleAddProductList()}>เพิ่ม</Button>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default FormProduct;
