import CalQuotaOption from './CalQuotaOption';
import _ from 'lodash';

export default function CalRemain(inputLeavetype, me) {
  console.log('inputLeaveytpe', inputLeavetype);
  if (inputLeavetype?.options?.length > 0) {
    return CalQuotaOption(me, inputLeavetype);
  } else {
    return (
      (inputLeavetype?.quantity || inputLeavetype?._id?.quantity) -
      (inputLeavetype?.sum || 0)
    );
  }

  // const leaveType = _.isObject(inputLeavetype?._id)
  //   ? { ...inputLeavetype, ...inputLeavetype?._id }
  //   : inputLeavetype;
  // if (leaveType?.options?.length === 0) {
  //   return leaveType?.remain ? leaveType?.remain : leaveType?.quantity;
  // } else {
  //   return CalQuotaOption(me, leaveType) - (leaveType?.sum || 0);
  // }
}
