import {
  INVOICE_ALL,
  INVOICE_DELETE,
  INVOICE_GET,
  INVOICE_LOADING,
  INVOICE_POST,
  INVOICE_PUT,
  INVOICE_ERROR,
} from '../type';
import api from '../../utils/functions/api';

export const getAllInvoice = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/invoice?name=${name}&size=${size}&page=${page}`
    );
    if (status === 200) {
      dispatch({ type: INVOICE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: INVOICE_ERROR });
  }
};
export const getOneInvoice = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/invoice/${id}`
    );
    if (status === 200) {
      dispatch({ type: INVOICE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: INVOICE_ERROR });
  }
};

export const deleteInvoice = (id) => async (dispatch) => {
  try {
    dispatch({ type: INVOICE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/invoice/${id}`
    );
    dispatch({ type: INVOICE_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: INVOICE_ERROR });
  }
};

export const addInvoice = (payload) => async (dispatch) => {
  try {
    dispatch({ type: INVOICE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/invoice`,
      {
        ...payload,
      }
    );
    dispatch({ type: INVOICE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: INVOICE_ERROR });
  }
};

export const putInvoice = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: INVOICE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/invoice/${id}`,
      payload
    );
    dispatch({ type: INVOICE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: INVOICE_ERROR });
  }
};
