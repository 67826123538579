import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { Button, Card, CardContent, TextField } from '@mui/material';

import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import FormPurchaseProduct from 'components/Forms/FormPurchaseProduct';
import Stack from '@mui/material/Stack';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory } from 'react-router-dom';
/// /Modal
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ProductServe from 'components/Forms/FormProductArray';
import * as action from '../../../redux/action';
import SumAllPrice from 'components/Card/SumAllPrice';
import Sumprice from 'components/Card/Sumprice';
import SumDiscount from 'components/Card/SumDiscount';
function NewPurchase() {
  const { handleSubmit, control, setValue, watch } = useForm({});
  const [date, setDate] = React.useState(new Date());
  const [dueDate, setDueDate] = React.useState(new Date());
  const [productList, setProductList] = useState([]);
  const [reload, setReload] = useState(false);
  const [findName, setFindName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [productVat, setProductVat] = React.useState(false);
  const history = useHistory();
  dayjs.extend(buddhistEra);
  // console.log(dayjs(date).format(`BBMMDD`));
  // post data

  const dispatch = useDispatch();
  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /// /////
  const onSubmitQuotation = async (data) => {
    if (productList.length === 0) alert('กรุณาเพิ่มสินค้า');
    else {
      try {
        const ProcessData = await {
          ...data,
          date,
          dueDate,
          productVat,
          prefix: `PO${dayjs(date).format('BB')}-`,
          allPrice: SumAllPrice(productList),
          product: data?.productArray,
        };
        await dispatch(action.addPurchase(ProcessData));
        await dispatch(action.getAllPurchase({ name: findName, page, size }));
        history.push('/purchase/order');
      } catch (error) {}
    }
  };
  // axios

  ///

  useEffect(() => {}, []);

  const productDelete = (index) => {
    productList.splice(index, 1);
    setProductList(productList);
    setReload(!reload);
  };

  const Price = () => {
    if (productList?.length === 0) {
      return 0;
    }
    return Sumprice(productList) - SumDiscount(productList);
  };

  const taxPrice = () => {
    if (productVat) {
      return 0;
    } else {
      return Price() * 0.07;
    }
  };

  const allPrice = () => {
    return Price() + taxPrice();
  };

  useEffect(
    () =>
      // console.log("product list", productList);
      () => {},
    []
  );

  return (
    <form onSubmit={handleSubmit(onSubmitQuotation)}>
      <div className="min-h-screen  pt-12 ">
        <div className=" ml-2 w-full rounded ">
          <h1 className="text-indigo-500  border-b-orange-500 text-4xl  font-bold ml-2">
            {' '}
            | ใบสั่งซื้อ
          </h1>
        </div>

        <div className="flex w-full pt-6 h-full">
          {' '}
          <div>
            <Card className="justify-center mt-2 ">
              <CardContent>
                <h1 className="pb-4"> ข้อมูลผู้ขาย</h1>
                <Controller
                  name="businessName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      value={field.value}
                      label="ชื่อผู้ขาย"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="TaxId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      label="หมายเลขผู้เสียภาษี"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="tel"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      id="outlined-basic"
                      label="เบอร์โทร"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="address"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      id="outlined-multiline-basic"
                      label="ที่อยู่"
                      variant="outlined"
                      fullWidth
                      multiline
                      margin="normal"
                      rows={4}
                    />
                  )}
                />
              </CardContent>
            </Card>{' '}
          </div>
          <div className="่ w-full  pl-4 ">
            <div className="flex justify-end pb-4">
              <Stack spacing={2} direction="row">
                {' '}
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </Stack>
            </div>
            <div>
              <Card>
                <CardContent>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่"
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่วันที่กำหนดส่ง"
                      value={dueDate}
                      onChange={(newValue) => {
                        setDueDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>

                  <Controller
                    name="credit"
                    control={control}
                    defaultValue="-"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="จำนวนวันเครดิต"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="payment"
                    control={control}
                    defaultValue="โอน/เงินสด"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="วิธีการชำระเงิน"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </CardContent>
              </Card>
            </div>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  position: 'absolute',
                }}
              >
                <Typography id="modal-modal-title">
                  <FormPurchaseProduct
                    setOpen={setOpen}
                    setProductList={setProductList}
                    setReload={setReload}
                    productList={productList}
                    reload={reload}
                  />
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        <div>
          <div className="mt-2">
            {console.log('productVat', productVat)}
            <ProductServe
              control={control}
              setProductList={setProductList}
              setValue={setValue}
              watch={watch}
              setProductVat={setProductVat}
              productVat={productVat}
              statusHide={false}
              statusPickUp={true}
            />
          </div>
          <Card>
            <CardContent>
              <div className="flex w-full ">
                <div className="w-1/2">
                  {' '}
                  <Controller
                    name="note"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-multiline-basic"
                        label="หมายเหตุ"
                        variant="outlined"
                        fullWidth
                        multiline
                        margin="normal"
                        rows={4}
                      />
                    )}
                  />
                </div>

                <div className="flex justify-between  w-1/2 ml-2  ">
                  <div>
                    <div>รวมเงิน </div>
                    <div>ส่วนลดการค้า</div>
                    <div>เงินหลังหักส่วนลด</div>
                    <div>VAT 7%</div>
                    <div className="font-bold ">จำนวนเงินทั้งสิ้น </div>
                  </div>
                  <div className="text-right">
                    <div>
                      {Sumprice(productList)
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div>
                      {SumDiscount(productList)
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div>
                      {Price()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>
                    <div>
                      {taxPrice()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div className="font-bold ">
                      {allPrice()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </form>
  );
}
export default NewPurchase;
