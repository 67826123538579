import { useHistory, useParams } from 'react-router';
import { Card, CardContent, Button, TextField } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import * as action from '../../redux/action';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';

export default function ({ title }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { handleSubmit, control, reset, setValue } = useForm({
    mode: 'onSubmit',
  });

  function onSubmit(data) {
    const confirm = window.confirm('ยืนยันการเปลี่ยนรหัสผ่าน');
    if (confirm) {
      try {
        console.log(params.id, data);
        dispatch(action.putUser(params.id, data));
        history.goBack();
      } catch (error) {
        alert('เปลี่ยนรหัสผ่านไม่สำเร็จ');
      }
    }
  }

  const renderTitle = () => <ViewTitle title={title} />;

  const changepsw = () => (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <div>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    value={field.value}
                    size="small"
                    id="outlined-basic"
                    label="รหัสผ่านใหม่"
                    margin="dense"
                    variant="outlined"
                    type="password"
                    fullWidth
                  />
                )}
              />
              <div className="pt-4 text-center">
                <Button type="submit" variant="contained">
                  Save
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </form>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      <div className="py-1">
        <BackButton />
      </div>
      <div className="">
        <div>{changepsw()}</div>
      </div>
      <div />
    </div>
  );
}
