import {
  GROUP_ALL,
  GROUP_GET,
  GROUP_POST,
  GROUP_PUT,
  GROUP_DELETE,
  GROUP_LOADING,
  GROUP_ERROR,
} from '../type';

import api from '../../utils/functions/api';

export const groupCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GROUP_LOADING });
    const { data } = await api.post(`${process.env.REACT_APP_API_URL}/group`, {
      ...payload,
      // eslint-disable-next-line comma-dangle
    });
    dispatch({ type: GROUP_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: GROUP_ERROR });
  }
};

export const groupGetAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/group?name=${name}&size=${size}&page=${page}`
    );
    if (status === 200) {
      dispatch({ type: GROUP_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: GROUP_ERROR });
  }
};

export const groupGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/group/${id}`
    );
    if (status === 200) {
      dispatch({ type: GROUP_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: GROUP_ERROR });
  }
};

export const groupPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: GROUP_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/group/${id}`,
      payload
    );
    dispatch({ type: GROUP_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: GROUP_ERROR });
  }
};
export const groupDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: GROUP_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/group/${id}`
    );
    dispatch({ type: GROUP_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: GROUP_ERROR });
  }
};
