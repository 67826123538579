/* eslint-disable react/prop-types */
import {
  Table,
  TableHead,
  TableContainer,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  Button,
  TablePagination,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import _ from 'lodash';

export default function JobListTable({
  work,
  page,
  size,
  setPage,
  setSize,
  handleEdit,
  handleDelete,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            {/* <colgroup>
              <col width="90%" />
              <col width="10%" />
            </colgroup> */}
            <TableHead>
              <TableRow>
                <TableCell>
                  <h1 className="font-bold  text-center "> วันที่</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center "> งาน</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">ผู้ทำงาน</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">สถานที่</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold text-center "> ดำเนินการ</h1>
                </TableCell>
              </TableRow>
            </TableHead>

            {_.isEmpty(work?.arr) ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    ไม่มีรายการงาน
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {work?.arr?.map((e) => (
                  <TableRow key={e._id}>
                    <TableCell>
                      <h1 className=" text-center ">
                        {dayjs(e?.date).format('DD/MM/YYYY')}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1>{e?.name}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="">
                        <b>พนักงาน</b>
                        {e?.worker.map((worker) => (
                          <div>
                            - {worker?.name} {worker?.surname}{' '}
                          </div>
                        ))}
                        <b>แผนก</b>
                        {e?.department.map((e) => (
                          <div>- {e?.name}</div>
                        ))}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1>{e?.locationName || '-'}</h1>
                    </TableCell>
                    <TableCell align="center">
                      <div className="flex gap-1 justify-center  ">
                        <Button
                          onClick={() => handleEdit(e._id)}
                          color="warning"
                          variant="contained"
                        >
                          แก้ไข
                        </Button>
                        <Button
                          onClick={() => handleDelete(e._id)}
                          color="error"
                          variant="contained"
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 100]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={work?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );
}
