import axios from 'axios';
import {
  INFORMATION_ALL,
  INFORMATION_GET,
  INFORMATION_LOADING,
  INFORMATION_PUT,
} from '../type';

export const reset = () => (dispatch) => {
  dispatch({ type: INFORMATION_ALL, payload: [] });
};

export function getAllInformation() {
  return async function (dispatch) {
    reset();
    // console.log("param redux", params);
    axios
      .get(`${process.env.REACT_APP_API_URL}/information`)
      .then((response) => {
        dispatch({ type: INFORMATION_ALL, payload: response.data });
        // handle success
      })
      .catch((err) => {
        // console.error(err);
      });
  };
}

export function getOneInformation(id) {
  return async function (dispatch) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/information/${id}`)
      .then((response) => {
        dispatch({ type: INFORMATION_GET, payload: response.data });
        // handle success
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export function addInformation(ProcessData) {
  console.log('form action', ProcessData);
  return async function (dispatch) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/information/`, ProcessData)
      .then((response) => {
        // console.log("response: ", response);
        // do something about response
      })
      .catch((err) => {
        // console.error(err);
      });
  };
}

export function putInformation(id, ProcessData) {
  return async function (dispatch) {
    if (ProcessData.file) {
      console.log('With File Process');
      // Upload File First
      const formData = new FormData();
      formData.append('file', ProcessData.file);
      try {
        const { data: uploadData } = await axios.post(
          `${process.env.REACT_APP_API_URL}/upload/file`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );

        if (uploadData) {
          // console.log("Upload Data", uploadData);
          try {
            const { data: editedData } = await axios.put(
              `${process.env.REACT_APP_API_URL}/information/${id}`,
              {
                ...ProcessData,
                file: uploadData?.id,
              }
            );
            dispatch({ type: INFORMATION_PUT });
          } catch (err) {
            console.error(err);
            dispatch({ type: INFORMATION_LOADING });
          }
        }
      } catch (error) {
        console.error(error);
        dispatch({ type: INFORMATION_LOADING });
      }
    } else {
      console.log('Without file process');
      try {
        const { data: editedData } = await axios.put(
          `${process.env.REACT_APP_API_URL}/information/${id}`,
          ProcessData
        );
        // console.log("response: ", response);
        dispatch({ type: INFORMATION_PUT });
        // do something about response
      } catch (err) {
        console.error(err);
        dispatch({ type: INFORMATION_LOADING });
      }
    }
  };
}
