import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/action';
import NotAuthorized from 'components/Error/Error';
// components

import Sidebar from 'components/common/Sidebar/Sidebar';

import FooterAdmin from 'components/common/Footers/FooterAdmin.js';

// views

// import Dashboard from "../views/admin/Dashboard.js";
import PurchaseOrder from 'views/purcahse/PurchaseOrder/purchaseOrder';
import EditPurchase from 'views/purcahse/PurchaseOrder/editPurchase';
import NewPurchase from 'views/purcahse/PurchaseOrder/newPurchase';
import PickUpOrder from 'views/purcahse/pickUpOrder/pickUpOrder';
import CreatePickOrder from 'views/purcahse/PurchaseOrder/createPickOrder';
import NewPickUpOrder from 'views/purcahse/pickUpOrder/newPickUpOrder';
import EditPickUpOrder from 'views/purcahse/pickUpOrder/editPickUpOrder';
import Loading from 'components/Loading';
import { HomeNavbar } from 'components/Navbars';
export default function Purchase() {
  const module = 'PCD';
  const name = 'แผนกจัดซื้อ';
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(actions.getMe());
    dispatch(actions.getAllInformation());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading isLoading />;
  }

  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen h-full">
        <Sidebar />
        <div className="pt-4 hidden md:block ">
          <HomeNavbar onMobileNavOpen={() => {}} />
        </div>
        <div className="relative md:ml-64 bg-gray-200  min-h-screen  pt-6 md:px-8">
          {/* <AdminNavbar /> */}
          {/* Header */}

          <div className="py-4 w-full min-h-screen  ">
            <Switch>
              <Route path="/purchase/order" exact>
                <PurchaseOrder title="ใบสั่งซื้อ" subtitle={name} />
              </Route>
              <Route path="/purchase/order/new" exact component={NewPurchase}>
                <NewPurchase title="สร้างใบสั่งซื้อ" subtitle={name} />
              </Route>
              <Route path="/purchase/order/pickUp/:id" exact>
                <CreatePickOrder title="สร้างใบรับสินค้า" subtitle={name} />
              </Route>
              <Route
                path="/purchase/order/edit/:id"
                exact
                component={EditPurchase}
              >
                <EditPurchase title="แก้ไขใบสั่งซื้อ" subtitle={name} />
              </Route>
              <Route path="/purchase/pickUpOrder" exact>
                <PickUpOrder title="ใบรับสินค้า" subtitle={name} />
              </Route>
              <Route path="/purchase/pickUpOrder/new" exact>
                <NewPickUpOrder title="สร้างใบรับสินค้า" subtitle={name} />
              </Route>
              <Route path="/purchase/pickUpOrder/edit/:id" exact>
                <EditPickUpOrder title="แก้ไขใบสั่งซื้อ" subtitle={name} />
              </Route>
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        {/* Header */}

        <div className="px-4 md:px-10 mx-auto w-full ">
          <NotAuthorized />
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
