import React from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';

export default function CalQuotaOption(me, inputLeavetype) {
  const leaveType = _.isObject(inputLeavetype._id)
    ? inputLeavetype._id
    : inputLeavetype;
  const currentDate = dayjs().startOf('day');
  const datePass = dayjs(me?.userData?.datePass);
  const dayWork = currentDate.diff(datePass, 'day');
  console.log(leaveType?.name, 'dayDifference', dayWork);
  const sortOption = _.orderBy(leaveType?.options, ['quantity'], ['asc']);
  const filterOption = _.filter(sortOption, (each) => each.quantity <= dayWork);
  const maxOption = _.maxBy(filterOption, 'quantity');
  const sumDayQuota = maxOption?.longevity || 0;

  return sumDayQuota - (leaveType?.sum || 0);
}
