import {
  LEAVE_TYPE_ALL,
  LEAVE_TYPE_GET,
  LEAVE_TYPE_POST,
  LEAVE_TYPE_PUT,
  LEAVE_TYPE_DELETE,
  LEAVE_TYPE_LOADING,
  LEAVE_TYPE_ERROR,
} from '../type';

import api from '../../utils/functions/api';

export const leaveTypeCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: LEAVE_TYPE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/leave-type`,
      {
        ...payload,
        // eslint-disable-next-line comma-dangle
      }
    );
    dispatch({ type: LEAVE_TYPE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: LEAVE_TYPE_ERROR });
  }
};

export const leaveTypeGetAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/leave-type?name=${name}&size=${size}&page=${page}`
    );
    if (status === 200) {
      dispatch({ type: LEAVE_TYPE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LEAVE_TYPE_ERROR });
  }
};

export const leaveTypeGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/leave-type/${id}`
    );
    if (status === 200) {
      dispatch({ type: LEAVE_TYPE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LEAVE_TYPE_ERROR });
  }
};

export const leaveTypePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: LEAVE_TYPE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/leave-type/${id}`,
      payload
    );
    dispatch({ type: LEAVE_TYPE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: LEAVE_TYPE_ERROR });
  }
};
export const leaveTypeDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: LEAVE_TYPE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/leave-type/${id}`
    );
    dispatch({ type: LEAVE_TYPE_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: LEAVE_TYPE_ERROR });
  }
};
