/* eslint-disable import/prefer-default-export */
/* eslint-disable func-names */
import axios from 'axios';
import { ME_ERROR, ME_GET } from '../type';

export function getMe(userId = localStorage.getItem('userId')) {
  return async function (dispatch) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/${userId}`)
      .then((response) => {
        dispatch({ type: ME_GET, payload: response.data });
        // handle success
      })
      .catch((err) => {
        console.error(err);
        localStorage.removeItem('userId');
        localStorage.removeItem('Token');
        alert('โทเค็นหมดอายุ');
        window.location.reload();
        dispatch({ type: ME_ERROR });
      });
  };
}
