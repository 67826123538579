import {
  Table,
  TableHead,
  TableContainer,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  Button,
  TablePagination,
} from '@mui/material';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import React from 'react';
import _ from 'lodash';

import { useHistory } from 'react-router';

export default function CheckInlist({
  checkIn,
  page,
  size,
  setPage,
  setSize,
  disabledButton,
}) {
  const history = useHistory();
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const late = _?.filter(checkIn?.arr, (e) => e?.late_status === true);
  const normal = _?.filter(checkIn?.arr, (e) => e?.late_status === false);
  console.log('late', late);
  console.log('normal', normal);
  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" size="small">
            <colgroup>
              <col width="30%" />
              <col width="30%" />
              <col width="20%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h1 className="font-bold  text-center "> วันที่</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold text-center ">เวลาเข้างาน</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold text-center ">เวลาออกงาน</h1>
                </TableCell>

                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {checkIn?.arr?.length !== 0 ? (
              <TableBody>
                {checkIn?.arr?.map((e) => (
                  <TableRow key={e?._id}>
                    <TableCell>
                      <h1 className=" text-center ">
                        {dayjs(e?.date).format('DD/MM/YYYY')}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="text-center ">
                        {dayjs(e?.date).format('HH:mm')}
                      </h1>
                    </TableCell>
                    <TableCell>
                      {e?.checkOut_status ? (
                        <div>
                          <h1 className="text-center ">
                            {dayjs(e?.date_checkOut).format('HH:mm') ||
                              'ยังไม่เช็คชื่อ'}
                          </h1>
                        </div>
                      ) : (
                        <h1 className="text-red-500">ยังไม่เช็คชื่อออก</h1>
                      )}
                      {e?.ot_status && (
                        <h1 className="font-bold text-green-500  ">
                          อนุมัติโอที
                        </h1>
                      )}
                    </TableCell>

                    <TableCell>
                      <div className="py-1">
                        {!disabledButton && (
                          <Button
                            variant="contained"
                            size="small"
                            disabled={e?.checkOut_status || disabledButton}
                            onClick={() =>
                              history.push(`checkIn/out/${e?._id}`)
                            }
                          >
                            ลงชื่อออก
                          </Button>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    ไม่มีรายการ
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            <TableBody>
              <TableRow>
                <TableCell align="right">
                  <div className="font-bold">รวม</div>
                </TableCell>
                <TableCell align="right">
                  <div className="flex jusify-center">
                    <div className="font-bold pl-18">เข้างานปกติ</div>
                    <div className="pl-8">{normal?.length} ครั้ง</div>
                  </div>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>

            {/* <TableRow>
              <div className="flex grid-cols-5 gap-5">
                <div>1</div>
                <div>1</div>
                <div>1</div>
                <div>1</div>
                <div>1</div>
                <div>1</div>
              </div>
            </TableRow> */}
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">
                  <div className="flex jusify-center">
                    <div className="font-bold pl-22">เข้างานสาย</div>
                    <div className="pl-8">{late?.length} ครั้ง</div>
                  </div>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>

            {/* <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">
                  <div className="font-bold">เข้างานสาย</div>
                </TableCell>
                <TableCell align="left" colSpan={1}>
                  {late?.length} ครั้ง
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody> */}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 30, 100]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={checkIn?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );
}
CheckInlist.propTypes = {
  checkIn: PropTypes.object,

  page: PropTypes.object,
  size: PropTypes.object,

  setPage: PropTypes.object,
  setSize: PropTypes.object,
  disabledButton: PropTypes.bool,
};
CheckInlist.defaultProps = {
  checkIn: PropTypes.object,
  page: '7',
  size: null,
  setPage: null,
  setSize: null,
  disabledButton: false,
};
