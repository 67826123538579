import {
  Button,
  Card,
  CardContent,
  TextField,
  InputLabel,
} from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import * as action from '../../redux/action';

function AdEmployee() {
  dayjs.extend(buddhistEra);
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit, control, reset } = useForm({ mode: 'onChange' });

  const [value, setValue] = React.useState(null);
  const [role, setRole] = React.useState('USER');

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  async function onSubmit(data) {
    try {
      const payload = {
        ...data,
        dateStart: value,
        role,
      };

      console.log(payload);
      await dispatch(action.addUser(payload));
      await dispatch(action.getAllUser({ name: '', page: 1, size: '' }));
      history.push('/hr/employee');
    } catch (error) {}
  }

  return (
    <div className="min-h-screen ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-16">
          <Card>
            <CardContent>
              <div>
                <h1 className="text-xl text-gray-700 ">สร้างบัญชีผู้ใช้งาน</h1>
              </div>

              <div className="mt-8">
                <h1>ข้อมูลส่วนตัว</h1>
                <Controller
                  name="name"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      value={field.value}
                      id="outlined-basic"
                      label="ชื่อ-นามสกุล"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <div className="flex">
                  {' '}
                  <Controller
                    name="email"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />{' '}
                  <Controller
                    name="tel"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-basic"
                        label="เบอร์โทร"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />{' '}
                </div>
                <Controller
                  name="address"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      id="outlined-basic"
                      label="ที่อยู่"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={role}
                    label="role"
                    onChange={handleRoleChange}
                  >
                    <MenuItem value="USER">USER</MenuItem>
                    <MenuItem value="ADMIN">ADMIN</MenuItem>
                  </Select>
                </FormControl>
                <div className="pb-4 pt-4">ข้อมูลการทำงาน</div>
                <div className="flex">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่เริ่มงาน"
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div className="flex">
                  <div className="pr-2 w-1/3">
                    <Controller
                      name="under"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          id="outlined-basic"
                          label="สังกัด"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />{' '}
                  </div>
                  <div className="pr-2 w-1/3">
                    <Controller
                      name="position"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          id="outlined-basic"
                          label="ตำแหน่ง"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="pr-2 w-1/3">
                    <Controller
                      name="salary"
                      defaultValue="0"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          id="outlined-basic"
                          label="เงินเดือน"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </div>
                <Controller
                  name="username"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      value={field.value}
                      id="outlined-basic"
                      label="Username"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />{' '}
                <Controller
                  name="password"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      id="outlined-basic"
                      label="password"
                      margin="dense"
                      variant="outlined"
                      type="password"
                      fullWidth
                    />
                  )}
                />{' '}
              </div>

              <Button type="submit">Save</Button>
            </CardContent>
          </Card>
        </div>{' '}
      </form>
    </div>
  );
}
export default AdEmployee;
