/* eslint-disable react/prop-types */
import React from 'react';
import {
  Modal,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Chip,
} from '@mui/material';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';

export default function JobInfoBu({ openJob, setOpenJob, job }) {
  const history = useHistory();
  const handleClose = () => setOpenJob(false);
  // const handleEdit = async (id) => {
  //   history.push(`/work/edit/${id}`);
  // };
  const renderDetailWorker = () => (
    <div className="px-4 px-2">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <h1 className="text-xl">ชื่องาน</h1>
            </TableCell>
            <TableCell>
              <h1 className="text-xl">{job?.name || job?.leaveType}</h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1>วันที่</h1>
            </TableCell>
            <TableCell>
              <h1>{dayjs(job?.date).format('DD/MM/BBBB')}</h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1>เวลา</h1>
            </TableCell>
            <TableCell>
              <h1>{job?.time || '-'}</h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1>สถานที่ทำงาน</h1>
            </TableCell>
            <TableCell>
              <h1>{job?.locationName || '-'}</h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1 className="">รถ</h1>
            </TableCell>
            <TableCell>
              <h1>{job?.vehicle || '-'}</h1>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <h1>พนักงาน</h1>
            </TableCell>
            <TableCell>
              {job?.worker && (
                <h1>
                  {job?.worker.map((worker) => (
                    <div>
                      {worker?.name} {worker?.surname}{' '}
                    </div>
                  )) || '-'}
                </h1>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1>หมายเหตุ</h1>
            </TableCell>
            <TableCell>
              <h1>{job?.note || '-'}</h1>
              {job?.link_url && (
                <a
                  href={`${job?.link_url}`}
                  target="_blank"
                  className="text-blue-600"
                  rel="noreferrer"
                >
                  {job?.link_url || ''}
                </a>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
  const renderDetailLeave = () => (
    <div className="p-4">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <h1 className="">ชื่อ-สกุล</h1>
            </TableCell>
            <TableCell>
              <h1 className="">
                {job?.name} {job?.surname}{' '}
              </h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1 className="">ประเภทการลา</h1>
            </TableCell>
            <TableCell>
              <h1 className="">{job?.leaveType}</h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1>วันที่ลา</h1>
            </TableCell>
            <TableCell>
              <h1>
                {dayjs(job?.dateStart).format('DD/MM/BBBB')} -
                {dayjs(job?.dateEnd).format('DD/MM/BBBB')}
              </h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1>ผลการพิจารณา</h1>
            </TableCell>
            <TableCell>
              <Chip label={job?.status} color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1 className="">ผู้อนุมัติ</h1>
            </TableCell>
            <TableCell>
              <h1>
                {job?.approver_by?.name || '-'}{' '}
                {job?.approver_by?.surname || '-'}{' '}
              </h1>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );

  return (
    <div>
      <Modal
        open={openJob}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            // height: 200,
            // width: 200,
          }}
        >
          <Card sx={{ width: 400 }}>
            <div className="p-4">
              <div className="flex justify-between">
                <h1 className="text-2xl ">รายละเอียด </h1>
                <div className="flex">
                  {!job?.allDay ? (
                    <></>
                  ) : (
                    <Chip label="เต็มวัน" color="success" size="large" /> || '-'
                  )}
                </div>
              </div>
            </div>
            {job?.leaveType ? renderDetailLeave() : renderDetailWorker()}
            {/* {job?.leaveType ? (
              ''
            ) : (
              <div className="flex justify-center p-2">
                <Button onClick={() => handleEdit(job._id)} variant="contained">
                  แก้ไข
                </Button>
              </div>
            )} */}
          </Card>
        </Box>
      </Modal>
    </div>
  );
}
