import {
  SUBBUSINESS_ALL,
  SUBBUSINESS_GET,
  SUBBUSINESS_POST,
  SUBBUSINESS_PUT,
  SUBBUSINESS_DELETE,
  SUBBUSINESS_LOADING,
  SUBBUSINESS_ERROR,
} from '../type';

import api from '../../utils/functions/api';

export const subBusinessCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SUBBUSINESS_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/sub-business`,
      {
        ...payload,
      }
    );
    dispatch({ type: SUBBUSINESS_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SUBBUSINESS_ERROR });
  }
};

export const subBusinessGetAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/sub-business?name=${name}&size=${size}&page=${page}`
    );
    if (status === 200) {
      dispatch({ type: SUBBUSINESS_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SUBBUSINESS_ERROR });
  }
};

export const subBusinessGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/sub-business/${id}`
    );
    if (status === 200) {
      dispatch({ type: SUBBUSINESS_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SUBBUSINESS_ERROR });
  }
};

export const subBusinessPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: SUBBUSINESS_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/sub-business/${id}`,
      payload
    );
    dispatch({ type: SUBBUSINESS_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SUBBUSINESS_ERROR });
  }
};
export const subBusinessDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: SUBBUSINESS_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/sub-business/${id}`
    );
    dispatch({ type: SUBBUSINESS_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SUBBUSINESS_ERROR });
  }
};
