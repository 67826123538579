import {
  Switch,
  TextField,
  Button,
  FormControlLabel,
  Card,
  CardContent,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router';
import * as actions from '../../../redux/action';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

export default function EditDaySalary({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const job = useSelector((state) => state.payment);
  console.log('Joooopppp', job);
  const [date, setDate] = useState();
  const { handleSubmit, control, setValue, reset } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(actions.paymentGet(params.id));

    return () => {};
  }, []);

  useEffect(() => {
    setValue('name', job?.name);
    setValue('tel', job?.tel);
    setValue('note', job?.note);
    setDate(job?.date);
    setValue('overdue', job?.overdue);
    setValue('follow', job?.follow);
    setValue('success', job?.success);
    return () => {};
  }, [job]);

  // const props = {
  //   handlButtonDelete: async (id) => {
  //     const confirm = window.confirm('ยืนยันลบข้อมูล');
  //     if (confirm) {
  //       try {
  //         await dispatch(actions.paymentDelete(id));
  //         window.location.reload();
  //         await dispatch(actions.paymentGetAll({}));

  //         history.push('/salary/calendar');
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   },
  // };

  const submit = async (data) => {
    const confirm = window.confirm('ยืนยัน');
    if (confirm) {
      try {
        console.log('data', data);
        const dataSubmit = {
          ...data,
          date,
          name: data?.name,
          tel: data?.tel,
          note: data?.note,
          overdue: data?.overdue,
          follow: data?.follow,
          success: data?.success,
        };
        await dispatch(actions.paymentPut(params.id, dataSubmit));
        await dispatch(actions.paymentGetAll({}));
        history.goBack();
        // await reset(defaultValue);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="p-8 grid-cols-2">
      <h1 className="text-xl font-bold">แก้ไขตารางการชำระเงิน</h1>
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(submit)}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value}
                  label="ชื่อบริษัท"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              )}
            />
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale="th"
            >
              <DatePicker
                label="วันที่ครบกำหนด"
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                  console.log('Date', date);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
            <Controller
              name="tel"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value}
                  label="เบอร์โทรศัพท์"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              )}
            />
            <Controller
              name="note"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value}
                  label="หมายเหตุ"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              )}
            />
            <div className="py-auto px-2 flex">
              <Controller
                name="overdue"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <div>
                    <FormControlLabel
                      control={<Switch {...field} checked={field.value} />}
                      label="ค้างชำระ"
                    />
                  </div>
                )}
              />
              <Controller
                name="follow"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <div>
                    <FormControlLabel
                      control={<Switch {...field} checked={field.value} />}
                      label="ติดตาม"
                    />
                  </div>
                )}
              />
              <Controller
                name="success"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <div>
                    <FormControlLabel
                      control={<Switch {...field} checked={field.value} />}
                      label="ชำระแล้ว"
                    />
                  </div>
                )}
              />
            </div>
            <div className="flex justify-end w-full pt-4">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>{' '}
        </CardContent>{' '}
      </Card>
    </div>
  );
}
