import { Card, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';

export default function TimeStampSettingCard({
  Controller,
  control,
  timeIn,
  setTimeIn,
  timeOut,
  setTimeOut,
}) {
  console.log('time', timeIn);
  return (
    <div>
      <Card>
        <div className="p-4 lg:grid grid-cols-2 gap-1 ">
          <div>
            <Controller
              name="name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  value={field.value}
                  id="outlined-basic"
                  label="ชื่อเรียก"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
          <div> </div>
          <div className="">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="เวลาเข้างาน"
                value={timeIn}
                ampm={false}
                onChange={(e) => setTimeIn(e)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </div>
          <div className="">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="เวลาออกงาน"
                ampm={false}
                value={timeOut}
                onChange={(e) => setTimeOut(e)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </div>
        </div>
      </Card>
    </div>
  );
}
