import { log } from 'async';
import _ from 'lodash';

export default function CalQuotaLeave(leaveType, leaveDoc) {
  const countQuota = [];
  _.map(leaveType?.arr, (e, index) => {
    const quota = _.find(leaveDoc?.quota, (q) => {
      if (q?._id?._id === e?._id) {
        return q;
      }
    });
    if (quota) {
      return countQuota.push(quota);
    }
    countQuota.push(e);
  });
  console.log('countQuota', countQuota);
  return countQuota;
}
