import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line import/order
import accessRight from '../utils/functions/accessRight';
// eslint-disable-next-line import/order
import * as actions from '../redux/action';
// components

import Sidebar from 'components/common/Sidebar/Sidebar';

import FooterAdmin from 'components/common/Footers/FooterAdmin.js';
import NotAuthorized from 'components/Error/Error';
// views

import {
  Salary,
  NewSalary,
  EditSalary,
  SalaryDoc,
  FinanceCalendar,
  FndWorkList,
  EditWork,
  EditDaySalalry,
} from '../views/finance/salary';

import Loading from 'components/Loading';
import { HomeNavbar } from 'components/Navbars';

export default function Salarys() {
  const module = 'FND';
  const name = 'แผนกการเงิน';
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(actions.getMe());
    dispatch(actions.getAllInformation());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading isLoading />;
  }

  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen h-full">
        <Sidebar />
        <div className="pt-4 hidden md:block ">
          <HomeNavbar onMobileNavOpen={() => {}} />
        </div>
        <div className="relative md:ml-64 bg-gray-200 min-h-screen  pt-10 md:px-8">
          {/* <AdminNavbar /> */}
          {/* Header */}
          <div className="py-4 w-full min-h-screen">
            <Switch>
              <Route path="/salary/calendar" exact>
                <FinanceCalendar title="ตารางงานแผนกการเงิน" subtitle={name} />
              </Route>
              <Route path="/salary/work-list" exact>
                <FndWorkList title="รายการงาน" subtitle={name} />
              </Route>
              <Route path="/salary/work-list/edit/:id" exact>
                <EditWork title="แก้ไขงาน" subtitle={name} />
              </Route>
              <Route path="/salary/payroll" exact>
                <Salary title="จัดการเงินเดือน" subtitle={name} />
              </Route>
              <Route path="/salary/Doc" exact>
                <SalaryDoc title="รายงานเงินเดือน" subtitle={name} />
              </Route>
              <Route path="/salary/new" exact>
                <NewSalary title="สร้างเงินเดือน" subtitle={name} />
              </Route>
              <Route path="/salary/editaddsalary/:id" exact>
                <EditDaySalalry title="แก้ไขตารางการชำระเงิน" subtitle={name} />
              </Route>
              <Route path="/salary/edit/:id" exact>
                <EditSalary title="แก้ไขเงินเดือน" subtitle={name} />
              </Route>

              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        {/* Header */}

        <div className="px-4 md:px-10 mx-auto w-full ">
          <NotAuthorized />
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
