/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Button, Card, CardContent, TextField } from '@mui/material';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import Loading from 'components/Loading';
import FormEmployee from '../../components/Forms/FormEmmployee';
import * as action from '../../redux/action';
import ImageUpload from '../../components/ImageUpload/ImageUpload';

function NewEmployee({ title, subtitle }) {
  dayjs.extend(buddhistEra);
  const { handleSubmit, setValue, control, watch } = useForm({
    mode: 'onChange',
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const department = useSelector((state) => state.Department);
  const group = useSelector((state) => state.group);
  const level = useSelector((state) => state.level);
  const subBusiness = useSelector((state) => state.subBusiness);
  const position = useSelector((state) => state.position);
  const [date, setDate] = React.useState(null);
  const [datePass, setDatePass] = React.useState(null);
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectDepartment, setSelectDepartment] = React.useState('');
  const [file, setFile] = React.useState('');
  const [modalFileOpen, setModalFileOpen] = React.useState(false);
  const [selectPosition, setSelectPosition] = React.useState([]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'certificate',
  });

  console.log('Watch Data', watch());
  const handleChange = (event) => {
    const departments = _.find(department?.arr, { _id: event?._id });
    setValue('department', departments);
  };
  const handleChangePositon = (event) => {
    const positions = _.find(position?.arr, { _id: event?._id });
    setValue('position', positions);
  };
  const handleChangeGroup = (data) => {
    const groups = _.find(group?.arr, { _id: data?._id });
    setValue('group', groups);
  };
  const handleChangeLevel = (event) => {
    const levels = _.find(level?.arr, { _id: event?._id });
    setValue('level', levels);
  };
  const handlesubBusiness = (event) => {
    const e = _.find(subBusiness?.arr, { _id: event?._id });
    setValue('subBusiness', e);
  };
  useEffect(() => {
    dispatch(action.getAllDepartment({}));
    dispatch(action.positionGetAll({}));
    dispatch(action.groupGetAll({}));
    dispatch(action.levelGetAll({}));
    dispatch(action.subBusinessGetAll({}));
    return () => {};
  }, []);

  async function onSubmit(data) {
    const confirm = window.confirm('ยืนยันบันทึกข้อมูล');
    if (confirm) {
      try {
        if (!_.isEmpty(selectedImage)) {
          data.image = {
            image: selectedImage[0]?.data_url,
            imageType: 'profile',
            alt: '',
          };
        }
        const payload = {
          ...data,
          salary: parseFloat(data?.salary),
          department: data?.department?._id,
          dateStart: date,
          position: data?.position?._id,
          level: data?.level?._id,
          group: data?.group?._id,
          subBusiness: data?.subBusiness?._id,
          file: _.isEmpty(file) ? undefined : file[0],
        };
        console.log('payload', payload);

        await dispatch(action.addUser(payload));
        dispatch(action.getAllUser({}));
        alert('สร้างพนักงานสำเร็จ');
        history.goBack();
      } catch (error) {
        alert('ชื่อผู้ใช้นี้ถูกใช้แล้ว');
      }
    }
  }
  const renderViewTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;
  const renderForm = () => (
    <FormEmployee
      hideUpload={true}
      hideButton={true}
      fields={fields}
      append={append}
      remove={remove}
      Controller={Controller}
      control={control}
      department={department}
      handleChange={handleChange}
      date={date}
      datePass={datePass}
      setDatePass={setDatePass}
      setDate={setDate}
      position={position}
      subBusiness={subBusiness}
      handleChangePositon={handleChangePositon}
      handleChangeGroup={handleChangeGroup}
      handleChangeLevel={handleChangeLevel}
      handlesubBusiness={handlesubBusiness}
      edit
      group={group}
      level={level}
      file={file}
      setFile={setFile}
      modalFileOpen={modalFileOpen}
      setModalFileOpen={setModalFileOpen}
    />
  );
  if (
    position.isLoading ||
    !position.isCompleted ||
    department.isLoading ||
    !department.isCompleted
  ) {
    return <Loading isLoading />;
  }
  if (
    !position.isLoading ||
    position.isCompleted ||
    !department.isLoading ||
    department.isCompleted
  ) {
    return (
      <div className="min-h-screen ">
        {renderViewTitle()}
        {renderBackButton()}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-2">
            <Card>
              <CardContent>
                <div className="">
                  {renderForm()}
                  <div className="  w-full flex">
                    <div className="w-1/2 p-2">
                      <div>
                        <ImageUpload
                          images={selectedImage}
                          maxNumber={1}
                          previewSize={200}
                          setImages={setSelectedImage}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <Controller
                      name="username"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          value={field.value}
                          id="outlined-basic"
                          label="Username"
                          size="small"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />{' '}
                  </div>

                  <Controller
                    name="password"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-basic"
                        label="password"
                        size="small"
                        variant="outlined"
                        type="password"
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="text-center pt-2">
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
        </form>
      </div>
    );
  }
}
NewEmployee.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
NewEmployee.defaultProps = {
  title: '',
  subtitle: '',
};
export default NewEmployee;
