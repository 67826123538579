import React from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as actions from '../../redux/action';
import FormLevel from '../../components/Forms/FormLevel';

function NewLevel({ title, subtitle }) {
  const { handleSubmit, control } = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackbuttom = () => <BackButton />;

  const onSubmit = async (data) => {
    // eslint-disable-next-line no-alert
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.levelCreate(data));
        await dispatch(actions.levelGetAll({}));
        alert('บันทึกสำเร็จ');
        history.goBack();
      } catch (error) {
        console.log('error');
      }
    }
  };

  const renderForm = () => (
    <di>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormLevel control={control} Controller={Controller} />
      </form>
    </di>
  );

  return (
    <div>
      <div>
        {renderTitle()}
        {renderBackbuttom()}
      </div>
      <div>{renderForm()}</div>
    </div>
  );
}

NewLevel.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
NewLevel.defaultProps = {
  title: '',
  subtitle: '',
};

export default NewLevel;
