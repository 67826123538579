/* eslint-disable react/jsx-curly-newline */
/* eslint-disable radix */
/* eslint-disable no-else-return */
/* eslint-disable quotes */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Autocomplete,
  TextField,
} from '@mui/material';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import dayjs from 'dayjs';
import ProductServe from '../../../components/Forms/FormProductArray';
import * as actions from '../../../redux/action';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import SumAllPrice from 'components/Card/SumAllPrice';
import SumDiscount from 'components/Card/SumDiscount';
import Sumprice from 'components/Card/Sumprice';
function EditQt({ title, subtitle }) {
  dayjs.extend(buddhistEra);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const quotation = useSelector((state) => state.quotation);
  const customer = useSelector((state) => state.customer);
  const product = useSelector((state) => state.product);
  const [productVatUni, setProductVatUni] = useState(false);
  const [date, setDate] = React.useState(new Date());
  const [productList, setProductList] = useState([]);
  const [dueDate, setDueDate] = React.useState(new Date());
  const [productVat, setProductVat] = useState(false);
  const [customerData, setCustomerData] = useState();
  const { handleSubmit, control, setValue, watch } = useForm({
    mode: 'onChange',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'productArray',
  });

  useEffect(() => {
    dispatch(actions.getOneQuotation(params.id));
    dispatch(actions.getAllProduct());
    dispatch(actions.getAllCustomer({}));

    return () => {};
  }, []);
  useEffect(() => {
    setDate(quotation?.date);
    setProductList(quotation?.product);
    if (!quotation.isloading) {
      setValue('productArray', quotation?.product);
    }
    setValue('businessname', quotation?.customer?.businessname);
    setValue('taxId', quotation?.customer?.TaxId);
    setValue('tel', quotation?.customer?.tel);
    setValue('name', quotation?.customer?.name);
    setValue('address', quotation?.customer?.address);
    setValue('payment', quotation?.payment);
    setValue('credit', quotation?.credit);
    setValue('note', quotation?.note);
    setValue('running_number', quotation?.running_number);
    setProductVat(quotation?.statusTax);
    setProductVatUni(quotation?.statusTaxUni);

    return () => {};
  }, [quotation]);
  console.log('name', quotation?.customer?.businessname);
  console.log('Test quotation', quotation);
  // console.log("product", quotation.product);

  const onSubmitQuotation = async (data) => {
    if (productList.length === 0) alert('กรุณาเพิ่มสินค้า');
    else {
      try {
        const ProcessData = await {
          ...data,
          date,
          dueDate,
          prefix: `QT${dayjs(date).format(`BB`)}-`,
          ...data,
          // customer: data?.businessname,
          customer: customerData?._id,
          product: data?.productArray,
          allPrice: SumAllPrice(),
          statusTax: productVat,
          statusTaxUni: productVatUni,
        };
        console.log('dataSubmit', ProcessData);
        await dispatch(actions.putQuotation(params.id, ProcessData));
        await dispatch(actions.getAllQuotation({ name: '', page: 1, size: 5 }));
        history.goBack();
        // history.push('/sale/editquotation');
      } catch (error) {}
    }
  };
  const tax = () => {
    if (!productVat) {
      return 0;
    }
    const price = SumAllPrice(productList);
    const taxAll = price * 0.03;
    return taxAll;
  };
  const taxUniversity = () => {
    if (!productVatUni) {
      return 0;
    }
    const priceUniversity = SumAllPrice(productList);
    const taxUniversity = priceUniversity * 0.01;
    return taxUniversity;
  };

  const priceTax = () => SumAllPrice(productList) - tax();
  const priceTaxUni = () => SumAllPrice(productList) - taxUniversity();

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleCheckCustomer = (data) => {
    const customers = _.find(customer?.arr, { _id: data?._id });
    setValue(`businessname`, customers);
    setValue('taxId', customers?.TaxId);
    setValue('tel', customers?.tel);
    setValue('name', customers?.name);
    setValue('address', customers?.address);
  };
  if (customer.isloading && product.isloading) {
    return <>error</>;
  }
  if (!customer.isloading && !product.isloading) {
    return (
      <div className="min-h-screen">
        <div>
          {renderTitle()}
          <div className="py-2">
            <BackButton />
          </div>
        </div>
        <div>
          <div>
            <div className="flex">
              <Card>
                <div className="p-2">
                  <h1 className="pb-4"> ข้อมูลลูกค้า</h1>

                  <Controller
                    name="businessname"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="ชื่อลูกค้า"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                  <Controller
                    name="taxId"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="หมายเลขผู้เสียภาษี"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="tel"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-basic"
                        label="เบอร์โทร"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="address"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-multiline-basic"
                        label="ที่อยู่"
                        variant="outlined"
                        fullWidth
                        multiline
                        margin="normal"
                        rows={4}
                      />
                    )}
                  />
                </div>
              </Card>

              <div className="p-2 w-full">
                <Card>
                  <CardContent>
                    {' '}
                    <form onSubmit={handleSubmit(onSubmitQuotation)}>
                      <div className="flex justify-between">
                        <div className="flex">
                          <h1 className="text-xl pt-8 pr-2">
                            {quotation?.prefix}
                          </h1>
                          <div>
                            <Controller
                              name="running_number"
                              control={control}
                              defaultValue="-"
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  value={field.value}
                                  label="เลขที่เอกสาร"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                />
                              )}
                            />
                          </div>
                          <div className="p-4 ">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="วันที่"
                                value={date}
                                onChange={(newValue) => {
                                  setDate(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>{' '}
                          </div>
                        </div>
                        <Button
                          variant="contained"
                          type="submit"
                          // className="bg-blue-800 my-1 text-base"
                          // onClick={handleSubmit(onSubmitQuotation)}
                        >
                          บันทึก
                        </Button>
                      </div>
                      {/* <div className="py-2">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="วันที่กำหนดส่ง"
                            value={dueDate}
                            onChange={(newValue) => {
                              setDueDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>{' '}
                      </div> */}
                      <Controller
                        name="credit"
                        control={control}
                        defaultValue="-"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            label="จำนวนวันเครดิต"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                      <Controller
                        name="payment"
                        control={control}
                        defaultValue="โอน/เงินสด"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            label="วิธีการชำระเงิน"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />{' '}
                    </form>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-2">
              <ProductServe
                control={control}
                setProductList={setProductList}
                setValue={setValue}
                setProductVat={setProductVat}
                edit
                watch={watch}
                statusHide={true}
                statusPickUp={false}
                productVatUni={productVatUni}
                setProductVatUni={setProductVatUni}
                productVat={productVat}
              />
            </div>
            <Card>
              <CardContent>
                <div className="flex w-full ">
                  <div className="w-1/2">
                    {' '}
                    <Controller
                      name="note"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          id="outlined-multiline-basic"
                          label="หมายเหตุ"
                          variant="outlined"
                          fullWidth
                          multiline
                          margin="normal"
                          rows={4}
                        />
                      )}
                    />
                  </div>

                  <div className="flex justify-between  w-1/2 ml-2  ">
                    <div>
                      <div>รวมเงิน </div>
                      <div>ส่วนลดการค้า</div>
                      <div>เงินหลังหักส่วนลด</div>
                      {productVatUni === true ? (
                        <div>หัก ณ ที่จ่าย 1%</div>
                      ) : (
                        <div>หัก ณ ที่จ่าย 3%</div>
                      )}
                      <div className="font-bold ">จำนวนเงินทั้งสิ้น </div>
                    </div>

                    {productVatUni === true ? (
                      <div>
                        <div>
                          {Sumprice(productList)
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>

                        <div>
                          {SumDiscount(productList)
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>

                        <div>
                          {SumAllPrice(productList)
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                        <div>
                          <div>
                            {taxUniversity()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                        </div>

                        <div className="font-bold ">
                          {priceTaxUni()
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          {Sumprice(productList)
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>

                        <div>
                          {SumDiscount(productList)
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>

                        <div>
                          {SumAllPrice(productList)
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                        <div>
                          <div>
                            {tax()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                        </div>

                        <div className="font-bold ">
                          {priceTax()
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  }
  return <>error</>;
}
export default EditQt;
