import axios from 'axios';

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const token = localStorage.getItem('Token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
  ,
);
export default axios;
