import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import * as action from '../../redux/action';
import { ViewTitle } from 'components/ViewTitle';
import { TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Loading from 'components/Loading';
function Customers({ title, subtitle }) {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [findName, setFindName] = useState('');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(5);

  // console.log("size before set", size);

  useEffect(() => {
    // console.log(process.env.REACT_APP_API_URL);
    dispatch(action.getAllCustomer({ name: findName, page, size }));
    return () => {};
  }, [findName, size, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const DeleteProduct = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(action.deleteCustomer(id));
        await dispatch(action.getAllCustomer({ findName, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderAddButton = () => (
    <Button
      variant="contained"
      size="large"
      onClick={() => history.push('/customer/new')}
    >
      เพิ่ม
    </Button>
  );

  if (customer.isLoading && !customer.isCompleted) {
    return (
      <div>
        <Loading isLoading={customer.isLoading} />
      </div>
    );
  }
  if (!customer.isLoading && customer.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="py-4">{renderAddButton()}</div>
        </div>
        <Card className="mb-2">
          <CardContent>
            <div>
              <div className="flex">
                <TextField
                  id="outlined-basic"
                  label="ค้นหา"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setFindName(e.target.value)}
                  fullWidth
                />
              </div>
            </div>
          </CardContent>
        </Card>
        <Paper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {' '}
                    <h1 className="font-bold">ชื่อลูกค้า</h1>
                  </TableCell>
                  <TableCell>
                    <h1 className="font-bold">เลขประจำตัวผู้เสียภาษี</h1>
                  </TableCell>
                  <TableCell>
                    <h1 className="font-bold">เบอร์โทร</h1>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {customer.arr?.map((Customers) => (
                  <TableRow key={Customers._id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/customer/edit/${Customers?._id}`}>
                        {Customers.businessname}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={`/customer/edit/${Customers?._id}`}>
                        {Customers.TaxId}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={`/customer/edit/${Customers?._id}`}>
                        {Customers.tel}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        color="error"
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        onClick={() => DeleteProduct(Customers?._id)}
                      >
                        ลบ
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={customer?.total}
            rowsPerPage={size}
            page={page - 1}
          />
        </Paper>
      </div>
    );
  }
}
export default Customers;
