import { Search } from '@mui/icons-material';
import { Card, TextField } from '@mui/material';
import React from 'react';

export default function FillterName({ setName }) {
  return (
    <div>
      <div className=" flex justify-end py-1 ">
        <div className="   w-1/2 ">
          <Card>
            <div className="p-2 flex w-full ">
              <div className="p-2">
                <Search />
              </div>
              <TextField
                id="outlined-basic"
                label="ค้นหา"
                size="small"
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
