/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

function FormSetting({ control, Controller, setting }) {
  const moduleAccess = setting?.arr?.moduleAccess;

  return (
    <div>
      <Card>
        <div className=" py-1 px-2 lg:grid grid-cols-2 gap-1">
          <div>
            <Controller
              name="moduleAccess.FND"
              control={control}
              defaultValue={moduleAccess ? moduleAccess?.FND : true}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="โมดูลแผนกการเงิน"
                  />
                </div>
              )}
            />
          </div>
          <div>
            <Controller
              name="moduleAccess.SLD"
              control={control}
              defaultValue={moduleAccess ? moduleAccess?.SLD : true}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="โมดูลแผนกขาย"
                  />
                </div>
              )}
            />
          </div>
          <div>
            <Controller
              name="moduleAccess.HRD"
              defaultValue={moduleAccess ? moduleAccess?.HRD : true}
              control={control}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="โมดูลแผนกบุคคล"
                  />
                </div>
              )}
            />
          </div>
          <div>
            <Controller
              name="moduleAccess.PCD"
              control={control}
              defaultValue={moduleAccess ? moduleAccess?.PCD : true}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="โมดูลแผนกจัดซื้อ"
                  />
                </div>
              )}
            />
          </div>
          <div>
            <Controller
              name="moduleAccess.PDD"
              defaultValue={moduleAccess ? moduleAccess?.PDD : true}
              control={control}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="โมดูลคลังสินค้า"
                  />
                </div>
              )}
            />
          </div>
          <div>
            <Controller
              name="moduleAccess.ADM"
              defaultValue={moduleAccess ? moduleAccess?.ADM : true}
              control={control}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="โมดูลแผนกแอดมิน"
                  />
                </div>
              )}
            />
          </div>
          <div>
            <Controller
              name="calendarAll"
              control={control}
              defaultValue={setting?.arr ? setting?.arr?.calendarAll : false}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="โมดูลตารางงานรวม"
                  />
                </div>
              )}
            />
          </div>
          <div>
            <Controller
              name="calendarMe"
              control={control}
              defaultValue={setting?.arr ? setting?.arr?.calendarMe : false}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="โมดูลตารางงานส่วนบุคคล"
                  />
                </div>
              )}
            />
          </div>
          {/* <div>
            <Controller
              name="Project"
              control={control}
              defaultValue={moduleAccess ? moduleAccess?.PM : false}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="โมดูลโครงการ"
                  />
                </div>
              )}
            />
          </div> */}
          <div>
            <Controller
              name="multipleApplover"
              control={control}
              defaultValue={
                setting?.arr ? setting?.arr?.multipleApplover : false
              }
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="อนุมัติใบลา 2 ท่าน"
                  />
                </div>
              )}
            />
          </div>
          {/* <div className="font-bold"> ตารางการชำระเงิน </div>

          <div>
            <Controller
              name="multipleApplover"
              control={control}
              defaultValue={setting?.arr ? setting?.arr?.financeTable : false}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="ตารางการชำระเงิน"
                  />
                </div>
              )}
            />
          </div> */}

          {/* <div>
            <Controller
              name="leaveRequest.typeOne"
              control={control}
              defaultValue={setting?.arr ? setting?.arr?.leaveRequest : false}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="ใบลาแบบที่1"
                  />
                </div>
              )}
            />
          </div>
          <div></div>
          <div>
            <Controller
              name="leaveRequest.typeTwo"
              control={control}
              defaultValue={setting?.arr ? setting?.arr?.leaveRequest : false}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="ใบลาแบบที่2"
                  />
                </div>
              )}
            />
          </div> */}
        </div>
        <div>
          <div className="font-bold py-1 px-2">ใบสลิปเงือนเดือน</div>
          <div className=" pl-2">
            <Controller
              name="salaryslip.typeOne"
              control={control}
              defaultValue={
                setting?.arr ? setting?.arr?.salaryslip?.typeOne : false
              }
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="แบบที่1"
                  />
                </div>
              )}
            />
          </div>
          <div className="pl-2">
            <Controller
              name="salaryslip.typeTwo"
              control={control}
              defaultValue={
                setting?.arr ? setting?.arr?.salaryslip?.typeTwo : false
              }
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="แบบที่2"
                  />
                </div>
              )}
            />
          </div>
        </div>
        <div className="pl-2">
          <div className="font-bold py-1 px-2">ตารางการชำระเงิน</div>
          <div>
            <Controller
              name="financeTable"
              control={control}
              defaultValue={setting?.arr ? setting?.arr?.financeTable : false}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="ตารางการชำระเงิน"
                  />
                </div>
              )}
            />
          </div>
        </div>
        <div className="p-4 flex justify-end ">
          <Button type="submit" variant="contained">
            บันทึก
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default FormSetting;
