export const CUSTOMER_ALL = 'CUSTOMER_ALL';
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CUSTOMER_GET = 'CUSTOMER_GET';
export const CUSTOMER_POST = 'CUSTOMER_POST';
export const CUSTOMER_PUT = 'CUSTOMER_PUT';
export const CUSTOMER_DELETE = 'CUSTOMER_DELETE';
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR';

export const QUOTATION_ALL = 'QUOTATION_ALL';
export const QUOTATION_LOADING = 'QUOTATION_LOADING';
export const QUOTATION_GET = 'QUOTATION_GET';
export const QUOTATION_POST = 'QUOTATION_POST';
export const QUOTATION_PUT = 'QUOTATION_PUT';
export const QUOTATION_DELETE = 'QUOTATION_DELETE';
export const QUOTATION_ERROR = 'QUOTATION_ERROR';

export const PRODUCT_ALL = 'PRODUCT_ALL';
export const PRODUCT_GET = 'PRODUCT_GET';
export const PRODUCT_POST = 'PRODUCT_POST';
export const PRODUCT_PUT = 'PRODUCT_PUT';
export const PRODUCT_DELETE = 'PRODUCT_DELETE';
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';

export const INVOICE_ALL = 'INVOICE_ALL';
export const INVOICE_GET = 'INVOICE_GET';
export const INVOICE_POST = 'INVOICE_POST';
export const INVOICE_PUT = 'INVOICE_PUT';
export const INVOICE_DELETE = 'INVOICE_DELETE';
export const INVOICE_LOADING = 'INVOICE_LOADING';
export const INVOICE_ERROR = 'INVOICE_ERROR';

export const RECEIPT_ALL = 'RECEIPT_ALL';
export const RECEIPT_GET = 'RECEIPT_GET';
export const RECEIPT_POST = 'RECEIPT_POST';
export const RECEIPT_PUT = 'RECEIPT_PUT';
export const RECEIPT_DELETE = 'RECEIPT_DELETE';
export const RECEIPT_LOADING = 'RECEIPT_LOADING';
export const RECEIPT_ERROR = 'RECEIPT_ERROR';

export const PURCHASE_ALL = 'PURCHASE_ALL';
export const PURCHASE_GET = 'PURCHASE_GET';
export const PURCHASE_POST = 'PURCHASE_POST';
export const PURCHASE_PUT = 'PURCHASE_PUT';
export const PURCHASE_DELETE = 'PURCHASE_DELETE';
export const PURCHASE_LOADING = 'PURCHASE_LOADING';
export const PURCHASE_ERROR = 'PURCHASE_ERROR';

export const USER_ALL = 'USER_ALL';
export const USER_GET = 'USER_GET';
export const USER_POST = 'USER_POST';
export const USER_PUT = 'USER_PUT';
export const USER_DELETE = 'USER_DELETE';
export const USER_LOADING = 'USER_LOADING';
export const USER_ERROR = 'USER_ERROR';

export const ME_GET = 'ME_GET';
export const ME_RESET = 'ME_RESET';
export const ME_ERROR = 'ME_ERROR';
export const ME_PROMPT = 'ME_PROMPT';

export const LEAVEDOC_ALL = 'LEAVEDOC_ALL';
export const LEAVEDOC_LOADING = 'LEAVEDOC_LOADING';
export const LEAVEDOC_GET = 'LEAVEDOC_GET';
export const LEAVEDOC_POST = 'LEAVEDOC_POST';
export const LEAVEDOC_PUT = 'LEAVEDOC_PUT';
export const LEAVEDOC_DELETE = 'LEAVEDOC_DELETE';
export const LEAVEDOC_ERROR = 'LEAVEDOC_ERROR';

export const PICKUPORDER_ALL = 'PICKUPORDER_ALL';
export const PICKUPORDER_GET = 'PICKUPORDER_GET';
export const PICKUPORDER_POST = 'PICKUPORDER_POST';
export const PICKUPORDER_PUT = 'PICKUPORDER_PUT';
export const PICKUPORDER_DELETE = 'PICKUPORDER_DELETE';
export const PICKUPORDER_LOADING = 'PICKUPORDER_LOADING';
export const PICKUPORDER_ERROR = 'PICKUPORDER_ERROR';

export const SALARY_ALL = 'SALARY_ALL';
export const SALARY_GET = 'SALARY_GET';
export const SALARY_POST = 'SALARY_POST';
export const SALARY_PUT = 'SALARY_PUT';
export const SALARY_DELETE = 'SALARY_DELETE';
export const SALARY_LOADING = 'SALARY_LOADING';
export const SALARY_ERROR = 'SALARY_ERROR';
export const SALARY_RESET = 'SALARY_RESET';

export const AVAILABLE_MODULE_ALL = 'AVAILABLE_MODULE_ALL';
export const AVAILABLE_MODULE_ERROR = 'AVAILABLE_MODULE_ERROR';
export const AVAILABLE_MODULE_LOADING = 'AVAILABLE_MODULE_LOADING';
export const AVAILABLE_MODULE_PUT = 'AVAILABLE_MODULE_PUT';
export const AVAILABLE_LOADING = 'AVAILABLEC_LOADING';

export const DEPARTMENT_ALL = 'DEPARTMENT_ALL';
export const DEPARTMENT_GET = 'DEPARTMENT_GET';
export const DEPARTMENT_POST = 'DEPARTMENT_POST';
export const DEPARTMENT_PUT = 'DEPARTMENT_PUT';
export const DEPARTMENT_DELETE = 'DEPARTMENT_DELETE';
export const DEPARTMENT_LOADING = 'DEPARTMEN_LOADING';
export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR';

export const INFORMATION_ALL = 'INFORMATION_ALL';
export const INFORMATION_POST = 'INFORMATION_POST';
export const INFORMATION_GET = 'INFORMATION_GET';
export const INFORMATION_PUT = 'INFORMATION_PUT';
export const INFORMATION_LOADING = 'INFORMATION_LOADING';

export const WORK_ALL = 'WORK_ALL';
export const WORK_GET = 'WORK_GET';
export const WORK_POST = 'WORK_POST';
export const WORK_PUT = 'WORK_PUT';
export const WORK_DELETE = 'WORK_DELETE';
export const WORK_LOADING = 'WORK_LOADING';
export const WORK_ERROR = 'WORK_ERROR';

export const CHECKIN_ALL = 'CHECKIN_ALL';
export const CHECKIN_LOADING = 'CHECKIN_LOADING';
export const CHECKIN_GET = 'CHECKIN_GET';
export const CHECKIN_POST = 'CHECKIN_POST';
export const CHECKIN_PUT = 'CHECKIN_PUT';
export const CHECKIN_DELETE = 'CHECKIN_DELETE';
export const CHECKIN_ERROR = 'CHECKIN_ERROR';

export const TIMESTAMP_SETTING_ALL = 'TIMESTAMP_SETTING_ALL';
export const TIMESTAMP_SETTING_LOADING = 'TIMESTAMP_SETTING_LOADING';
export const TIMESTAMP_SETTING_GET = 'TIMESTAMP_SETTING_GET';
export const TIMESTAMP_SETTING_POST = 'TIMESTAMP_SETTING_POST';
export const TIMESTAMP_SETTING_PUT = 'TIMESTAMP_SETTING_PUT';
export const TIMESTAMP_SETTING_DELETE = 'TIMESTAMP_SETTING_DELETE';
export const TIMESTAMP_SETTING_ERROR = 'TIMESTAMP_SETTING_ERROR';

export const LEAVE_TYPE_ALL = 'LEAVE_TYPE_ALL';
export const LEAVE_TYPE_LOADING = 'LEAVE_TYPE_LOADING';
export const LEAVE_TYPE_GET = 'LEAVE_TYPE_GET';
export const LEAVE_TYPE_POST = 'LEAVE_TYPE_POST';
export const LEAVE_TYPE_PUT = 'LEAVE_TYPE_PUT';
export const LEAVE_TYPE_DELETE = 'LEAVE_TYPE_DELETE';
export const LEAVE_TYPE_ERROR = 'LEAVE_TYPE_ERROR';

export const POSITION_ALL = 'POSITION_ALL';
export const POSITION_LOADING = 'POSITION_LOADING';
export const POSITION_GET = 'POSITION_GET';
export const POSITION_POST = 'POSITION_POST';
export const POSITION_PUT = 'POSITION_PUT';
export const POSITION_DELETE = 'POSITION_DELETE';
export const POSITION_ERROR = 'POSITION_ERROR';

export const GROUP_ALL = 'GROUP_ALL';
export const GROUP_LOADING = 'GROUP_LOADING';
export const GROUP_GET = 'GROUP_GET';
export const GROUP_POST = 'GROUP_POST';
export const GROUP_PUT = 'GROUP_PUT';
export const GROUP_DELETE = 'GROUP_DELETE';
export const GROUP_ERROR = 'GROUP_ERROR';

export const LEVEL_ALL = 'LEVEL_ALL';
export const LEVEL_LOADING = 'LEVEL_LOADING';
export const LEVEL_GET = 'LEVEL_GET';
export const LEVEL_POST = 'LEVEL_POST';
export const LEVEL_PUT = 'LEVEL_PUT';
export const LEVEL_DELETE = 'LEVEL_DELETE';
export const LEVEL_ERROR = 'LEVEL_ERROR';

export const SETTING_ALL = 'SETTING_ALL';
export const SETTING_LOADING = 'SETTING_LOADING';
export const SETTING_GET = 'SETTING_GET';
export const SETTING_POST = 'SETTING_POST';
export const SETTING_PUT = 'SETTING_PUT';
export const SETTING_DELETE = 'SETTING_DELETE';
export const SETTING_ERROR = 'SETTING_ERROR';

export const SUBBUSINESS_ALL = 'SUBBUSINESS_ALL';
export const SUBBUSINESS_LOADING = 'SUBBUSINESS_LOADING';
export const SUBBUSINESS_GET = 'SUBBUSINESS_GET';
export const SUBBUSINESS_POST = 'SUBBUSINESS_POST';
export const SUBBUSINESS_PUT = 'SUBBUSINESS_PUT';
export const SUBBUSINESS_DELETE = 'SUBBUSINESS_DELETE';
export const SUBBUSINESS_ERROR = 'SUBBUSINESS_ERROR';

export const NEWS_ALL = 'NEWS_ALL';
export const NEWS_LOADING = 'NEWS_LOADING';
export const NEWS_GET = 'NEWS_GET';
export const NEWS_POST = 'NEWS_POST';
export const NEWS_PUT = 'NEWS_PUT';
export const NEWS_DELETE = 'NEWS_DELETE';
export const NEWS_ERROR = 'NEWS_ERROR';

export const NOTIFY_REQ_TOKEN = 'NOTIFY_REQ_TOKEN';
export const NOTIFY_DEPARTMENT = 'NOTIFY_DEPARTMENT';
export const NOTIFY_DIRECT = 'NOTIFY_DIRECT';
export const NOTIFY_LOADING = 'NOTIFY_LOADING';
export const NOTIFY_ERROR = 'NOTIFY_ERROR';

export const FILE_ALL = 'FILE_ALL';
export const FILE_LOADING = 'FILE_LOADING';
export const FILE_GET = 'FILE_GET';
export const FILE_POST = 'FILE_POST';
export const FILE_PUT = 'FILE_PUT';
export const FILE_DELETE = 'FILE_DELETE';
export const FILE_ERROR = 'FILE_ERROR';

export const PROJECT_ALL = 'PROJECT_ALL';
export const PROJECT_LOADING = 'PROJECT_LOADING';
export const PROJECT_GET = 'PROJECT_GET';
export const PROJECT_POST = 'PROJECT_POST';
export const PROJECT_PUT = 'PROJECT_PUT';
export const PROJECT_DELETE = 'PROJECT_DELETE';
export const PROJECT_ERROR = 'PROJECT_ERROR';

export const PAYMENT_ALL = 'PAYMENT_ALL';
export const PAYMENT_LOADING = 'PAYMENT_LOADING';
export const PAYMENT_GET = 'PAYMENT_GET';
export const PAYMENT_POST = 'PAYMENT_POST';
export const PAYMENT_PUT = 'PAYMENT_PUT';
export const PAYMENT_DELETE = 'PAYMENT_DELETE';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
