import {
  PURCHASE_ALL,
  PURCHASE_DELETE,
  PURCHASE_GET,
  PURCHASE_LOADING,
  PURCHASE_POST,
  PURCHASE_PUT,
  PURCHASE_ERROR,
} from '../type';

import api from '../../utils/functions/api';

export const getAllPurchase = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/purchase?name=${name}&size=${size}&page=${page}`
    );
    if (status === 200) {
      dispatch({ type: PURCHASE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PURCHASE_ERROR });
  }
};

export const getOnePurchase = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/purchase/${id}`
    );
    if (status === 200) {
      dispatch({ type: PURCHASE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PURCHASE_ERROR });
  }
};

export const deletePurchasee = (id) => async (dispatch) => {
  try {
    dispatch({ type: PURCHASE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/purchase/${id}`
    );
    dispatch({ type: PURCHASE_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PURCHASE_ERROR });
  }
};

export const addPurchase = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PURCHASE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/purchase`,
      {
        ...payload,
      }
    );
    dispatch({ type: PURCHASE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PURCHASE_ERROR });
  }
};

export const putPurchase = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PURCHASE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/purchase/${id}`,
      payload
    );
    dispatch({ type: PURCHASE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PURCHASE_ERROR });
  }
};
