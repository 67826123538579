import { TextField } from '@mui/material';
import React from 'react';

export default function SubBusinessForm({ control, Controller, subBusiness }) {
  return (
    <div className="py-2">
      <div>
        <Controller
          name="name"
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value}
              label="ชื่อบริษัท"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div>
    </div>
  );
}
