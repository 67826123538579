import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
import _ from 'lodash';
import { DateTime } from 'luxon';

export default function ShowCalendar({ work, setJob, handleJobOpen }) {
  const formatEventTime = (isoDate) =>
    DateTime.fromISO(isoDate).set({ hour: 12, minute: 1 }).toISODate();
  const getEvent = _.map(work.arr, (eachWork) => {
    const events = {
      id: eachWork?._id,
      // title: `${eachWork?.time || ''} ${eachWork?.name}  `,
      title: eachWork?.name,
      start: formatEventTime(eachWork?.date),
      backgroundColor: eachWork?.color ? eachWork?.color : '#1273de',
      borderColor: '#FFFFFF',
    };
    return events;
  });
  return (
    <div>
      <div className="p-2">
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          locale="th"
          eventClassNames="cursor-pointer hover:shadow-md border-none"
          events={getEvent}
          headerToolbar={{
            start: 'dayGridMonth,dayGridWeek,dayGridDay',
            center: 'title',
            end: 'prevYear,prev,next,nextYear',
          }}
          eventClick={(info) => {
            const jobs = _.find(
              work?.arr,
              (eachWork) => eachWork?._id === info?.event?.id
            );

            setJob(jobs);
            handleJobOpen();
          }}
        />
      </div>
    </div>
  );
}
