import {
  QUOTATION_ALL,
  QUOTATION_DELETE,
  QUOTATION_GET,
  QUOTATION_LOADING,
  QUOTATION_POST,
  QUOTATION_PUT,
} from '../type';

const initialState = {
  Quotation: null,
  isLoading: true,
  isCompleted: true,
};

function QuotationReducer(state = initialState, action) {
  switch (action.type) {
    case QUOTATION_LOADING:
      return { isLoading: true, isCompleted: false };
    case QUOTATION_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case QUOTATION_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case QUOTATION_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case QUOTATION_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case QUOTATION_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default QuotationReducer;
