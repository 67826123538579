import {
  GROUP_ALL,
  GROUP_GET,
  GROUP_POST,
  GROUP_PUT,
  GROUP_DELETE,
  GROUP_LOADING,
} from '../type';

const initialState = {
  group: null,
  isLoading: true,
  isCompleted: true,
};

// eslint-disable-next-line default-param-last
function GroupReducer(state = initialState, action) {
  switch (action.type) {
    case GROUP_LOADING:
      return { isLoading: true, isCompleted: false };
    case GROUP_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case GROUP_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case GROUP_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case GROUP_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case GROUP_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default GroupReducer;
