import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/action';
import NotAuthorized from 'components/Error/Error';
// components

import Sidebar from 'components/common/Sidebar/Sidebar';

import FooterAdmin from 'components/common/Footers/FooterAdmin.js';

// views

// import Dashboard from "../views/admin/Dashboard.js";
import Products from '../views/Product/Product';
import NewProduct from 'views/Product/newProduct';
import EditProduct from 'views/Product/editProduct';
import Loading from 'components/Loading';
import { HomeNavbar } from 'components/Navbars';
export default function Product() {
  const module = 'PDD';
  const name = 'สินค้า';
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(actions.getAllInformation());
    dispatch(actions.getMe());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading isLoading />;
  }

  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen h-full">
        <Sidebar />
        <div className="pt-4 hidden md:block ">
          <HomeNavbar onMobileNavOpen={() => {}} />
        </div>
        <div className="relative md:ml-64 bg-gray-200 min-h-screen  pt-6 md:px-8">
          {/* <AdminNavbar /> */}
          {/* Header */}

          <div className="py-4 w-full min-h-screen   ">
            <Switch>
              <Route path="/product" exact>
                <Products title="สินค้า" subtitle={name} />
              </Route>
              <Route path="/product/new" exact>
                <NewProduct title="สร้างสินค้า" subtitle={name} />
              </Route>
              <Route path="/product/edit/:id" exact>
                <EditProduct title="แก้ไขสินค้า" subtitle={name} />
              </Route>
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        {/* Header */}

        <div className="px-4 md:px-10 mx-auto w-full ">
          <NotAuthorized />
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
