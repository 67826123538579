import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import React from 'react';
import FormNewProduct from 'components/Forms/FormNewProduct ';
import { Button } from '@mui/material';
import Save from '@mui/icons-material/Save';
import { useDispatch } from 'react-redux';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import * as action from '../../redux/action';

function NewProduct({ title, subtitle }) {
  const { handleSubmit, control } = useForm({
    mode: 'onChange',
  });

  const dispatch = useDispatch();
  const history = useHistory();

  function addProduct(payload) {
    dispatch(action.addProduct(payload));
  }
  function onSubmit(data) {
    // console.log("dataSubmit from newproduct", data.productabout);
    addProduct(data);
    history.push('/product');
  }
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div className="min-h-screen">
      <div>
        <div>
          {renderTitle()}
          <div className="py-2">
            <BackButton />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormNewProduct Controller={Controller} control={control}>
            <Button variant="contained" startIcon={<Save />} type="submit">
              บันทึก
            </Button>
          </FormNewProduct>
        </form>
      </div>
    </div>
  );
}
export default NewProduct;
