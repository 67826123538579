import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Controller, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import * as action from '../../redux/action';

function LeaveForm({ title }) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);
  const leaveDocs = useSelector((state) => state.leaveDoc);
  const leaveType = useSelector((state) => state.leaveType);
  const me = useSelector((state) => state.me);
  const [date, setDate] = React.useState(new Date());
  const [dateStart, setDateStart] = React.useState(new Date());
  const [status, setStatus] = useState(true);
  const [dateEnd, setDateEnd] = React.useState(new Date());
  const { handleSubmit, control } = useForm({ mode: 'onChange' });
  const [leaveTypeSelect, setLeaveTypeSelect] = React.useState('ลาป่วย');
  const [leaveDocData, setleaveDocData] = useState(leaveDocs?.arr);
  const [eachUser, setEachUser] = useState();
  const lastLeaveDoc = leaveDocs?.arr?.[0];
  const history = useHistory();

  const dayDiff = dayjs(lastLeaveDoc?.dateEnd)
    .endOf('day')
    .diff(dayjs(lastLeaveDoc?.dateStart).startOf('day'), 'day');

  dayjs.extend(buddhistEra);

  const handleChange = (event) => {
    if (event.target.value === 'ลากิจ') {
      setStatus(false);
      setLeaveTypeSelect(event.target.value);
    } else {
      setStatus(true);
      setLeaveTypeSelect(event.target.value);
    }
  };
  function getLastLeavDoc(name) {
    dispatch(action.getAllLeaveDoc({ name }));
  }
  useEffect(() => {
    dispatch(action.getAllUser({}));
    getLastLeavDoc(users?.name);
    setleaveDocData(leaveDocs?.arr);
    dispatch(action.leaveTypeGetAll({}));

    return () => {};
  }, []);

  const handleChangeApprove = (event) => {
    setEachUser(event.target.value);
  };
  useEffect(() => () => {}, [users]);
  const sumDay = () => {
    const date1 = dayjs(dateEnd).startOf('day');
    const date2 = dayjs(dateStart).startOf('day');
    const date = date1.toDate().getTime() - date2.toDate().getTime();
    const sumday = date / (1000 * 3600 * 24);

    return sumday + 1;
  };

  const handleOnclickSave = async (data) => {
    const confirm = window.confirm('ยืนยันข้อมูล');
    if (confirm) {
      try {
        const payload = {
          ...data,
          date,
          name: me?.userData?.name,
          surname: me?.userData?.surname,
          department: me?.userData?.department?.name,
          position: me?.userData?.position?.name,
          lastdateEnd: lastLeaveDoc?.dateEnd,
          lastdateStart: lastLeaveDoc?.dateStart,
          lastleaveType: lastLeaveDoc?.leaveType,
          lastleaveCause: lastLeaveDoc?.leaveCause,
          approverName: eachUser?.name,
          approverSurname: eachUser?.surname,
          approverEmail: eachUser?.email,
          dateStart: dayjs(dateStart).startOf('date'),
          dateEnd: dayjs(dateEnd).endOf('date'),
          leaveTypeID: leaveTypeSelect,
          employee: me?.userData?._id,
        };
        await dispatch(action.addLeaveDoc(payload));
        alert('บันทึกข้อมูลสำเร็จ');
        history.goBack();
      } catch (error) {
        alert('บันทึกผิดพลาด');
      }
    }
  };
  const renderTitle = () => <ViewTitle title={title} />;

  // const dataLeave = _.findIndex(leaveDocs?.arr,)
  return (
    <div className="min-h-screen">
      <form onSubmit={handleSubmit(handleOnclickSave)}>
        <div>
          {renderTitle()}
          <div className="py-2">
            <BackButton />
          </div>
          <div>
            <Card>
              <CardContent>
                {' '}
                <div className="flex">
                  <div className="w-full  pl-2">
                    <h1 className=" font-bold pb-2">วันที่เขียน</h1>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="วันที่"
                        value={date}
                        onChange={(newValue) => {
                          setDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                    <h1 className=" font-bold pt-2">เขียนที่</h1>
                    <Controller
                      name="address"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          id="outlined-basic"
                          label="address"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="pt-8 ">
                  <Paper>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <h1 className="text-l font-bold text-center">
                                ชื่อ-นามสกุล
                              </h1>
                            </TableCell>
                            <TableCell>
                              <h1 className="text-l  pl-8 ">
                                {me?.userData?.name} {me?.userData?.surname}
                              </h1>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <h1 className="text-l font-bold text-center">
                                ตำแหน่ง
                              </h1>
                            </TableCell>
                            <TableCell>
                              <h1 className="text-l  pl-8 ">
                                {me?.userData?.position?.name || ''}
                              </h1>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <h1 className="text-l font-bold text-center">
                                แผนก
                              </h1>
                            </TableCell>
                            <TableCell>
                              <h1 className="text-l  pl-8 ">
                                {me?.userData?.department?.name}
                              </h1>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
                <div className="border-b-2 border-blue-400  pt-8 pb-4">
                  <h1 className="font-bold">ข้อมูลการลาครั้งล่าสุด</h1>
                  <div className="">
                    <Paper>
                      <TableContainer>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <h1 className="text-l font-bold text-center">
                                  ลา
                                </h1>
                              </TableCell>
                              <TableCell>
                                <h1 className="text-l  pl-8 ">
                                  {lastLeaveDoc?.leaveType || '-'}
                                </h1>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <h1 className="text-l font-bold text-center">
                                  สาเหตุ
                                </h1>
                              </TableCell>
                              <TableCell>
                                <h1 className="text-l  pl-8 ">
                                  {lastLeaveDoc?.leaveCause || '-'}
                                </h1>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <h1 className="text-l font-bold text-center">
                                  ตั้งแต่วันที่
                                </h1>
                              </TableCell>
                              <TableCell>
                                <h1 className="text-l  pl-8 ">
                                  {lastLeaveDoc?.dateStart &&
                                    dayjs(lastLeaveDoc?.dateStart).format(
                                      'DD/MM/BB'
                                    )}
                                </h1>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <h1 className="text-l font-bold text-center">
                                  ถึงวันที่
                                </h1>
                              </TableCell>
                              <TableCell>
                                <h1 className="text-l  pl-8 ">
                                  {lastLeaveDoc?.dateEnd &&
                                    dayjs(lastLeaveDoc?.dateEnd).format(
                                      'DD/MM/BB'
                                    )}
                                </h1>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <h1 className="text-l font-bold text-center">
                                  จำนวนวัน
                                </h1>
                              </TableCell>
                              <TableCell>
                                <h1 className="text-l  pl-8 ">{dayDiff + 1}</h1>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>
                <div>
                  <h1 className="font-bold pt-8 pl-4 ">ข้อมูลการลา</h1>
                  <div className="lg:flex pb-4 ">
                    <div className="lg:w-1/3 font-bold pt-2">
                      ผู้อนุมัติใบลา
                    </div>
                    <div className="pt-2 w-full ">
                      <FormControl sx={{ minWidth: 120 }} fullWidth>
                        <InputLabel id="type" size="small">
                          ผู้อนุมัติ
                        </InputLabel>
                        <Select
                          label="ผู้อนุมัติ"
                          value={eachUser}
                          onChange={handleChangeApprove}
                          required
                          size="small"
                          fullWidth
                        >
                          {_.map(users?.arr, (each) => (
                            <MenuItem key={each?.id} value={each}>
                              {each?.name} {each?.surname}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="lg:flex ">
                    <h1 className="pl-2 pr-2 font-bold lg:w-1/3">ขอลา</h1>
                    <div className=" w-full pb-2">
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ลา
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leaveTypeSelect}
                            size="small"
                            label="ลา "
                            fullWidth
                            onChange={handleChange}
                          >
                            {_.map(leaveType?.arr, (each, index) => (
                              <MenuItem key={index} value={each?._id}>
                                {each?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                  </div>
                  <div className="lg:flex">
                    <h1 className="p-2 font-bold lg:w-1/3">สาเหตุ</h1>
                    <div className="w-full">
                      <Controller
                        name="leaveCause"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            // disabled={status}
                            fullWidth
                            value={field.value}
                            id="outlined-basic"
                            label="สาเหตุ"
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className=" pt-4 lg:flex">
                    <h1 className="p-2 font-bold lg:w-1/3">ตั้งแต่วันที่ </h1>
                    <div className="w-full">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="วันที่"
                          value={dateStart}
                          onChange={(newValue) => {
                            setDateStart(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="pt-4 lg:flex">
                    <h1 className="p-2 font-bold lg:w-1/3 ">ถึงวันที่ </h1>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="วันที่"
                        value={dateEnd}
                        onChange={(newValue) => {
                          setDateEnd(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="pt-2  flex">
                    <h1 className="p-2 font-bold w-1/2 lg:w-1/3 ">มีกำหนด </h1>
                    <div className="flex w-full">
                      <h1 className="pt-2 pr-2 font-bold">{sumDay()}</h1>
                      <h1 className="pt-2 font-bold">วัน </h1>
                    </div>
                  </div>

                  <div>
                    <h1 className="pl-2 pr-2 font-bold pt-2">
                      ในระหว่างการลาจะติดต่อข้าพเจ้าได้ที่
                    </h1>
                    <div className="pl-2 pt-2">
                      <Controller
                        name="contact"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            rows={4}
                            fullWidth
                            value={field.value}
                            id="outlined-basic"
                            label="ติดต่อ"
                            required
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center pt-4">
                  <Button type="submit" variant="contained">
                    save
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </form>
    </div>
  );
}
export default LeaveForm;
