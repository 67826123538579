/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { useForm, Controller } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, TextField } from '@mui/material';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import InputAdornment from '@mui/material/InputAdornment';
import ModalUploadFile from 'components/Modal/ModalUploadFile';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import * as actions from '../../../redux/action';

function EditSalary({ title, subtitle }) {
  dayjs.extend(buddhistEra);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const salary = useSelector((state) => state.Salary);

  const [findName, setFindName] = useState('');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(5);
  const [taxSSO, setTaxSSO] = React.useState();
  const [date, setDate] = React.useState(new Date());
  // const [dueDate, setDueDate] = React.useState(new Date());
  const { handleSubmit, control, setValue, getValues, reset } = useForm({});
  const [modalFileOpen, setModalFileOpen] = useState(false);
  const [file, setFile] = useState([]);

  useEffect(() => {
    dispatch(actions.getOneSalary(params.id));
    return () => {};
  }, [findName, page, size]);
  useEffect(() => {
    setValue('ot', salary?.ot);
    setValue('extra', salary?.extra);
    setValue('tax', salary?.tax);
    setDate(salary?.date);
    setValue('emExtra', salary?.emExtra);
    setValue('allowance', salary?.allowance);
    setValue('emExtra2', salary?.emExtra2);
    setValue('salary', salary?.salary);
    setValue('etc', salary?.etc);
    setTaxSSO(salary?.taxSSO);
    return () => {};
  }, [salary]);
  // console.log("data", salary);
  //
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const handleChangeTax = (event) => {
    setTaxSSO(event.target.value);
  };
  // console.log(salary);

  const calculateSSO = (salary) => {
    const SSO = (salary * taxSSO) / 100;
    if (salary > 15000) {
      const SSO = (15000 * taxSSO) / 100;
      return SSO;
    }
    return SSO;
  };
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;

  const salarys = () => (
    <Paper>
      <form>
        <ModalUploadFile
          isOpen={modalFileOpen}
          files={file}
          setFiles={setFile}
          handleClose={() => setModalFileOpen(false)}
        />
        {/* {console.log(file.[0])}, */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead className="bg-blue-600 ">
              <TableRow>
                <TableCell sx={{ width: '10%' }}>
                  <h1 className="text-white font-bold">เงินเดือน</h1>
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <h1 className="text-white font-bold text-center">
                    ค่าแรงวันหยุด
                  </h1>
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <h1 className="text-white font-bold text-center">
                    เบี้ยเลี้ยง
                  </h1>
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <h1 className="text-white font-bold text-center">
                    เงินเพิ่มพิเศษ
                  </h1>
                </TableCell>

                <TableCell sx={{ width: '10%' }} className="bg-red-700">
                  <h1 className="text-white font-bold text-center">
                    ประกันสังคม
                  </h1>
                </TableCell>

                <TableCell sx={{ width: '10%' }} className="bg-red-700">
                  <h1 className="text-white font-bold text-center">อื่นๆ</h1>
                </TableCell>
                <TableCell sx={{ width: '10%' }} className="bg-green-700">
                  <h1 className="text-white font-bold text-center">เงินสมทบ</h1>
                </TableCell>
                <TableCell sx={{ width: '10%' }} className="bg-green-700">
                  <h1 className="text-white font-bold text-center">เงินสมทบ</h1>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={salary.index}>
                <TableCell sx={{ width: '10%' }}>
                  <Controller
                    name="salary"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <div>
                    <Controller
                      name="ot"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <div>
                    <Controller
                      name="allowance"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <div>
                    <Controller
                      name="extra"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <h1 className="text-center">
                    {calculateSSO(salary?.salary)}
                  </h1>
                </TableCell>

                <TableCell sx={{ width: '10%' }}>
                  <div>
                    <Controller
                      name="etc"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <div>
                    <Controller
                      name="emExtra"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <div>
                    <Controller
                      name="emExtra2"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>{' '}
          </Table>
        </TableContainer>{' '}
      </form>
    </Paper>
  );

  const onSubmitSalary = async (data) => {
    try {
      // console.log("dataSubmit", data);
      await dispatch(
        actions.putSalary(params.id, {
          ...data,
          salary: data.salary,
          sumSalary:
            parseInt(salary?.salary) +
            parseInt(data?.ot) +
            parseInt(data?.extra) +
            parseInt(data?.allowance),
          taxSSO,
          datePay: date,
          file: _.isEmpty(file) ? undefined : file[0],
        })
      );
      await dispatch(actions.getAllSalary({ name: '', page: 1, size: 5 }));
      history.goBack();
    } catch (error) {}
  };

  if (salary.isloading) {
    return <>error</>;
  }
  if (!salary.isloading) {
    return (
      <div className="min-h-screen">
        <div className="pb-2">
          {renderTitle()}
          {renderBackButton()}
        </div>
        <Card>
          <CardContent>
            <div>
              <div className="flex">
                <div className="p-2 w-full">
                  <Card>
                    <CardContent>
                      {' '}
                      <form onSubmit={handleSubmit(onSubmitSalary)}>
                        <div className="flex justify-between">
                          <div>
                            <div>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  label="วันที่"
                                  value={date}
                                  onChange={(newValue) => {
                                    setDate(newValue);
                                  }}
                                  renderInput={(data) => (
                                    <TextField {...data} size="small" />
                                  )}
                                />
                              </LocalizationProvider>{' '}
                            </div>
                            <div className="pt-4">
                              <h1>เงินสมประกันสังคม</h1>

                              <TextField
                                size="small"
                                value={taxSSO}
                                onChange={handleChangeTax}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex pl-16 w-3/5">
                            <div>
                              <h1 className="font-bold">ชื่อ-สกุล</h1>
                              <h1 className="font-bold">เบอร์โทร</h1>
                              <h1 className="font-bold">ตำแหน่ง</h1>
                              <h1 className="font-bold">แผนก</h1>
                            </div>
                            <div className="pl-4">
                              <h1 className="font-bold">
                                {salary?.user?.name} {salary?.user?.surname}
                              </h1>
                              <h1 className="font-bold">
                                {salary?.user?.tel || '-'}
                              </h1>
                              <h1 className="font-bold">
                                {salary?.user?.position?.name || ''}
                              </h1>
                              <h1 className="font-bold">
                                {salary?.user?.under}
                              </h1>
                            </div>
                          </div>

                          <Button
                            variant="contained"
                            type="submit"
                            // onClick={onSubmitQuotation}
                          >
                            บันทึก
                          </Button>
                        </div>
                      </form>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
            <div>
              <div className="mt-2">{salarys()}</div>
            </div>
            <div className="pt-4 flex">
              <Button
                variant="contained"
                startIcon={<FileUploadIcon />}
                onClick={() => setModalFileOpen(true)}
              >
                อัพโหลด
              </Button>
              <div>
                {salary?.file && (
                  <div className="pl-4">
                    <a href={salary?.file?.url} target="_blank">
                      <h1 className="text-green-600 font-bold ">
                        {salary?.file?.file_name}
                      </h1>
                    </a>
                  </div>
                )}
                {!_.isEmpty(file) && (
                  <div className="pl-4">
                    <h1 className="font-bold">ไฟล์ใหม่</h1>
                    <h1>{file?.[0]?.name}</h1>
                  </div>
                )}{' '}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
  return <>error</>;
}
export default EditSalary;
