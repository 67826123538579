/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/aria-props */
import React, { useState, useEffect } from 'react';
import { Button, Chip, Paper, TablePagination } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Add from '@mui/icons-material/Add';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import Loading from 'components/Loading';
import FillterName from 'components/Card/FillterName';
import * as action from '../../redux/action';

function HrEmployee({ title, subtitle }) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [name, setName] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const userFilter = _.filter(user?.arr, { role: 'USER' });

  async function deleteUser(id) {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(action.deleteUser(id));
        dispatch(action.getAllUser({ page, size }));
      } catch (error) {
        alert('ลบข้อมูลไม่สำเร็จ');
      }
    }
  }
  useEffect(() => {
    dispatch(action.getAllUser({ page, size, name }));
  }, [page, size, name]);

  const handleAdd = () => {
    history.push('/hr/newemployee');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const renderSearch = () => (
    <div>
      <FillterName setName={setName} />
    </div>
  );
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderTable = () => (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <p className="font-bold">รหัสพนักงาน</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold">ชื่อ-นามสกุล</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold">เบอร์โทร</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold">ตำแหน่ง</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold">แผนก</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold">ดำเนินการ</p>
                </TableCell>
              </TableRow>
            </TableHead>

            {_.isEmpty(userFilter) ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    ไม่มีข้อมูล
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {userFilter?.map((users, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {users?.employeeId || '-'}
                    </TableCell>

                    <TableCell>
                      {users?.name} {users?.surname}{' '}
                      {users?.workStatus?.status && (
                        <Chip size="small" label="ลาออก" color="error" />
                      )}
                    </TableCell>

                    <TableCell>{users?.tel} </TableCell>

                    <TableCell> {users?.position?.name || ''}</TableCell>

                    <TableCell>{users?.department?.name} </TableCell>

                    <TableCell>
                      <Stack spacing={1} direction="row">
                        <Button
                          color="success"
                          variant="contained"
                          size="small"
                          onClick={
                            () =>
                              history.push(`/hr/detailemployee/${users._id}`)
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          color="warning"
                          variant="contained"
                          size="small"
                          onClick={
                            () => history.push(`/hr/editemployee/${users._id}`)
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                        >
                          แก้ไข
                        </Button>
                        <Button
                          color="error"
                          size="small"
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            deleteUser(users?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={user?.total}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );

  if (!user.isCompleted || user?.isLoading) {
    return (
      <div>
        <Loading isLoading />
      </div>
    );
  }
  if (user.isCompleted || !user?.isLoading) {
    return (
      <div>
        <div>
          <div className="flex justify-between py-4">
            {renderTitle()}
            <div>
              <Button
                variant="contained"
                startIcon={<Add />}
                color="primary"
                onClick={() => {
                  handleAdd();
                }}
              >
                เพิ่มพนักงาน
              </Button>
            </div>
          </div>
          {renderSearch()}
          {renderTable()}
        </div>
      </div>
    );
  }
}
HrEmployee.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
HrEmployee.defaultProps = {
  title: '',
  subtitle: '',
};

export default HrEmployee;
