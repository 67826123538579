import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
// import DescriptionIcon from '@mui/icons-material/Description';
import { Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { Link } from 'react-router-dom';

export default function POListTable({
  setPage,
  setSize,
  size,
  page,
  pickUpOrder,
  handlePrintQtReport = () => {},
  DeleteProduct = () => {},
}) {
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  return (
    <div>
      <div>
        {' '}
        <Paper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p className="font-bold">เลขที่</p>
                  </TableCell>
                  <TableCell>
                    <p className="font-bold">ชื่อลูกค้า</p>
                  </TableCell>
                  <TableCell>
                    <p className="font-bold">เบอร์โทร</p>
                  </TableCell>
                  <TableCell>
                    <p className="font-bold text-center">ดำเนินการ</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pickUpOrder.arr?.map((pickUpOrders) => (
                  <TableRow key={pickUpOrders._id}>
                    <TableCell component="th" scope="row">
                      <Link
                        to={`/purchase/pickUpOrder/edit/${pickUpOrders?._id}`}
                      >
                        {pickUpOrders.prefix + pickUpOrders.running_number}{' '}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/purchase/pickUpOrder/edit/${pickUpOrders?._id}`}
                      >
                        {pickUpOrders?.businessName}
                      </Link>{' '}
                    </TableCell>
                    <TableCell>{pickUpOrders?.tel}</TableCell>
                    <TableCell align="right">
                      <Stack
                        spacing={1}
                        direction="row"
                        className="justify-end"
                      >
                        <Button
                          variant="contained"
                          color="success"
                          startIcon={<PrintIcon />}
                          onClick={() => {
                            // console.log("print", invoices);
                            handlePrintQtReport(pickUpOrders);
                          }}
                        >
                          พิมพ์
                        </Button>

                        <Button
                          color="error"
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            DeleteProduct(pickUpOrders?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </Stack>
                    </TableCell>{' '}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={pickUpOrder?.total || 0}
            rowsPerPage={size}
            page={page - 1}
          />
        </Paper>
      </div>
    </div>
  );
}
