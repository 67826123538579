import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import * as action from '../../redux/action';
import AddWorkBu from '../../components/Forms/AddWorkBu';

function HrEditWork({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const user = useSelector((state) => state.user);
  const work = useSelector((state) => state.work);
  const department = useSelector((state) => state.Department);
  const [date, setDate] = React.useState(new Date());
  const { control, setValue, handleSubmit } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'employeeArray',
  });

  const {
    fields: departmentFields,
    append: departmentAppend,
    remove: departmentRemove,
  } = useFieldArray({
    control,
    name: 'departmentArr',
  });

  useEffect(() => {
    setValue('job', work?.name);
    setValue('time', work?.time);
    setValue('locationName', work?.locationName);
    setValue('vehicle', work?.vehicle);
    setValue('note', work?.note);
    setDate(work.date);
    setValue('employeeArray', work?.worker);
    setValue('departmentArr', work?.department);
    setValue('link_url', work?.link_url);
    return () => {};
  }, [work]);

  useEffect(() => {
    dispatch(action.getOnework(params.id));
    dispatch(action.getAllUser({}));
    dispatch(action.getAllDepartment({}));
    return () => {};
  }, []);
  const onSubmit = async (data) => {
    const confirm = window.confirm('ยืนยัน');
    if (confirm) {
      try {
        // console.log('data', data);
        const dataSubmit = {
          ...data,
          date,
          worker: data?.employeeArray,
          department: data?.departmentArr,
          name: data?.job,
        };

        await dispatch(action.putwork(params.id, dataSubmit));
        await dispatch(action.getAllwork({}));
        history.goBack();
      } catch (error) {
        console.log(error);
      }
    }
  };
  const props = {
    handlButtonDelete: async (id) => {
      const confirm = window.confirm('ยืนยันลบข้อมูล');
      if (confirm) {
        try {
          await dispatch(action.deletework(id));
          await dispatch(action.getAllwork({}));
          history.push('/work/calendar');
        } catch (error) {
          console.log(error);
        }
      }
    },
  };
  const renderEdit = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <div className="">
          <AddWorkBu
            control={control}
            Controller={Controller}
            work={work}
            setValue={setValue}
            fields={fields}
            append={append}
            remove={remove}
            user={user}
            date={date}
            setDate={setDate}
            {...props}
            department={department}
            departmentFields={departmentFields}
            departmentAppend={departmentAppend}
            departmentRemove={departmentRemove}
          />
        </div>
      </Card>
    </form>
  );
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  return (
    <div>
      {renderTitle()}
      <div className="py-2">
        <BackButton />
      </div>
      {renderEdit()}
    </div>
  );
}

HrEditWork.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
HrEditWork.defaultProps = {
  title: '',
  subtitle: '',
};

export default HrEditWork;
