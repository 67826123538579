import React, { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Button } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

export default function Cameras({ imgSrc, setImgSrc }) {
  const webcamRef = useRef(null);
  const [mirrored, setMirrored] = useState(false);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    // console.log(imageSrc);
    setImgSrc(imageSrc);
    // setImgOut(imageSrc);
  }, [webcamRef]);

  const retake = () => {
    setImgSrc(null);
  };

  return (
    <div className="container  ">
      {imgSrc ? (
        <img src={imgSrc} alt="webcam" />
      ) : (
        <Webcam
          height={600}
          width={600}
          ref={webcamRef}
          mirrored={mirrored}
          screenshotFormat="image/jpeg"
          screenshotQuality={0.8}
          
        />
      )}
      <div className="controls">
        <div>
          <input
            type="checkbox"
            onChange={(e) => setMirrored(e.target.checked)}
          />
          สลับด้านกล้อง
        </div>
      </div>
      <div className="btn-container text-center">
        {imgSrc ? (
          <Button color="error" variant="contained" onClick={retake}>
            Retake photo
          </Button>
        ) : (
          <Button color="success" variant="contained" onClick={capture}>
            {' '}
            <CameraAltIcon /> ถ่ายรูป
          </Button>
        )}
      </div>
    </div>
  );
}
