/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/aria-props */
import React, { useState, useEffect } from 'react';
import { Button, TablePagination, Paper, Card } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useHistory } from 'react-router';
import PrintIcon from '@mui/icons-material/Print';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LeaveReport from 'components/Report/leaveReport';
//import leaveReportCopy from 'components/Report/leaveReportCopy';
import { ViewTitle } from 'components/ViewTitle';
import FillterName from 'components/Card/FillterName';
import * as action from '../../redux/action';
import _ from 'lodash';
import leaveReportNew from 'components/Report/common/leaveReportNew';
function HrDoc({ title, subtitle }) {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(5);
  const history = useHistory();
  const dispatch = useDispatch();
  const [findLeaveDoc, setFindLeaveDoc] = useState('');

  const leaveDoc = useSelector((state) => state.leaveDoc);

  const renderLayout = () => <ViewTitle title={title} subtitle={subtitle} />;
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  function deleteLeaveDoc(id) {
    dispatch(action.deleteLeaveDoc(id));
    dispatch(action.getAllLeaveDoc({ name: findLeaveDoc, page, size }));
  }
  useEffect(() => {
    dispatch(action.getAllLeaveDoc({ name: findLeaveDoc, page, size }));
  }, [findLeaveDoc, page, size]);

  function handleEditLeaveDoc(docId) {
    history.push(`/hr/leaveform/${docId}`);
  }
  function handlePrintLeaveDoc(docData) {
    leaveReportNew(docData);
  }
  function handlePrintLeaveDoc1(docData) {
    LeaveReport(docData);
  }

  const renderTable = () => (
    <Card>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <p className="font-bold">ลำดับ</p>
              </TableCell>
              <TableCell>
                <p className="font-bold">ชื่อ-นามสกุล</p>
              </TableCell>
              <TableCell>
                <p className="font-bold">ชนิดการลา</p>
              </TableCell>
              <TableCell>
                <p className="font-bold">ตำแหน่ง</p>
              </TableCell>
              <TableCell>
                <p className="font-bold">ผลการพิจารณา</p>
              </TableCell>
              <TableCell>
                <p className="font-bold">ดำเนินการ</p>
              </TableCell>
            </TableRow>
          </TableHead>

          {_.isEmpty(leaveDoc?.arr) ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} align="center">
                  ไม่มีข้อมูล
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {leaveDoc?.arr?.map((leaveDocs, index) => (
                <TableRow key={leaveDocs.index}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    {leaveDocs.name} {leaveDocs.surname}
                  </TableCell>
                  <TableCell>
                    {' '}
                    {leaveDocs?.leaveTypeID?.name
                      ? leaveDocs?.leaveTypeID?.name
                      : leaveDocs?.leaveType}
                  </TableCell>
                  <TableCell>{leaveDocs.position}</TableCell>
                  <TableCell>
                    <div className="font-bold">{leaveDocs.status}</div>
                  </TableCell>
                  <TableCell>
                    <Stack spacing={1} direction="row">
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<PrintIcon />}
                        onClick={() => {
                          handlePrintLeaveDoc(leaveDocs);
                        }}
                      >
                        พิมพ์
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<FactCheckIcon />}
                        onClick={() => {
                          handleEditLeaveDoc(leaveDocs._id);
                        }}
                      >
                        ตรวจสอบ
                      </Button>
                      <Button
                        color="error"
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        disabled={leaveDocs?.approve_status}
                        onClick={() => {
                          deleteLeaveDoc(leaveDocs?._id);
                        }}
                      >
                        ลบ
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        count={leaveDoc?.total}
        rowsPerPage={size}
        page={page - 1}
      />
    </Card>
  );

  return (
    <div className="min-h-screen">
      {renderLayout()}
      <div className="">
        <div className="pb-2">
          <FillterName setName={setFindLeaveDoc} />
        </div>
        {renderTable()}
      </div>
    </div>
  );
}
HrDoc.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
HrDoc.defaultProps = {
  title: '',
  subtitle: '',
};
export default HrDoc;
