import {
  PRODUCT_ALL,
  PRODUCT_DELETE,
  PRODUCT_GET,
  PRODUCT_LOADING,
  PRODUCT_PUT,
  PRODUCT_ERROR,
  PRODUCT_POST,
} from '../type';
import api from '../../utils/functions/api';

export const getAllProduct = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product?name=${name}&size=${size}&page=${page}`
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
  }
};
export const getOneProduct = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product/${id}`
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/product/${id}`
    );
    dispatch({ type: PRODUCT_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
  }
};

export const addProduct = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/product`,
      {
        ...payload,
      }
    );
    dispatch({ type: PRODUCT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
  }
};

export const putProduct = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/product/${id}`,
      payload
    );
    dispatch({ type: PRODUCT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
  }
};
