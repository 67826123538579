import {
  TIMESTAMP_SETTING_ALL,
  TIMESTAMP_SETTING_DELETE,
  TIMESTAMP_SETTING_GET,
  TIMESTAMP_SETTING_LOADING,
  TIMESTAMP_SETTING_POST,
  TIMESTAMP_SETTING_PUT,
} from '../type';

const initialState = {
  TimeStampSetting: null,
  isLoading: true,
  isCompleted: true,
};

function TimeStampSettingReducer(state = initialState, action) {
  switch (action.type) {
    case TIMESTAMP_SETTING_LOADING:
      return { isLoading: true };
    case TIMESTAMP_SETTING_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case TIMESTAMP_SETTING_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case TIMESTAMP_SETTING_POST:
      return {
        ...action.payload,
        iisLoading: false,
        isCompleted: true,
      };
    case TIMESTAMP_SETTING_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case TIMESTAMP_SETTING_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default TimeStampSettingReducer;
