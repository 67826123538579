import { TextField } from '@mui/material';
import React from 'react';

export default function LocationAdd({ setLocations, locations }) {
  return (
    <div>
      <div className="">
        <TextField
          id="outlined-basic"
          defaultValue={locations.latitude ? locations?.latitude : ''}
          label="latitude"
          margin="dense"
          variant="outlined"
          onChange={(e) =>
            setLocations({ ...locations, latitude: e.target.value })
          }
          fullWidth
        />
      </div>
      <div className="">
        <TextField
          id="outlined-basic"
          label="longitude"
          margin="dense"
          variant="outlined"
          defaultValue={locations.longitude ? locations?.longitude : ''}
          onChange={(e) =>
            setLocations({ ...locations, longitude: e.target.value })
          }
          fullWidth
        />
      </div>
    </div>
  );
}
