import React from 'react';
import _ from 'lodash';
import { Box, Card } from '@mui/material';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { ViewTitle } from 'components/ViewTitle';

function HrDashboard({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const renderViewtitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderViewtitle()}
      <div className="">
        <Box>
          <Card>dsds</Card>
        </Box>
      </div>
    </div>
  );
}
export default HrDashboard;
