/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  Modal,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Chip,
  FormControlLabel,
  Switch,
  Button,
  Link,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { title } from 'process';
import { name } from 'dayjs/locale/th';
import * as action from '../../redux/action';
import { useDispatch } from 'react-redux';
// import _ from 'lodash';
// const status = useSelector((state) => state.status);

export default function PaymentInfoBu({
  openJob,
  setOpenJob,
  job,
  setValue,
  handlButtonDelete = () => {},
}) {
  const history = useHistory();
  const handleClose = () => setOpenJob(false);

  const handleEdit = async (id) => {
    history.push(`/salary/editaddsalary/${id}`);
  };

  const dispatch = useDispatch();
  // const handlButtonDelete = async (id) => {
  //   const confirm = window.confirm('ยืนยันลบข้อมูล');
  //   if (confirm) {
  //     try {
  //       await dispatch(action.paymentDelete(id));
  //       await dispatch(action.paymentGetAll({}));
  //       history.push('/salary/calendar');
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  // const handleEdit = async (id) => {
  //   history.push(`/work/edit/${id}`);
  // };

  // const handleDelete = (index) => {
  //   remove(index);
  // };

  // function getColor(props) {
  //   const color = props.color;
  //   if
  //   }

  const { control } = useForm({
    mode: 'onChange',
  });
  const renderDetailPayment = () => (
    <div className="px-4 px-2">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <h1 className="text-xl">ชื่อบริษัท</h1>
            </TableCell>
            <TableCell>
              <h1 className="text-xl">{job?.name}</h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1>วันที่ครบกำหนด</h1>
            </TableCell>
            <TableCell>
              <h1>{dayjs(job?.date).format('DD/MM/BBBB')}</h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1>โทรศัพท์</h1>
            </TableCell>
            <TableCell>
              <h1>{job?.tel || '-'}</h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1>หมายเหตุ</h1>
            </TableCell>
            <TableCell>
              <h1>{job?.note || '-'}</h1>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );

  return (
    <div>
      <Modal
        open={openJob}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            // height: 200,
            // width: 200,
          }}
        >
          <Card sx={{ width: 400 }}>
            <div className="p-4">
              <div className="flex justify-between">
                <h1 className=" text-2xl ">รายละเอียด </h1>
                <div className="justify-end pr-4 m-2">
                  <Button
                    variant="contained"
                    color="warning"
                    size="small"
                    onClick={() => handleEdit(job._id)}
                  >
                    แก้ไข
                  </Button>{' '}
                  {''}
                  <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handlButtonDelete(job?._id);
                    }}
                  >
                    ลบ
                  </Button>
                </div>
              </div>

              <div className="flex">
                {!job?.allDay ? (
                  <></>
                ) : (
                  <Chip label="เต็มวัน" color="success" size="large" /> || '-'
                )}
              </div>
            </div>
            {renderDetailPayment()}
          </Card>
        </Box>
        {/* </form> */}
      </Modal>
    </div>
  );
}
