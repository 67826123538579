/* eslint-disable react/prop-types */
import { Button, Paper, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { useHistory } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import TablePagination from '@mui/material/TablePagination';
import { BackButton } from 'components/Button';
import Loading from 'components/Loading';
import * as action from '../../redux/action';
import { ViewTitle } from '../../components/ViewTitle';

export default function Confirmleave({ title, subtitle }) {
  dayjs.extend(buddhistEra);
  const dispatch = useDispatch();
  const history = useHistory();
  const me = useSelector((state) => state.me);
  const leaveDoc = useSelector((state) => state.leaveDoc);
  const settings = useSelector((state) => state.setting);

  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(5);

  const setting = settings?.arr;

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleViewLeaveDoc = (docId) => {
    history.push(`/work/leave-work/aprove/${docId}`);
  };

  function disabledButton(status) {
    if (status === 'อนุมัติ') {
      return true;
    }
    return false;
  }
  useEffect(() => {
    // dispatch(action.resetLeaveDoc());
    dispatch(
      action.getAllLeaveDoc({
        approve_status: false,
        approve: me?.userData?._id,
        page,
        size,
      })
    );
  }, [page, size]);

  const deleteLeaveDoc = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(action.deleteLeaveDoc(id));
        dispatch(
          action.getAllLeaveDoc({ name: me?.userData?.name, page: 1, size: 5 })
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  if (!leaveDoc.isLoading || leaveDoc.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className=" py-2"> </div>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <p className="font-bold" />
                </TableCell>
                <TableCell>
                  {' '}
                  <p className="font-bold">ชื่อ-นามสกุล</p>
                </TableCell>
                <TableCell>
                  {' '}
                  <p className="font-bold">ลา</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold">วันที่ลา</p>
                </TableCell>
                {setting?.multipleApplover && (
                  <TableCell>
                    <p className="font-bold">การพิจาณา</p>
                  </TableCell>
                )}
                <TableCell>
                  <p className="font-bold">ผลการอนุมัติ</p>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {leaveDoc?.arr?.length === 0 ? (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={setting?.multipleApplover ? 5 : 6}
                  >
                    ไม่มีรายการ
                  </TableCell>
                </TableRow>
              ) : (
                leaveDoc?.arr?.map((leaveDocs) => (
                  <TableRow key={leaveDocs.index}>
                    <TableCell component="th" scope="row" />
                    <TableCell>
                      {leaveDocs?.name} {leaveDocs?.surname}
                    </TableCell>
                    <TableCell>
                      {leaveDocs?.leaveTypeID?.name
                        ? leaveDocs?.leaveTypeID?.name
                        : leaveDocs?.leaveType}
                    </TableCell>
                    <TableCell>
                      {dayjs(leaveDocs?.dateStart).format('DD/MM/BBBB')}
                    </TableCell>
                    {setting?.multipleApplover && (
                      <TableCell>
                        <div>
                          <div>
                            ผู้พิจารณาคนที่ 1 : {leaveDocs?.approve1?.status}
                          </div>
                          <div>
                            ผู้พิจารณาคนที่ 2 : {leaveDocs?.approve2?.status}
                          </div>
                        </div>
                      </TableCell>
                    )}
                    <TableCell>
                      <div>{leaveDocs?.status}</div>
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        spacing={1}
                        direction="row"
                        className="justify-center"
                      >
                        <Button
                          variant="contained"
                          startIcon={<FactCheckIcon />}
                          size="small"
                          onClick={() => {
                            handleViewLeaveDoc(leaveDocs._id);
                          }}
                        >
                          ตรวจสอบ
                        </Button>

                        <Button
                          color="error"
                          variant="contained"
                          size="small"
                          disabled={disabledButton(leaveDocs?.status)}
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            deleteLeaveDoc(leaveDocs?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={leaveDoc?.total || 0}
            rowsPerPage={size}
            page={page - 1}
          />
        </TableContainer>
      </div>
    );
  }
  return <Loading isLoading />;
}
