import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../../redux/action';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import PrintIcon from '@mui/icons-material/Print';
import { Card, CardContent } from '@mui/material';
import { TextField } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import Button from '@mui/material/Button';
import PoReport from '../../../components/Report/PoReport';
import { useHistory } from 'react-router';
import { ViewTitle } from 'components/ViewTitle';
function Po({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const purchase = useSelector((state) => state.purchase);
  const information = useSelector((state) => state.information);
  const [findName, setFindName] = useState('');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(5);

  useEffect(() => {
    dispatch(action.getAllPurchase({ name: findName, page, size }));
    return () => {};
  }, [findName, page, size]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };

  const handlePrintQtReport = (data) => {
    PoReport(data, information);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const DeleteProduct = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(action.deletePurchasee(id));
        await dispatch(action.getAllPurchase({ findName, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };
  const onCreateRe = (id) => {
    history.push(`/purchase/order/pickUp/${id}`);
  };

  const renderViewTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderAddButton = () => (
    <Button
      variant="contained"
      onClick={() => history.push('/purchase/order/new')}
      size="large"
    >
      เพิ่ม
    </Button>
  );

  if (purchase.isloading) {
    return <div>loading</div>;
  }
  if (!purchase.isloading) {
    return (
      <div>
        <div className="flex justify-between">
          {renderViewTitle()}
          <div className="py-4">{renderAddButton()}</div>
        </div>
        <Card className="mb-2">
          <CardContent>
            <div>
              <div className="flex">
                <TextField
                  id="outlined-basic"
                  label="ค้นหา"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setFindName(e.target.value)}
                  fullWidth
                />
              </div>
            </div>
          </CardContent>
        </Card>
        <Paper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p className="font-bold">เลขที่</p>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <p className="font-bold">ชื่อลูกค้า</p>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <p className="font-bold">เบอร์โทร</p>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchase.arr?.map((purchases) => (
                  <TableRow key={purchases._id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/purchase/order/edit/${purchases?._id}`}>
                        {purchases.prefix + purchases.running_number}{' '}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={`/purchase/order/edit/${purchases?._id}`}>
                        {purchases?.businessName}
                      </Link>{' '}
                    </TableCell>
                    <TableCell>{purchases?.tel}</TableCell>
                    <TableCell>
                      {purchases?.status_pickUpOrder ? (
                        <h1 className="text-green-600">รับสินค้า</h1>
                      ) : (
                        <h1 className="text-red-600">ยังไม่รับสินค้า</h1>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        spacing={1}
                        direction="row"
                        className="justify-end"
                      >
                        <Button
                          variant="contained"
                          color="success"
                          startIcon={<PrintIcon />}
                          onClick={() => {
                            // console.log("print", invoices);
                            handlePrintQtReport(purchases);
                          }}
                        >
                          พิมพ์
                        </Button>

                        <Button
                          variant="contained"
                          startIcon={<DescriptionIcon />}
                          onClick={() => {
                            onCreateRe(purchases?._id);
                          }}
                        >
                          สร้างใบรับสินค้า
                        </Button>

                        <Button
                          color="error"
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            DeleteProduct(purchases?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </Stack>
                    </TableCell>{' '}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={purchase?.total || 0}
            rowsPerPage={size}
            page={page - 1}
          />
        </Paper>
      </div>
    );
  }
  return <h1>404</h1>;
}
export default Po;
