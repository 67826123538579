import {
  DEPARTMENT_ALL,
  DEPARTMENT_DELETE,
  DEPARTMENT_GET,
  DEPARTMENT_LOADING,
  DEPARTMENT_POST,
  DEPARTMENT_PUT,
} from '../type';

const initialState = {
  Department: null,
  isLoading: true,
  isCompleted: true,
};

function DepartmentReducer(state = initialState, action) {
  switch (action.type) {
    case DEPARTMENT_LOADING:
      return { isLoading: true, isCompleted: false };
    case DEPARTMENT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DEPARTMENT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DEPARTMENT_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DEPARTMENT_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case DEPARTMENT_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default DepartmentReducer;
