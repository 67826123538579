import {
  INFORMATION_ALL,
  INFORMATION_LOADING,
  INFORMATION_POST,
  INFORMATION_PUT,
  INFORMATION_GET,
} from '../type';

function InformationReducer(state = { salary: null }, action) {
  switch (action.type) {
    case INFORMATION_LOADING:
      return { isLoading: true };
    case INFORMATION_ALL:
      return {
        ...action.payload,
        isLoading: false,
      };
    case INFORMATION_GET:
      return {
        ...action.payload,
        isLoading: false,
      };
    case INFORMATION_POST:
      return {
        ...action.payload,
        isLoading: true,
      };
    case INFORMATION_PUT:
      return {
        isLoading: true,
      };
    default:
      return state;
  }
}

export default InformationReducer;
