import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/th';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const LeaveReport = async (data) => {
  const calculateDayLeave = () => {
    const date1 = dayjs(data?.dateEnd).startOf('day');
    const date2 = dayjs(data?.dateStart).startOf('day');
    const date = date1.toDate().getTime() - date2.toDate().getTime();

    const sumday = date / (1000 * 3600 * 24);

    return sumday + 1;
  };

  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 20, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },

    content: [
      { text: 'ใบลา', style: 'header', margin: [0, 0, 0, 20] },
      {
        table: {
          widths: ['*', '40%'],
          body: [
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: 'เขียนที่  ' + `${data?.address || '-'}\n`,
                style: 'body',

                border: [false, false, false, false],
              },
            ],
            [
              { text: '', border: [false, false, false, false] },
              {
                text:
                  '\nวันที่   ' +
                  `${dayjs(data.date).format('DD เดือน MMMM พ.ศ BBBB')}`,
                style: 'body',

                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        text: 'เรียน ' + `${data?.approverName || '-'}`,
        margin: [40, 50, 14, 0],
        style: 'body',
      },
      {
        text: [
          {
            text: '.\t\t\t',
            style: 'tab',
          },
          {
            text: 'ข้าพเจ้า  ' + `${data?.name || '-'}\t`,
            style: 'body',
          },
          'ตำแหน่ง \t' +
            `${data?.position || ''}\t` +
            'สังกัด \t' +
            `${data?.under || ''}\t`,

          '  ขอ ' +
            `${data?.leaveTypeID?.name || ''}\t` +
            'เนื่องจาก ' +
            `${data?.leaveCause || '-'}\t` +
            ' ตั้งแต่วันที่ ' +
            `${dayjs(data?.dateStart).format('DD MMMM BBBB') || ''} ` +
            ' ถึงวันที่ ' +
            `${dayjs(data?.dateEnd).format('DD MMMM BBBB') || ''}  ` +
            ` มีกำหนด ${calculateDayLeave()} วัน\t`,

          'ข้าพเจ้าได้ ' +
            `${data?.leaveType || ''} ` +
            ' ครั้งสุดท้ายตั้งแต่วันที่  ' +
            `${dayjs(data?.dateStart).format('DD MMMM BBBB') || ''}   ` +
            ' ถึงวันที่    ' +
            `${dayjs(data?.dateEnd).format('DD MMMM BBBB') || ''}  ` +
            ` มีกำหนด  ${calculateDayLeave()} วัน\t` +
            ' ในระหว่างลาจะติดต่อข้าพเจ้าได้ที่ \t' +
            `${data.contact || '-'}`,
        ],
        margin: [40, 20, 14, 0],
        style: 'body',
      },
      { text: '', margin: [0, 20, 0, 20] },
      {
        table: {
          widths: ['*', '45%'],

          body: [
            [
              {
                text: '',

                border: [false, false, false, false],
              },
              {
                text: 'ของแสดงความนับถือ',
                style: 'bodySign',

                border: [false, false, false, false],
              },
            ],
            [
              { text: '', border: [false, false, false, false] },
              {
                text:
                  '(ลงชื่อ)........................................................\t\n\n' +
                  `(${data?.name || '...............................'} )`,
                style: 'bodySign',
                margin: [0, 50, 0, 20],

                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      { text: '', margin: [0, 40, 0, 20] },
      {
        table: {
          widths: ['*', '45%'],

          body: [
            [
              {
                text: 'ความเห็นผู้บังคับบัญชา',
                style: 'header1',
                margin: [40, 40, 0, 4],
                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'bodySign',

                border: [false, false, false, false],
              },
            ],
            [
              {
                colSpan: 2,
                border: [false, false, false, false],
                margin: [40, 0, 0, 4],
                text:
                  '..................................................................................................................................................................................................................\n' +
                  '..................................................................................................................................................................................................................\n' +
                  '..................................................................................................................................................................................................................\n' +
                  '..................................................................................................................................................................................................................\n',
              },
              '',
            ],
            [
              {
                colSpan: 2,
                text: '( ) อนุญาติ\t ( ) ไม่อนุญาติ ',
                border: [false, false, false, false],
                style: 'header2',
                margin: [40, 0, 0, 4],
              },
              '',
            ],
            [
              { text: '', border: [false, false, false, false] },
              {
                text:
                  '(ลงชื่อ)........................................................\t\n\n' +
                  '(ตำแหน่ง)......................................................\t\n\n' +
                  '( วันที่ ).......................................................\t\n',
                style: 'bodySign2',
                margin: [0, 50, 0, 20],

                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],

    footer: [],

    styles: {
      header: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
      },
      header1: {
        fontSize: 14,
        bold: true,
        alignment: 'left',
      },
      header2: {
        fontSize: 13,
        bold: true,
        alignment: 'left',
      },
      body: {
        fontSize: 12,
        alignment: 'left',
      },
      body1: {
        alignment: 'left',
        fontSize: 12,
        bold: true,
      },
      bodySign: {
        alignment: 'center',
        fontSize: 12,
      },
      bodySign2: {
        alignment: 'center',
        fontSize: 11,
        bold: true,
      },
      tab: {
        color: 'white',
      },
    },
    images: {},
  };
  pdfMake.createPdf(documentRef).open();
};
export default LeaveReport;
