import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ViewTitle } from 'components/ViewTitle';
import {
  Card,
  Box,
  Button,
  Modal,
  Stack,
  FormControlLabel,
  Switch,
} from '@mui/material';
// eslint-disable-next-line import/order
import * as actions from '../../../redux/action';
import ShowCalendar from 'components/Calendar/ShowCalendar';
import PropTypes from 'prop-types';
import Add from '@mui/icons-material/Add';

import JobInfoBu from 'components/Modal/JobInfoBu';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import AddSalary from '../../../components/Forms/AddSalary';
import ShowPayment from '../../../components/Calendar/ShowPayment';
import PaymentInfoBu from '../../../components/Modal/PaymentInfoBu';
//import * as action from '../../../redux/action';
import { useHistory } from 'react-router';

export default function FinanceCalendar({ title, subtitle }) {
  const payment = useSelector((state) => state.payment);
  const [openJob, setOpenJob] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [job, setJob] = React.useState();
  const history = useHistory();

  const dispatch = useDispatch();
  const [date, setDate] = React.useState(new Date());
  // const [events, setEvents] = React.useState(false);

  const handleJobOpen = () => setOpenJob(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { control, setValue, handleSubmit, reset } = useForm({
    mode: 'onChange',
  });
  //const handleEvents = () => setEvents(true);
  console.log('Payment', payment);

  const defaultValue = {
    payment: '',
  };
  const props = {
    handlButtonDelete: async (id) => {
      const confirm = window.confirm('ยืนยันลบข้อมูล');
      if (confirm) {
        try {
          await dispatch(actions.paymentDelete(id));
          window.location.reload();
          await dispatch(actions.paymentGetAll({}));

          history.push('/salary/calendar');
        } catch (error) {
          console.log(error);
        }
      }
    },
  };

  useEffect(() => {
    dispatch(
      actions.paymentGetAll({
        page: 1,
        size: 100,
      })
    );

    return () => {};
  }, []);
  const renderCalendar = () => (
    <div>
      <div className="">
        <Box sx={{}}>
          <Card>
            <ShowPayment
              payment={payment}
              setJob={setJob}
              handleJobOpen={handleJobOpen}
              // handleEvents={handleEvents}
            />
          </Card>
        </Box>
      </div>
    </div>
  );
  const renderModal = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
            }}
          >
            <AddSalary
              control={control}
              Controller={Controller}
              setValue={setValue}
              date={date}
              setDate={setDate}
            />
          </Box>
        </form>
      </Modal>
    </div>
  );
  const onSubmit = async (data) => {
    const confirm = window.confirm('ยืนยัน');
    if (confirm) {
      try {
        const dataSubmit = {
          ...data,
          date,
          name: data?.name,
          tel: data?.tel,
          note: data?.note,
          overdue: data?.overdue,
          follow: data?.follow,
          success: data?.success,
        };
        await dispatch(actions.paymentCreate(dataSubmit));
        await dispatch(actions.paymentGetAll({}));
        handleClose();
        await reset(defaultValue);
      } catch (error) {}
    }
    console.log('data', data);
  };

  const renderButton = () => (
    <div className="pb-4 flex justify-end">
      <Stack spacing={2} direction="row">
        <Button variant="contained" startIcon={<Add />} onClick={handleOpen}>
          เพิ่มการชำระเงิน
        </Button>
      </Stack>
      {renderModal()}
      {renderModalJob()}
    </div>
  );

  const renderModalJob = () => (
    <PaymentInfoBu
      openJob={openJob}
      setOpenJob={setOpenJob}
      job={job}
      {...props}
    />
  );

  const renderViewTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  return (
    <div>
      {renderViewTitle()}
      {renderButton()}
      {renderCalendar()}
      {renderModalJob()}
    </div>
  );
}

FinanceCalendar.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
FinanceCalendar.defaultProps = {
  title: '',
  subtitle: '',
};
