/* eslint-disable no-else-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable indent */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { Button, Card, CardContent, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../../redux/action';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory, useParams } from 'react-router-dom';

import ProductServe from 'components/Forms/FormProductArray';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';

function EditPickUpOrder({ title, subtitle }) {
  const { handleSubmit, control, setValue, watch } = useForm({});
  const [date, setDate] = React.useState(new Date());
  const [dueDate, setDueDate] = React.useState(new Date());
  const [productVat, setProductVat] = useState(false);
  const history = useHistory();
  const params = useParams();
  dayjs.extend(buddhistEra);

  const pickUpOrder = useSelector((state) => state.pickUpOrder);
  const dispatch = useDispatch();
  const [productList, setProductList] = useState([]);

  const onSubmitPurchase = async (data) => {
    if (productList.length === 0) alert('กรุณาเพิ่มสินค้า');
    else {
      try {
        const ProcessData = await {
          ...data,
          productVat,
          date,
          dueDate,
          allPrice: allPrice(),
          product: data?.productArray,
        };
        await dispatch(action.putPickUpOrder(params.id, ProcessData));
        await dispatch(
          action.getAllPickUpOrder({ name: '', page: 1, size: 5 })
        );
        history.push('/purchase/pickUpOrder');
      } catch (error) {}
    }
  };

  useEffect(() => {
    dispatch(action.getOnePickUpOrder(params.id));
  }, []);

  useEffect(() => {
    setValue('businessName', pickUpOrder?.businessName);
    setValue('date', pickUpOrder?.date);
    setValue('TaxId', pickUpOrder?.TaxId);
    setValue('tel', pickUpOrder?.tel);
    setValue('name', pickUpOrder?.name);
    setValue('address', pickUpOrder?.address);
    setValue('payment', pickUpOrder?.payment);
    setValue('doc', pickUpOrder?.doc);
    setValue('note', pickUpOrder?.note);
    setValue('productArray', pickUpOrder?.product);
    setProductVat(pickUpOrder?.productVat);
    return () => {};
  }, [pickUpOrder]);
  useEffect(() => {
    // console.log("product list", productList);
    return () => {};
  }, []);

  const sumPrice = () => {
    if (productList?.length === 0) {
      return 0;
    } else {
      const sumP = _.sumBy(productList, (each) => each?.price * each?.quantity);
      return sumP;
    }
  };
  const sumDiscount = () => {
    if (productList?.length === 0) {
      return 0;
    } else {
      const sumP = _.sumBy(productList, (each) => parseInt(each?.discount));
      return sumP;
    }
  };
  const Price = () => {
    if (productList?.length === 0) {
      return 0;
    }
    return sumPrice() - sumDiscount();
  };
  const taxPrice = () => {
    if (productVat) {
      return 0;
    }
    return Price() * 0.07;
  };

  const allPrice = () => {
    return Price() + taxPrice();
  };

  const renderViewTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;

  return (
    <form onSubmit={handleSubmit(onSubmitPurchase)}>
      <div className="min-h-screen ">
        {renderViewTitle()}
        <div className="flex justify-between">
          {renderBackButton()}
          <Button variant="contained" type="submit" size="large">
            บันทึก
          </Button>
        </div>

        <div className="flex w-full pt-6 h-full">
          <div>
            <Card className="justify-center mt-2 ">
              <CardContent>
                <h1 className="pb-4"> ข้อมูลผู้ขาย</h1>
                <Controller
                  name="businessName"
                  control={control}
                  defaultValue={''}
                  rules={{ required: false }}
                  // defaultValue={{ businessname: "" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      label="ชื่อลูกค้า"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="TaxId"
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      label="หมายเลขผู้เสียภาษี"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="tel"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      id="outlined-basic"
                      label="เบอร์โทร"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="address"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      id="outlined-multiline-basic"
                      label="ที่อยู่"
                      variant="outlined"
                      fullWidth
                      multiline
                      margin="normal"
                      rows={4}
                    />
                  )}
                />
              </CardContent>
            </Card>
          </div>
          <div className="่ w-full  pl-4 ">
            <div>
              <Card>
                <CardContent>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่"
                      value={pickUpOrder?.date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่วันที่กำหนดส่ง"
                      value={pickUpOrder?.dueDate}
                      onChange={(newValue) => {
                        setDueDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <Controller
                    name="doc"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="เอกสารอ้างอิง"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="payment"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="วิธีการชำระเงิน"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-2">
            <ProductServe
              control={control}
              statusHide={false}
              statusPickUp={true}
              setProductList={setProductList}
              setValue={setValue}
              edit={true}
              watch={watch}
              setProductVat={setProductVat}
              productVat={productVat}
            />
          </div>
          <Card>
            <CardContent>
              <div className="flex w-full ">
                <div className="w-1/2">
                  {' '}
                  <Controller
                    name="note"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-multiline-basic"
                        label="หมายเหตุ"
                        variant="outlined"
                        fullWidth
                        multiline
                        margin="normal"
                        rows={4}
                      />
                    )}
                  />
                </div>

                <div className="flex justify-between  w-1/2 ml-2  ">
                  <div>
                    <div>รวมเงิน </div>
                    <div>ส่วนลดการค้า</div>
                    <div>เงินหลังหักส่วนลด</div>
                    <div>VAT 7%</div>
                    <div className="font-bold ">จำนวนเงินทั้งสิ้น </div>
                  </div>
                  <div className="text-right">
                    <div>
                      {sumPrice()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div>
                      {sumDiscount()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div>
                      {Price()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>
                    <div>
                      {taxPrice()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div className="font-bold ">
                      {allPrice()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </form>
  );
}
export default EditPickUpOrder;
