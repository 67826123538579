/* eslint-disable react/prop-types */
import { TextField } from '@mui/material';
import React from 'react';

export default function FormInfoBusiness({ Controller, control }) {
  return (
    <div>
      <div className="lg:grid grid-cols-2 gap-1 ">
        <div>
          <Controller
            name="name"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                value={field.value}
                id="outlined-basic"
                label="ชื่อบริษัท"
                margin="dense"
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="nameEng"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                id="outlined-basic"
                label="ชื่อบริษัท ภาษาอังกฤษ(ถ้ามี)"
                margin="dense"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="taxId"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                value={field.value}
                id="outlined-basic"
                label="เลขที่ผู้เสียภาษี"
                margin="dense"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="tel"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                value={field.value}
                id="outlined-basic"
                label="เบอร์โทร"
                margin="dense"
                type="string"
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="email"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                value={field.value}
                id="outlined-basic"
                label="e-mail"
                margin="dense"
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="webUrl"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                id="outlined-basic"
                label="เว็บไซต์"
                margin="dense"
                variant="outlined"
                size="small"
                multiline
                fullWidth
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="address"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                value={field.value}
                id="outlined-basic"
                label="ที่อยู่"
                margin="dense"
                variant="outlined"
                size="small"
                multiline
                rows={2}
                fullWidth
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
