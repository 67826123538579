/* eslint-disable jsx-a11y/aria-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from 'react';
import {
  Button,
  CardContent,
  TextField,
  Card,
  Paper,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Modal,
  Box,
  Checkbox,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import * as action from '../../redux/action';
import Loading from '../../components/Loading';

function Department({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const department = useSelector((state) => state.Department);
  const setting = useSelector((state) => state.setting);
  const { handleSubmit, control, reset } = useForm({ mode: 'onChange' });
  const [name, setName] = React.useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(action.getAllDepartment({ name, page, size }));
    dispatch(action.settingGetAll({}));
  }, [name, page, size]);

  const module = setting?.arr;

  const defaultValue = {
    name: '',
    FND: '',
    SLD: '',
    HRD: '',
    PCD: '',
    PDD: '',
    ADM: '',
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const deleteDepartment = async (id) => {
    try {
      const confirm = window.confirm('ยืนยันการลบข้อมูล');
      if (confirm) {
        await dispatch(action.deleteDepartment(id));
        await dispatch(action.getAllDepartment({ name, page, size }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderTable = () => (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <p className="font-bold text-center ">ลำดับ</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold text-center ">ชื่อแผนก</p>
                </TableCell>
                <TableCell>
                  {' '}
                  <p className="font-bold text-center ">ดำเนินการ</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {department?.arr?.map((eachDepartment, index) => (
                <TableRow key={eachDepartment.index}>
                  <TableCell component="th" scope="row" align="center">
                    {index + 1}
                  </TableCell>
                  <TableCell align="center">{eachDepartment?.name}</TableCell>
                  <TableCell align="center">
                    <div className="flex justify-center">
                      <Stack spacing={1} direction="row">
                        <Button
                          color="warning"
                          variant="contained"
                          onClick={() => {
                            history.push(
                              `/hr/department/edit/${eachDepartment._id}`
                            );
                          }}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          color="error"
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            deleteDepartment(eachDepartment?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </Stack>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={department?.total}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );

  if (department?.isLoading || !department.isCompleted) {
    return (
      <div>
        <Loading isLoading={department?.isLoading} />
      </div>
    );
  }
  if (!department?.isLoading || !department.isCompleted) {
    return (
      <div className="">
        <div className="">
          <div className="flex justify-between">
            {renderTitle()}
            <div className="py-4">
              <Button
                variant="contained"
                startIcon={<Add />}
                color="primary"
                onClick={() => {
                  history.push('/hr/department/create');
                }}
              >
                เพิ่มแผนก
              </Button>
            </div>
          </div>
          {renderTable()}
        </div>
      </div>
    );
  }
}
Department.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
Department.defaultProps = {
  title: '',
  subtitle: '',
};

export default Department;
