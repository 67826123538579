/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  Button,
  Card,
  CardContent,
  Autocomplete,
  TextField,
} from '@mui/material';
import ProductServe from '../../../components/Forms/FormProductArray';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import * as actions from '../../../redux/action';
import SumDiscount from 'components/Card/SumDiscount';
import Sumprice from 'components/Card/Sumprice';
import SumAllPrice from 'components/Card/SumAllPrice';

function NewIn({ title, subtitle }) {
  dayjs.extend(buddhistEra);
  const dispatch = useDispatch();
  const history = useHistory();
  const customer = useSelector((state) => state.customer);
  const product = useSelector((state) => state.product);
  const { handleSubmit, control, setValue, watch } = useForm({});
  const [findName, setFindName] = useState('');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState('');
  const [date, setDate] = React.useState(new Date());
  const [dueDate, setDueDate] = React.useState(new Date());
  const [reload, setReload] = useState(false);
  const [productList, setProductList] = useState([]);
  const [customerData, setCustomerData] = useState();
  const [productVatUni, setProductVatUni] = useState(false);
  const [open, setOpen] = useState(false);
  const [productVat, setProductVat] = useState(customerData?.status);
  useEffect(() => {
    dispatch(actions.getAllCustomer({}));
    dispatch(actions.getAllProduct());

    return () => {};
  }, []);

  const handleCheckCustomer = (data) => {
    const customers = _.find(customer.arr, { _id: data?._id });
    setCustomerData(customers);
    setValue('taxId', customers?.TaxId);
    setValue('tel', customers?.tel);
    setValue('address', customers?.address);
    setValue('status', customers?.status);
    // console.log("customer id", customers?._id);
  };

  const onSubmitQuotation = async (data) => {
    if (productList.length === 0) alert('กรุณาเพิ่มสินค้า');
    else {
      try {
        const ProcessData = await {
          date,
          dueDate,
          prefix: `IN${dayjs(date).format('BB')}-`,
          ...data,
          customer: customerData?._id,
          product: data?.productArray,
          allPrice: SumAllPrice(),
          statusTax: productVat,
          statusTaxUni: productVatUni,
        };
        // console.log("dataSubmit", ProcessData);
        await dispatch(actions.addInvoice(ProcessData));
        await dispatch(actions.getAllInvoice({ findName, page, size }));
        history.push('/sale/invoice');
      } catch (error) {}
    }
  };
  const tax = (status) => {
    if (!status) {
      return 0;
    }
    const price = SumAllPrice(productList);
    const tax = price * 0.03;
    return tax;
  };

  const taxUniversity = () => {
    if (!productVatUni) {
      return 0;
    }
    const priceUniversity = SumAllPrice(productList);
    const taxUniversity = priceUniversity * 0.01;
    return taxUniversity;
  };
  const priceTaxUni = () => SumAllPrice(productList) - taxUniversity();

  const priceTax = () => SumAllPrice(productList) - tax(customerData?.status);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (customer.isloading && product.isloading) {
    return <>error</>;
  }
  if (!customer.isloading && !product.isloading) {
    return (
      <div className="min-h-screen">
        <div>
          {renderTitle()}
          <div className="py-2">
            <BackButton />
          </div>
        </div>
        <div>
          <div>
            <div className="flex">
              <Card>
                <div className="p-2">
                  <h1 className="pb-4"> ข้อมูลลูกค้า</h1>
                  <div className="flex w-full">
                    <div className="w-2/3">
                      <Autocomplete
                        disablePortal
                        id="free-solo-demo"
                        freeSolo
                        options={customer?.arr || []}
                        getOptionLabel={(option) => option.businessname}
                        onChange={(e, newValue) =>
                          handleCheckCustomer(newValue)
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="ชื่อลูกค้า" required />
                        )}
                      />{' '}
                    </div>{' '}
                    <div className="pl-4 pt-2">
                      <Controller
                        name="status"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  // checked={checked}
                                  // onChange={handleChange}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label="นิติบุคคล"
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <Controller
                    name="taxId"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="หมายเลขผู้เสียภาษี"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="tel"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-basic"
                        label="เบอร์โทร"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="address"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-multiline-basic"
                        label="ที่อยู่"
                        variant="outlined"
                        fullWidth
                        multiline
                        margin="normal"
                        rows={4}
                      />
                    )}
                  />
                </div>
              </Card>

              <div className="p-2 w-full">
                <Card>
                  <CardContent>
                    {' '}
                    <form onSubmit={handleSubmit(onSubmitQuotation)}>
                      <div className="flex justify-between">
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="วันที่"
                              value={date}
                              onChange={(newValue) => {
                                setDate(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>{' '}
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="กำหนดชำระ"
                              value={dueDate}
                              onChange={(newValue) => {
                                setDueDate(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>{' '}
                        </div>
                        <Button
                          variant="contained"
                          type="submit"
                          // onClick={onSubmitQuotation}
                        >
                          บันทึก
                        </Button>
                      </div>
                      <Controller
                        name="credit"
                        control={control}
                        defaultValue="-"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            label="จำนวนวันเครดิต"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                      <Controller
                        name="payment"
                        control={control}
                        defaultValue="โอน/เงินสด"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            label="วิธีการชำระเงิน"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />{' '}
                    </form>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-2">
              <div className="mt-2">
                <ProductServe
                  control={control}
                  setProductList={setProductList}
                  setValue={setValue}
                  watch={watch}
                  productVatUni={productVatUni}
                  setProductVat={setProductVat}
                  setProductVatUni={setProductVatUni}
                  productVat={productVat}
                  statusHide={false}
                  statusPickUp={false}
                />
              </div>
            </div>
            <Card>
              <CardContent>
                <div className="flex w-full ">
                  <div className="w-1/2">
                    {' '}
                    <Controller
                      name="note"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          id="outlined-multiline-basic"
                          label="หมายเหตุ"
                          variant="outlined"
                          fullWidth
                          multiline
                          margin="normal"
                          rows={4}
                        />
                      )}
                    />
                  </div>

                  <div className="flex justify-between  w-1/2 ml-2  ">
                    <div>
                      <div>รวมเงิน </div>
                      <div>ส่วนลดการค้า</div>
                      <div>เงินหลังหักส่วนลด</div>
                      {productVatUni === true ? (
                        <div>หัก ณ ที่จ่าย 1%</div>
                      ) : (
                        <div>หัก ณ ที่จ่าย 3%</div>
                      )}

                      <div className="font-bold ">จำนวนเงินทั้งสิ้น </div>
                    </div>
                    <div>
                      {productVatUni === true ? (
                        <div>
                          <div>
                            {Sumprice(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>

                          <div>
                            {SumDiscount(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>

                          <div>
                            {SumAllPrice(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            <div>
                              {taxUniversity()
                                ?.toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>
                          </div>

                          <div className="font-bold ">
                            {priceTaxUni()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {' '}
                          <div>
                            {Sumprice(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
                          </div>
                          <div>
                            {SumDiscount(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            {SumAllPrice(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            {tax(customerData?.status)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div className="font-bold ">
                            {priceTax()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  }
  return <>error</>;
}
export default NewIn;
