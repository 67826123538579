import {
  PICKUPORDER_ALL,
  PICKUPORDER_DELETE,
  PICKUPORDER_GET,
  PICKUPORDER_LOADING,
  PICKUPORDER_POST,
  PICKUPORDER_PUT,
} from '../type';

function pickUpOrderReducer(state = { pickUpOrder: null }, action) {
  switch (action.type) {
    case PICKUPORDER_LOADING:
      return { isLoading: true };
    case PICKUPORDER_ALL:
      return {
        ...action.payload,
        isLoading: false,
      };
    case PICKUPORDER_GET:
      return {
        ...action.payload,
        isLoading: false,
      };
    case PICKUPORDER_POST:
      return {
        ...action.payload,
        isLoading: true,
      };
    case PICKUPORDER_PUT:
      return {
        isLoading: true,
      };
    case PICKUPORDER_DELETE:
      return {
        isLoading: true,
      };
    default:
      return state;
  }
}

export default pickUpOrderReducer;
