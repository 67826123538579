import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// components
import Sidebar from 'components/common/Sidebar/Sidebar';
import FooterAdmin from 'components/common/Footers/FooterAdmin.js';
// views
import Employee from 'views/employee/employee.js';
import EditEmployeeData from 'views/employee/editEmployeeData.js';
import LeaveForm from 'views/employee/leaveForm.js';
import ViewLeaveForm from 'views/employee/viewleaveForm.js';
import ChangePsw from 'views/employee/changePsw';
import Loading from 'components/Loading';
import * as actions from '../redux/action';
import { HomeNavbar } from 'components/Navbars';
import NotifyRedirect from '../views/employee/NotifyRedirect';

export default function EmployeeLayout() {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  useEffect(() => {
    dispatch(actions.getMe());
    dispatch(actions.getAllInformation());
    return () => {};
  }, []);

  if (me.isLoading) {
    return <Loading isLoading />;
  }
  if (!me.isLoading) {
    return (
      <div className="min-h-screen h-full">
        <Sidebar />
        <div className="pt-4 hidden md:block ">
          <HomeNavbar onMobileNavOpen={() => {}} />
        </div>
        <div className="relative md:ml-64 bg-gray-200 min-h-screen  pt-6 md:px-8">
          <div className="px-4 md:px-10 mx-auto w-full min-h-screen ">
            <Switch>
              <Route path="/employee/notify/redireact" exact>
                <NotifyRedirect title="ลงทะเบียนการแจ้งเตือน" />
              </Route>
              <Route path="/employee/me" exact>
                <Employee title="ข้อมูลส่วนตัว" />
              </Route>
              <Route path="/employee/leaveform" exact>
                <LeaveForm title="สร้างใบลา" />
              </Route>
              <Route path="/employee/changepassword/:id" exact>
                <ChangePsw title="เปลี่ยนรหัสผ่าน" />
              </Route>
              <Route path="/employee/leaveform/:id" exact>
                <ViewLeaveForm title="ใบลา" />
              </Route>
              <Route path="/employee/editemployee/:id" exact>
                <EditEmployeeData title="แก้ไขข้อมูล" />
              </Route>
              <Redirect from="/." to="/." />
            </Switch>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}
