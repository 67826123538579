import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// components

import Sidebar from 'components/common/Sidebar/Sidebar';

import FooterAdmin from 'components/common/Footers/FooterAdmin.js';

// views
import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/action';
// import NotAuthorized from 'components/Error/Error';

// import Dashboard from "../views/admin/Dashboard.js";
import Receipt from '../views/sale/receipt/receipt.js';
import Create from '../views/sale/quotation/createQuotation.js';
import Quotation from '../views/sale/quotation/quotation.js';
import EditQuotation from 'views/sale/quotation/editQuotation.js';
import Newreceipt from 'views/sale/receipt/newReceipt.js';
import Invoice from 'views/sale/invoice/invoice.js';
import NewInvoice from 'views/sale/invoice/newInvoice.js';
import EditInvoice from 'views/sale/invoice/editInvoice.js';
import EditReceipt from 'views/sale/receipt/editReceipt.js';
import NewRE from '../views/sale/invoice/newRefromIn.js';
import { Loading } from 'components/Loading';
import { HomeNavbar } from 'components/Navbars';

export default function Sale() {
  const module = 'SLD';
  const name = 'ฝ่ายขาย';
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(actions.getMe());
    dispatch(actions.getAllInformation());
    return () => {};
  }, []);

  if (me?.isLoading) {
    return <Loading isLoading />;
  }
  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen h-full">
        <Sidebar />
        <div className="pt-4 hidden md:block ">
          <HomeNavbar onMobileNavOpen={() => {}} />
        </div>
        <div className="relative md:ml-64 bg-gray-200 min-h-screen  pt-6 md:px-8">
          {/* <AdminNavbar /> */}
          {/* Header */}
          <div className="py-4 w-full min-h-screen   ">
            <Switch>
              <Route path="/sale/quotation" exact>
                <Quotation title="ใบเสนอราคา" subtitle={name} />
              </Route>
              <Route path="/sale/editquotation/:id" exact>
                <EditQuotation title="แก้ไขใบเสนอราคา" subtitle={name} />
              </Route>
              <Route path="/sale/createQuotation" exact>
                <Create title="สร้างใบเสนอราคา" subtitle={name} />
              </Route>
              <Route path="/sale/receipt" exact>
                <Receipt title="ใบเสร็จรับเงิน" subtitle={name} />
              </Route>
              <Route path="/sale/newreceipt" exact>
                <Newreceipt title="สร้างใบเสร็จรับเงิน" subtitle={name} />
              </Route>
              <Route path="/sale/editreceipt/:id" exact>
                <EditReceipt title="แก้ไขใบเสร็จรับเงิน" subtitle={name} />
              </Route>
              <Route path="/sale/invoice" exact>
                <Invoice title="ใบแจ้งหนี้/ใบส่งของ" subtitle={name} />
              </Route>
              <Route path="/sale/newre/:id" exact>
                <NewRE title="สร้างใบเสร็จรับเงิน" subtitle={name} />
              </Route>
              <Route path="/sale/newinvoice" exact>
                <NewInvoice title="สร้างใบแจ้งหนี้/ใบส่งของ" subtitle={name} />
              </Route>
              <Route path="/sale/editinvoice/:id" exact component={EditInvoice}>
                <EditInvoice title="แก้ไขใบแจ้งหนี้/ใบส่งของ" subtitle={name} />
              </Route>
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
  // return (
  //   <>
  //     <Sidebar />
  //     <div className="relative md:ml-64 bg-gray-200">
  //       {/* Header */}

  //       <div className="px-4 md:px-10 mx-auto w-full ">
  //         <NotAuthorized />
  //         <FooterAdmin />
  //       </div>
  //     </div>
  //   </>
  // );
}
