import {
  CHECKIN_ALL,
  CHECKIN_GET,
  CHECKIN_POST,
  CHECKIN_PUT,
  CHECKIN_DELETE,
  CHECKIN_LOADING,
} from '../type';
const initialState = {
  checkIn: null,
  isLoading: true,
  isCompleted: true,
};
function checkInReducer(state = initialState, action) {
  switch (action.type) {
    case CHECKIN_LOADING:
      return { isLoading: true, isCompleted: false };
    case CHECKIN_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case CHECKIN_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case CHECKIN_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case CHECKIN_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case CHECKIN_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default checkInReducer;
