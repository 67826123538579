import api from '../../utils/functions/api';
import {
  WORK_ALL,
  WORK_DELETE,
  WORK_GET,
  WORK_LOADING,
  WORK_PUT,
  WORK_ERROR,
  WORK_POST,
} from '../type';

export const resetAllwork = () => (dispatch) => {
  dispatch({ type: WORK_ALL, payload: [] });
};

export const getAllwork = (params) => async (dispatch) => {
  try {
    const {
      fillterMe = '',
      creater = '',
      page = 1,
      size = '',
      department = '',
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/work?&page=${page}&size=${size}&fillterMe=${fillterMe}&creater=${creater}&department=${department}`
    );
    if (status === 200) {
      dispatch({ type: WORK_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WORK_ERROR });
  }
};

export const getOnework = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/work/${id}`
    );
    if (status === 200) {
      dispatch({ type: WORK_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: WORK_ERROR });
  }
};

export const deletework = (id) => async (dispatch) => {
  try {
    dispatch({ type: WORK_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/work/${id}`
    );
    dispatch({ type: WORK_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WORK_ERROR });
  }
};

export const addwork = (payload) => async (dispatch) => {
  try {
    dispatch({ type: WORK_LOADING });
    const { data } = await api.post(`${process.env.REACT_APP_API_URL}/work`, {
      ...payload,
      // eslint-disable-next-line comma-dangle
    });
    dispatch({ type: WORK_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WORK_ERROR });
  }
};

export const putwork = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: WORK_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/work/${id}`,
      payload
    );
    dispatch({ type: WORK_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: WORK_ERROR });
  }
};
