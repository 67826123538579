import axios from 'axios';
import {
  QUOTATION_ALL,
  QUOTATION_PUT,
  QUOTATION_POST,
  QUOTATION_DELETE,
  QUOTATION_GET,
  QUOTATION_LOADING,
  QUOTATION_ERROR,
} from '../type';
import api from '../../utils/functions/api';

export const getAllQuotation = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/quotation?name=${name}&size=${size}&page=${page}`
    );
    if (status === 200) {
      dispatch({ type: QUOTATION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: QUOTATION_ERROR });
  }
};
export const getOneQuotation = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/quotation/${id}`
    );
    if (status === 200) {
      dispatch({ type: QUOTATION_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: QUOTATION_ERROR });
  }
};

export const deleteQuotation = (id) => async (dispatch) => {
  try {
    dispatch({ type: QUOTATION_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/quotation/${id}`
    );
    dispatch({ type: QUOTATION_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: QUOTATION_ERROR });
  }
};

export const addQuotation = (payload) => async (dispatch) => {
  try {
    dispatch({ type: QUOTATION_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/quotation`,
      {
        ...payload,
      }
    );
    dispatch({ type: QUOTATION_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: QUOTATION_ERROR });
  }
};

export const putQuotation = (id, payload) => async (dispatch) => {
  console.log('iD', id, payload);
  try {
    dispatch({ type: QUOTATION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/quotation/${id}`,
      payload
    );
    dispatch({ type: QUOTATION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: QUOTATION_ERROR });
  }
};
