import {
  Switch,
  TextField,
  Button,
  Card,
  FormControlLabel,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import React from 'react';

export default function AddSalary({ control, Controller, date, setDate }) {
  return (
    <div className="p-8 grid-cols-2">
      <h1 className="text-xl font-bold">เพิ่มตารางการชำระเงิน</h1>
      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            value={field.value}
            label="ชื่อบริษัท"
            variant="outlined"
            margin="normal"
            fullWidth
          />
        )}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale="th">
        <DatePicker
          label="วันที่ครบกำหนด"
          value={date}
          onChange={(newValue) => {
            setDate(newValue);
            console.log('Date', date);
          }}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </LocalizationProvider>
      <Controller
        name="tel"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            value={field.value}
            label="เบอร์โทรศัพท์"
            variant="outlined"
            margin="normal"
            fullWidth
          />
        )}
      />
      <Controller
        name="note"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            value={field.value}
            label="หมายเหตุ"
            variant="outlined"
            margin="normal"
            fullWidth
          />
        )}
      />

      <div className="py-auto px-2 flex">
        <Controller
          name="overdue"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <div>
              <FormControlLabel
                control={<Switch {...field} checked={field.value} />}
                label="ค้างชำระ"
              />
            </div>
          )}
        />
        <Controller
          name="follow"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <div>
              <FormControlLabel
                control={<Switch {...field} checked={field.value} />}
                label="ติดตาม"
              />
            </div>
          )}
        />
        <Controller
          name="success"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <div>
              <FormControlLabel
                control={<Switch {...field} checked={field.value} />}
                label="ชำระแล้ว"
              />
            </div>
          )}
        />
      </div>
      <div className="flex justify-end w-full pt-4">
        <Button variant="contained" type="submit">
          บันทึก
        </Button>
      </div>
    </div>
  );
}
