/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Card,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from '@mui/material';
import _ from 'lodash';

export default function FormLeaveType({
  control,
  Controller,
  leaveType,
  option,
  setOption,
  fields,
  append,
  remove,
  setValue,
}) {
  const handleAdd = () => {
    const maxAdd = 3;
    if (fields.length < maxAdd) {
      append({
        // eslint-disable-next-line react/prop-types
        name: '',
      });
    } else {
      alert('เพิ่มเกินจำนวน');
    }
  };

  return (
    <div>
      <Card>
        <div className=" py-1 px-2 lg:grid grid-cols-2 gap-1">
          <div>
            <Controller
              name="name"
              // defaultValue={leaveType ? leaveType?.name : ''}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  value={field.value}
                  id="outlined-basic"
                  label="ชื่อชนิดการลา"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="quantity"
              defaultValue={leaveType ? leaveType?.quantity : ''}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  value={field.value}
                  id="outlined-basic"
                  label="จำนวนโควตา"
                  margin="dense"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="longevity"
              control={control}
              defaultValue={leaveType ? leaveType?.longevity : '1'}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  // value={field.value}
                  id="outlined-basic"
                  label="อายุงานที่ลาได้ (วัน)"
                  margin="dense"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              )}
            />
          </div>
        </div>
        <div>
          <div className="px-2">
            <FormControlLabel
              control={
                <Switch
                  checked={option}
                  onChange={(e) => setOption(e.target.checked)}
                />
              }
              label="ออปชั่นการลาตามอายุงาน"
            />
          </div>
          {option && (
            <div className="px-4">
              {_.map(fields, (each, index) => (
                <div className="flex gap-1">
                  <div className="py-2">{index + 1}</div>

                  <div>
                    <Controller
                      name={`options.${index}.quantity`}
                      control={control}
                      // defaultValue={leaveType ? leaveType?.longevity : '1'}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          // value={field.value}
                          id="outlined-basic"
                          label="อายุงานที่ลาได้ (วัน)"
                          margin="dense"
                          variant="outlined"
                          type="number"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name={`options.${index}.longevity`}
                      control={control}
                      // defaultValue={leaveType ? leaveType?.longevity : '1'}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          // value={field.value}
                          id="outlined-basic"
                          label="จำนวนวัน"
                          margin="dense"
                          variant="outlined"
                          type="number"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="pt-2">
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      // onClick={() => handleAdd()}
                    >
                      ลบ
                    </Button>
                  </div>
                </div>
              ))}

              <div className="px-2">
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={() => handleAdd()}
                >
                  เพิ่ม
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-center py-4 ">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </Card>
    </div>
  );
}
