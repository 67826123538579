import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// components

import Sidebar from 'components/common/Sidebar/Sidebar';

import FooterAdmin from 'components/common/Footers/FooterAdmin.js';
import NotAuthorized from 'components/Error/Error';
import Customers from '../views/Customer/Customer';
import NewCustomer from '../views/Customer/NewCustomer';
import EditCustomer from '../views/Customer/editCustomer';

import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/action';
import Loading from 'components/Loading';
import { HomeNavbar } from '../components/Navbars';
// import Dashboard from "../views/admin/Dashboard.js";

export default function Customer() {
  const module = 'SLD';
  const name = 'ลูกค้า';
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(actions.getMe());
    dispatch(actions.getAllInformation());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading isLoading />;
  }

  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen h-full">
        <Sidebar />
        <div className="pt-4 hidden md:block ">
          <HomeNavbar onMobileNavOpen={() => {}} />
        </div>
        <div className="relative md:ml-64 bg-gray-200 min-h-screen  pt-6 md:px-8">
          {/* <AdminNavbar /> */}
          {/* Header */}

          <div className="py-4 w-full min-h-screen ">
            <Switch>
              <Route path="/customer/" exact>
                <Customers title="ลูกค้า" subtitle={name} />
              </Route>
              <Route path="/customer/new" exact>
                <NewCustomer title="สร้างลูกค้า" subtitle={name} />
              </Route>
              <Route path="/customer/edit/:id" exact component={EditCustomer}>
                <EditCustomer title="แก้ไขลูกค้า" subtitle={name} />
              </Route>
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        {/* Header */}

        <div className="px-4 md:px-10 mx-auto w-full ">
          <NotAuthorized />
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
