import { applyMiddleware, compose, createStore } from 'redux';
import ThunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducer';

export default function configureStore(preloadedState) {
  let middlewares;
  if (process.env.NODE_ENV === 'production') {
    middlewares = [ThunkMiddleware];
  } else {
    middlewares = [ThunkMiddleware, logger];
  }

  const usedMiddleware = [applyMiddleware(...middlewares)];
  const composedEnhancers = compose(...usedMiddleware);
  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducer', () => store.replaceReducer(rootReducer));
  }
  return store;
}
