/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router';
import {
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Button,
} from '@mui/material';
import { ViewTitle } from 'components/ViewTitle';
import * as action from '../../redux/action';

const tableInfo = (info) => (
  <div>
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 50 }} aria-label="simple table">
          <colgroup>
            <col width="10%" />
            <col width="30%" />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>
                <h1 className="text-lg   font-sans">ชื่อบริษัท</h1>
              </TableCell>
              <TableCell align="left">
                <div className="tex-left text-lg text-gray-700  font-sans">
                  {info?.name}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="text-lg  font-sans ">ชื่อบริษัทภาษาอังกฤษ</h1>
              </TableCell>
              <TableCell>
                <div className="tex-left text-lg font-sans">
                  {info?.nameEng}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="text-lg  font-sans ">เลขที่ผู้เสียภาษี</h1>
              </TableCell>
              <TableCell>
                {' '}
                <div className="tex-left text-lg   font-sans">
                  {info?.taxId}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="text-lg  font-sans ">เบอร์โทร</h1>
              </TableCell>
              <TableCell>
                {' '}
                <div className="tex-left text-lg   font-sans">{info?.tel}</div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="text-lg  font-sans">Email</h1>
              </TableCell>
              <TableCell>
                {' '}
                <div className="tex-left text-lg font-sans">{info?.email}</div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="text-lg  font-sans ">ที่อยู่</h1>
              </TableCell>
              <TableCell>
                {' '}
                <div className="tex-left text-lg  font-sans">
                  {info?.address}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="text-lg  font-sans ">เว็บไซต์</h1>
              </TableCell>
              <TableCell>
                {' '}
                <h1 className="text-lg  font-sans ">{info?.webUrl}</h1>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h1 className="text-lg  font-sans ">โลโก้</h1>
              </TableCell>
              <TableCell>
                <img width={350} height={350} src={info?.image?.url} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  </div>
);
export default function InfoBusiness() {
  const dispatch = useDispatch();
  const history = useHistory();
  const information = useSelector((state) => state.information);
  useEffect(() => {
    dispatch(action.getAllInformation());

    return () => {};
  }, []);

  useEffect(() => {
    if (information?.arr?.length === 0) {
      history.push('/admin/newinfo');
    }

    return () => {};
  }, [information?.arr?.length]);

  const renderEditButton = () => (
    <div>
      <Button
        variant="contained"
        startIcon={<EditIcon />}
        color="warning"
        onClick={() => {
          history.push(`/admin/editinfo/${information?._id}`);
        }}
      >
        แก้ไข
      </Button>
    </div>
  );

  return (
    <div className="h-screen ">
      <div>
        <div className="flex justify-between">
          <ViewTitle title="จัดการข้อมูลบริษัท" />
          <div className="py-4">{renderEditButton()}</div>
        </div>
        <div className=" w-full">
          <div className="p-2 ">{tableInfo(information)}</div>
          <div className="pt-6  pl-4 " />
        </div>
      </div>
    </div>
  );
}
