/* eslint-disable import/no-unresolved */
import React from 'react';
import _ from 'lodash';

import Chart from 'react-apexcharts';
// const data = [
//   {
//     x: 1994,
//     y: 2543763,
//   },
//   {
//     x: 1995,
//     y: 4486659,
//   },
//   {
//     x: 1996,
//     y: 7758386,
//   },
//   {
//     x: 1997,
//     y: 12099221,
//   },
//   {
//     x: 1998,
//     y: 18850762,
//   },
//   {
//     x: 1999,
//     y: 28153765,
//   },
//   {
//     x: 2000,
//     y: 41479495,
//   },
// ];
// const data2 = [
//   {
//     x: 1994,
//     y: 255773,
//   },
//   {
//     x: 1995,
//     y: 47559,
//   },
//   {
//     x: 1996,
//     y: 778386,
//   },
//   {
//     x: 1997,
//     y: 12721,
//   },
//   {
//     x: 1998,
//     y: 1870762,
//   },
//   {
//     x: 1999,
//     y: 287765,
//   },
//   {
//     x: 2000,
//     y: 414795,
//   },
// ];

export default function OverAllCahrt({ receipt, pickUpOrder }) {
  const NewProductTransactionDay = _.sortBy(
    _.map(receipt?.arr, (e) => {
      const data = {
        allPrice: e.allPrice,
        date: e.date,
      };
      return data;
    }),
    ['date']
  );
  const NewPickupOrderTransactionDay = _.sortBy(
    _.map(pickUpOrder, (e) => {
      const data = {
        allPrice: e.allPrice,
        date: e.date,
      };
      return data;
    }),
    ['date']
  );

  const generateDayWiseTimeSeries = (count) => {
    let i = 0;
    const series = [];

    while (i < count) {
      series.push([
        NewProductTransactionDay[i].date,
        NewProductTransactionDay[i].allPrice?.toFixed(2),
      ]);
      i += 1;
    }
    console.log('series', series);
    return series;
  };
  const generatePickupWiseTimeSeries = (count) => {
    let i = 0;
    const series = [];

    while (i < count) {
      series.push([
        NewPickupOrderTransactionDay[i].date,
        NewPickupOrderTransactionDay[i].allPrice?.toFixed(2),
      ]);
      i += 1;
    }
    console.log('series', series);
    return series;
  };

  const options = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    series: [
      {
        name: 'รายได้',
        data: generateDayWiseTimeSeries(_.size(NewProductTransactionDay)),
      },
      {
        name: 'รายจ่าย',
        data: generatePickupWiseTimeSeries(
          _.size(NewPickupOrderTransactionDay)
        ),
      },
    ],

    title: {
      text: 'ภาพรวม',
      align: 'left',
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: [
      {
        labels: {
          offsetX: 14,
          offsetY: -5,
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        opposite: true,
        tickAmount: 4,
        seriesName: 'Linear',
        labels: {
          formatter: (value) => `${value}บาท`,
        },
      },
    ],
  };

  return (
    <div>
      <div className="w-full xl:w-2/3 xl:justify-self-center">
        <Chart
          options={options}
          series={options?.series}
          type="line"
          width="100%"
        />
      </div>
    </div>
  );
}
