import React, { useState, useEffect } from 'react';
import { BackButton } from 'components/Button';
import TimeStampSettingCard from 'components/Card/TimeStampSettingCard';
import { ViewTitle } from 'components/ViewTitle';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/action';
import { useGeolocated } from 'react-geolocated';
import { Button, Card } from '@mui/material';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import { useParams } from 'react-router';

export default function EditTimeStampSetting({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const timeStampSetting = useSelector((state) => state.TimeStampSetting);
  const { handleSubmit, control, setValue } = useForm({ mode: 'onChange' });
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });
  const [timeIn, setTimeIn] = useState('');
  const [timeOut, setTimeOut] = useState('');
  const [locations, setLocations] = useState({});

  useEffect(() => {
    dispatch(actions.getOneTimeStampSetting(params?.id));
    return () => {};
  }, []);
  useEffect(() => {
    setValue('name', timeStampSetting?.name);
    setTimeIn(timeStampSetting?.time_in);
    setTimeOut(timeStampSetting?.time_out);
    setLocations(timeStampSetting?.location);
    return () => {};
  }, [timeStampSetting]);

  const getLocation = () => setLocations(coords);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;
  const renderLocationButton = () => (
    <Card>
      <div className="p-4">
        <h1 className="font-bold">สถานที่เข้างาน</h1>
        <h1 className="text-lg  font-sans text-gray-700">
          latitude : {locations?.latitude}
          longitude:{locations?.longitude}
        </h1>
        <Button onClick={() => getLocation()} variant="outlined">
          ดึงตำแหน่ง
        </Button>
      </div>
    </Card>
  );
  const rendercard = () => (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <TimeStampSettingCard
          Controller={Controller}
          control={control}
          timeIn={timeIn}
          setTimeIn={setTimeIn}
          timeOut={timeOut}
          setTimeOut={setTimeOut}
        />
        {renderLocationButton()}
        <div className="p-4 flex justify-center">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
  const submit = async (data) => {
    const confirm = window.confirm('บันทึก');
    if (confirm) {
      const payload = {
        ...data,
        time_in: dayjs(timeIn),
        time_out: dayjs(timeOut),
        location: {
          longitude: locations.longitude,
          latitude: locations.latitude,
        },
      };
      await dispatch(actions.putTimeStampSetting(params.id, payload));
      await dispatch(actions.getAllTimeStampSetting({}));
      history.goBack();
    }
  };

  return (
    <div>
      {renderTitle()}
      {renderBackButton()}
      <div className="pt-2">
        <Card>
          <div className="p-4">{rendercard()}</div>
        </Card>
      </div>
    </div>
  );
}
