import {
  CHECKIN_ALL,
  CHECKIN_GET,
  CHECKIN_POST,
  CHECKIN_PUT,
  CHECKIN_DELETE,
  CHECKIN_LOADING,
  CHECKIN_ERROR,
} from '../type';

import api from '../../utils/functions/api';

export const CheckInCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CHECKIN_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/CheckIn`,
      {
        ...payload,
        // eslint-disable-next-line comma-dangle
      }
    );
    dispatch({ type: CHECKIN_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CHECKIN_ERROR });
  }
};

export const CheckIngetAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      me = '',
      projectId = '',
      dateStart = '',
      dateEnd = '',
      checkOutSatus = true,
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/CheckIn?name=${name}&size=${size}&page=${page}&me=${me}&projectId=${projectId}&dateStart=${dateStart}&dateEnd=${dateEnd}&checkOutSatus=${checkOutSatus}`
    );
    if (status === 200) {
      dispatch({ type: CHECKIN_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: CHECKIN_ERROR });
  }
};

export const CheckInGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/CheckIn/${id}`
    );
    if (status === 200) {
      dispatch({ type: CHECKIN_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: CHECKIN_ERROR });
  }
};

export const CheckInPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: CHECKIN_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/CheckIn/${id}`,
      payload
    );
    dispatch({ type: CHECKIN_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CHECKIN_ERROR });
  }
};
export const CheckInDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: CHECKIN_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/CheckIn/${id}`
    );
    dispatch({ type: CHECKIN_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CHECKIN_ERROR });
  }
};
