/* eslint-disable no-undef */
/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Modal, Stack, Button } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import AddWorkBu from 'components/Forms/AddWorkBu';
import JobListTable from '../../../components/Table/JobList';
import * as actions from '../../../redux/action';
// eslint-disable-next-line import/order
import Loading from 'components/Loading';

function FndWorkList({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const work = useSelector((state) => state.work);
  const user = useSelector((state) => state.user);
  const department = useSelector((state) => state.Department);
  const [open, setOpen] = useState(false);
  const [date, setDate] = React.useState(new Date());
  const [time, setTime] = React.useState();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  console.log(department);
  const { control, setValue, handleSubmit, reset } = useForm({
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'employeeArray',
  });

  const {
    fields: departmentFields,
    append: departmentAppend,
    remove: departmentRemove,
  } = useFieldArray({
    control,
    name: 'departmentArr',
  });

  const defaultValue = {
    job: '',
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handlePushBack = () => {
    history.goBack();
  };

  const renderAddButton = () => (
    <div className="pb-6 flex justify-end">
      <Stack spacing={2} direction="row">
        <Button variant="contained" startIcon={<Add />} onClick={handleOpen}>
          เพิ่มงาน
        </Button>
      </Stack>
    </div>
  );

  useEffect(() => {
    dispatch(actions.getAllwork({ fillterMe: '', creater: 'FND', page, size }));
    dispatch(actions.getAllUser({}));
    dispatch(actions.getAllDepartment({}));
    return () => {};
  }, [page, size]);

  const onSubmit = async (data) => {
    const confirm = window.confirm('ยืนยัน');
    if (confirm) {
      try {
        const dataSubmit = {
          ...data,
          date,
          worker: data?.employeeArray,
          department: data?.departmentArr,
          name: data?.job,
          creater: 'FND',
        };

        await dispatch(actions.addwork(dataSubmit));
        dispatch(actions.getAllUser({}));
        dispatch(actions.getAllwork({ creater: 'FND' }));
        dispatch(actions.getAllLeaveDoc({ name: '', page: 1, size: 0 }));
        await reset(defaultValue);
        handleClose();
      } catch (error) {
        console.log('error');
      }
    }
  };

  const renderModal = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
            }}
          >
            <AddWorkBu
              control={control}
              Controller={Controller}
              fields={fields}
              append={append}
              remove={remove}
              setValue={setValue}
              user={user}
              date={date}
              setDate={setDate}
              time={time}
              setTime={setTime}
              department={department}
              departmentFields={departmentFields}
              departmentAppend={departmentAppend}
              departmentRemove={departmentRemove}
            />
          </Box>
        </form>
      </Modal>
    </div>
  );

  const handleEdit = async (id) => {
    history.push(`/salary/work-list/edit/${id}`);
  };

  if (work.isLoading || !work.isCompleted) {
    return <Loading isLoading />;
  }
  if (!work.isLoading || work.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderModal()}
        <div className="py-2 flex justify-between ">
          <div>
            <Button variant="outlined" onClick={handlePushBack}>
              กลับ
            </Button>
          </div>
          <div>{renderAddButton()}</div>
        </div>
        <JobListTable
          work={work}
          page={page}
          size={size}
          setPage={setPage}
          setSize={setSize}
          handleEdit={handleEdit}
        />
      </div>
    );
  }
}

FndWorkList.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
FndWorkList.defaultProps = {
  title: '',
  subtitle: '',
};

export default FndWorkList;
