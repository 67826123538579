import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/th';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const EmployeeSalaryReport = async (data, information) => {
  // console.log('data', data);
  console.log('information', information);
  const countTaxSSO = () => {
    const sso = data.salary * (data?.taxSSO / 100);
    if (data?.salary >= 15000) {
      return 15000 * (data?.taxSSO / 100);
    }
    return sso;
  };
  const yearRevenue = () =>
    data?.allSalary?.allsalary -
    data?.allSalary?.sumSSO -
    data?.allSalary?.sumEtc;
  const revenue = () => data?.salary + data?.ot + data?.extra + data?.allowance;
  const expenses = () => {
    const sso = countTaxSSO();
    return sso + data?.tax + data?.etc;
  };
  const sumSalary = () => revenue() - expenses();

  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 20, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },

    info: {
      title: `สลิปเงินเดือน_${data?.user?.name}_${dayjs(data?.date).format(
        'MMMM_BBBB'
      )}`,
    },

    content: [
      {
        margin: [0, 30],
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: ['20%', '20%', '*', '40%'],

          body: [
            [
              {
                text: 'ใบจ่ายเงินเดือน\nPAY SLIP',
                style: 'header',
                border: [false, false, false, true],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: '',
                rowSpan: 6,
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: `${information?.name}\n${information?.address} \nเลขประจำตัวผู้เสียภาษี ${information?.taxId}\n โทรศัพท์ ${information?.tel} `,
                rowSpan: 6,
                style: 'body2',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'เดือน\t' + `${dayjs(data?.date).format('MMMM BBBB')}`,
                style: 'body2',
                border: [false, false, false, false],
                margin: [0, 10],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: 'ชื่อ-สกุล',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: `${data?.user?.name}  ${data?.user?.surname}`,
                border: [false, false, false, false],
                style: 'body2',
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: 'เลขบัตรประจำตัวประชาชน',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: `${data?.user?.personalID || '-'}`,
                border: [false, false, false, false],
                style: 'body2',
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: 'ตำแหน่ง',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: `${data?.user?.position?.name || '-'}`,
                border: [false, false, false, false],
                style: 'body2',
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: 'วันที่จ่ายเงิน',
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(data?.datePay).format('DD MMMM BBBB') || '-'}`,
                border: [false, false, false, false],
                style: 'body2',
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
          ],
        },
      },
      {
        margin: [0, 20],
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'รายได้',
                colSpan: 2,
                style: 'body',
                fillColor: '#d2d4d2',
              },
              '',
              {
                text: 'รายการหัก',
                colSpan: 2,
                style: 'body',
                fillColor: '#d2d4d2',
              },
              '',
              {
                text: 'รายได้สะสมต่อปี',
                colSpan: 2,
                style: 'body',
                fillColor: '#d2d4d2',
              },
              '',
            ],
            [
              { text: 'เงินเดือน', style: 'body1' },
              {
                text: `${
                  data?.salary
                    ?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-'
                }`,
                style: 'body1',
              },
              { text: 'ภาษีหัก ณ ที่จ่าย', style: 'body1' },
              { text: '0.00', style: 'body1' },
              { text: 'รายได้สะสมต่อปี', style: 'body1', fillColor: '#d2d4d2' },
              {
                text: `${data?.allSalary?.allsalary
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'body1',
              },
            ],
            [
              { text: 'ค่าแรงวันหยุด', style: 'body1' },
              {
                text: `${
                  data?.ot?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                  '-'
                }`,
                style: 'body1',
              },
              { text: 'เงินประกันสังคม', style: 'body1' },
              {
                text: countTaxSSO()
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                style: 'body1',
              },
              { text: 'ภาษีสะสมต่อปี', style: 'body1', fillColor: '#d2d4d2' },
              {
                text: `${data?.allSalary?.sumTax
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'body1',
              },
            ],
            [
              { text: 'ค่าเบี้ยเลี้ยง', style: 'body1' },
              {
                text: `${
                  data?.allowance
                    ?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-'
                }`,
                style: 'body1',
              },
              { text: 'รายการหักอื่นๆ', style: 'body1' },
              {
                text: `${
                  data?.etc?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                  '-'
                }`,
                style: 'body1',
              },
              {
                text: 'เงินประกันสังคมต่อปี',
                style: 'body1',
                fillColor: '#d2d4d2',
              },
              {
                text: `${data?.allSalary?.sumSSO
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'body1',
              },
            ],
            [
              { text: 'เงินพิเศษอื่นๆ', style: 'body1' },
              {
                text: `${
                  data?.extra?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                  '0.00'
                }`,
                style: 'body1',
              },
              { text: '', style: 'body1' },
              {
                text: '',
                style: 'body1',
              },
              {
                text: 'รายการหักอื่นๆ ต่อปี',
                style: 'body1',
                fillColor: '#d2d4d2',
              },
              {
                text: `${data?.allSalary?.sumEtc
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'body1',
              },
            ],
            [
              { text: 'รวมรายได้', style: 'body1', fillColor: '#d2d4d2' },
              {
                text: `${data?.sumSalary
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'body1',
              },
              { text: 'รวมรายการหัก', style: 'body1', fillColor: '#d2d4d2' },
              {
                text: expenses()
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                style: 'body1',
              },
              { text: '', style: 'body1' },
              { text: '' },
            ],
            [
              {
                text: 'รวมรับสุทธิ',
                margin: [0, 8],
                style: 'footTb',
                colSpan: 3,
                fillColor: '#d2d4d2',
              },
              { text: '' },
              { text: '' },
              {
                text: sumSalary()
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                style: 'body3',
                margin: [0, 8],
              },

              {
                text: 'รับสุทธิต่อปี',
                style: 'footTb',
                margin: [0, 8],
                fillColor: '#d2d4d2',
              },
              {
                text: yearRevenue()
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                style: 'body3',
                margin: [0, 8],
              },
            ],
            // [{ text: "รายการหัก" }, "", ""],
            // [{ text: "รายได้สะสมต่อปี" }, "", ""],
          ],
        },
      },
      {
        margin: [0, 30],
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: ['*', '33%'],

          body: [
            [
              {
                text: '(ประทับตราบริษัท)',
                style: { alignment: 'right' },
                margin: [44, 0],
                border: [false, false, false, false],
              },
              {
                text: 'ผู้รับเงิน ................................................',
                border: [false, false, false, false],
                style: 'body2',
              },
            ],
            [
              {
                text: '',
                style: '',
                border: [false, false, false, false],
              },
              {
                margin: [0, 20],
                text: 'ผู้จ่ายเงิน ................................................',
                border: [false, false, false, false],
                style: 'body2',
              },
            ],
          ],
        },
      },
    ],

    footer: [],

    styles: {
      header: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
      },
      body: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      body1: {
        fontSize: 12,
        bold: true,
        alignment: 'right',
      },
      body2: {
        fontSize: 12,
        bold: true,
        alignment: 'left',
      },

      body3: {
        fontSize: 12,
        bold: true,
        alignment: 'right',
        decoration: 'underline',
        decorationStyle: 'double',
      },

      footTb: {
        fontSize: 12,
        bold: true,
        alignment: 'right',
        alignment: 'center',
      },
      tab: {
        color: 'white',
      },
    },
    images: {},
  };
  pdfMake.createPdf(documentRef).open();
};
export default EmployeeSalaryReport;
