/* eslint-disable jsx-a11y/aria-props */
import {
  Button,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Avatar,
  Chip,
  Box,
  Tab,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import CheckInReport from '../../components/Report/CheckInReport';
import MonthlyCheckinReport from 'components/Report/MonthlyCheckinReport';
import { ViewTitle } from 'components/ViewTitle';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CardDateFilltter from '../../components/Card/CardDateFilltter';
import * as actions from '../../redux/action';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import SummaryTimestamp from '../../components/Table/SummaryTimestamp';
import CardMonthPrintFilltter from '../../components/Card/CardMonthPrintFilltter';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: 'white',
  boxShadow: 10,
  p: 4,
};

export default function TimeStamp({ title, subtitle }) {
  const dispatch = useDispatch();
  const checkIn = useSelector((state) => state.checkIn);
  const users = useSelector((state) => state.user);
  const information = useSelector((state) => state.information);
  const [name, setName] = useState('');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(10);
  const [value, setValue] = React.useState('1');
  const [dateStart, setDateStart] = React.useState(dayjs().startOf('day'));
  const [dateEnd, setDateEnd] = React.useState(dayjs().endOf('day'));
  const [month, setMonth] = React.useState(dayjs().startOf('month'));
  const [openCheckin, setOpenCheckin] = React.useState(false);
  const [openCheckout, setOpenCheckout] = React.useState(false);
  const [selectCheckin, setSelectCheckin] = React.useState();
  const [selectCheckout, setSelectCheckout] = React.useState();

  const handleOpen = () => {
    setOpenCheckin(true);
  };
  const handleClose = () => {
    setOpenCheckin(false);
    setSelectCheckin();
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const handleOpenCheckOut = () => {
    setOpenCheckout(true);
  };
  const handleCloseCheckOut = () => {
    setOpenCheckout(false);
  };
  const handlePrintReport = () => {
    CheckInReport(checkIn?.arr);
  };

  const handleMonthlyPrint = () => {
    MonthlyCheckinReport(users, month, information);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  useEffect(() => {
    dispatch(
      actions.CheckIngetAll({
        name,
        page,
        size,
        dateStart: dayjs(dateStart).startOf('day').toISOString(),
        dateEnd: dayjs(dateEnd).endOf('day').toISOString(),
      })
    );
    dispatch(
      actions.getAllUser({
        timeStamp: true,
        name,
        size: 2000,
        date: dayjs(month).startOf('day').toISOString(),
      })
    );

    return () => {};
  }, [name, page, size, dateStart, dateEnd, month]);
  const renderLayout = () => <ViewTitle title={title} subtitle={subtitle} />;
  console.log('CheckIn', checkIn);
  console.log('selectCheckin', selectCheckin);
  const renderFillter = () => (
    <CardDateFilltter
      dateStart={dateStart}
      setDateStart={setDateStart}
      dateEnd={dateEnd}
      setDateEnd={setDateEnd}
      name={name}
      setName={setName}
      handlePrintReport={handlePrintReport}
    />
  );
  const rendermodal = () => (
    <div>
      <Modal
        open={openCheckin}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="p-8">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="text-center  ">
                <div className="font-bold  ">หมายเหตุ</div>
                <div className=" p-6 border-green-600 border-2 text-sm">
                  {selectCheckin}
                </div>
              </div>
            </Typography>
          </Box>
        </div>
      </Modal>
    </div>
  );

  const rendermodalCheckout = () => (
    <div>
      <Modal
        open={openCheckout}
        onClose={handleCloseCheckOut}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="p-8">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="text-center">
                <div className="text-bold">หมายเหตุ</div>
                {/* <div>{checkIn?.arr?.noteCheckOut}</div> */}
                <div className="p-6 border-green-600 border-2 text-sm">
                  {selectCheckout}
                </div>
              </div>
            </Typography>
          </Box>
        </div>
      </Modal>
    </div>
  );

  const late = _?.filter(checkIn?.arr, (e) => e?.late_status === true);
  const normal = _?.filter(checkIn?.arr, (e) => e?.late_status === false);

  const renderSummary = () => (
    <div className=" hidden lg:block ">
      <div className="py-1 sm:grid sm:grid-cols-2 lg:grid lg:grid-cols-3 gap-2  ">
        <Card sx={{ height: 120, background: '#0080ff' }}>
          <div className="pt-2 text-center text-white ">รายการทั้งหมด </div>
          <div className="text-center text-white text-4xl ">
            {checkIn?.arr?.length}
          </div>
          <div className=" text-center text-white ">รายการ </div>
        </Card>
        <Card sx={{ height: 120, background: '#1AA61A' }}>
          <div className="pt-2 text-center text-white ">เข้างานปกติ </div>
          <div className="text-center text-white text-4xl ">
            {normal?.length || 0}
          </div>
          <div className=" text-center text-white ">รายการ </div>
        </Card>
        <Card sx={{ height: 120, background: '#A30000' }}>
          <div className="pt-2 text-center text-white ">เข้างานสาย </div>
          <div className="text-center text-white text-4xl ">
            {late?.length || 0}
          </div>
          <div className=" text-center text-white ">รายการ </div>
        </Card>
      </div>
    </div>
  );

  const renderTableCheckin = () => (
    <div className="">
      {renderFillter()}
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <h1 className="font-bold  text-center "> รหัสพนักงาน</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center "> ชื่อ-นามสกุล</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">วันที่</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">เวลาเข้างาน</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">เวลาออกงาน</h1>
                </TableCell>
                {/* <TableCell>
                  <h1 className="font-bold  text-center ">ชั่วโมงทำงาน</h1>
                </TableCell> */}
                <TableCell>
                  <h1 className="font-bold  text-center ">สถานที่เข้างาน</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">สถานที่ออกงาน</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold text-center">รูป</h1>
                </TableCell>
                <TableCell>
                  <div> </div>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {_.isEmpty(checkIn?.arr) ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    <h1 className=" text-center ">ไม่มีรายการลงเวลา </h1>
                  </TableCell>
                </TableRow>
              ) : (
                checkIn?.arr?.map((e) => (
                  <TableRow key={e._id}>
                    <TableCell>
                      <h1 className="font-bold">{e?.employee?.employeeId}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="font-bold">
                        {e?.employee?.name} {e?.employee?.surname}
                      </h1>
                      <div className="text-xs">แผนก {e?.department?.name}</div>
                    </TableCell>
                    <TableCell>
                      <h1 className=" ">{dayjs(e?.date).format('DD/MM/BB')}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" ">{dayjs(e?.date).format('HH:mm')} น.</h1>
                      {e?.late_status && (
                        <Chip label="สาย" size="small" color="error" />
                      )}
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">
                        {(e?.checkOut_status &&
                          dayjs(e?.date_checkOut).format('HH:mm')) ||
                          '-'}{' '}
                        น.
                      </h1>
                    </TableCell>
                    {/* <TableCell>
                      <h1 className=" text-center ">
                        {e?.sum_hours} ชม. {e?.sum_min} นาที
                      </h1>
                    </TableCell> */}
                    <TableCell>
                      <h1 className=" text-center ">{e?.placeCheckIn?.name}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">
                        {e?.placeCheckOut?.name}
                      </h1>
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-3 w-full p-2">
                        <Card>
                          <Avatar
                            src={e?.image?.url}
                            variant="square"
                            sx={{ width: 100, height: 100 }}
                          />
                          <div className="px-2 py-2 text-center">เข้างาน</div>
                          <div className="flex justify-center">
                            <button
                              onClick={() => {
                                handleOpen();
                                setSelectCheckin(e?.noteCheckin);
                              }}
                            >
                              <div className="text-green-700">เพิ่มเติม</div>
                            </button>
                          </div>
                        </Card>
                        {e?.checkOut_status && (
                          <Card>
                            <Avatar
                              src={e?.image_checkOut?.url}
                              variant="square"
                              sx={{ width: 100, height: 100 }}
                            />
                            <div className="p-2 text-center">ออกงาน</div>
                            {/* <div className="text-center">ดูเพิ่มเติม</div> */}
                            <div className="flex justify-center pb-4">
                              <button
                                onClick={() => {
                                  handleOpenCheckOut();
                                  setSelectCheckout(e?.noteCheckOut);
                                }}
                              >
                                <div className="text-green-700">เพิ่มเติม</div>
                              </button>
                            </div>
                          </Card>
                        )}
                      </div>
                    </TableCell>

                    <TableCell>
                      {/* <div className="py-1">
                  <Button
                  variant="contained"
                  color="error"
                        onClick={() => handleDelete(e?._id)}
                      >
                        ลบ
                        </Button>
                    </div> */}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 100]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={checkIn?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );

  const renderEmmployee = () => (
    <div>
      <CardMonthPrintFilltter
        month={month}
        setMonth={setMonth}
        name={name}
        setName={setName}
        handleMonthlyPrint={handleMonthlyPrint}
      />
      <SummaryTimestamp users={users} />
    </div>
  );

  const renderTab = () => (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="รายงานประจำวัน" value="1" />
            <Tab label="รายงานประจำเดือน" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1"> {renderTableCheckin()}</TabPanel>
        <TabPanel value="2">{renderEmmployee()}</TabPanel>
      </TabContext>
    </Box>
  );

  if (checkIn.isLoading || !checkIn.isCompleted) {
    return <Loading isLoading={checkIn?.isLoading} />;
  }
  if (!checkIn.isLoading || checkIn.isCompleted) {
    return (
      <div className=" min-h-screen ">
        <div>
          {rendermodal()}
          {rendermodalCheckout()}
          {renderLayout()}
          {renderSummary()}
          {renderTab()}
          {/* {renderTableCheckin()} */}
        </div>
      </div>
    );
  }
}
TimeStamp.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
TimeStamp.defaultProps = {
  title: '',
  subtitle: '',
};
