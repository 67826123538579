import {
  LEVEL_ALL,
  LEVEL_GET,
  LEVEL_POST,
  LEVEL_PUT,
  LEVEL_DELETE,
  LEVEL_LOADING,
} from '../type';

const initialState = {
  level: null,
  isLoading: true,
  isCompleted: true,
};

// eslint-disable-next-line default-param-last
function LevelReducer(state = initialState, action) {
  switch (action.type) {
    case LEVEL_LOADING:
      return { isLoading: true, isCompleted: false };
    case LEVEL_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LEVEL_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LEVEL_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LEVEL_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case LEVEL_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default LevelReducer;
