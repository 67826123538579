import LayoutPageAdd from 'components/layout/LayoutPageAdd';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import FormNewProduct from '../../components/Forms/FormNewProduct ';
import { Button } from '@mui/material';
import Save from '@mui/icons-material/Save';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../redux/action';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';

function EditProduct({ title, subtitle }) {
  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
  });
  const product = useSelector((state) => state.product);
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  // console.log("product", product);
  function getProduct(id) {
    dispatch(action.getOneProduct(id));
  }

  async function onSubmit(data) {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      try {
        await dispatch(action.putProduct(params.id, data));
        await dispatch(action.getAllProduct({}));
        history.goBack();
      } catch (error) {}
    }
  }
  useEffect(() => {
    // console.log(process.env.REACT_APP_API_URL);
    getProduct(params.id);
  }, []);
  useEffect(() => {
    setValue('productName', product?.productName);
    setValue('productQuantity', product?.productQuantity);
    setValue('productPrice', product?.productPrice);
    setValue('productUnit', product?.productUnit);
    setValue('productAbout', product?.productAbout);
    setValue('cost', product?.cost);
    return () => {};
  }, [product]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div className="min-h-screen">
      <div>
        <div>
          {renderTitle()}
          <div className="py-2">
            <BackButton />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormNewProduct Controller={Controller} control={control}>
            <div className="text-center pt-4">
              <Button variant="contained" startIcon={<Save />} type="submit">
                บันทึก
              </Button>
            </div>
          </FormNewProduct>
        </form>
      </div>
    </div>
  );
}
export default EditProduct;
