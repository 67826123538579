/* eslint-disable object-curly-newline */
import React, { useEffect } from 'react';
import { ViewTitle } from 'components/ViewTitle';
import { Card, Box, Modal, Stack, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AddWorkBu from 'components/Forms/AddWorkBu';
import PropTypes from 'prop-types';
import ShowCalendar from '../../components/Calendar/ShowCalendar';
import * as actions from '../../redux/action';
import Loading from 'components/Loading';
import JobInfoBu from 'components/Modal/JobInfoBu';

export default function HrCalendar({ title, subtitle }) {
  const dispatch = useDispatch();
  const work = useSelector((state) => state.work);
  const user = useSelector((state) => state.user);
  const { control, setValue, handleSubmit, reset } = useForm({
    mode: 'onChange',
  });
  const [open, setOpen] = React.useState(false);
  const [openJob, setOpenJob] = React.useState(false);
  const [date, setDate] = React.useState(new Date());
  const [time, setTime] = React.useState();
  const [job, setJob] = React.useState();
  const [fillterMe, setFilterMe] = React.useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleJobOpen = () => setOpenJob(true);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'employeeArray',
  });
  const defaultValue = {
    job: '',
  };
  useEffect(() => {
    dispatch(actions.getAllUser({}));
    dispatch(actions.getAllwork({ fillterMe: '', creater: 'HR' }));
    dispatch(actions.getAllLeaveDoc({ name: '', page: 1, size: 0 }));
    return () => {};
  }, []);

  const onSubmit = async (data) => {
    const confirm = window.confirm('ยืนยัน');
    if (confirm) {
      try {
        const dataSubmit = {
          ...data,
          date,
          worker: data?.employeeArray,
          name: data?.job,
        };

        await dispatch(actions.addwork(dataSubmit));
        dispatch(actions.getAllUser({}));
        dispatch(actions.getAllwork({ fillterMe }));
        dispatch(actions.getAllLeaveDoc({ name: '', page: 1, size: 0 }));
        await reset(defaultValue);
        handleClose();
      } catch (error) {
        console.log('error');
      }
    }
  };

  const renderModal = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
            }}
          >
            <AddWorkBu
              control={control}
              Controller={Controller}
              fields={fields}
              append={append}
              remove={remove}
              setValue={setValue}
              user={user}
              date={date}
              setDate={setDate}
              time={time}
              setTime={setTime}
            />
          </Box>
        </form>
      </Modal>
    </div>
  );
  const renderColor = () => (
    <div className="pb-4">
      <Card>
        <div className="p-2">
          <Card>
            <div className="">
              <div className="flex">
                <FiberManualRecordIcon sx={{ color: '#1273de' }} />
                <h1>ลงพื้นที่ทั้งวัน</h1>
              </div>
              <div className="flex">
                <FiberManualRecordIcon sx={{ color: '#4caf50' }} />
                <h1>ลงพื้นที่ครึ่งวัน</h1>
              </div>
              <div className="flex">
                <FiberManualRecordIcon sx={{ color: '#ef6c00' }} />
                <h1>ลางานครึ่งวัน</h1>
              </div>
              <div className="flex">
                <FiberManualRecordIcon sx={{ color: '#fccb00' }} />
                <h1>ทำงานในวันหยุด</h1>
              </div>{' '}
              <div className="flex">
                <FiberManualRecordIcon sx={{ color: '#7b1fa2' }} />
                <h1>ทำงานในวันหยุดครึ่งวัน</h1>
              </div>
              <div className="flex">
                <FiberManualRecordIcon sx={{ color: '#f5071b' }} />
                <h1>ลางาน</h1>
              </div>
            </div>
          </Card>
        </div>
      </Card>
    </div>
  );

  const renderViewTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderCalendar = () => (
    <div>
      <div className="">
        <Box sx={{}}>
          <Card>
            <ShowCalendar
              work={work}
              setJob={setJob}
              handleJobOpen={handleJobOpen}
            />
          </Card>
        </Box>
      </div>
    </div>
  );
  const renderModalJob = () => (
    <JobInfoBu openJob={openJob} setOpenJob={setOpenJob} job={job} />
  );
  // const renderButton = () => (
  //   <div className="pb-6 flex justify-end">
  //     <Stack spacing={2} direction="row">
  //       <Button variant="contained" startIcon={<Add />} onClick={handleOpen}>
  //         เพิ่มงาน
  //       </Button>
  //     </Stack>
  //     {renderModal()}
  //     {renderModalJob()}
  //   </div>
  // );

  if (work.isLoading || !work.isCompleted) {
    return <Loading isLoading />;
  }
  if (!work.isLoading || work.isCompleted) {
    return (
      <div>
        {renderViewTitle()}
        {/* {renderButton()} */}
        {renderCalendar()}
        {renderModalJob()}
        {renderColor()}
      </div>
    );
  }
}

HrCalendar.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
HrCalendar.defaultProps = {
  title: '',
  subtitle: '',
};
