import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import dayjs from 'dayjs';
import 'dayjs/locale/th';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const callateFunc = (timestamp) => {
  const count = _?.groupBy(timestamp, ['late_status', true]);

  return count;
};

const genBody = (rows) => {
  const body = _.map(rows, (row, index) => [
    { text: index + 1, alignment: 'center' },

    {
      text: `${row?.employeeId || ''}`,
    },
    {
      text: `${row?.name} ${row?.surname}`,
    },
    {
      text: `${row?.department?.name}`,
    },
    {
      text: `${row?.timestamp?.length}`,
      alignment: 'center',
    },
    {
      text: `${callateFunc(row?.timestamp)?.true?.length || 0}`,
      alignment: 'center',
    },
  ]);

  return body;
};

const MonthlyCheckinReport = async (rows, date, information) => {
  console.log('rows', rows);
  const genItemBody = genBody(rows?.arr);
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 80, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    info: {
      title: `รายงานการลงเวลา_${dayjs(date).format('MM_YYYY')}`,
    },
    header: [
      { text: information?.name, style: 'header', margin: [0, 20, 0, 0] },
      {
        text: `รายงานการลงเวลา ประจำเดือน ${dayjs(date).format('MMMM BBBB')}`,
        style: 'header2',
        margin: [0, 2, 0, 0],
      },
    ],
    content: [
      {
        table: {
          widths: ['10%', '17%', '25%', '20%', '15%', '15%'],
          header: 1,
          body: [
            [
              {
                text: 'ลำดับ',
                style: 'body',
              },
              {
                text: 'รหัสพนักงาน',
                style: 'body',
              },
              {
                text: 'ชื่อพนักงาน',
                style: 'body',
              },

              {
                text: 'แผนก',
                style: 'body',
              },
              {
                text: 'จำนวนครั้ง',
                style: 'body',
              },
              {
                text: 'เข้างานสาย',
                style: 'body',
              },
            ],
            ...genItemBody,
          ],
        },
      },

      { text: '', margin: [0, 40, 0, 20] },
    ],

    footer: [],

    styles: {
      header: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
      },
      header2: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
      },

      body: {
        fontSize: 12,
        alignment: 'center',
      },
    },
    images: {},
  };
  pdfMake.createPdf(documentRef).open();
};
export default MonthlyCheckinReport;
