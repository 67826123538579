import React, { useState, useEffect } from 'react';
import {
  Button, CardContent, TextField, Autocomplete,
  Paper,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';

import Add from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as action from '../../redux/action';

function AdPage() {
  const { handleSubmit, control, reset } = useForm({ mode: 'onChange' });
  const [name, setName] = React.useState('');
  const [role, setRole] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const axios = require('axios');
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const [password, setPassword] = useState({
    password: '',
    showPassword: false,
  });
  const defaultValue = {
    name: '',
    tel: '',
    email: '',
    username: '',
  };

  function deleteUser(id) {
    dispatch(action.deleteUser(id));
  }

  useEffect(() => {
    dispatch(action.getAllUser({ name, page, size }));
  }, [name, page, size]);

  function handleEditUser(userId) {
    console.log(userId);
    history.push(`/hr/editemployee/${userId}`);
  }

  const handleAdd = () => {
    history.push('/admin/newemployee');
  };
  return (
    <div className="min-h-screen">
      <div className="pt-16">
        <div className="text-3xl pb-4 border-l-8 border-orange-500 ">
          <h1 className="font-blod pl-4">พนักงาน</h1>
        </div>
        <div className="pt-2 pb-2">
          <Button
            variant="contained"
            startIcon={<Add />}
            color="primary"
            onClick={() => {
              handleAdd();
            }}
          >
            เพิ่มพนักงาน
          </Button>
        </div>

        <Paper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p className="font-bold" />
                  </TableCell>
                  <TableCell>
                    <p className="font-bold">ชื่อ-นามสกุล</p>
                  </TableCell>
                  <TableCell>
                    <p className="font-bold">เบอร์โทร</p>
                  </TableCell>
                  <TableCell>
                    <p className="font-bold">ตำแหน่ง</p>
                  </TableCell>
                  <TableCell>
                    <p className="font-bold">สังกัด</p>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {user?.arr?.map((users) => (
                  <TableRow key={users.index}>
                    <TableCell component="th" scope="row" />

                    <TableCell>
                      {' '}
                      <Link to={`/hr/editemployee/${users._id}`}>
                        {users?.name}
                      </Link>
                    </TableCell>

                    <TableCell>
                      <Link to={`/hr/editemployee/${users._id}`}>
                        {users?.tel}
                        {' '}
                      </Link>
                    </TableCell>

                    <TableCell>
                      {' '}
                      <Link to={`/hr/editemployee/${users._id}`}>
                        {users?.position}
                        {' '}
                      </Link>
                    </TableCell>

                    <TableCell>
                      <Link to={`/hr/editemployee/${users._id}`}>
                        {users?.under}
                        {' '}
                      </Link>
                    </TableCell>

                    <TableCell align="right">
                      <Stack
                        spacing={1}
                        direction="row"
                        className="justify-end"
                      >
                        <Button
                          color="error"
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            deleteUser(users?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={quotation?.total}
            rowsPerPage={size}
            page={page - 1}
          /> */}
        </Paper>
      </div>
    </div>
  );
}
export default AdPage;
