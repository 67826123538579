/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router';
import { Card, FormControlLabel, Switch, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { Delete } from '@mui/icons-material';

export default function ListTimestampSetting({
  timeStampSetting,
  handleOnselect = () => {},
  handleDelete = () => {},
}) {
  const history = useHistory();
  return (
    <div className="lg:grid grid-cols-3 gap-2 ">
      {timeStampSetting?.arr?.length !== 0 ? (
        _.map(timeStampSetting?.arr, (e, index) => (
          <div className="py-1">
            <Card key={index} sx={{ maxWidth: 505, minHeight: 170 }}>
              <div className="flex justify-between">
                <div className="flex w-full p-4 ">
                  <div>
                    <h1 className="text-xl font-bold">{e?.name}</h1>
                    <h1> เวลาเข้างาน {dayjs(e?.time_in).format('HH:mm')}</h1>
                    <h1> เวลาออกงาน {dayjs(e?.time_out).format('HH:mm')}</h1>
                  </div>
                  <div>
                    <Link to={`timestamp-setting/edit/${e?._id}`}>
                      <IconButton size="small">
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    </Link>
                  </div>
                </div>
                <div className="p-2">
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => handleDelete(e?._id)}
                  >
                    <Delete fontSize="inherit" />
                  </IconButton>
                </div>
              </div>
              <div className="p-4">
                <FormControlLabel
                  control={
                    <Switch
                      checked={e?.status}
                      name="gilad"
                      onChange={(each) =>
                        handleOnselect(each?.target.checked, e?._id)
                      }
                    />
                  }
                  label="เปิดให้ลงเวลา"
                />
              </div>
            </Card>
          </div>
        ))
      ) : (
        <Card sx={{ maxWidth: 445, minHeight: 170 }}>
          <div className="py-20 items-center my-auto flex flex-wrap justify-center">
            <div className="text-xl font-bold">ไม่มีรายการ</div>
          </div>
        </Card>
      )}
    </div>
  );
}
