import {
  SUBBUSINESS_ALL,
  SUBBUSINESS_DELETE,
  SUBBUSINESS_GET,
  SUBBUSINESS_LOADING,
  SUBBUSINESS_POST,
  SUBBUSINESS_PUT,
} from '../type';

const initialState = {
  subBusiness: null,
  isLoading: true,
  isCompleted: true,
};

function SubBusinessReducer(state = initialState, action) {
  switch (action.type) {
    case SUBBUSINESS_LOADING:
      return { isLoading: true, isCompleted: false };
    case SUBBUSINESS_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SUBBUSINESS_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SUBBUSINESS_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SUBBUSINESS_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case SUBBUSINESS_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default SubBusinessReducer;
