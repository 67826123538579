import {
  PAYMENT_ALL,
  PAYMENT_GET,
  PAYMENT_POST,
  PAYMENT_PUT,
  PAYMENT_DELETE,
  PAYMENT_LOADING,
  PAYMENT_ERROR,
} from '../type';

import api from '../../utils/functions/api';

export const paymentCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PAYMENT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/payment`,
      {
        ...payload,
      }
    );
    dispatch({ type: PAYMENT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PAYMENT_ERROR });
  }
};

export const paymentGetAll =
  ({ name = '', size = '', page = 1 }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/payment?name=${name}&size=${size}&page=${page}`
      );
      if (status === 200) {
        dispatch({ type: PAYMENT_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: PAYMENT_ERROR });
    }
  };

export const paymentGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/payment/${id}`
    );
    if (status === 200) {
      dispatch({ type: PAYMENT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PAYMENT_ERROR });
  }
};

export const paymentPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PAYMENT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/payment/${id}`,
      payload
    );
    dispatch({ type: PAYMENT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PAYMENT_ERROR });
  }
};
export const paymentDelete = (id) => async (dispatch) => {
  try {
    console.log('ID ', id);
    dispatch({ type: PAYMENT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/payment/${id}`
    );
    dispatch({ type: PAYMENT_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PAYMENT_ERROR });
  }
};
