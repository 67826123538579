import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { useHistory } from 'react-router';
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Loading from 'components/Loading';
import * as actions from '../../redux/action';

function Position({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const position = useSelector((state) => state.position);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  useEffect(() => {
    dispatch(actions.positionGetAll({ page, size }));
    return () => {};
  }, [page, size]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderAddButton = () => (
    <div>
      <Button
        variant="contained"
        onClick={() => history.push('/hr/position/new')}
      >
        เพิ่ม
      </Button>
    </div>
  );

  const handleDelete = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.positionDelete(id));
        await dispatch(actions.positionGetAll({ page, size }));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const renderTable = () => (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            <colgroup>
              <col width="20%" />
              <col width="25%" />
              <col width="20%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h1 className="font-bold text-center ">ลำดับ</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold text-center ">ตำแหน่ง</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">ดำเนินการ</h1>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {_.isEmpty(position?.arr) ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <h1 className=" text-center ">ไม่มีรายการ </h1>
                  </TableCell>
                </TableRow>
              ) : (
                position?.arr?.map((e, index) => (
                  <TableRow key={e._id}>
                    <TableCell>
                      <h1 className=" text-center ">{index + 1}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">{e?.name}</h1>
                    </TableCell>
                    <TableCell>
                      <div className="py-1 flex gap-2 justify-center ">
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={() =>
                            history.push(`/hr/position/edit/${e?._id}`)
                          }
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleDelete(e?._id)}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 100]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={position?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );

  if (position.isLoading || !position.isCompleted) {
    return <Loading isLoading />;
  }
  if (!position.isLoading || position.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="py-4">{renderAddButton()}</div>
        </div>
        <div>{renderTable()}</div>
      </div>
    );
  }
}

Position.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
Position.defaultProps = {
  title: '',
  subtitle: '',
};

export default Position;
