import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ViewTitle } from 'components/ViewTitle';
import { Card, Box, Button } from '@mui/material';
// eslint-disable-next-line import/order
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as actions from '../../redux/action';
import ShowCalendar from '../../components/Calendar/ShowCalendar';

import JobInfoBu from '../../components/Modal/JobInfoBu';
import Loading from 'components/Loading';

function CalendaMe({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const work = useSelector((state) => state.work);
  const me = useSelector((state) => state.me);
  const [openJob, setOpenJob] = React.useState(false);
  const [job, setJob] = React.useState();
  const { access } = me?.userData?.position;

  const handleJobOpen = () => setOpenJob(true);

  useEffect(() => {
    dispatch(actions.getAllUser({}));
    dispatch(
      actions.getAllwork({
        fillterMe: me?.userData?._id,
        department: me?.userData?.department?._id,
      })
    );

    return () => {};
  }, []);
  const renderModalJob = () => (
    <JobInfoBu openJob={openJob} setOpenJob={setOpenJob} job={job} />
  );
  const renderCalendar = () => (
    <div>
      <div className="">
        <Box sx={{}}>
          <Card>
            <ShowCalendar
              work={work}
              setJob={setJob}
              handleJobOpen={handleJobOpen}
            />
          </Card>
        </Box>
      </div>
    </div>
  );

  const renderButtonWork = () => (
    <Button variant="contained" onClick={() => history.push('list')}>
      รายการงาน
    </Button>
  );

  const renderViewTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (work?.isLoading || !work?.isCompleted) {
    return <Loading isLoading />;
  }

  if (!work?.isLoading && work?.isCompleted) {
    return (
      <div>
        {renderModalJob()}
        {renderViewTitle()}
        {access?.ADD_WORK && (
          <div className="flex justify-end py-2 ">{renderButtonWork()}</div>
        )}
        {renderCalendar()}
      </div>
    );
  }
  return <Loading isLoading />;
}

CalendaMe.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CalendaMe.defaultProps = {
  title: '',
  subtitle: '',
};

export default CalendaMe;
