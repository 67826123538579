/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useForm, Controller, useFieldArray } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import {
  Button,
  Autocomplete,
  TextField,
  Radio,
  FormControl,
} from '@mui/material';

import _ from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Delete from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import * as actions from '../../redux/action';
import { checkbox } from '@tailwindcss/custom-forms/src/defaultOptions';

function ProductServe({
  statusPickUp = true,
  statusHide = true,
  setProductList,
  control,
  setValue,
  edit,
  watch,
  productVat,
  setProductVat,
  productVatUni,
  setProductVatUni,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const product = useSelector((state) => state.product);

  // const [productVat, setProductVat] = useState(false);
  // const [productVatUni, setProductVatUni] = useState(false);

  const handleChange = (event) => setProductVat(event.target.checked);
  const handleChangeUni = (event) => setProductVatUni(event.target.checked);
  console.log('ProductVatUni, ProductVat', productVatUni, productVat);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'productArray',
  });
  useEffect(() => {
    dispatch(actions.getAllProduct({}));
    return () => {};
  }, []);
  setProductList(watch('productArray'));
  const handleCheckProduct = (data, index) => {
    const products = _.find(product.arr, { _id: data?._id });
    setValue(`productArray.${index}.name`, products?.productName);
    setValue(`productArray.${index}.quantity`, products?.productQuantity);
    setValue(`productArray.${index}.price`, products?.productPrice);
    setValue(`productArray.${index}.cost`, products?.cost || 0);
    setValue(`productArray.${index}.unit`, products?.productUnit);
    setValue(`productArray.${index}.about`, products?.productAbout);
    setValue(`productArray.${index}.vat`, products?.productVat);
  };

  const handleAdd = (service) => {
    if (service) {
      append({
        name: '',
        price: '0',
        unit: '-',
        about: '',
        discount: '0',
        quantity: '0',
        cost: '0',
        service: true,
      });
    } else if (product.arr.length === 0) {
      alert('ยังไม่มีสินค้าในคลัง');
    } else {
      append({
        name: product.arr[0]?.id,
        price: '0',
        unit: '-',
        about: '',
        discount: '0',
        quantity: '0',
        cost: '0',
        service: false,
      });
    }
  };

  const SingleCheckboxSelector = () => {
    const [selectedItem, setSelectedItem] = useState(null);

    const items = ['vat', 'vatUni'];
  };
  const handleDelete = (index) => {
    remove(index);
  };
  console.log('ProductVatUni', productVatUni);
  console.log('ProductVat', productVat);
  const checkboxone = () => {
    if (productVat && productVatUni === true) {
      return <div> โปรดเลือกเพียง</div>;
    }
  };

  return (
    <Paper>
      <div className="flex flex-row justify-end pt-2">
        <div>
          {statusPickUp && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={productVat}
                    // checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="ไม่คิด VAT 7%"
              />
            </div>
          )}
        </div>
        <div>
          {statusHide && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={productVat}
                    onChange={handleChange}
                    defaultValue={productVat}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="นิติบุคคล"
              />
            </div>
          )}
        </div>

        <div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={productVatUni}
                  onChange={handleChangeUni}
                  defaultValue={productVatUni}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="มหาลัย"
            />
          </div>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-aria-label="simple table">
          <colgroup>
            <col width="5%" />
            <col width="30%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="15%" />
            <col width="5%" />
          </colgroup>
          <TableHead className="bg-gray-600  ">
            <TableRow>
              <TableCell>
                {' '}
                <h1 className="text-center font-bold text-white">ลำดับ</h1>
              </TableCell>
              <TableCell>
                <h1 className="text-center font-bold text-white">ชื่อสินค้า</h1>
              </TableCell>
              <TableCell>
                {' '}
                <h1 className="text-center font-bold text-white">จำนวน</h1>
              </TableCell>
              <TableCell>
                {' '}
                <h1 className="text-center font-bold text-white">หน่วย</h1>
              </TableCell>
              <TableCell>
                {' '}
                <h1 className="text-center font-bold text-white">ราคาต้นทุน</h1>
              </TableCell>
              <TableCell>
                {' '}
                <h1 className="text-center font-bold text-white">
                  ราคาต่อหน่วย
                </h1>
              </TableCell>
              <TableCell>
                {' '}
                <h1 className="text-center font-bold text-white">ส่วนลด</h1>
              </TableCell>
              <TableCell>
                {' '}
                <h1 className="text-center font-bold text-white">
                  จำนวนเงิน
                </h1>{' '}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(fields, (item, index) => (
              <TableRow
                key={item?.id}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <div className="py-1 text-center">{`${index + 1}`}</div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className="py-1">
                    {edit ? (
                      <>
                        {!item?.service ? (
                          <>
                            {' '}
                            <div className="pb-2">
                              <Controller
                                name={`productArray.${index}.name`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id="outlined-multiline-basic"
                                    label="ชื่อสินค้า"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />{' '}
                            </div>
                            <Controller
                              name={`productArray.${index}.about`}
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  id="outlined-multiline-basic"
                                  label="รายละเอียดสินค้า"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={3}
                                />
                              )}
                            />{' '}
                          </>
                        ) : (
                          <>
                            {' '}
                            <div className="pb-1">
                              <Controller
                                name={`productArray.${index}.name`}
                                control={control}
                                // defaultValue={item?.price}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id="outlined-basic"
                                    label="ชื่อบริการ"
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                            <Controller
                              name={`productArray.${index}.about`}
                              control={control}
                              // defaultValue={item?.price}
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  multiline
                                  rows={2}
                                  label="รายละเอียดสินค้า"
                                  {...field}
                                />
                              )}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {!item?.service ? (
                          <>
                            <div className="pb-2">
                              <Controller
                                name={`productArray.${index}.name`}
                                control={control}
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    disablePortal
                                    id="free-solo-demo"
                                    freeSolo
                                    options={product?.arr || []}
                                    getOptionLabel={(option) =>
                                      option?.productName
                                    }
                                    onChange={(e, newValue) =>
                                      handleCheckProduct(newValue, index)
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        label="ชื่อสินค้า"
                                      />
                                    )}
                                  />
                                )}
                              />{' '}
                            </div>
                            <Controller
                              name={`productArray.${index}.about`}
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  id="outlined-multiline-basic"
                                  label="รายละเอียดสินค้า"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={2}
                                />
                              )}
                            />{' '}
                          </>
                        ) : (
                          <>
                            <div className="pb-1">
                              <Controller
                                name={`productArray.${index}.name`}
                                control={control}
                                // defaultValue={item?.price}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id="outlined-basic"
                                    label="ชื่อบริการ"
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </div>

                            <Controller
                              name={`productArray.${index}.about`}
                              control={control}
                              // defaultValue={item?.price}
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  multiline
                                  rows={2}
                                  label="รายละเอียดสินค้า"
                                  {...field}
                                />
                              )}
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className="py-1">
                    <Controller
                      name={`productArray.${index}.quantity`}
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          size="small"
                          fullWidth
                          required
                          type="number"
                          label="จำนวน"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className="py-1">
                    <Controller
                      name={`productArray.${index}.unit`}
                      control={control}
                      // defaultValue={item?.price}
                      render={({ field }) => (
                        <TextField
                          size="small"
                          fullWidth
                          required
                          label="หน่วย"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className="py-1">
                    <Controller
                      name={`productArray.${index}.cost`}
                      control={control}
                      // defaultValue={item?.price}
                      render={({ field }) => (
                        <TextField
                          size="small"
                          fullWidth
                          label="ราคาต้นทุน"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className="py-1">
                    <Controller
                      name={`productArray.${index}.price`}
                      control={control}
                      // defaultValue={item?.price}
                      render={({ field }) => (
                        <TextField
                          size="small"
                          fullWidth
                          required
                          label="ราคาต่อหน่วย"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className="py-1">
                    <Controller
                      name={`productArray.${index}.discount`}
                      control={control}
                      // defaultValue={item?.price}
                      render={({ field }) => (
                        <TextField
                          size="small"
                          fullWidth
                          required
                          label="ส่วนลด"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div className="py-1">
                    {' '}
                    {watch(`productArray.${index}.quantity`) *
                      watch(`productArray.${index}.price`)}
                  </div>
                </TableCell>
                <TableCell>
                  <Button
                    color="error"
                    variant="contained"
                    className="w-full "
                    onClick={() => {
                      handleDelete(index);
                    }}
                  >
                    <Delete />
                    ลบ
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>{' '}
        </Table>
      </TableContainer>{' '}
      <div className="flex">
        <Button
          color="primary"
          variant="contained"
          className="w-full "
          onClick={() => {
            handleAdd();
          }}
        >
          <AddIcon />
          เพิ่มสินค้า
        </Button>
        <Button
          color="success"
          variant="contained"
          className="w-full "
          onClick={() => {
            handleAdd(true);
          }}
        >
          <AddIcon />
          เพิ่มบริการ
        </Button>
      </div>
    </Paper>
  );
}

export default ProductServe;
