/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import {
  Button,
  Card,
  CardContent,
  Autocomplete,
  TextField,
  IconButton,
  ButtonUnstyled,
} from '@mui/material';

import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory } from 'react-router-dom';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import SumAllPrice from 'components/Card/SumAllPrice';
import Sumprice from 'components/Card/Sumprice';
import SumDiscount from 'components/Card/SumDiscount';
import * as action from '../../../redux/action';
import ProductServe from '../../../components/Forms/FormProductArray';

function NewPickUpOrder({ title, subtitle }) {
  const [customerData, setCustomerData] = useState();
  const { handleSubmit, control, setValue, watch } = useForm({});
  const [date, setDate] = React.useState(new Date());
  const [dueDate, setDueDate] = React.useState(new Date());
  const [productList, setProductList] = useState([]);
  const [reload, setReload] = useState(false);
  const [findName, setFindName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [productVat, setProductVat] = useState(false);
  const history = useHistory();
  dayjs.extend(buddhistEra);
  // console.log(dayjs(date).format(`BBMMDD`));
  // post data
  const dispatch = useDispatch();
  // Modal
  const [open, setOpen] = useState(false);
  // print

  const onSubmit = async (data) => {
    if (productList.length === 0) alert('กรุณาเพิ่มสินค้า');
    else {
      try {
        // console.log("data.name", data.payment);
        const ProcessData = await {
          ...data,
          date,
          productVat,
          dueDate,
          prefix: `RI${dayjs(date).format('BB')}-`,
          allPrice: SumAllPrice(productList),
          product: data?.productArray,
        };
        const prdouctPayload = await {
          arr: _.map(data?.productArray, (each) => ({
            productName: each.name,
            productQuantity: each?.quantity,
            productUnit: each?.unit,
            productAbout: each?.about,
            cost: each?.price,
          })),
        };
        console.log('product', prdouctPayload);

        await dispatch(action.addPickUpOrder(ProcessData));
        await dispatch(action.addProduct(prdouctPayload));
        await dispatch(action.getAllPickUpOrder({ findName, page, size }));
        history.push('/purchase/pickUpOrder');
      } catch (error) {}
    }
  };
  // axios

  ///

  const Price = () => {
    if (productList?.length === 0) {
      return 0;
    }
    return Sumprice(productList) - SumDiscount(productList);
  };

  const taxPrice = () => {
    if (productVat) {
      return 0;
    }
    return Price() * 0.07;
  };

  const allPrice = () => Price() + taxPrice();

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => <BackButton />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        {renderTitle()}

        <div className="flex justify-between">
          {renderBackButton()}
          <Button variant="contained" type="submit" size="large">
            บันทึก
          </Button>
        </div>
        <div className="flex w-full pt-6 h-full">
          <div>
            <Card className="justify-center mt-2 ">
              <CardContent>
                <h1 className="pb-4"> ข้อมูลผู้ขาย</h1>
                <Controller
                  name="businessName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      value={field.value}
                      label="ชื่อผู้ขาย"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="TaxId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      label="หมายเลขผู้เสียภาษี"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="tel"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      id="outlined-basic"
                      label="เบอร์โทร"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="address"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      id="outlined-multiline-basic"
                      label="ที่อยู่"
                      variant="outlined"
                      fullWidth
                      multiline
                      margin="normal"
                      rows={4}
                    />
                  )}
                />
              </CardContent>
            </Card>{' '}
          </div>
          <div className="่ w-full  pl-4 ">
            <div>
              <Card>
                <CardContent>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่"
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่วันที่กำหนดส่ง"
                      value={dueDate}
                      onChange={(newValue) => {
                        setDueDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>

                  <Controller
                    name="doc"
                    control={control}
                    defaultValue="-"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="เอกสารอ้างอิง"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="payment"
                    control={control}
                    defaultValue="โอน/เงินสด"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="วิธีการชำระเงิน"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-2">
            <ProductServe
              statusHide={false}
              statusPickUp={true}
              setProductList={setProductList}
              setValue={setValue}
              control={control}
              watch={watch}
              setProductVat={setProductVat}
              productVat={productVat}
            />
          </div>
          <Card>
            <CardContent>
              <div className="flex w-full ">
                <div className="w-1/2">
                  {' '}
                  <Controller
                    name="note"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-multiline-basic"
                        label="หมายเหตุ"
                        variant="outlined"
                        fullWidth
                        multiline
                        margin="normal"
                        rows={4}
                      />
                    )}
                  />
                </div>

                <div className="flex justify-between  w-1/2 ml-2  ">
                  <div>
                    <div>รวมเงิน </div>
                    <div>ส่วนลดการค้า</div>
                    <div>เงินหลังหักส่วนลด</div>
                    <div>VAT 7%</div>
                    <div className="font-bold ">จำนวนเงินทั้งสิ้น </div>
                  </div>
                  <div className="text-right">
                    <div>
                      {Sumprice(productList)
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div>
                      {SumDiscount(productList)
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div>
                      {Price()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>
                    <div>
                      {taxPrice()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div className="font-bold ">
                      {allPrice()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </form>
  );
}
export default NewPickUpOrder;
