import { Card, CardContent, TextField, Button } from '@mui/material';
import React from 'react';
// import { useForm, Controller } from 'react-hook-form';

function FormNewProduct({ children, control, Controller }) {
  return (
    <Card className="">
      <CardContent>
        <Controller
          name="productName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อสินค้า"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <div className="flex w-full">
          <div className=" w-full">
            <Controller
              name="productQuantity"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  id="outlined-basic"
                  label="จำนวน"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              )}
            />
          </div>

          <div className="pl-2 w-full">
            <Controller
              name="productUnit"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="หน่วย"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              )}
            />
          </div>
          <div className="pl-2 w-full">
            <Controller
              name="cost"
              control={control}
              required
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="ราคาต้นทุน"
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                />
              )}
            />
          </div>
          <div className="pl-2 w-full">
            <Controller
              name="productPrice"
              control={control}
              required
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="ราคาขาย"
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                />
              )}
            />
          </div>
        </div>
        <Controller
          name="productAbout"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              id="outlined-multiline-basic"
              label="รายละเอียดสินค้า"
              variant="outlined"
              fullWidth
              multiline
              rows={2}
            />
          )}
        />{' '}
        <div className="w-full">{children}</div>
      </CardContent>
    </Card>
  );
}

export default FormNewProduct;
