import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

export default function UndefinedLeavedoc() {
  return (
    <div>
      <div className="container mx-auto px-4 h-full min-h-screen  ">
        <div className="flex content-center items-center justify-center h-full  min-h-screen">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-xl text-center font-bold ">
                    ใบลาอาจถูกลบโดยพนักงาน
                  </div>
                  <div className="text-center py-4  ">
                    <CancelIcon sx={{ fontSize: 120, color: 'red' }} />{' '}
                  </div>
                </div>
                <div className="pt-4">
                  <div className="w-full  px-4">
                    <div className="text-sm text-gray-600 font-semibold py-1 text-center md:text-left">
                      Copyright © {new Date().getFullYear()}{' '}
                      <a
                        href="https://iarc.psu.ac.th/"
                        className="text-gray-600 hover:text-gray-800 text-sm font-semibold py-1"
                      >
                        IARC PSU
                      </a>
                      <h1> version {process.env.REACT_APP_VERSION_RELEASE}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
