import { combineReducers } from 'redux';
import CustomerReducer from './CustomerReducer';
import QuotationReducer from './QuotationReducer';
import ProductReducer from './ProductReducer';
import InvoiceReducer from './InvoiceReducer';
import ReceiptReducer from './RecieptReducer';
import UserReducer from './UserReducer';
import MeReducer from './MeReducer';
import LeaveDocReducer from './LeaveDocReducer';
import PurchaseReducer from './PurchaseReducer';
import PickUpOrderReducer from './PickUpOrderReducer';
import SalaryReducer from './SalaryReducer';
import AvailableModuleReducer from './AvailableModuleReducer';
import DepartmentReducer from './DepartmentReducer';
import InformationReducer from './InformationReducer';
import WorkReducer from './WorkReducer';
import checkInReducer from './CheckInReducer';
import TimeStampSettingReducer from './TimeStampSettingReducer';
import leaveTypeReducer from './leaveTypeReducer';
import positionReducer from './PositionReducer';
import settingReducer from './SettingReducer';
import GroupReducer from './GroupReducer';
import LevelReducer from './LevelReducer';
import SubBusinessReducer from './SubBusinessReducer';
import newsReducer from './NewsReducer';
import NotifyReducer from './NotifyReducer';
import FileReducer from './FileReducer';
import PaymentReducer from './PaymentReducer';
import ProjectReducer from './ProjectReducer';

export default combineReducers({
  customer: CustomerReducer,
  quotation: QuotationReducer,
  product: ProductReducer,
  invoice: InvoiceReducer,
  receipt: ReceiptReducer,
  user: UserReducer,
  purchase: PurchaseReducer,
  me: MeReducer,
  leaveDoc: LeaveDocReducer,
  pickUpOrder: PickUpOrderReducer,
  Salary: SalaryReducer,
  AvailableModule: AvailableModuleReducer,
  Department: DepartmentReducer,
  Project: ProjectReducer,
  information: InformationReducer,
  work: WorkReducer,
  checkIn: checkInReducer,
  TimeStampSetting: TimeStampSettingReducer,
  leaveType: leaveTypeReducer,
  position: positionReducer,
  setting: settingReducer,
  group: GroupReducer,
  level: LevelReducer,
  subBusiness: SubBusinessReducer,
  news: newsReducer,
  notify: NotifyReducer,
  file: FileReducer,
  payment: PaymentReducer,
});
