/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { ViewTitle } from 'components/ViewTitle';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Box,
  Button,
  Card,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import SubBusinessForm from '../../components/Forms/SubBusinessForm';
import * as actions from '../../redux/action';
import Loading from '../../components/Loading';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function SubBusiness({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const subBusiness = useSelector((state) => state.subBusiness);
  const rederTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const { handleSubmit, control } = useForm({});

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(actions.subBusinessGetAll({}));

    return () => {};
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const rederAddButtom = () => (
    <div>
      <div className="flex justify-end py-4 ">
        <Button variant="contained" onClick={handleOpen}>
          เพิ่ม
        </Button>
      </div>
    </div>
  );

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.subBusinessCreate(data));
        await dispatch(actions.subBusinessGetAll({}));
        handleClose();
      } catch (error) {}
    }
  };

  const handleDelete = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      await dispatch(actions.subBusinessDelete(id));
      await dispatch(actions.subBusinessGetAll({}));
    }
  };

  // const handleEdit

  const renderModal = () => (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style }}>
        <h2 id="child-modal-title" className="font-bold">
          เพิ่มบริษัทในเครือ
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SubBusinessForm control={control} Controller={Controller} />
          <div className="flex justify-end">
            <Button type="submit" variant="contained">
              บันทึก
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );

  const renderTable = () => (
    <div className="w-full">
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h1 className="font-bold text-center ">ลำดับ</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold text-center ">สังกัด/บริษัทย่อย</h1>
                </TableCell>
                <TableCell>
                  {' '}
                  <h1 className="font-bold  text-center ">ดำเนินการ</h1>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {_.isEmpty(subBusiness?.arr) ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <h1 className=" text-center ">ไม่มีรายการ </h1>
                  </TableCell>
                </TableRow>
              ) : (
                subBusiness?.arr?.map((e, index) => (
                  <TableRow key={e._id}>
                    <TableCell>
                      <h1 className="text-center">{index + 1}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="text-center ">{e?.name}</h1>
                    </TableCell>
                    <TableCell>
                      <div className="py-1 flex gap-2 justify-center ">
                        <Button
                          variant="contained"
                          color="warning"
                          size="small"
                          onClick={() =>
                            history.push(`/hr/sub-business/edit/${e?._id}`)
                          }
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          onClick={() => handleDelete(e?._id)}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
  if (!subBusiness?.isLoading || subBusiness?.isCompleted) {
    return (
      <div>
        {renderModal()}
        <div className="flex justify-between">
          {rederTitle()}
          {rederAddButtom()}
        </div>

        <div className="w-full">{renderTable()}</div>
      </div>
    );
  }
  if (subBusiness?.isLoading || !subBusiness?.isCompleted) {
    return <Loading isLoading />;
  }
}

SubBusiness.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default SubBusiness;
