/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import {
  Button,
  TextField,
  Autocomplete,
  FormControlLabel,
  Switch,
} from '@mui/material';
import React, { useState } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Delete from '@mui/icons-material/Delete';
import { SketchPicker, TwitterPicker } from 'react-color';
import _ from 'lodash';

export default function AddWorkBu({
  control,
  Controller,
  fields,
  append,
  remove,
  setValue,
  user,
  date,
  setDate,
  time,
  setTime,
  department,
  departmentFields,
  departmentAppend,
  departmentRemove,
  setColor,
  color,
}) {
  const [linkOpen, setlinkOpen] = useState(false);
  const [openDp, setOpenDp] = useState(_.isEmpty(departmentFields.length));
  const [openEm, setOpenEm] = useState(_.isEmpty(fields));

  console.log('departmentFields', departmentFields);

  const handleAdd = () =>
    append({
      // eslint-disable-next-line react/prop-types
      name: user.arr[0]?.id,
    });
  // const departmentuser = _.filter();
  const handleDelete = (index) => {
    remove(index);
  };

  const handleCheckProduct = (data, index) => {
    const users = _.find(user?.arr, { _id: data?._id });
    setValue(`employeeArray.${index}`, users);
  };

  const handleDeAdd = () =>
    departmentAppend({
      // eslint-disable-next-line react/prop-types
      name: user.arr[0]?.id,
    });
  // const departmentuser = _.filter();
  const handleDeDelete = (index) => {
    departmentRemove(index);
  };

  const handleCheckDE = (data, index) => {
    const departments = _.find(department?.arr, { _id: data?._id });
    setValue(`departmentArr.${index}`, departments);
  };

  const addWorker = () =>
    _.map(fields, (item, index) => (
      <div key={item?.id}>
        <div className="flex pt-2">
          <div className="w-2/3">
            <Controller
              name={`employeeArray.${index}`}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  id="free-solo-demo"
                  freeSolo
                  size="small"
                  options={user?.arr || []}
                  getOptionLabel={(option) =>
                    `${option?.name || ''} ${option?.surname || ''}`
                  }
                  onChange={(e, newValue) =>
                    handleCheckProduct(newValue, index)
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="ชื่อพนักงาน" />
                  )}
                />
              )}
            />
          </div>
          <div className="pl-4">
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={() => {
                handleDelete(index);
              }}
            >
              <Delete />
              ลบ
            </Button>
          </div>
        </div>
      </div>
    ));

  const addDepartment = () =>
    _.map(departmentFields, (item, index) => (
      <div key={item?.id}>
        <div className="flex pt-2">
          <div className="w-2/3">
            <Controller
              name={`departmentArr.${index}`}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  id="free-solo-demo"
                  freeSolo
                  size="small"
                  options={department?.arr || []}
                  getOptionLabel={(option) => ` แผนก ${option?.name || ''}`}
                  onChange={(e, newValue) => handleCheckDE(newValue, index)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="แผนก" />
                  )}
                />
              )}
            />
          </div>
          <div className="pl-4">
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={() => {
                handleDeDelete(index);
              }}
            >
              <Delete />
              ลบ
            </Button>
          </div>
        </div>
      </div>
    ));

  return (
    <div className="p-8  ">
      <div>
        <div className="py-2">
          <Controller
            name="job"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                label="ชื่องาน"
                required
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
        <div className="md:flex gap-2 py-1 ">
          <div className="md:w-2/3 py-2 lg:py-1 ">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale="th"
            >
              <DatePicker
                label="วันที่"
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="lg:py-1 ">
            <Controller
              name="time"
              control={control}
              defaultValue="08:30"
              render={({ field }) => (
                <TextField
                  {...field}
                  id="time"
                  label="เวลา"
                  type="time"
                  size="small"
                  defaultValue="08:30"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: 150 }}
                />
              )}
            />
          </div>
        </div>
        <div className="py-2">
          <Controller
            name="locationName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                label="สถานที่ทำงาน"
                required
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <div className="py-1">
          <Controller
            name="vehicle"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                label="รถที่ใช้"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
        <div className="py-1">
          <Controller
            name="note"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                label="รายละเอียดเพิ่มเติม"
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>

        <div>
          <TwitterPicker
            triangle="hide"
            onChangeComplete={(e) => setColor(e.hex)}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={linkOpen}
                onChange={(e) => setlinkOpen(e.target.checked)}
              />
            }
            label="เพิ่มLink"
          />
        </div>
        {linkOpen && (
          <Controller
            name="link_url"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                label="link"
                size="small"
                variant="outlined"
                fullWidth
              />
            )}
          />
        )}
        <h1 className="font-bold text-xl py-4">ผู้ทำงาน</h1>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={openEm}
                onChange={(e) => setOpenEm(e.target.checked)}
              />
            }
            label="พนักงาน"
          />
        </div>
        {openEm && (
          <div>
            <div className="py-1">{addWorker()}</div>
            <div className="flex justify-center pt-2">
              <Button variant="outlined" onClick={handleAdd}>
                เพิ่มพนักงาน
              </Button>
            </div>
          </div>
        )}
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={openDp}
                onChange={(e) => setOpenDp(e.target.checked)}
              />
            }
            label="แผนก"
          />
        </div>
        {openDp && (
          <div>
            <div className="py-1">{addDepartment()}</div>
            <div className="flex justify-center pt-2">
              <Button variant="outlined" onClick={handleDeAdd}>
                เพิ่มแผนก
              </Button>
            </div>
          </div>
        )}
        <div className="flex justify-end w-full pt-4">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </div>
    </div>
  );
}
