import {
  DEPARTMENT_ALL,
  DEPARTMENT_DELETE,
  DEPARTMENT_GET,
  DEPARTMENT_LOADING,
  DEPARTMENT_ERROR,
  DEPARTMENT_POST,
  DEPARTMENT_PUT,
} from '../type';
import api from '../../utils/functions/api';

export const getAllDepartment = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/department?name=${name}&size=${size}&page=${page}`
    );
    if (status === 200) {
      dispatch({ type: DEPARTMENT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DEPARTMENT_ERROR });
  }
};
export const getOneDepartment = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/department/${id}`
    );
    if (status === 200) {
      dispatch({ type: DEPARTMENT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DEPARTMENT_ERROR });
  }
};

export const deleteDepartment = (id) => async (dispatch) => {
  try {
    dispatch({ type: DEPARTMENT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/department/${id}`
    );
    dispatch({ type: DEPARTMENT_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DEPARTMENT_ERROR });
  }
};

export const addDepartment = (payload) => async (dispatch) => {
  try {
    dispatch({ type: DEPARTMENT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/department`,
      {
        ...payload,
      }
    );
    dispatch({ type: DEPARTMENT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DEPARTMENT_ERROR });
  }
};

export const putDepartment = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: DEPARTMENT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/department/${id}`,
      payload
    );
    dispatch({ type: DEPARTMENT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DEPARTMENT_ERROR });
  }
};
