/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import {
  Button,
  TextField,
  Autocomplete,
  FormControlLabel,
  FormControl,
  Switch,
} from '@mui/material';
import React, { useState } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Delete from '@mui/icons-material/Delete';
import _ from 'lodash';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

export default function AddWork({
  control,
  Controller,
  fields,
  append,
  remove,
  setValue,
  user,
  date,
  setDate,
  status,
  setStatus,
}) {
  //set status select

  const [open, setOpen] = React.useState(false);
  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [linkOpen, setlinkOpen] = useState(false);
  const handleAdd = () =>
    append({
      // eslint-disable-next-line react/prop-types
      name: user.arr[0]?.id,
    });
  // const departmentuser = _.filter();
  const handleDelete = (index) => {
    remove(index);
  };
  const handleCheckProduct = (data, index) => {
    const users = _.find(user?.arr, { _id: data?._id });
    setValue(`employeeArray.${index}`, users);
  };
  console.log('user', user);

  const addWorker = () =>
    _.map(fields, (item, index) => (
      <div key={item?.id}>
        <div className="flex pt-2">
          <div className="w-2/3">
            <Controller
              name={`employeeArray.${index}.name`}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  id="free-solo-demo"
                  freeSolo
                  options={user?.arr || []}
                  getOptionLabel={(option) =>
                    `${option?.name || ''} ${option?.surname || ''}`
                  }
                  onChange={(e, newValue) =>
                    handleCheckProduct(newValue, index)
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="ชื่อพนักงาน" />
                  )}
                />
              )}
            />
          </div>
          <div className="pl-4">
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                handleDelete(index);
              }}
            >
              <Delete />
              ลบ
            </Button>
          </div>
        </div>
      </div>
    ));

  return (
    <div className="p-8">
      <div>
        <h1 className="text-xl font-bold">เพิ่มงาน</h1>
        <Controller
          name="job"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value}
              label="ชื่องาน"
              required
              variant="outlined"
              margin="normal"
              fullWidth
            />
          )}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale="th">
          <DatePicker
            label="วันที่"
            value={date}
            onChange={(newValue) => {
              setDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
        <div className="md:flex">
          <Controller
            name="consult"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                label="อาจารย์ที่ปรึกษา"
                required
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          />
          <Controller
            name="vehicle"
            control={control}
            defaultValue="รถศูนย์วิจัย"
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                label="รถ"
                required
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          />
        </div>
        <div className="pt-3 flex">
          <Controller
            name="time"
            control={control}
            defaultValue="08:30"
            render={({ field }) => (
              <TextField
                {...field}
                id="time"
                label="เวลาออกเดินทาง"
                type="time"
                defaultValue="08:30"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                sx={{ width: 150 }}
              />
            )}
          />
          {/* <div className="pt-2 flex"> */}
          {/* <Controller
            name="time2"
            control={control}
            render={({ field }) => (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale="th"
              >
                <TimePicker
                  {...field}
                  ampm={false}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            )}
          /> */}
          {/* </div> */}

          {/* <div className="py-auto px-3 flex">
            <Controller
              name="allDay"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={<Switch {...field} checked={field.value} />}
                    label="ทั้งวัน"
                  />
                </div>
              )}
            />
            <Controller
              name="workAllDay"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={<Switch {...field} checked={field.value} />}
                    label="วันหยุด"
                  />
                </div>
              )}
            />
            <Controller
              name="halfDay"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <div>
                  <FormControlLabel
                    control={<Switch {...field} checked={field.value} />}
                    label="ครึ่งวัน"
                  />
                </div>
              )}
            />
          </div> */}
        </div>
        <Controller
          name="status"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <div>
              <FormControl sx={{ py: 2, minWidth: 240 }}>
                <InputLabel id="demo-controlled-open-select-label">
                  เลือกการทำงาน
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  {...field}
                  //  value={field.value}
                  // onChange={field.onChange}
                  label="การทำงาน"
                >
                  <MenuItem value="">
                    <em>เลือกการทำงาน</em>
                  </MenuItem>
                  <MenuItem value="allDay">ลงพื้นที่ทั้งวัน</MenuItem>
                  <MenuItem value="workAllDay">ลงพื้นที่ครึ่งวัน</MenuItem>
                  <MenuItem value="halfDay">ลางานครึ่งวัน</MenuItem>
                  <MenuItem value="dayOff">ทำงานในวันหยุด</MenuItem>
                  <MenuItem value="dayOffHalfDay">
                    ทำงานในวันหยุดครึ่งวัน
                  </MenuItem>
                  <MenuItem value="laeveWork">ลางาน</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
        />

        {/* <form class="max-w-sm mx-auto">
          <label
            for="countries"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            การทำงาน
          </label>
       
            <select
              id="countries"
              class="bg-gray-100 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option selected>เลือกการทำงาน</option>
              <option value="allDay">ทำงานปกติ</option>
              <option value="workAllDay">ทำงานทั้งวัน</option>
              <option value="halfDay">ทำงานครึ่งวัน</option>
              <option value="dayOff">ทำงานในวันหยุด</option>
              <option value="dayOffHalfDay">ทำงานในวันหยุดครึ่งวัน</option>
              <option value="laeveWork">ลางาน</option>
            </select>{' '}
          
        </form> */}

        <Controller
          name="note"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value}
              label="รายละเอียดเพิ่มเติม"
              variant="outlined"
              margin="normal"
              fullWidth
            />
          )}
        />
        {console.log(linkOpen)}

        <div>
          <FormControlLabel
            control={
              <Switch
                checked={linkOpen}
                onChange={(e) => setlinkOpen(e.target.checked)}
              />
            }
            label="เพิ่มLink"
          />
        </div>
        {linkOpen && (
          <Controller
            name="link_url"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value}
                label="link"
                variant="outlined"
                fullWidth
              />
            )}
          />
        )}
        <div className="pt-4">
          <h1 className="font-bold text-xl pl-2">ผู้ทำงาน</h1>
          {addWorker()}
        </div>
        <div className="flex justify-center pt-2">
          <Button variant="outlined" onClick={handleAdd}>
            เพิ่มพนักงาน
          </Button>
        </div>
        <div className="flex justify-end w-full pt-4">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </div>
    </div>
  );
}
