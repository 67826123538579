import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import Save from '@mui/icons-material/Save';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as action from '../../redux/action';
import FormCustomer from '../../components/Forms/FormCustomer';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';

function NewCustomer({ title, subtitle }) {
  const history = useHistory();

  const dispatch = useDispatch();
  // const customer = useSelector((state) => state.customer);
  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
  });

  async function onSubmit(data) {
    try {
      await dispatch(
        action.addCustomer(
          data.buName,
          data.taxId,
          data.name,
          data.tel,
          data.address,
          data?.status
        )
      );
      await dispatch(action.getAllCustomer());
      history.push('/customer');
    } catch (error) {}
  }

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      <div>
        {renderTitle()}
        <div className="py-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormCustomer
          title="ข้อมูลลูกค้า"
          Controller={Controller}
          control={control}
          watch={watch}
        />
        <div className="pt-2 pl-2 ">
          <Button variant="contained" startIcon={<Save />} type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
}
export default NewCustomer;
