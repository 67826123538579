import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
import _ from 'lodash';
import { DateTime } from 'luxon';

export default function ShowPayment({ payment, setJob, handleJobOpen }) {
  const formatEventTime = (isoDate) =>
    DateTime.fromISO(isoDate).set({ hour: 12, minute: 1 }).toISODate();
  const getEvent = _.map(payment.arr, (eachPayment) => {
    const events = {
      id: eachPayment?._id,
      // title: `${eachWork?.time || ''} ${eachWork?.name}  `,
      title: eachPayment?.name,
      start: formatEventTime(eachPayment?.date),
      backgroundColor: eachPayment?.overdue
        ? '#b71c1c'
        : eachPayment?.follow
        ? '#ffc107'
        : '#43a047',
      borderColor: '#FFFFFF',
    };
    return events;
  });

  //Code original
  // const getEvent = _.map(payment.arr, (eachPayment) => {
  //   const events = {
  //     id: eachPayment?._id,
  //     // title: `${eachWork?.time || ''} ${eachWork?.name}  `,
  //     title: eachPayment?.name,
  //     start: formatEventTime(eachPayment?.date),
  //     backgroundColor: eachPayment?.color ? eachPayment?.color : '#F91C0A',
  //     borderColor: '#FFFFFF',
  //   };
  //   return events;
  // });
  return (
    <div>
      <div className="p-2">
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          locale="th"
          eventClassNames="cursor-pointer hover:shadow-md border-none"
          events={getEvent}
          headerToolbar={{
            start: 'dayGridMonth,dayGridWeek,dayGridDay',
            center: 'title',
            end: 'prevYear,prev,next,nextYear',
          }}
          eventClick={(info) => {
            const jobs = _.find(
              payment?.arr,
              (eachPayment) => eachPayment?._id === info?.event?.id
            );

            setJob(jobs);
            handleJobOpen();
          }}
        />
      </div>
    </div>
  );
}
