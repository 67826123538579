import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { TextField } from '@mui/material';

export default function NewsModal({ Controller, control }) {
  return (
    <div>
      <div className="p-2">
        <b>เพิ่มข่าว</b>
        <div className="py-2">
          <Controller
            name="head"
            defaultValue={''}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="หัวข้อ"
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
        <div className="py-2">
          <Controller
            name="topic"
            defaultValue={''}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="รายละเอียด"
                variant="outlined"
                size="small"
                multiline
                rows={3}
                fullWidth
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
