/* eslint-disable no-underscore-dangle */
/* eslint-disable no-alert */
/* eslint-disable import/order */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/alt-text */
import { Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../redux/action';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import logoImage from '../assets/img/logopng.png';
import { Password } from '@mui/icons-material';
import Loading from 'components/Loading';
import axios from 'axios';
export default function Login() {
  const dispatch = useDispatch();
  const { handleSubmit, control, setValue } = useForm({});
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('Token')) {
      dispatch(action.getMe());
    }
    return () => {};
  }, []);

  useEffect(() => {
    console.log('Layout : Auth');
    async function initSystem() {
      const { data, status } = await axios.post(
        `${process.env.REACT_APP_API_URL}/init-system`
      );
      console.log('init system : ', data.message);
      if (status === 200) setIsLoading(false);
    }
    initSystem();
    return () => {};
  }, []);

  useEffect(() => {
    if (localStorage.getItem('Token')) {
      if (me?.userData?._id) {
        history.push('/home');
      }
      if (me?.error) {
        window.alert('Username or Password is invalid');
        setValue('password', '');
      }

      if (me?.login) {
        history.push('/home');
      }

      if (me?.prompt) {
        dispatch(action.getMe());
      }
    }
    return () => {};
  }, [me, me?.userData?._id, me?.login]);

  async function handleOnSubmit(data) {
    try {
      await dispatch(action.login(data));
    } catch (error) {
      console.error(error);
    }
    // if (!user.validPassword(password)) {
    //   alert('ชื่อผู้ใช้หรือรหัสผ่านผิด โปรดลองอีกครั้ง');
    // }
  }

  const renderForm = () => (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div>
        <div className="grid grid-flow-row auto-rows-max ">
          <div className="pl-4 pr-4 w-full mt-8 justify-item-center">
            <div className="flex justify-end"></div>

            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="username" size="small" fullWidth />
              )}
            />
          </div>
          <div className="pl-4 pr-4 mt-2">
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="password"
                  type="password"
                  fullWidth
                  size="small"
                />
              )}
            />
          </div>
          <div className="justify-item-center p-4">
            <Button fullWidth variant="contained" type="submit">
              Login
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
  if (!isLoading) {
    return (
      <div className="container mx-auto px-4 h-full min-h-screen  ">
        <div className="flex content-center items-center justify-center h-full  min-h-screen">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="flex justify-center">
                    <Box sx={{ height: 100, width: 200 }}>
                      <img src={logoImage} />
                    </Box>
                  </div>
                  <div className="text-center mb-3 mt-2">
                    <h6 className="text-gray-600 text-sm font-bold">
                      เข้าสู่ระบบ
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                {renderForm()}
                <div className="my-4 font-sansserif  ">
                  <div className="text-sm text-gray-600 font-normal py-1 text-center ">
                    พัฒนาระบบโดย
                    <a
                      href="https://iarc.psu.ac.th/"
                      className="text-gray-600 hover:text-indigo-800 text-sm font-normal py-1"
                    >
                      ศูนย์วิจัยระบบอัตโนมัติอัจฉริยะ คณะวิศวกรรมศาสตร์
                      มหาวิทยาลัยสงขลานครินทร์
                    </a>
                    <br />
                    <div className="text-xs my-2">
                      &copy; 2022 E-Company Version
                      {process.env.REACT_APP_VERSION_RELEASE}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <Loading />;
}
