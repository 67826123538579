import {
  FILE_ALL,
  FILE_DELETE,
  FILE_GET,
  FILE_LOADING,
  FILE_POST,
  FILE_PUT,
  FILE_ERROR,
} from '../type';

import api from '../../utils/functions/api';

export const getAllFiles =
  ({ name = '', size = '', page = 1, certificate = '', user = '' }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/file?name=${name}&size=${size}&page=${page}&certificate=${certificate}&user=${user}`
      );

      if (status === 200) {
        dispatch({ type: FILE_ALL, payload: data });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: FILE_ERROR });
    }
  };

export const getOneFile = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/file/${id}`
    );
    if (status === 200) {
      dispatch({ type: FILE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: FILE_ERROR });
  }
};

export const deleteOneFile = (id) => async (dispatch) => {
  try {
    dispatch({ type: FILE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/file/${id}`
    );
    dispatch({ type: FILE_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: FILE_ERROR });
  }
};

export const addFile = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILE_LOADING });

    const formData = new FormData();
    formData.append('file', payload?.file);
    formData.append('user', payload.user);
    formData.append('is_certificate', payload.is_certificate);

    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/file`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );

    dispatch({ type: FILE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: FILE_ERROR });
  }
};

export const putFile = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: FILE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/file/${id}`,
      payload
    );
    dispatch({ type: FILE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: FILE_ERROR });
  }
};
