import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Controller, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import dayjs from 'dayjs';
import CalQuotaLeave from 'function/CalQuotaLeave';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import * as action from '../../redux/action';
import CalQuotaOption from '../../function/CalQuotaOption';
import CalRemain from '../../function/CalRemain';
import ModalUploadFile from 'components/Modal/ModalUploadFile';

function ApplyLeave({ title }) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);
  const leaveDocs = useSelector((state) => state.leaveDoc);
  const leaveType = useSelector((state) => state.leaveType);
  const me = useSelector((state) => state.me);
  const setting = useSelector((state) => state.setting.arr);
  //stateการเพิ่มไฟล์
  const [FileOpen, setFlieOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [date, setDate] = React.useState(new Date());
  const [dateStart, setDateStart] = React.useState(new Date());
  const [status, setStatus] = useState(true);
  const [dateEnd, setDateEnd] = React.useState(new Date());
  const { handleSubmit, control, watch } = useForm({ mode: 'onChange' });
  const [leaveTypeSelect, setLeaveTypeSelect] = React.useState('ลาป่วย');
  const [leaveDocData, setleaveDocData] = useState(leaveDocs?.arr);
  console.log('SETING', setting);
  const [eachUser, setEachUser] = useState();
  const [remain, setRemain] = useState(1);
  const [checkRemain, setCheckRemain] = useState(0);
  const lastLeaveDoc = leaveDocs?.arr?.[0];
  const history = useHistory();

  const dayDiff = dayjs(lastLeaveDoc?.dateEnd)
    .endOf('day')
    .diff(dayjs(lastLeaveDoc?.dateStart).startOf('day'), 'day');

  dayjs.extend(buddhistEra);

  const disableSelect = (eachLeaveType) => {
    if (CalRemain(eachLeaveType, me) > 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (event) => {
    const tempLeaveType = event.target.value;
    console.log('tempLeaveType', tempLeaveType);
    const tempRemain = CalRemain(tempLeaveType, me);
    setRemain(tempRemain);

    // if (!_.isEmpty(tempLeaveType?.options || tempLeaveType?._id?.options)) {
    //   console.log('54- Sum Option Select', CalQuotaOption(me, tempLeaveType));
    //   const sumOptionuse =
    //     CalQuotaOption(me, tempLeaveType) - tempLeaveType.sum;
    //   console.log('sumOptionuse', sumOptionuse);

    //   setRemain(CalQuotaOption(me, tempLeaveType));
    // } else {
    //   setRemain(tempLeaveType.remain || tempLeaveType.quantity);
    // }

    console.log(event);
    if (event.target.value._id === 'ลากิจ') {
      setStatus(false);
      setLeaveTypeSelect(tempLeaveType);
    } else {
      setStatus(true);
      setLeaveTypeSelect(tempLeaveType);
    }
  };

  // const checksumday = () => {
  //   if (sumDay > remain) {
  //     alert('');
  //   } else {
  //   }
  // };

  function getLastLeavDoc(name) {
    dispatch(action.getAllLeaveDoc({ name: me?.userData?.name }));
  }

  useEffect(() => {
    dispatch(action.getAllUser({}));
    getLastLeavDoc(users?.name);
    setleaveDocData(leaveDocs?.arr);
    dispatch(action.leaveTypeGetAll({}));
    return () => {};
  }, []);

  // const handleChangeApprove = (event) => {
  //   setEachUser(event.target.value);
  // };
  // console.log('Allsum', sumDay);

  useEffect(() => () => {}, [users]);
  //console.log('remain', remain);

  const sumDay = () => {
    const date1 = dayjs(dateEnd).startOf('day');
    const date2 = dayjs(dateStart).startOf('day');
    const date = date1.toDate().getTime() - date2.toDate().getTime();
    const sumday = date / (1000 * 3600 * 24);
    const allsumday = sumday + 1;

    return allsum(allsumday);
  };
  const allsum = (value) => {
    if (value > remain) {
      alert('จำนวนวันการลาเกิน');
      setDateEnd(date);
      return 1;
    } else {
      return value;
    }
  };
  const handleOnclickSave = async (data) => {
    const confirm = window.confirm('ยืนยันข้อมูล');
    console.log('data', data);
    if (confirm) {
      try {
        const payload = {
          ...data,
          date,
          name: me?.userData?.name,
          email: me?.userData?.email,
          surname: me?.userData?.surname,
          department: me?.userData?.department?.name,
          sumdate: sumDay(),
          position: me?.userData?.position?.name,
          lastdateEnd: lastLeaveDoc?.dateEnd,
          lastdateStart: lastLeaveDoc?.dateStart,
          lastleaveType: lastLeaveDoc?.leaveType,
          lastleaveCause: lastLeaveDoc?.leaveCause,
          approverEmail: me?.userData?.department?.approver_1?.email,
          approver2Email:
            setting?.multipleApplover === true
              ? me?.userData?.department?.approver_2?.email
              : null,
          approver_by: me?.userData?.department?.approver_1?._id,
          approver2_by:
            setting?.multipleApplover === true
              ? me?.userData?.department?.approver_2?._id
              : null,
          employee: me.userData?._id,
          dateStart,
          dateEnd,
          file: _.isEmpty(file) ? undefined : file[0],
          leaveTypeID: leaveTypeSelect,
        };
        console.log('patload', payload);
        await dispatch(action.addLeaveDoc(payload));
        alert('บันทึกข้อมูลสำเร็จ');
        history.goBack();
      } catch (error) {
        alert('บันทึกผิดพลาด');
      }
    }
  };
  //การเพิ่มไฟล์แนบ
  const uploadfile = () => (
    <form>
      <ModalUploadFile
        files={file}
        isOpen={FileOpen}
        setFiles={setFile}
        handleClose={() => setFlieOpen(false)}
      />
    </form>
  );
  const renderTitle = () => <ViewTitle title={title} />;

  // const checkDisable = (each, me) => {
  //   if (each?.options !== null) {
  //     console.log('OPTION ', each);
  //     if (CalQuotaOption(me, each) <= 0) {
  //       return true;
  //     }
  //     return false;
  //   } else if (each.quantity - each.sum <= 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // const dataLeave = _.findIndex(leaveDocs?.arr,)

  return (
    <div className="min-h-screen">
      <form onSubmit={handleSubmit(handleOnclickSave)}>
        <div>
          {renderTitle()}
          <div className="py-2">
            <BackButton />
          </div>
          <div>
            <Card>
              <CardContent>
                <div>
                  <Card>
                    <div className="p-2  ">
                      <div>
                        ชื่อ-สกุล : {me?.userData?.name} {me?.userData?.surname}{' '}
                      </div>
                      <div>รหัสพนักงาน : {me?.userData?.employeeId}</div>
                      <div>
                        สังกัด/บริษัทย่อย :{' '}
                        {me?.userData?.subBusiness?.name || '-'}
                      </div>
                      <div>แผนก : {me?.userData?.department?.name || '-'}</div>
                    </div>
                  </Card>
                </div>
                <div>
                  <h1 className="font-bold  py-4 text-xl "> </h1>

                  <div className="lg:flex ">
                    <h1 className="pl-2 pr-2 font-bold lg:w-1/3">ขอลา</h1>
                    <div className=" w-full pb-2">
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ลา
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leaveTypeSelect}
                            size="small"
                            label="ลา "
                            fullWidth
                            onChange={handleChange}
                          >
                            {/* {_.map(leaveType?.arr, (each, index) => (
                              <MenuItem key={index} value={each?._id}>
                                {each?.name}
                              </MenuItem>
                            ))} */}

                            {/* {_.map(leaveDocs.quota, (each, index) => (
                              <MenuItem
                                key={index}
                                value={each?._id}
                                // disabled={each?.quata?.remain == 0}
                              >
                                {each?._id.name}
                              </MenuItem>
                            ))} */}
                            {_.map(
                              CalQuotaLeave(leaveType, leaveDocs),
                              (each, index) => (
                                <MenuItem
                                  key={index}
                                  value={each}
                                  disabled={disableSelect(each)}
                                >
                                  {each?._id?.name || each?.name}
                                  {/* {JSON.stringify(each)} */}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                  </div>
                  <div className="lg:flex">
                    <h1 className="p-2 font-bold lg:w-1/3">สาเหตุ</h1>
                    <div className="w-full">
                      <Controller
                        name="leaveCause"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            // disabled={status}
                            fullWidth
                            value={field.value}
                            id="outlined-basic"
                            label="สาเหตุ"
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className=" pt-4 lg:flex">
                    <h1 className="p-2 font-bold lg:w-1/3">ตั้งแต่วันที่ </h1>
                    <div className="w-full">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="วันที่"
                          value={dateStart}
                          onChange={(newValue) => {
                            setDateStart(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="pt-4 lg:flex">
                    <h1 className="p-2 font-bold lg:w-1/3 ">ถึงวันที่ </h1>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="วันที่"
                        value={dateEnd}
                        onChange={(newValue) => {
                          setDateEnd(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="flex flex-center pt-4">
                    <h1 className="p-2 font-bold lg:w-1/4 ">มีความต้องการลา</h1>
                    <Controller
                      name="statusAllDay"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <div>
                          <FormControlLabel
                            control={
                              <Switch {...field} checked={field.value} />
                            }
                            label="ทั้งวัน"
                          />
                        </div>
                      )}
                    />
                    <Controller
                      name="statusHalfDay"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <div>
                          <FormControlLabel
                            control={
                              <Switch {...field} checked={field.value} />
                            }
                            label="ครึ่งวัน"
                          />
                        </div>
                      )}
                    />
                  </div>
                  <div className="pt-2  flex">
                    <h1 className="p-2 font-bold w-1/2 lg:w-1/3 ">
                      จำนวนคงเหลือ{' '}
                    </h1>
                    <div className="flex w-full">
                      <h1 className="pt-2 pr-2 font-bold">{remain}</h1>
                      {console.log('leaveTypeSelect', leaveTypeSelect)}
                      {console.log('remainnnnnnnnnnnnnnnnnnnnnnnnnnn', remain)}
                      <h1 className="pt-2 font-bold">วัน </h1>
                    </div>
                  </div>
                  <div className="pt-2  flex">
                    <h1 className="p-2 font-bold w-1/2 lg:w-1/3 ">
                      จำนวนที่ต้องการลา{' '}
                    </h1>
                    <div className="flex w-full">
                      <h1 className="pt-2 pr-2 font-bold">{sumDay()}</h1>
                      <h1 className="pt-2 font-bold">วัน </h1>
                    </div>
                  </div>
                  <div className="">
                    <h1 className="p-2 font-bold w-1/2 lg:w-1/3 ">
                      แนบเอกสารการลา
                    </h1>
                    <div className="">{uploadfile()}</div>
                    <div className="pl-2">
                      <Button
                        variant="contained"
                        // startIcon={<FileUploadIcon />}
                        onClick={() => setFlieOpen(true)}
                      >
                        อัพโหลดเอกสาร
                      </Button>
                      <div className="py-2">
                        {leaveDocs?.file && (
                          <div className="pl-4">
                            <a href={leaveDocs?.file?.url} target="_blank">
                              <h1 className="text-green-600 font-bold ">
                                {leaveDocs?.file?.file_name}
                              </h1>
                            </a>
                          </div>
                        )}
                        {!_.isEmpty(file) && (
                          <div className="pl-4">
                            <h1 className="font-bold">ไฟล์ใหม่</h1>
                            <h1>{file?.[0]?.name}</h1>
                          </div>
                        )}{' '}
                      </div>
                    </div>
                  </div>
                  <div>
                    <h1 className="pl-2 pr-2 font-bold pt-2">
                      ช่องทางติดต่อระหว่างการลา
                    </h1>
                    <div className="pl-2 pt-2">
                      <Controller
                        name="contact"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            rows={4}
                            fullWidth
                            value={field.value}
                            id="outlined-basic"
                            label="ติดต่อ"
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center pt-4">
                  <Button type="submit" variant="contained">
                    บันทึก
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </form>
    </div>
  );
}
export default ApplyLeave;
