/* eslint-disable default-param-last */
import {
  PAYMENT_ALL,
  PAYMENT_DELETE,
  PAYMENT_GET,
  PAYMENT_LOADING,
  PAYMENT_POST,
  PAYMENT_PUT,
} from '../type';

const initialState = {
  payment: null,
  isLoading: true,
  isCompleted: true,
};

function PaymentReducer(state = initialState, action) {
  switch (action.type) {
    case PAYMENT_LOADING:
      return { isLoading: true, isCompleted: false };
    case PAYMENT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PAYMENT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PAYMENT_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PAYMENT_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case PAYMENT_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default PaymentReducer;
