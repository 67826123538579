/* eslint-disable react/prop-types */
import React from 'react';
import {
  Modal,
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Chip,
} from '@mui/material';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import {
  AccessAlarm,
  CalendarMonthOutlined,
  DirectionsCar,
  Group,
  PersonAddAlt,
  PriorityHigh,
} from '@mui/icons-material';

export default function JobInfo({ openJob, setOpenJob, job }) {
  const history = useHistory();
  const handleClose = () => setOpenJob(false);
  const handleEdit = async (id) => {
    history.push(`/work/edit/${id}`);
  };
  console.log('job', job);
  const renderDetailWorker = () => (
    <div className="p-4">
      <div className="grid grid-cols-2 gap-1 ">
        <div>
          <Card
            sx={{ backgroundColor: job?.workAllDay ? '#fccb00' : '#1273de' }}
          >
            <div className="p-2 text-white ">ชื่องาน</div>
            <div className="px-2 py-4 text-xl text-white ">{job?.name}</div>
          </Card>
        </div>
        <div>
          <Card>
            <b className="p-2 flex justify-between ">
              วันที่{' '}
              <div>
                {job?.allDay && <Chip label="เต็มวัน" color="success" />}{' '}
              </div>
            </b>
            <div className="px-2 py-2 text-xl gap-2 ">
              <CalendarMonthOutlined />
              {dayjs(job?.date).format('DD/MM/BBBB')}
            </div>
          </Card>
        </div>
        <div>
          <Card>
            <b className="p-2">เวลา</b>
            <div className="px-2 py-2 text-xl gap-1 ">
              <AccessAlarm />
              {job?.time || '-'}
            </div>
          </Card>
        </div>
        <div>
          <Card>
            <b className="p-2">อาจารย์ที่ปรึกษา</b>
            <div className="px-2 py-2 text-xl gap-2 ">
              <PersonAddAlt />
              {job?.consult || '-'}
            </div>
          </Card>
        </div>
        <div>
          <Card>
            <b className="p-2">รถ</b>
            <div className="px-2 py-2 text-xl gap-2 ">
              <DirectionsCar />
              {job?.vehicle || '-'}
            </div>
          </Card>
        </div>
        <div>
          <Card>
            <Group />
            <b className="p-2">พนักงาน</b>
            <div className="px-2 py-2  gap-2 ">
              <div>
                {job?.worker && (
                  <h1>
                    {job?.worker.map((worker) => (
                      <div>
                        {worker?.name} {worker?.surname}{' '}
                      </div>
                    )) || '-'}
                  </h1>
                )}
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Card>
            <b className="p-2">หมายเหตุ</b>
            <div className="px-2 py-2  gap-2 ">
              <PriorityHigh />
              {job?.note || '-'}
            </div>
          </Card>
        </div>
        {job?.link_url && (
          <div>
            <Card>
              <b className="p-2">link</b>
              <div className="px-2 py-2  gap-2 ">
                <a
                  href={`${job?.link_url}`}
                  target="_blank"
                  className="text-blue-600"
                  rel="noreferrer"
                >
                  {job?.link_url || ''}
                </a>
              </div>
            </Card>
          </div>
        )}
      </div>
      <div className="flex justify-end">ผู้สร้าง :{job?.createrID?.name}</div>
    </div>
  );
  const renderDetailLeave = () => (
    <div className="p-4">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <h1 className="">ชื่อ-สกุล</h1>
            </TableCell>
            <TableCell>
              <h1 className="">
                {job?.name} {job?.surname}{' '}
              </h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1 className="">ประเภทการลา</h1>
            </TableCell>
            <TableCell>
              <b className="">{job?.leaveTypeID?.name}</b>
              <h1>{job?.leaveCause}</h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1>วันที่ลา</h1>
            </TableCell>
            <TableCell>
              <h1>
                {dayjs(job?.dateStart).format('DD/MM/BBBB')} -
                {dayjs(job?.dateEnd).format('DD/MM/BBBB')}
              </h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1>ผลการพิจารณา</h1>
            </TableCell>
            <TableCell>
              <Chip label={job?.status} color="success" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <h1 className="">ผู้อนุมัติ</h1>
            </TableCell>
            <TableCell>
              <h1>
                {job?.approver_by?.name || '-'}{' '}
                {job?.approver_by?.surname || '-'}{' '}
              </h1>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );

  return (
    <div>
      <Modal
        open={openJob}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            // height: 200,
            // width: 200,
          }}
        >
          <Card sx={{ width: 400 }}>
            <div className="p-4">
              <div className="flex justify-between">
                <div className="flex"></div>
              </div>
            </div>
            {job?.leaveTypeID ? renderDetailLeave() : renderDetailWorker()}
            {job?.leaveTypeID ? (
              ''
            ) : (
              <div className="flex justify-end p-2">
                <Button
                  onClick={() => handleEdit(job._id)}
                  variant="contained"
                  color="warning"
                  size="small"
                >
                  แก้ไข
                </Button>
              </div>
            )}
          </Card>
        </Box>
      </Modal>
    </div>
  );
}
