import _ from 'lodash';

const accessRight = (me, moduleName) => {
  // if (!me?.userData) {
  //   return false;
  // }
  const access = me?.userData?.department?.access;
  console.log('accessaccess', access);

  // const {
  //   userData: {
  //     department: { access },
  //   },
  // } = me;
  // const access = me?.userData?.department?.access;

  if (moduleName) {
    if (access[`${_.toUpper(moduleName)}`]) {
      return true;
    }
    return false;
  }
  return true;
};

export default accessRight;

/*
การใช้งาน
me คือข้อมูล user ผู้ใช้งานระบบที่ได้จาก server
module คือชื่อ module จาก layout เช่น TMS CRM เป็นต้น
levelPage สำหรับกำหนดระดับการเข้าถึงของ Page นั้น ๆ
ทั้งนี้หากต้องการตรวจสอบการเข้าถึงโมดูลไม่จำเป็นต้องส่งข้อมูลมาแต่ใช้ค่าเริ่มต้นในฟังก์ชันแทน
*/
