import {
  POSITION_ALL,
  POSITION_GET,
  POSITION_POST,
  POSITION_PUT,
  POSITION_DELETE,
  POSITION_LOADING,
} from '../type';
const initialState = {
  position: null,
  isLoading: true,
  isCompleted: true,
};
function positionReducer(state = initialState, action) {
  switch (action.type) {
    case POSITION_LOADING:
      return { isLoading: true, isCompleted: false };
    case POSITION_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case POSITION_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case POSITION_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case POSITION_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case POSITION_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default positionReducer;
