import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { BackButton } from 'components/Button';
import Loading from 'components/Loading';
import * as actions from '../../redux/action';
import FormPosition from '../../components/Forms/FormPosition';

function EditPosition({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const position = useSelector((state) => state.position);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      position: position?.name,
    },
  });

  useEffect(() => {
    dispatch(actions.positionNGet(id));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.positionPut(id, data));
        await dispatch(actions.positionGetAll({}));
        alert('บันทึกสำเร็จ');
        history.goBack();
      } catch (error) {
        console.log('error');
      }
    }
  };

  const renderForm = () => (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormPosition
          Controller={Controller}
          control={control}
          position={position}
        />
      </form>
    </div>
  );
  if (position.isLoading || !position.isCompleted) {
    return <Loading isLoading />;
  }
  if (!position.isLoading || position.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="py-2">{renderForm()}</div>
      </div>
    );
  }
}

EditPosition.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
EditPosition.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditPosition;
