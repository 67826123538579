import React, { useEffect } from 'react';
import { Card } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import * as action from '../../redux/action';
import { ViewTitle } from '../../components/ViewTitle';
import { useDispatch, useSelector } from 'react-redux';

export default function Colleague({ title, subtitle }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const information = useSelector((state) => state.information);

  useEffect(() => {
    dispatch(action.getAllUser({}));
  }, []);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div className="min-h-screen">
      {renderTitle()}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 pt-4">
        {user?.arr?.map((each) => (
          <div className="transition-transform transform hover:scale-105  shadow rounded">
            <Card>
              <div className="p-6">
                <div className="">
                  <div className="flex justify-center">
                    <Avatar
                      src={each.image ? each.image.url : ''}
                      // variant="square"
                      sx={{ width: 150, height: 150 }}
                    />
                  </div>
                  <div className="mx-auto  pt-4">
                    <div className="flex">
                      <h1 className="font-bold text-l  w-1/2">รหัสพนักงาน</h1>
                      <h1 className=" w-full">{each?.employeeId}</h1>
                    </div>
                    <div className="flex">
                      <h1 className="font-bold text-l  w-1/2">ชื่อ-สกุล</h1>
                      <h1 className=" w-full">
                        {each?.name} {each?.surname}
                      </h1>
                    </div>
                    <div className="flex">
                      <h1 className="font-bold text-l  w-1/2">เบอร์โทร</h1>
                      <h1 className=" w-full">{each?.tel || ''}</h1>
                    </div>
                    <div className="flex">
                      <h1 className="font-bold text-l  w-1/2">E-mail</h1>
                      <h1 className=" w-full">{each?.email || ''}</h1>
                    </div>
                    <div className="flex">
                      <h1 className="font-bold text-l  w-1/2">ตำแหน่ง</h1>
                      <h1 className=" w-full">{each?.position?.name || ''}</h1>
                    </div>
                    <div className="flex">
                      <h1 className="font-bold text-l  w-1/2">แผนก</h1>
                      <h1 className=" w-full">{each?.department.name || ''}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}
