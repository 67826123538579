import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FormLeaveType from '../../components/Forms/FormLeaveType';
import * as actions from '../../redux/action';

function NewLeaveType({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const { handleSubmit, control } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
  });
  const [option, setOption] = useState(false);

  const onSubmit = async (data) => {
    const confirm = window.confirm('ยืนยันบันทึกข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.leaveTypeCreate(data));
        await dispatch(actions.leaveTypeGetAll({}));
        alert('บันทึกสำเร็จ');
        history.goBack();
      } catch (error) {
        alert(`การบันทึกมีปัญหา${error}`);
      }
    }
  };

  const renderForm = () => (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormLeaveType
          control={control}
          Controller={Controller}
          setOption={setOption}
          option={option}
          fields={fields}
          append={append}
          remove={remove}
        />
      </form>
    </div>
  );

  const renderBackButton = () => <BackButton />;
  return (
    <div>
      {renderTitle()}
      <div>{renderBackButton()}</div>
      <div>{renderForm()}</div>
    </div>
  );
}

NewLeaveType.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
NewLeaveType.defaultProps = {
  title: '',
  subtitle: '',
};

export default NewLeaveType;
