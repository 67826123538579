import { Card, CardContent, TextField, Button } from '@mui/material';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';

const defaultValue = {
  productName: '',
  productQuality: '',
  productUnit: '',
  productPrice: '',
  productDiscount: '0',
  productAbout: '',
};

function FormPurchaseProduct({
  children,
  setProductList,
  setReload,
  productList,
  reload,
  setOpen,
  product,
}) {
  const { control, setValue, getValues, reset } = useForm({});

  const handleAddProductList = (data) => {
    if (
      getValues('productName') === '' ||
      getValues('productQuality') === '' ||
      getValues('productUnit') === '' ||
      getValues('productPrice') === ''
    ) {
      alert('กรุณากรอกข้อมูลให้ครบ');
    } else {
      const productObj = {
        productName: getValues('productName'),
        productQuality: getValues('productQuality'),
        productUnit: getValues('productUnit'),
        productPrice: getValues('productPrice'),
        productAbout: getValues('productAbout'),
        productDiscount: getValues('productDiscount'),
      };

      productList.push(productObj);
      console.log('productList in submit', productList);
      setProductList(productList);
      setOpen(false);
      reset(defaultValue);
      setReload(!reload);
    }
  };

  return (
    <>
      <div>{children}</div>
      <Card className="">
        <CardContent>
          <Controller
            name="productName"
            control={control}
            // defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="outlined-basic"
                label="ชื่อสินค้า"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          />
          <div className="flex">
            <div className="pr-2">
              <Controller
                name="productQuality"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="จำนวน"
                    variant="outlined"
                    type="number"
                    margin="normal"
                    fullWidth
                  />
                )}
              />{' '}
            </div>
            <div className="pr-2">
              <Controller
                name="productUnit"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="หน่วย"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                )}
              />{' '}
            </div>
            <div className="pr-2">
              <Controller
                name="productPrice"
                control={control}
                required
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="ราคาต่อหน่วย"
                    variant="outlined"
                    margin="normal"
                    type="number"
                    fullWidth
                  />
                )}
              />{' '}
            </div>
            <div>
              <Controller
                name="productDiscount"
                control={control}
                required
                defaultValue="0"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="ส่วนลด"
                    variant="outlined"
                    margin="normal"
                    type="number"
                    fullWidth
                  />
                )}
              />{' '}
            </div>
          </div>
          <Controller
            name="productAbout"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="outlined-multiline-basic"
                label="รายละเอียดสินค้า"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
              />
            )}
          />{' '}
          <div className="w-full">
            <Button onClick={() => handleAddProductList()}>เพิ่ม</Button>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default FormPurchaseProduct;
