import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
// import imageToBase64 from 'image-to-base64/browser';
import _ from 'lodash';
// import { margin } from '@mui/system';
// import { da } from 'date-fns/locale';
// import logoImage from '../../assets/img/companyLogo.jpeg';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const SalaryReport = async (data, date, endDate, information) => {
  const countTaxSSO = (data) => {
    const sso = data?.salary * (data?.taxSSO / 100);
    return sso;
  };
  const revenue = (data) =>
    data?.salary + data?.ot + data?.extra + data?.allowance;
  const expenses = (data) => data?.sumSSO + data?.tax + data?.etc;
  const sumSalary = (data) => revenue(data) - expenses(data);
  /// calculate SumSalary All
  const sumAllSalary = _.sumBy(data?.arr, (each) => each?.salary);
  const sumAllOt = _.sumBy(data?.arr, (each) => each?.ot);
  const sumAllExtra = _.sumBy(data?.arr, (each) => each?.extra);
  const sumAllowance = _.sumBy(data?.arr, (each) => each?.allowance);
  const sumAllRevenue = sumAllSalary + sumAllOt + sumAllExtra + sumAllowance;
  /// Calculate Sumrevenue
  const sumAllSSO = _.sumBy(data?.arr, (each) => each?.sumSSO);
  const sumEtc = _.sumBy(data?.arr, (each) => each?.etc);
  const sumTax = _.sumBy(data?.arr, (each) => each?.tax);
  const sumAllexpenses = sumAllSSO + sumEtc + sumTax;
  /// Calculate emExtra
  const sumAllEmExtra1 = _.sumBy(data?.arr, (each) => each?.emExtra);
  const sumAllEmExtra2 = _.sumBy(data?.arr, (each) => each?.emExtra2);

  const genBody = (data) => {
    const dataList = _.map(data, (eachData, index) => [
      { text: index + 1, alignment: 'center' },

      {
        text: `${eachData?._id?.name} ${eachData?._id?.surname}`,
        style: 'table2',
      },
      { text: `${eachData?._id?.personalID || '-'}`, style: 'table1' },
      {
        text: `${eachData?.salary
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
      {
        text: `${eachData?.ot?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
      {
        text: `${eachData?.allowance
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
      {
        text: `${eachData?.extra
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
      {
        text: `${eachData?.sumSalary
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
      {
        text: `${eachData?.sumSSO
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
      {
        text: `${eachData?.tax
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
      {
        text: `${eachData?.etc
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
      {
        text: expenses(eachData)
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
        style: 'table1',
      },
      {
        text: sumSalary(eachData)
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
        style: 'table1',
      },
      { text: '', border: [true, false, true, false] },
      {
        text: `${eachData?.emExtra
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
      {
        text: `${eachData?.emExtra2
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
    ]);

    return dataList;
  };

  const genItemBody = genBody(data.arr);

  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 20, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },

    content: [
      {
        text: `ใบสรุปจ่ายเงินเดือน\nประจำปี ${dayjs(date).format(
          'MMMM'
        )} - ${dayjs(endDate).format('MMMM BBBB')}\n ${information?.name}`,
        style: 'headerPage',
        margin: [0, 0, 0, 20],
      },
      {
        table: {
          widths: [
            '2%', // 1
            '15%', // 2
            '10%', // 3
            '9%', // 4
            '6%', // 5
            '6%', // 6
            '5%', // 7
            '9%', // 8
            '6%', // 9
            '*', // 10
            '*', // 11
            '6%', // 12
            '8%', // 13
            '0.5%', // 14
            '6%', // 15
            '6%', // 16
          ],

          body: [
            [
              {
                text: 'ที่',
                rowSpan: 2,
                style: 'table',
                fillColor: '#d2d4d2',
                margin: [0, 12.5],
              },
              {
                text: 'ชื่อ-สกุล',
                rowSpan: 2,
                style: 'table',
                fillColor: '#d2d4d2',
                margin: [0, 12.5],
              },
              {
                text: 'เลขบัตร\nประชาชน',
                rowSpan: 2,
                style: 'table',
                fillColor: '#d2d4d2',
                margin: [0, 12.5],
              },
              {
                text: 'รายได้',
                colSpan: 4,
                style: 'table',
                fillColor: '#d2d4d2',
              },
              { text: '5', fillColor: '#d2d4d2' },
              { text: '5', fillColor: '#d2d4d2' },
              { text: '6', fillColor: '#d2d4d2' },
              {
                text: 'รวม\nรายได้',
                rowSpan: 2,
                style: 'table',
                fillColor: '#d2d4d2',
                margin: [0, 12.5],
              },
              {
                text: 'รายการหัก',
                colSpan: 3,
                style: 'table',
                fillColor: '#d2d4d2',
              },
              { text: '9' },
              { text: '10' },
              {
                text: 'รวมหัก',
                style: 'table',
                fillColor: '#d2d4d2',
                rowSpan: 2,
                margin: [0, 12.5],
              },
              {
                text: 'รวม\nรับสุทธิ',
                rowSpan: 2,
                style: 'table',
                fillColor: '#d2d4d2',
                margin: [0, 12.5],
              },
              {
                text: '',
                border: [true, false, true, false],
              },
              {
                text: 'เงินสมทบนายจ้าง',
                colSpan: 2,
                style: 'table',
                fillColor: '#d2d4d2',
              },
              { text: '15' },
            ],
            [
              { text: '', fillColor: '#d2d4d2' },
              { text: '2', fillColor: '#d2d4d2' },
              { text: '3', fillColor: '#d2d4d2' },
              {
                text: 'เงินเดือน',
                style: 'table',
                fillColor: '#d2d4d2',
                margin: [0, 6.25],
              },
              {
                text: 'ค่าแรงวันหยุด',
                style: 'table',
                fillColor: '#d2d4d2',
                margin: [0, 6.25],
              },
              {
                text: 'ค่าเบี้ย\nเลี้ยง',
                style: 'table',
                fillColor: '#d2d4d2',
                margin: [0, 6.25],
              },
              {
                text: 'เงินเพิ่ม\nพิเศษ',
                style: 'table',
                fillColor: '#d2d4d2',
                margin: [0, 6.25],
              },
              { text: '' },
              {
                text: 'ปสก',
                style: 'table',
                fillColor: '#d2d4d2',
                margin: [0, 6.25],
              },
              {
                text: 'ภงด.1',
                style: 'table',
                fillColor: '#d2d4d2',
                margin: [0, 6.25],
              },
              {
                text: 'อื่นๆ',
                style: 'table',
                fillColor: '#d2d4d2',
                margin: [0, 6.25],
              },
              { text: '', style: 'table', fillColor: '#d2d4d2' },
              { text: '12', fillColor: '#d2d4d2' },
              { text: '', border: [true, false, true, false] },
              {
                text: 'นายจ้าง',
                style: 'table',
                fillColor: '#d2d4d2',
                margin: [0, 6.25],
              },
              {
                text: 'ลูกจ้าง',
                style: 'table',
                fillColor: '#d2d4d2',
                margin: [0, 6.25],
              },
            ],

            ...genItemBody,
            [
              { text: 'รวม', colSpan: 3, style: 'sum1' },
              { text: '2' },
              { text: '3' },
              {
                text: ` ${sumAllSalary
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'sum',
              },
              {
                text: `${sumAllOt
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'sum',
              },
              {
                text: `${sumAllowance
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'sum',
              },
              {
                text: `${sumAllExtra
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'sum',
              },
              {
                text: `${sumAllRevenue
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'sum',
              },
              {
                text: `${sumAllSSO
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'sum',
              },
              {
                text: `${sumTax
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'sum',
              },
              {
                text: `${sumEtc
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'sum',
              },
              {
                text: `${sumAllexpenses
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'sum',
              },
              {
                text: `${(sumAllRevenue - sumAllexpenses)
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'sum',
              },
              { text: '' },
              {
                text: `${sumAllEmExtra1
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'sum',
              },
              {
                text: `${sumAllEmExtra2
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'sum',
              },
            ],
          ],
        },
      },
    ],

    footer: [],

    styles: {
      headerPage: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      table: {
        bold: true,
        alignment: 'center',
      },
      table1: {
        fontSize: 9,
        bold: true,
        alignment: 'right',
      },
      table2: {
        fontSize: 9,
        bold: true,
        alignment: 'left',
      },
      sum: {
        color: 'red',
        fontSize: 9,
        bold: true,
        alignment: 'right',
      },
      sum1: {
        color: 'red',
        fontSize: 9,
        bold: true,
        alignment: 'center',
      },
      tabHead: {
        bold: true,
        color: 'white',
      },
    },
    images: {},
  };
  pdfMake.createPdf(documentRef).open();
};
export default SalaryReport;
// [
//   { text: "1" },
//   { text: "2" },
//   { text: "3" },
//   { text: "4" },
//   { text: "5" },
//   { text: "6" },
//   { text: "7" },
//   { text: "8" },
//   { text: "9" },
//   { text: "10" },
//   { text: "11" },
//   { text: "12" },
//   { text: "13" },
//   { text: "14" },
//   { text: "15" },
// ],
