import {
  INVOICE_ALL,
  INVOICE_DELETE,
  INVOICE_GET,
  INVOICE_LOADING,
  INVOICE_POST,
  INVOICE_PUT,
} from '../type';
const initialState = {
  invoice: null,
  isLoading: true,
  isCompleted: true,
};

function invoiceReducer(state = initialState, action) {
  switch (action.type) {
    case INVOICE_LOADING:
      return { isLoading: true, isCompleted: false };
    case INVOICE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case INVOICE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case INVOICE_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case INVOICE_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case INVOICE_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default invoiceReducer;
