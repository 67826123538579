import {
  PURCHASE_ALL,
  PURCHASE_DELETE,
  PURCHASE_GET,
  PURCHASE_LOADING,
  PURCHASE_POST,
  PURCHASE_PUT,
} from '../type';

function purchaseReducer(state = { pickUpOrder: null }, action) {
  switch (action.type) {
    case PURCHASE_LOADING:
      return { isLoading: true };
    case PURCHASE_ALL:
      return {
        ...action.payload,
        isLoading: false,
      };
    case PURCHASE_GET:
      return {
        ...action.payload,
        isLoading: false,
      };
    case PURCHASE_POST:
      return {
        ...action.payload,
        isLoading: true,
      };
    case PURCHASE_PUT:
      return {
        isLoading: true,
      };
    case PURCHASE_DELETE:
      return {
        isLoading: true,
      };
    default:
      return state;
  }
}

export default purchaseReducer;
