/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import { BackButton } from 'components/Button';
import { ViewTitle } from 'components/ViewTitle';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useGeolocated } from 'react-geolocated';
import { Button, Card, Modal, Box } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import TimeStampSettingCard from '../../components/Card/TimeStampSettingCard';
import * as actions from '../../redux/action';
import LocationAdd from '../../components/Card/LocationAdd';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function NewTimeStampSetting({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSubmit, control } = useForm({ mode: 'onChange' });
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });
  const [timeIn, setTimeIn] = useState('');
  const [timeOut, setTimeOut] = useState('');
  const [locations, setLocations] = useState({});
  const [open, setOpen] = useState(false);

  const getLocation = () => setLocations(coords);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const renderModal = () => (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...style, width: 300 }}>
        <LocationAdd setLocations={setLocations} locations={locations} />
        <Button onClick={handleClose}>บันทึก</Button>
      </Box>
    </Modal>
  );
  const renderLocationButton = () => (
    <Card>
      <div className="p-4">
        <h1 className="font-bold">สถานที่เข้างาน</h1>
        <h1 className="text-lg  font-sans text-gray-700">
          latitude : {locations?.latitude}
          longitude:{locations?.longitude}
        </h1>
        <div className="flex gap-1">
          <Button onClick={() => getLocation()} variant="outlined">
            ดึงตำแหน่งอัตโนมัติ
          </Button>
          <Button
            onClick={() => handleOpen()}
            variant="outlined"
            color="success"
          >
            กำหนดตำแหน่ง
          </Button>
        </div>
      </div>
    </Card>
  );

  const submit = async (data) => {
    const confirm = window.confirm('บันทึก');
    if (confirm) {
      const payload = {
        ...data,
        time_in: dayjs(timeIn),
        time_out: dayjs(timeOut),
        location: {
          longitude: locations.longitude,
          latitude: locations.latitude,
        },
      };
      await dispatch(actions.addTimeStampSetting(payload));
      await dispatch(actions.getAllTimeStampSetting({}));
      history.goBack();
    }
  };

  const rendercard = () => (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <TimeStampSettingCard
          Controller={Controller}
          control={control}
          timeIn={timeIn}
          setTimeIn={setTimeIn}
          timeOut={timeOut}
          setTimeOut={setTimeOut}
        />
        {renderLocationButton()}
        <div className="p-4 flex justify-center">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
  return (
    <div>
      <div className="pb-2">
        {renderTitle()}
        {renderBackButton()}
      </div>
      <Card>
        <div className="p-4">{rendercard()}</div>
        {renderModal()}
      </Card>
    </div>
  );
}
NewTimeStampSetting.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
NewTimeStampSetting.defaultProps = {
  title: '',
  subtitle: '',
};
