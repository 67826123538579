import {
  LEAVE_TYPE_ALL,
  LEAVE_TYPE_GET,
  LEAVE_TYPE_POST,
  LEAVE_TYPE_PUT,
  LEAVE_TYPE_DELETE,
  LEAVE_TYPE_LOADING,
} from '../type';
const initialState = {
  leavType: null,
  isLoading: true,
  isCompleted: true,
};
function leaveTypeReducer(state = initialState, action) {
  switch (action.type) {
    case LEAVE_TYPE_LOADING:
      return { isLoading: true, isCompleted: false };
    case LEAVE_TYPE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LEAVE_TYPE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LEAVE_TYPE_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LEAVE_TYPE_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case LEAVE_TYPE_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default leaveTypeReducer;
