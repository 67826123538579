import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { useParams } from 'react-router';
import dayjs from 'dayjs';

import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import * as action from '../../redux/action';

function ViewLeaveForm({ title }) {
  const { handleSubmit } = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();
  const params = useParams();

  const leaveDocs = useSelector((state) => state.leaveDoc);
  dayjs.extend(buddhistEra);
  // console.log("data leave", leaveDocs);
  const getData = () => {
    dispatch(action.getOneLeaveDoc(params.id));
  };

  useEffect(() => {
    getData();
    return () => {};
  }, [leaveDocs?.name]);
  // console.log(params.id);
  useEffect(() => () => {}, [leaveDocs, leaveDocs?.status]);
  const sumDay = () => {
    const date1 = dayjs(leaveDocs?.dateEnd).startOf('day');
    const date2 = dayjs(leaveDocs?.dateStart).startOf('day');
    const date = date1.toDate().getTime() - date2.toDate().getTime();
    const sumday = date / (1000 * 3600 * 24);

    return sumday + 1;
  };
  // const sumOldDay = () => {
  //   const date1 = dayjs(leaveDocs?.lastdateEnd).startOf('day');
  //   const date2 = dayjs(leaveDocs?.lastdateStart).startOf('day');
  //   const date = date1.toDate().getTime() - date2.toDate().getTime();

  //   const sumday = date / (1000 * 3600 * 24);

  //   return sumday + 1;
  // };

  const renderTitle = () => <ViewTitle title={title} />;
  // const dataLeave = _.findIndex(leaveDocs?.arr,)
  return (
    <div className="min-h-screen">
      <form onSubmit={handleSubmit()}>
        <div className="pt-8  ">
          {renderTitle()}
          <div className="py-2">
            <BackButton />
          </div>

          <div>
            <Card>
              <CardContent>
                <div className="p-2">
                  <TableContainer>
                    <Table>
                      <colgroup>
                        <col width="30%" />
                        <col width="70%" />
                      </colgroup>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <h1 className=" font-bold  ">ชื่อ-นามสกุล</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l  ">
                              {leaveDocs?.name} {leaveDocs?.surname || ''}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className=" font-bold   ">ตำแหน่ง</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l  ">{leaveDocs?.position}</h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">แผนก</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l ">
                              {leaveDocs?.under || '-'}
                            </h1>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

                <h1 className="font-bold pt-4 ">ข้อมูลการลา</h1>
                <div className="p-2">
                  <TableContainer>
                    <Table>
                      <colgroup>
                        <col width="30%" />
                        <col width="70%" />
                      </colgroup>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <h1 className=" font-bold  ">ลา</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l  ">
                              {leaveDocs?.leaveTypeID?.name}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className=" font-bold   ">วันที่</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l  ">
                              {dayjs(leaveDocs?.dateStart).format('DD/MM/BB')}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">ถึง</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l ">
                              {dayjs(leaveDocs?.dateEnd).format('DD/MM/BB')}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">จำนวน</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l ">{sumDay()}</h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">สาเหตุ</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l ">
                              {leaveDocs?.leaveCause || '-'}
                            </h1>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="font-bold   ">เบอร์ติดต่อ</h1>
                          </TableCell>
                          <TableCell>
                            <h1 className="text-l ">
                              {leaveDocs?.contact || '-'}
                            </h1>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

                <div className="pt-8   ">
                  <div className="">
                    <div className="    ">
                      <h1 className="font-bold pt-4 ">ความเห็นผู้บริหาร</h1>
                      <div>
                        <TableContainer>
                          <Table>
                            <colgroup>
                              <col width="30%" />
                              <col width="70%" />
                            </colgroup>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <h1 className=" font-bold  ">
                                    ผลการพิจารณา{' '}
                                  </h1>
                                </TableCell>
                                <TableCell>
                                  <div>
                                    <div className="font-bold">
                                      {leaveDocs?.status}
                                    </div>
                                    <div>
                                      ผู้พิจารณาคนที่ 1 :{' '}
                                      {leaveDocs?.approve1?.status}
                                    </div>
                                    <div>
                                      ผู้พิจารณาคนที่ 2 :{' '}
                                      {leaveDocs?.approve2?.status}
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <h1 className=" font-bold   ">หมายเหตุ</h1>
                                </TableCell>
                                <TableCell>
                                  <h1 className="text-l  ">
                                    {leaveDocs?.note}
                                  </h1>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </form>
    </div>
  );
}
ViewLeaveForm.propTypes = {
  title: PropTypes.string,
};
ViewLeaveForm.defaultProps = {
  title: '',
};
export default ViewLeaveForm;
