import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import {
  Card,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  CardContent,
  TextField,
  Button,
} from '@mui/material';
import * as action from '../../redux/action';

export default function NewInfoBusiness() {
  const dispatch = useDispatch();
  const history = useHistory();
  const information = useSelector((state) => state.information);
  const { handleSubmit, control, reset } = useForm({ mode: 'onChange' });

  useEffect(() => {
    dispatch(action.getAllInformation());

    return () => {};
  }, []);

  useEffect(() => {
    if (information?.arr?.length !== 0) {
      history.push('/admin/info');
    }

    return () => {};
  }, [information?.arr?.length]);

  const submit = async (data) => {
    try {
      await dispatch(action.addInformation(data));
      await dispatch(action.getAllInformation());
      history.push('/admin/info');
    } catch (error) {}
  };

  return (
    <div className="h-screen ">
      <Card>
        <div className=" w-full">
          <div className="pt-6 pl-8 ">
            <div>
              <div className="bg-gray-500 font-sans ">
                <h1 className="text-2xl p-2 text-white font-bold ">
                  ข้อมูลบริษัท
                </h1>
              </div>
              <div>
                <form onSubmit={handleSubmit(submit)}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 50 }} aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <h1 className="text-lg text-gray-700  font-sans">
                              ชื่อบริษัท
                            </h1>
                          </TableCell>
                          <TableCell align="left">
                            <div className="tex-left text-lg text-gray-700  font-sans">
                              <Controller
                                name="name"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    required
                                    value={field.value}
                                    id="outlined-basic"
                                    label="ชื่อบริษัท"
                                    margin="dense"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="text-lg  font-sans text-gray-700">
                              ชื่อบริษัทภาษาอังกฤษ (ถ้ามี)
                            </h1>
                          </TableCell>
                          <TableCell>
                            <div className="tex-left text-lg text-gray-700  font-sans">
                              <Controller
                                name="nameEng"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    value={field.value}
                                    id="outlined-basic"
                                    label="ชื่อบริษัท ภาษาอังกฤษ(ถ้ามี)"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="text-lg  font-sans text-gray-700">
                              เลขที่ผู้เสียภาษี
                            </h1>
                          </TableCell>
                          <TableCell>
                            {' '}
                            <div className="tex-left text-lg text-gray-700  font-sans">
                              <Controller
                                name="taxId"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    required
                                    value={field.value}
                                    id="outlined-basic"
                                    label="เลขที่ผู้เสียภาษี"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="text-lg  font-sans text-gray-700">
                              เบอร์โทร
                            </h1>
                          </TableCell>
                          <TableCell>
                            {' '}
                            <div className="tex-left text-lg text-gray-700  font-sans">
                              <Controller
                                name="tel"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    required
                                    value={field.value}
                                    id="outlined-basic"
                                    label="ชื่อ-นามสกุล"
                                    margin="dense"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="text-lg  font-sans text-gray-700">
                              Email
                            </h1>
                          </TableCell>
                          <TableCell>
                            {' '}
                            <div className="tex-left text-lg text-gray-700  font-sans">
                              <Controller
                                name="email"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    required
                                    value={field.value}
                                    id="outlined-basic"
                                    label="e-mail"
                                    margin="dense"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <h1 className="text-lg  font-sans text-gray-700">
                              ที่อยู่
                            </h1>
                          </TableCell>
                          <TableCell>
                            {' '}
                            <div className="tex-left text-lg text-gray-700  font-sans">
                              <Controller
                                name="address"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    required
                                    value={field.value}
                                    id="outlined-basic"
                                    label="ที่อยู่"
                                    margin="dense"
                                    variant="outlined"
                                    size="small"
                                    multiline
                                    rows={2}
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {' '}
                  <div className="p-4 text-center">
                    <Button
                      variant="contained"
                      startIcon={<EditIcon />}
                      color="success"
                      type="submit"
                    >
                      แก้ไข
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div />
        </div>
        {' '}
      </Card>
    </div>
  );
}
