/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// components

import Sidebar from 'components/common/Sidebar/Sidebar';
// eslint-disable-next-line import/extensions
import FooterAdmin from 'components/common/Footers/FooterAdmin.js';

import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/action';
import NotAuthorized from 'components/Error/Error';

// views

import Dashboard from '../views/admin/Dashboard.js';
import AdEm from '../views/admin/AdNewEmployee.js';
import User from 'views/admin/user/user.js';
import AdPage from 'views/admin/AdEmployee.js';
import InfoBusiness from 'views/admin/infoBusiness.js';
import EditInfoBusiness from 'views/admin/EditinfoBusiness.js';
import NewInfoBusiness from 'views/admin/newBusiness.js';
import SystemSetting from 'views/admin/SystemSetting';
import { SubtitlesRounded } from '@mui/icons-material';
import Loading from 'components/Loading';
import { HomeNavbar } from 'components/Navbars';
export default function Admin() {
  const module = 'ADM';
  const name = 'บริหาร';
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(actions.getMe());
    dispatch(actions.getAllInformation());
    return () => {};
  }, []);

  if (!me?.userData) {
    return (
      <div>
        <Loading isLoading />
      </div>
    );
  }
  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen h-full">
        <Sidebar />
        <div className="pt-4 hidden md:block ">
          <HomeNavbar onMobileNavOpen={() => {}} />
        </div>
        <div className="relative md:ml-64 bg-gray-200 min-h-screen  pt-6 md:px-8">
          {/* Header */}

          <div className="py-4 w-full min-h-screen ">
            <Switch>
              <Route path="/admin/dashboard" exact>
                <Dashboard title="ภาพรวมบริษัท" subtitle={name} />
              </Route>
              <Route path="/admin/user" exact component={User} />
              <Route path="/admin/newemployee" exact component={AdEm} />
              <Route path="/admin/info" exact component={InfoBusiness} />
              <Route path="/admin/newinfo" exact component={NewInfoBusiness} />
              <Route
                path="/admin/system-admin"
                exact
                component={SystemSetting}
              />
              <Route
                path="/admin/EditInfo/:id"
                exact
                component={EditInfoBusiness}
              />
              <Route path="/admin/employee" exact component={AdPage} />
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        {/* Header */}

        <div className="px-4 md:px-10 mx-auto w-full ">
          <NotAuthorized />
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
