import {
  AVAILABLE_MODULE_ALL,
  AVAILABLE_MODULE_ERROR,
  AVAILABLE_MODULE_LOADING,
  AVAILABLE_MODULE_PUT,
} from '../type';

const initialState = {
  information: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case AVAILABLE_MODULE_LOADING:
      return { isLoading: true, isCompleted: true };
    case AVAILABLE_MODULE_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case AVAILABLE_MODULE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case AVAILABLE_MODULE_PUT:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
