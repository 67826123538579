/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  Card,
  TextField,
  Autocomplete,
  Button,
  FormControlLabel,
  Switch,
  FormControl,
  IconButton,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';

import { Clear } from '@mui/icons-material';

export default function EditWork({
  control,
  Controller,
  work,
  setValue,
  fields,
  append,
  remove,
  user,
  date,
  setDate,
  status,
  setStatus,
  statusBoolean,
  handlButtonDelete = () => {},
}) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleAdd = () =>
    append({
      name: user.arr[0]?.id,
    });
  const handleDelete = (index) => {
    remove(index);
  };
  useEffect(() => {
    setValue('job', work?.name);
    setValue('time', work?.time);
    setValue('status', work?.status);
    setValue('allDay', work?.allDay);
    setValue('workAllDay', work?.workAllDay);
    setValue('halfDay', work?.halfDay);
    setValue('dayOff', work?.dayOff);
    setValue('dayOffHalfDay', work?.dayOffHalfDay);
    setValue('laeveWork', work?.laeveWork);
    setValue('consult', work?.consult);
    setValue('vehicle', work?.vehicle);
    setValue('note', work?.note);
    setDate(work.date);
    setValue('workerArray', work?.worker);
    setValue('link_url', work?.link_url);
    return () => {};
  }, [work]);
  console.log('Work Edit', work);

  const handleCheckProduct = (data, index) => {
    const users = _.find(user?.arr, { _id: data?._id });
    setValue(`workerArray.${index}`, users);
  };

  const statusString = (current) => {
    console.log('status Boolean', current?.allDay);
    if (current?.allDay === true) {
      return 'allDay';
    } else if (current?.workAllDay === true) {
      return 'workAllDay';
    } else if (current?.halfDay === true) {
      return 'halfDay';
    } else if (current?.dayOff === true) {
      return 'dayOff';
    } else if (current?.dayOffHalfDay === true) {
      return 'dayOffHalfDay';
    } else if (current?.laeveWork === true) {
      return 'laeveWork';
    }
  };
  const SS = statusString(work);

  console.log('statusString', SS, statusString(work));
  const addWorker = () =>
    _.map(fields, (item, index) => (
      <div key={item?.id}>
        <div className="flex pt-2">
          <div className="w-2/3">
            <Controller
              name={`workerArray.${index}`}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  id="free-solo-demo"
                  freeSolo
                  options={user?.arr || []}
                  getOptionLabel={(option) => option?.name}
                  onChange={(e, newValue) =>
                    handleCheckProduct(newValue, index)
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="ชื่อพนักงาน" />
                  )}
                />
              )}
            />
          </div>
          <div className="pl-4">
            <IconButton
              color="error"
              variant="contained"
              size="small"
              onClick={() => {
                handleDelete(index);
              }}
            >
              <Clear />
            </IconButton>
          </div>
        </div>
      </div>
    ));

  return (
    <div className=" ">
      <div>
        <Card sx={{}}>
          <div className="p-4">
            <Controller
              name="job"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value}
                  label="ชื่องาน"
                  required
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="วันที่"
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
            <div className="md:flex gap-1 ">
              <Controller
                name="consult"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value}
                    label="อาจารย์ที่ปรึกษา"
                    required
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
              <Controller
                name="vehicle"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value}
                    label="รถ"
                    required
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="pt-3 flex">
              <Controller
                name="time"
                control={control}
                defaultValue="08:30"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="time"
                    label="เวลา"
                    type="time"
                    size="small"
                    defaultValue="08:30"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    sx={{ width: 150 }}
                  />
                )}
              />
              {statusString(work) ? (
                <Controller
                  name="status"
                  control={control}
                  defaultValue={statusString(work)}
                  render={({ field }) => (
                    <div>
                      <FormControl sx={{ px: 1, minWidth: 240 }}>
                        <InputLabel id="demo-controlled-open-select-label">
                          เลือกการทำงาน
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          open={open}
                          onClose={handleClose}
                          onOpen={handleOpen}
                          //defaultValue={SS}
                          {...field}
                        >
                          <MenuItem value="">
                            <em>เลือกการทำงาน</em>
                          </MenuItem>
                          <MenuItem value="allDay">ลงพื้นที่ทั้งวัน</MenuItem>
                          <MenuItem value="workAllDay">
                            ลงพื้นที่ครึ่งวัน
                          </MenuItem>
                          <MenuItem value="halfDay">ลางานครึ่งวัน</MenuItem>
                          <MenuItem value="dayOff">ทำงานในวันหยุด</MenuItem>
                          <MenuItem value="dayOffHalfDay">
                            ทำงานในวันหยุดครึ่งวัน
                          </MenuItem>
                          <MenuItem value="laeveWork">ลางาน</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                />
              ) : (
                <div>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue={statusString(work)}
                    render={({ field }) => (
                      <div>
                        <FormControl sx={{ px: 1, minWidth: 240 }}>
                          <InputLabel id="demo-controlled-open-select-label">
                            เลือกการทำงาน
                          </InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            //defaultValue={SS}
                            {...field}
                          >
                            <MenuItem value="">
                              <em>เลือกการทำงาน</em>
                            </MenuItem>
                            <MenuItem value="allDay">ลงพื้นที่ทั้งวัน</MenuItem>
                            <MenuItem value="workAllDay">
                              ลงพื้นที่ครึ่งวัน
                            </MenuItem>
                            <MenuItem value="halfDay">ลางานครึ่งวัน</MenuItem>
                            <MenuItem value="dayOff">ทำงานในวันหยุด</MenuItem>
                            <MenuItem value="dayOffHalfDay">
                              ทำงานในวันหยุดครึ่งวัน
                            </MenuItem>
                            <MenuItem value="laeveWork">ลางาน</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  />
                </div>
              )}
            </div>
            <Controller
              name="note"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value}
                  label="รายละเอียดเพิ่มเติม"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              )}
            />

            <div className="py-auto flex">
              <Controller
                name="link_url"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value}
                    label="link"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="pt-4">
              <h1 className="font-bold text-xl pl-2">ผู้ทำงาน</h1>
              {addWorker()}
            </div>
            <div className="flex justify-center pt-2">
              <Button variant="outlined" onClick={handleAdd} size="small">
                เพิ่มพนักงาน
              </Button>
            </div>
          </div>
          <div className="flex justify-end p-4 gap-2">
            <Button
              variant="contained"
              size="large"
              color="error"
              onClick={() => {
                handlButtonDelete(work?._id);
              }}
            >
              ลบ
            </Button>
            <Button variant="contained" type="submit" size="large">
              บันทึก
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}
