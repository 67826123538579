import {
  NEWS_ALL,
  NEWS_GET,
  NEWS_POST,
  NEWS_PUT,
  NEWS_DELETE,
  NEWS_LOADING,
} from '../type';

const initialState = {
  news: null,
  isLoading: true,
  isCompleted: true,
};

// eslint-disable-next-line default-param-last
function newsReducer(state = initialState, action) {
  switch (action.type) {
    case NEWS_LOADING:
      return { isLoading: true, isCompleted: false };
    case NEWS_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case NEWS_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case NEWS_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case NEWS_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case NEWS_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default newsReducer;
