/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { Button, Card, CardContent, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../../redux/action';
import FormProduct from 'components/Forms/FormEditPurchaseProduct';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory, useParams } from 'react-router-dom';
/// /Modal
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ProductServe from 'components/Forms/FormProductArray';
import SumAllPrice from 'components/Card/SumAllPrice';
import Sumprice from 'components/Card/Sumprice';
import SumDiscount from 'components/Card/SumDiscount';
function CreatePickOrder() {
  const [customerData, setCustomerData] = useState();
  const { handleSubmit, control, setValue, watch } = useForm({});
  const [date, setDate] = React.useState(new Date());
  const [dueDate, setDueDate] = React.useState(new Date());
  const [reload, setReload] = useState(false);
  const [findName, setFindName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [productVat, setProductVat] = useState(false);
  const history = useHistory();
  const params = useParams();
  dayjs.extend(buddhistEra);
  // console.log(dayjs(date).format(`BBMMDD`));
  // post data

  const purchase = useSelector((state) => state.purchase);
  const customer = useSelector((state) => state.customer);
  const product = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const [productList, setProductList] = useState([]);

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /// /////
  const onSubmitPurchase = async (data) => {
    try {
      const ProcessData = await {
        ...data,
        date,
        dueDate,
        productVat,
        prefix: `RI${dayjs(date).format('BB')}-`,
        allPrice: allPrice(),
        product: data?.productArray,
      };
      const prdouctPayload = await {
        arr: _.map(data?.productArray, (each) => ({
          productName: each.name,
          productQuantity: each?.quantity,
          productUnit: each?.unit,
          productAbout: each?.about,
          cost: each?.price,
        })),
      };
      await dispatch(action.addPickUpOrder(ProcessData));
      await dispatch(action.addProduct(prdouctPayload));
      await dispatch(
        action.putPurchase(params.id, { status_pickUpOrder: true })
      );
      await dispatch(action.getAllPurchase({ name: findName, page, size }));
    } catch (error) {}
    // console.log("data.name", data.payment);

    // console.log("dataSubmit", params.id);

    history.push('/purchase/order');
  };
  // axios

  ///
  function getQtData(id) {
    dispatch(action.getOnePurchase(id));
  }

  function getData() {
    dispatch(action.getAllCustomer());
  }
  function getProduct() {
    dispatch(action.getAllProduct());
  }
  useEffect(() => {
    // console.log(customer?.arr);
    getData();
    getQtData(params.id);
    getProduct();
  }, []);

  useEffect(() => {
    setProductList(purchase?.product);
  }, [purchase]);
  // console.log("data", purchase);
  const ProductDelete = (index) => {
    productList.splice(index, 1);
    setProductList(productList);
    setReload(!reload);
  };
  useEffect(() => {
    setValue('businessName', purchase?.businessName);
    setValue('productArray', purchase?.product);
    setValue('date', purchase?.date);
    setValue('TaxId', purchase?.TaxId);
    setValue('tel', purchase?.tel);
    setValue('name', purchase?.name);
    setValue('address', purchase?.address);
    setValue('payment', purchase?.payment);
    setValue('note', purchase?.note);
    setValue('doc', purchase?.prefix + purchase?.running_number);
    setProductVat(purchase?.productVat);
    return () => {};
  }, [purchase]);
  useEffect(
    () =>
      // console.log("product list", productList);
      () => {},
    []
  );

  const Price = () => {
    if (productList?.length === 0) {
      return 0;
    }
    return Sumprice(productList) - SumDiscount(productList);
  };
  const taxPrice = () => {
    if (productVat) {
      return 0;
    }
    return Price() * 0.07;
  };

  const allPrice = () => {
    return Price() + taxPrice();
  };

  return (
    <form onSubmit={handleSubmit(onSubmitPurchase)}>
      <div className="min-h-screen  pt-12 ">
        <div className=" ml-2 w-full rounded ">
          <h1 className="text-red-400  border-b-orange-500 text-4xl  font-bold ml-2">
            {' '}
            | ใบรับสินค้า
          </h1>
        </div>

        <div className="flex w-full pt-6 h-full">
          {' '}
          <div>
            <Card className="justify-center mt-2 ">
              <CardContent>
                <h1 className="pb-4"> ข้อมูลผู้ขาย</h1>
                <Controller
                  name="businessName"
                  control={control}
                  defaultValue=""
                  rules={{ required: false }}
                  // defaultValue={{ businessname: "" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      label="ชื่อลูกค้า"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="TaxId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      label="หมายเลขผู้เสียภาษี"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="tel"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      id="outlined-basic"
                      label="เบอร์โทร"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="address"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      id="outlined-multiline-basic"
                      label="ที่อยู่"
                      variant="outlined"
                      fullWidth
                      multiline
                      margin="normal"
                      rows={4}
                    />
                  )}
                />
              </CardContent>
            </Card>{' '}
          </div>
          <div className="่ w-full  pl-4 ">
            <div className="flex justify-end pb-4 ">
              <Stack spacing={2} direction="row">
                {' '}
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </Stack>
            </div>
            <div>
              <Card>
                <CardContent>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่"
                      value={purchase?.date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่วันที่กำหนดส่ง"
                      value={purchase?.dueDate}
                      onChange={(newValue) => {
                        setDueDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <Controller
                    name="doc"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="เอกสารอ้างอิง"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="payment"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="วิธีการชำระเงิน"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </CardContent>
              </Card>
            </div>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  position: 'absolute',
                }}
              >
                <Typography id="modal-modal-title">
                  <FormProduct
                    setOpen={setOpen}
                    setProductList={setProductList}
                    setReload={setReload}
                    productList={productList}
                    reload={reload}
                    product={product}
                  />
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        <div>
          <div className="mt-2">
            <ProductServe
              control={control}
              setProductList={setProductList}
              setValue={setValue}
              edit
              watch={watch}
              setProductVat={setProductVat}
              productVat={productVat}
              statusHide={false}
              statusPickUp={true}
            />
          </div>
          <Card>
            <CardContent>
              <div className="flex w-full ">
                <div className="w-1/2">
                  {' '}
                  <Controller
                    name="note"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-multiline-basic"
                        label="หมายเหตุ"
                        variant="outlined"
                        fullWidth
                        multiline
                        margin="normal"
                        rows={4}
                      />
                    )}
                  />
                </div>

                <div className="flex justify-between  w-1/2 ml-2  ">
                  <div>
                    <div>รวมเงิน </div>
                    <div>ส่วนลดการค้า</div>
                    <div>เงินหลังหักส่วนลด</div>
                    <div>VAT 7%</div>
                    <div className="font-bold ">จำนวนเงินทั้งสิ้น </div>
                  </div>
                  <div className="text-right">
                    <div>
                      {Sumprice()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div>
                      {SumDiscount()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div>
                      {Price()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>
                    <div>
                      {taxPrice()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>

                    <div className="font-bold ">
                      {allPrice()
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </form>
  );
}
export default CreatePickOrder;
