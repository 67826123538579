import {
  USER_ALL,
  USER_DELETE,
  USER_GET,
  USER_LOADING,
  USER_POST,
  USER_PUT,
} from '../type';
const initialState = {
  User: null,
  isLoading: true,
  isCompleted: true,
};

function UserReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        isLoading: true,
        isCompleted: false,
      };
    case USER_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case USER_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case USER_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case USER_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case USER_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default UserReducer;
