/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router';
import { Card, Button, Avatar, Chip } from '@mui/material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

export default function ListTimestamp({ checkIn }) {
  const [ctime, setTime] = useState(new Date().toLocaleTimeString());
  const [showDelayed, setShowDelayed] = useState();

  const UpdateTime = () => {
    const time = new Date().toLocaleTimeString('th');
    setTime(time);
  };
  setInterval(UpdateTime);
  const history = useHistory();
  const handleClick = () => {
    setTimeout(() => {
      setShowDelayed(true);
    }, 1000);
  };
  return (
    <div className=" ">
      {checkIn?.arr?.length !== 0 ? (
        _.map(checkIn?.arr, (e, index) => (
          <div className="py-1">
            <Card key={index}>
              <div className="flex justify-center py-4 ">
                <div className="text-2xl font-bold ">
                  <div>{dayjs(new Date()).format('DD MMMM BBBB')}</div>
                  <div className="text-center">{ctime}</div>
                  <div className="flex justify-center">
                    {e?.late_status && (
                      <Chip label="เข้างานสาย" color="error" />
                    )}
                  </div>
                </div>
              </div>

              <div className="w-full flex justify-center py-2 ">
                <div className="p-4 ">
                  <h1 className="font-bold text-xl  ">
                    วันที่ {dayjs(e?.date).format('DD/MM/YYYY')}
                  </h1>
                  <h1 className="font-bold text-xl ">
                    เวลาเข้างาน {dayjs(e?.date).format('HH:mm')}
                  </h1>
                  <h1 className="font-bold text-xl ">
                    สถานที่เข้างาน {e?.placeCheckIn?.name}
                  </h1>
                  <div className="py-2">
                    <Button
                      variant="contained"
                      size="large"
                      disabled={e?.checkOut_status}
                      onClick={() => history.push(`checkIn/out/${e?._id}`)}
                    >
                      ลงชื่อออก
                    </Button>
                  </div>
                </div>
                <div className="p-2 flex justify-end  lg:px-2 ">
                  <div>
                    <Card>
                      <Avatar
                        src={e?.image?.url}
                        variant="square"
                        sx={{ width: 100, height: 100 }}
                      />
                      <div className="p-2 text-center">เข้างาน</div>
                    </Card>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        ))
      ) : (
        <Card>
          <div className="my-4  px-4">
            <div className="flex justify-center py-4 ">
              <div className="text-xl font-bold ">
                <div>{dayjs(new Date()).format('DD MMMM BBBB')}</div>
                <div className="text-center">{ctime}</div>
              </div>
            </div>
            <Link to={'/work/checkIn/add'}>
              <div className="bg-blue-600  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                <div className="flex justify-center">
                  {/* <Clock size={50} color="white" /> */}
                </div>

                <p className=" text-white text-2xl mt-4">ลงเวลา</p>
              </div>
            </Link>
          </div>
        </Card>
      )}
    </div>
  );
}
