/* eslint-disable react/jsx-wrap-multilines */
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import _ from 'lodash';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Loading from 'components/Loading';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import * as action from '../../redux/action';

function EditDepartment() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const setting = useSelector((state) => state.setting);
  const department = useSelector((state) => state.Department);
  const user = useSelector((state) => state.user);

  // const [approver1, setApprover1] = useState(department?.approver_1);
  // const [approver2, setApprover2] = useState(department?.approver_2);

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
  });
  dayjs.extend(buddhistEra);

  const onSubmit = async (data) => {
    try {
      const payload = {
        ...data,
        name: data?.name,
        approver_1: data?.approver1?._id,
        approver_2: data?.approver2?._id,
        access: { ...data },
      };
      console.log('data', payload);
      await dispatch(action.putDepartment(params.id, payload));
      await dispatch(action.getAllDepartment({}));
      history.push('/hr/department');
    } catch (error) {
      alert('error');
    }
  };
  useEffect(() => {
    dispatch(action.getOneDepartment(params?.id));
    dispatch(action.settingGetAll({}));
    dispatch(action.getAllUser({}));
    return () => {};
  }, []);

  useEffect(() => {
    setValue('name', department?.name);
    setValue('FND', department?.access?.FND);
    setValue('ADM', department?.access?.ADM);
    setValue('HRD', department?.access?.HRD);
    setValue('PCD', department?.access?.PCD);
    setValue('PDD', department?.access?.PDD);
    setValue('SLD', department?.access?.SLD);
    setValue('approver1', department?.approver_1);
    setValue('approver2', department?.approver_2);

    return () => {};
  }, [department]);

  const module = setting?.arr;

  const handleCheckUser = (data) => {
    const users = _.find(user?.arr, { _id: data?._id });
    setValue('approver1', users);
  };
  const handleCheckUser2 = (data) => {
    const users = _.find(user?.arr, { _id: data?._id });
    setValue('approver2', users);
  };

  const departmentForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Card>
          <CardContent>
            <div>
              <div>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      label="ชื่อแผนก"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
              </div>
              <b>ผู้อนุมัติใบลาในแผนก</b>
              <div>
                <div className="py-2">
                  <Controller
                    name="approver1"
                    control={control}
                    defaultValue={
                      department?.approver_1 ? department?.approver_1 : ''
                    }
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disablePortal
                        id="free-solo-demo"
                        freeSolo
                        size="small"
                        options={user?.arr || []}
                        getOptionLabel={(option) =>
                          `${option?.name || ''} ${option?.surname || ''}`
                        }
                        onChange={(e, newValue) => handleCheckUser(newValue)}
                        renderInput={(param) => (
                          <TextField
                            {...param}
                            size="small"
                            label="ชื่อพนักงาน"
                          />
                        )}
                      />
                    )}
                  />
                </div>

                {module?.multipleApplover && (
                  <div className="py-2">
                    <Controller
                      name="approver2"
                      control={control}
                      defaultValue={
                        department?.approver_2 ? department?.approver_2 : ''
                      }
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          id="free-solo-demo"
                          freeSolo
                          size="small"
                          options={user?.arr || []}
                          getOptionLabel={(option) =>
                            `${option?.name || ''} ${option?.surname || ''}`
                          }
                          onChange={(e, newValue) => handleCheckUser2(newValue)}
                          renderInput={(param) => (
                            <TextField
                              {...param}
                              size="small"
                              label="ชื่อพนักงาน"
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="font-bold py-2 ">โมดูลในระบบที่เข้าถึงได้</div>
              <div className="flex">
                {module?.moduleAccess?.FND && (
                  <div>
                    <Controller
                      name="FND"
                      defaultValue={false}
                      control={control}
                      render={({ field }) => (
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                checked={field.value}
                                // checked={checked}
                                // onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            label="การเงิน"
                          />
                        </div>
                      )}
                    />
                  </div>
                )}
                {module?.moduleAccess?.SLD && (
                  <div>
                    <Controller
                      name="SLD"
                      defaultValue={false}
                      control={control}
                      render={({ field }) => (
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                checked={field.value}
                                // checked={checked}
                                // onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            label="ฝ่ายขาย"
                          />
                        </div>
                      )}
                    />
                  </div>
                )}
                {module?.moduleAccess?.HRD && (
                  <div>
                    <Controller
                      name="HRD"
                      defaultValue={false}
                      control={control}
                      render={({ field }) => (
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                checked={field.value}
                                // checked={checked}
                                // onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            label="ฝ่ายบุคคล"
                          />
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="flex">
                {module?.moduleAccess?.PCD && (
                  <Controller
                    name="PCD"
                    defaultValue={false}
                    control={control}
                    render={({ field }) => (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              // checked={checked}
                              // onChange={handleChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="ฝ่ายจัดซื้อ"
                        />
                      </div>
                    )}
                  />
                )}
                {module?.moduleAccess?.PDD && (
                  <Controller
                    name="PDD"
                    defaultValue={false}
                    control={control}
                    render={({ field }) => (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              // checked={checked}
                              // onChange={handleChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="คลังสินค้า"
                        />
                      </div>
                    )}
                  />
                )}
                {module?.moduleAccess?.ADM && (
                  <Controller
                    name="ADM"
                    defaultValue={false}
                    control={control}
                    render={({ field }) => (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              // checked={checked}
                              // onChange={handleChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="ผู้ดูแลระบบ"
                        />
                      </div>
                    )}
                  />
                )}
              </div>
            </div>
            <div className="flex justify-end">
              <Button type="submit" variant="contained">
                บันทึก
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>{' '}
    </form>
  );

  if (
    department?.isLoading &&
    !department?.isCompleted &&
    setting?.isLoading &&
    !setting.isCompleted
  ) {
    return <Loading isLoading />;
  }

  if (
    !department?.isLoading ||
    department?.isCompleted ||
    !setting?.isLoading ||
    setting.isCompleted
  ) {
    return (
      <div className="min-h-screen ">
        <ViewTitle title="แก้ไขแผนก" />
        <div className="py-2">
          <BackButton />
        </div>
        <div className="">{departmentForm()}</div>
      </div>
    );
  }
  return <Loading isLoading />;
}
export default EditDepartment;
