import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router';
import * as action from '../../../redux/action';
import RiReport from '../../../components/Report/RiReport';
import { ViewTitle } from 'components/ViewTitle';
import POListTable from 'components/Table/POListTable';

function Ri({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const pickUpOrder = useSelector((state) => state.pickUpOrder);
  const information = useSelector((state) => state.information);
  const [findName, setFindName] = useState('');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(5);

  useEffect(() => {
    dispatch(action.getAllPickUpOrder({ name: findName, page, size }));
    return () => {};
  }, [findName, page, size]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const propTable = {
    handlePrintQtReport: (data) => {
      RiReport(data, information);
    },
    DeleteProduct: async (id) => {
      const confirm = window.confirm('ยืนยันการลบข้อมูล');
      if (confirm) {
        try {
          await dispatch(action.deletePickUpOrder(id));
          await dispatch(action.getAllPickUpOrder({}));
        } catch (error) {
          console.error(error);
        }
      }
    },
  };

  const renderTable = () => (
    <POListTable
      setPage={setPage}
      setSize={setSize}
      size={size}
      page={page}
      pickUpOrder={pickUpOrder}
      {...propTable}
    />
  );

  const onCreateRe = () => {
    history.push(`/purchase/pickUpOrder/new`);
  };

  const renderAddButton = () => (
    <Button variant="contained" onClick={() => onCreateRe()} size="large">
      เพิ่ม
    </Button>
  );

  if (pickUpOrder.isloading) {
    return <div>loading</div>;
  }
  if (!pickUpOrder.isloading) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="p-2">{renderAddButton()}</div>
        </div>
        <Card className="mb-2">
          <CardContent>
            <div>
              <div className="flex">
                <TextField
                  id="outlined-basic"
                  label="ค้นหา"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setFindName(e.target.value)}
                  fullWidth
                />
              </div>
            </div>
          </CardContent>
        </Card>
        {renderTable()}
      </div>
    );
  }
  return <h1>404</h1>;
}
export default Ri;
