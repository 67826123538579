import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ViewTitle } from '../../components/ViewTitle';
import { Button, Card } from '@mui/material';
import { useParams, useHistory } from 'react-router';
import SubBusinessForm from '../../components/Forms/SubBusinessForm';
import * as actions from '../../redux/action';
import { BackButton } from 'components/Button';

function EditSubBusiness({ title, subtitle }) {
  const { handleSubmit, control, setValue } = useForm({});
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const subBusiness = useSelector((state) => state.subBusiness);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  useEffect(() => {
    dispatch(actions.subBusinessGet(id));

    return () => {};
  }, []);

  useEffect(() => {
    setValue('name', subBusiness?.name);
    return () => {};
  }, [subBusiness]);

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.subBusinessPut(id, data));
        await dispatch(actions.subBusinessGetAll({}));
        history.goBack();
      } catch (error) {}
    }
  };
  const renderModal = () => (
    <Card>
      <div className="p-4">
        <h2 id="child-modal-title" className="font-bold">
          {title}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SubBusinessForm
            control={control}
            Controller={Controller}
            subBusiness={subBusiness}
          />
          <div className="flex justify-end">
            <Button type="submit" variant="contained">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </Card>
  );

  return (
    <div>
      {renderTitle()}
      <BackButton />
      <div className="py-1">{renderModal()}</div>
    </div>
  );
}

EditSubBusiness.propTypes = {};

export default EditSubBusiness;
