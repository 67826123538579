import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import dayjs from 'dayjs';
import 'dayjs/locale/th';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (rows) => {
  const body = _.map(rows, (row, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: `${row?.employee?.name} ${row?.employee?.name}`,
      alignment: 'left',
    },

    {
      text: `${dayjs(row?.date).format('DD/MM/BB HH:mm ') || ''}`,
      alignment: 'right',
    },
    row.checkOut_status
      ? {
          text: `${dayjs(row?.date_checkOut).format('DD/MM/BB HH:mm ')}`,
          alignment: 'right',
        }
      : {
          text: `ยังไม่ลงชื่อออก`,
          alignment: 'right',
        },
    {
      text: `${row?.amount || '0'} นาที`,
      alignment: 'right',
    },
  ]);

  return body;
};

const CheckInReport = async (rows) => {
  console.log('rows', rows);
  const genItemBody = genBody(rows);
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 50, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '12',
    },
    header: [
      { text: 'รายงานการเข้างาน', style: 'header', margin: [0, 20, 0, 0] },
    ],
    content: [
      {
        table: {
          widths: ['10%', '35%', '20%', '20%', '15%'],
          header: 1,
          body: [
            [
              {
                text: 'ลำดับ',
                style: 'body',
              },
              {
                text: 'ชื่อพนักงาน',
                style: 'body',
              },

              {
                text: 'เข้างาน',
                style: 'body',
              },
              {
                text: 'ออกงาน',
                style: 'body',
              },
              {
                text: 'รวมเวลา',
                style: 'body',
              },
            ],
            ...genItemBody,
          ],
        },
      },

      { text: '', margin: [0, 40, 0, 20] },
    ],

    footer: [],

    styles: {
      header: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
      },

      body: {
        fontSize: 14,
        alignment: 'center',
      },
    },
    images: {},
  };
  pdfMake.createPdf(documentRef).open();
};
export default CheckInReport;
