import {
  SETTING_ALL,
  SETTING_GET,
  SETTING_POST,
  SETTING_PUT,
  SETTING_DELETE,
  SETTING_LOADING,
} from '../type';
const initialState = {
  setting: null,
  isLoading: true,
  isCompleted: true,
};
function settingReducer(state = initialState, action) {
  switch (action.type) {
    case SETTING_LOADING:
      return { isLoading: true, isCompleted: false };
    case SETTING_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SETTING_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SETTING_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SETTING_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case SETTING_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default settingReducer;
