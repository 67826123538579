import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import {
  useParams,
  useHistory,
} from 'react-router-dom/cjs/react-router-dom.min';
import * as actions from '../../redux/action';
import FormLeaveType from '../../components/Forms/FormLeaveType';

function EditLeaveTpye({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { handleSubmit, control, setValue } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
  });
  const leaveType = useSelector((state) => state.leaveType);

  const [option, setOption] = useState(false);

  useEffect(() => {
    dispatch(actions.leaveTypeGet(id));
    return () => {};
  }, []);

  useEffect(() => {
    setValue('name', leaveType?.name);
    setValue('quantity', leaveType?.quantity);
    setValue('longevity', leaveType?.longevity);
    setValue('options', leaveType?.options);
    setOption(leaveType?.options);
    return () => {};
  }, [leaveType]);

  const onSubmit = async (data) => {
    const confirm = window.confirm('ยืนยันแก้ไขข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.leaveTypePut(id, data));
        await dispatch(actions.leaveTypeGetAll());
        alert('บันทึกข้อมูลสำเร็จ');
        history.goBack();
      } catch (error) {
        alert('การแก้ไขมีปัญหา' + error);
      }
    }
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormLeaveType
        control={control}
        Controller={Controller}
        leaveType={leaveType}
        setOption={setOption}
        option={option}
        fields={fields}
        append={append}
        remove={remove}
      />
    </form>
  );
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;
  return (
    <div>
      {renderTitle()}
      {renderBackButton()}
      <div className="py-2">{renderForm()}</div>
    </div>
  );
}

EditLeaveTpye.propTypes = {};

export default EditLeaveTpye;
