import React, { forwardRef, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

function FormCustomer({
  control, Controller, watch, customer,
}) {
  const [customerData, setCustomerData] = useState(customer);

  useEffect(() => {
    setCustomerData(customer);
  }, [customer]);

  console.log('customer?.status', customer?.status);
  return (
    <div>
      <Card className="justify-center">
        <CardContent>
          <Controller
            name="buName"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label="ชื่อลูกค้า*"
                variant="outlined"
                fullWidth
              />
            )}
          />
          {' '}
          <div className="flex w-full">
            <div className=" w-2/3">
              <Controller
                name="taxId"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="หมายเลขผู้เสียภาษี"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="pl-4 pt-5">
              <Controller
                name="status"
                defaultValue={false}
                control={control}
                render={({ field }) => (
                  <div>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          {...field}
                          checked={field.value}
                          // checked={checked}
                          // onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      )}
                      label="นิติบุคคล"
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2">
              {' '}
              <Controller
                name="name"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="ชื่อผู้ติดต่อ"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="w-1/2">
              <Controller
                name="tel"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="เบอร์โทร"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </div>
          </div>
          <Controller
            name="address"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="outlined-multiline-basic"
                label="ที่อยู่"
                variant="outlined"
                fullWidth
                multiline
                margin="normal"
                rows={4}
              />
            )}
          />
        </CardContent>
      </Card>
    </div>
  );
}

export default FormCustomer;
