import haversine from 'haversine-distance';
import _ from 'lodash';

export default function CalDistanceFunction(locations, myLocations) {
  console.log('myLocations', myLocations);
  console.log('locations', locations);

  try {
    console.log('---------');
    console.log(
      'fil',
      _.map(locations, (each) => haversine(myLocations, each?.location))
    );
    const distance = _.minBy(
      _.filter(
        locations,
        (each) => haversine(myLocations, each?.location) <= 1000
      ),
      (e) => haversine(myLocations, e?.location)
    );
    console.log('distance', distance);

    return distance;
  } catch (error) {
    console.log('error', error);
    return 1;
  }
}
