/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { Button, Chip, Paper, TablePagination } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ViewTitle } from 'components/ViewTitle';
import { Card, Avatar } from '@mui/material';
import { BackButton } from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../redux/action';
import _ from 'lodash';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import ModalUploadFile from '../../components/Modal/ModalUploadFile';

export default function DetailEmployee({ title, subtitle }) {
  const user = useSelector((state) => state.user);
  console.log('user', user?.certificate?.arr);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [name, setName] = useState();
  const params = useParams();
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  useEffect(() => {
    dispatch(action.getOneUser(params.id));
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const renderTable = () => (
    <div className=" px-20 py-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <p className="font-bold">ลำดับ</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold">ชื่อเอกสาร</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold">ไฟล์</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold">หมายเหตุ</p>
                </TableCell>
              </TableRow>
            </TableHead>

            {_.isEmpty(user?.certificate) ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    ไม่มีข้อมูล
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {user?.certificate?.map((users, index) => (
                  <TableRow key={users.index}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>

                    <TableCell>{users?.name || '-'}</TableCell>

                    <TableCell>
                      <a href={users?.file?.url || '-'} target="_blank">
                        <h1 className="text-blue-600 font-bold ">
                          {users?.file?.file_name || '-'}
                        </h1>
                      </a>
                    </TableCell>

                    <TableCell>{users?.note || '-'} </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      <BackButton />
      <br />
      <br />
      <Card>
        <div className="px-6 pb-32 ">
          <div className="w-full flex justify-center  pt-16">
            <div className="relative p-8 py-6 ">
              <Avatar
                alt="รูป"
                src={user?.image?.url}
                sx={{ width: 200, height: 200 }}
              />
            </div>
          </div>
          <div className="pl-20 text-lg pb-6 font-bold  ">ข้อมูลพนักงาน</div>
          <div className="flex justify-center">
            <div className="grid grid-cols-2 justify-center text-lg  ">
              <div className="flex border-r-4 border-green-600 mr-4 p-6 ">
                <div className=" font-semibold">
                  <h3>ชื่อ</h3>
                  <h3>อีเมลล์</h3>
                  <h3>โทรศัพท์</h3>
                  <h3>ที่อยู่</h3>
                </div>
                <div className=" px-4   ">
                  {user?.name} {user?.surname}
                  <br />
                  {user?.email}
                  <br />
                  {user?.tel}
                  <br />
                  {user?.address}
                </div>
              </div>
              <div className="flex pl-8 text-lg p-6 ">
                <div className="font-semibold">
                  <h3>วันเริ่มงาน</h3>
                  <h3>วันผ่านโปร</h3>
                  <h3>ฝ่าย</h3>
                  <h3>ตำแหน่ง</h3>
                  <h3>แผนก</h3>
                  <h3>ระดับพนักงาน</h3>
                </div>
                <div className=" text-center px-8 ">
                  {dayjs(user?.dateStart).format('DD/MM/YYYY')}
                  <br />
                  {dayjs(user?.datePass).format('DD/MM/YYYY')}
                  <br />
                  {user?.position?.name}
                  <br />
                  {user?.group?.name}
                  <br />
                  {user?.department?.name}
                  <br />
                  {user?.level?.name}
                </div>
              </div>
            </div>
          </div>
          <div className=" pl-20 py-4  text-lg font-bold">เอกสารเพิ่มเติม</div>
          <div>{renderTable()}</div>

          <div className=" pl-20 py-4    text-lg font-bold">หมายเหตุ</div>

          <div className=" px-48 pl-20 ">
            <div className="  m-3 ">{user?.note || 'ไม่มีข้อมูล'}</div>
          </div>
        </div>
      </Card>
    </div>
  );
}
