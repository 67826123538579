import {
  FILE_ALL,
  FILE_DELETE,
  FILE_GET,
  FILE_LOADING,
  FILE_POST,
  FILE_PUT,
} from '../type';

function FileReducer(state = { salary: null }, action) {
  switch (action.type) {
    case FILE_LOADING:
      return { isLoading: true };
    case FILE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case FILE_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case FILE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case FILE_PUT:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case FILE_DELETE:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default FileReducer;
