import axios from 'axios';
import {
  PICKUPORDER_PUT,
  PICKUPORDER_ALL,
  PICKUPORDER_DELETE,
  PICKUPORDER_GET,
  PICKUPORDER_LOADING,
  PICKUPORDER_ERROR,
  PICKUPORDER_POST,
} from '../type';
import api from '../../utils/functions/api';

export const getAllPickUpOrder = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/pickUpOrder?name=${name}&size=${size}&page=${page}`
    );
    if (status === 200) {
      dispatch({ type: PICKUPORDER_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PICKUPORDER_ERROR });
  }
};
export const getOnePickUpOrder = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/pickUpOrder/${id}`
    );
    if (status === 200) {
      dispatch({ type: PICKUPORDER_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PICKUPORDER_ERROR });
  }
};

export const deletePickUpOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: PICKUPORDER_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/pickUpOrder/${id}`
    );
    dispatch({ type: PICKUPORDER_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PICKUPORDER_ERROR });
  }
};

export const addPickUpOrder = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PICKUPORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/pickUpOrder`,
      {
        ...payload,
      }
    );
    dispatch({ type: PICKUPORDER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PICKUPORDER_ERROR });
  }
};

export const putPickUpOrder = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PICKUPORDER_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/pickUpOrder/${id}`,
      payload
    );
    dispatch({ type: PICKUPORDER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PICKUPORDER_ERROR });
  }
};
