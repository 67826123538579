import axios from 'axios';
import {
  RECEIPT_ALL,
  RECEIPT_DELETE,
  RECEIPT_GET,
  RECEIPT_LOADING,
  RECEIPT_POST,
  RECEIPT_PUT,
  RECEIPT_ERROR,
} from '../type';
import api from '../../utils/functions/api';

export const getAllReceipt = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1, graph = '' } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/receipt?name=${name}&size=${size}&page=${page}&graph=${graph}`
    );
    if (status === 200) {
      dispatch({ type: RECEIPT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: RECEIPT_ERROR });
  }
};

export const getOneReceipt = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/receipt/${id}`
    );
    if (status === 200) {
      dispatch({ type: RECEIPT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: RECEIPT_ERROR });
  }
};

export const deleteReceipt = (id) => async (dispatch) => {
  try {
    dispatch({ type: RECEIPT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/receipt/${id}`
    );
    dispatch({ type: RECEIPT_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: RECEIPT_ERROR });
  }
};

export const addReceipt = (payload) => async (dispatch) => {
  try {
    dispatch({ type: RECEIPT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/receipt`,
      {
        ...payload,
      }
    );
    dispatch({ type: RECEIPT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: RECEIPT_ERROR });
  }
};

export const putReceipt = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: RECEIPT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/receipt/${id}`,
      payload
    );
    dispatch({ type: RECEIPT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: RECEIPT_ERROR });
  }
};
