import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Button,
  CardContent,
  TextField,
  Card,
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { BackButton } from 'components/Button';
import _ from 'lodash';
import * as actions from '../../redux/action';
// import Loading from '../../components/Loading';

function CreateDepartment({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const setting = useSelector((state) => state.setting);
  const { handleSubmit, control } = useForm({ mode: 'onChange' });

  const [approver1, setApprover1] = useState();
  const [approver2, setApprover2] = useState();

  const module = setting?.arr;

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      const payload = {
        name: data?.name,
        approver_1: approver1?._id,
        approver_2: approver2?._id,
        access: { ...data },
      };
      await dispatch(actions.addDepartment(payload));
      await dispatch(actions.getAllDepartment({}));
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  const renderForm = () => (
    <div className="py-2">
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <b>ชื่อแผนก</b>
              <div>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      label="ชื่อแผนก"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
              </div>
              <b>ผู้อนุมัติใบลาในแผนก</b>
              <div>
                <div className="py-2">
                  <FormControl sx={{ minWidth: 120 }} fullWidth>
                    <InputLabel id="type" size="small">
                      คนที่ 1
                    </InputLabel>
                    <Select
                      label="คนที่ 1"
                      value={approver1}
                      onChange={(e) => setApprover1(e?.target?.value)}
                      required
                      fullWidth
                      size="small"
                    >
                      {_.map(user?.arr, (each) => (
                        <MenuItem key={each?.id} value={each}>
                          {each?.name} {each?.surname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="py-2">
                  <FormControl sx={{ minWidth: 120 }} fullWidth>
                    <InputLabel id="type" size="small">
                      คนที่ 2
                    </InputLabel>
                    <Select
                      label="คนที่ 2"
                      value={approver2}
                      onChange={(e) => setApprover2(e?.target?.value)}
                      required
                      fullWidth
                      size="small"
                    >
                      {_.map(user?.arr, (each) => (
                        <MenuItem key={each?.id} value={each}>
                          {each?.name} {each?.surname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <b>โมดูลภายในระบบ</b>
              <div className="flex">
                {module?.moduleAccess?.FND && (
                  <div>
                    <Controller
                      name="FND"
                      defaultValue={false}
                      control={control}
                      render={({ field }) => (
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                checked={field.value}
                                // checked={checked}
                                // onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            label="การเงิน"
                          />
                        </div>
                      )}
                    />
                  </div>
                )}
                {module?.moduleAccess?.SLD && (
                  <div>
                    <Controller
                      name="SLD"
                      defaultValue={false}
                      control={control}
                      render={({ field }) => (
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                checked={field.value}
                                // checked={checked}
                                // onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            label="ฝ่ายขาย"
                          />
                        </div>
                      )}
                    />
                  </div>
                )}
                {module?.moduleAccess?.HRD && (
                  <div>
                    <Controller
                      name="HRD"
                      defaultValue={false}
                      control={control}
                      render={({ field }) => (
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                checked={field.value}
                                // checked={checked}
                                // onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            label="ฝ่ายบุคคล"
                          />
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="flex">
                {module?.moduleAccess?.PCD && (
                  <Controller
                    name="PCD"
                    defaultValue={false}
                    control={control}
                    render={({ field }) => (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              // checked={checked}
                              // onChange={handleChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="ฝ่ายจัดซื้อ"
                        />
                      </div>
                    )}
                  />
                )}
                {module?.moduleAccess?.PDD && (
                  <Controller
                    name="PDD"
                    defaultValue={false}
                    control={control}
                    render={({ field }) => (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              // checked={checked}
                              // onChange={handleChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="คลังสินค้า"
                        />
                      </div>
                    )}
                  />
                )}
                {module?.moduleAccess?.ADM && (
                  <Controller
                    name="ADM"
                    defaultValue={false}
                    control={control}
                    render={({ field }) => (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              // checked={checked}
                              // onChange={handleChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="ผู้ดูแลระบบ"
                        />
                      </div>
                    )}
                  />
                )}
              </div>
              <div className="pt-4 text-center">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );

  useEffect(() => {
    dispatch(actions.getAllUser({}));
  }, []);

  return (
    <div>
      {renderTitle()}
      <BackButton />
      {renderForm()}
    </div>
  );
}

CreateDepartment.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
CreateDepartment.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateDepartment;
