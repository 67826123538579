import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import Loading from '../../components/Loading';
import ListTimestamp from '../../components/Card/ListTimestamp';
import * as action from '../../redux/action';

export default function CheckIn({ title, subtitle }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const checkIn = useSelector((state) => state.checkIn);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderCheckinCard = () => <ListTimestamp checkIn={checkIn} />;

  useEffect(() => {
    dispatch(
      action.CheckIngetAll({ me: me.userData?._id, checkOutSatus: false })
    );
    return () => {};
  }, []);

  if (!checkIn.isCompleted || checkIn.isLoading || me.isLoading) {
    return <Loading isLoading={checkIn.isLoading} />;
  }

  if (checkIn.isCompleted || !checkIn.isLoading || !me.isLoading) {
    return (
      <div className="min-h-screen">
        <div className="flex justify-between">{renderTitle()}</div>
        <div className="py-4">
          <div className="">{renderCheckinCard()}</div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Loading isLoading />
    </div>
  );
}
CheckIn.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CheckIn.defaultProps = {
  title: '',
  subtitle: '',
};
