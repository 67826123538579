/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory, useParams } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button, Card, CardContent, TextField } from '@mui/material';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import ProductServe from 'components/Forms/FormProductArray';
import dayjs from 'dayjs';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import * as actions from '../../../redux/action';

import Sumprice from 'components/Card/Sumprice';
import SumAllPrice from 'components/Card/SumAllPrice';
import SumDiscount from 'components/Card/SumDiscount';

function EditRe({ title, subtitle }) {
  dayjs.extend(buddhistEra);
  const dispatch = useDispatch();

  const history = useHistory();
  const params = useParams();
  const receipt = useSelector((state) => state.receipt);
  const customer = useSelector((state) => state.customer);
  const product = useSelector((state) => state.product);
  const { handleSubmit, control, setValue, watch } = useForm({});

  const [date, setDate] = React.useState(new Date());
  const [dueDate, setDueDate] = React.useState();
  const [productList, setProductList] = useState([]);
  const [customerData, setCustomerData] = useState();
  const [productVat, setProductVat] = useState(false);
  const [productVatUni, setProductVatUni] = useState(false);

  useEffect(() => {
    dispatch(actions.getOneReceipt(params.id));

    dispatch(actions.getAllProduct());

    return () => {};
  }, []);
  useEffect(() => {
    setProductList(receipt?.product);
    setDate(receipt?.date);
    setDueDate(receipt?.dueDate);
    setValue('productArray', receipt?.product);
    setValue('businessname', receipt?.customer?.businessname);
    setValue('taxId', receipt?.customer?.TaxId);
    setValue('tel', receipt?.customer?.tel);
    setValue('name', receipt?.customer?.name);
    setValue('address', receipt?.customer?.address);
    setValue('payment', receipt?.payment);
    setValue('sale', receipt?.sale);
    setValue('note', receipt?.note);
    setValue('doc', receipt?.doc);
    setValue('status', receipt?.customer?.status);
    setValue('running_number', receipt?.running_number);
    setProductVat(receipt?.statusTax);
    setProductVatUni(receipt?.statusTaxUni);
    console.log('Status in receipt', receipt.statusTax, receipt.statusTaxUni);
    return () => {};
  }, [receipt]);

  const onSubmitQuotation = async (data) => {
    if (productList.length === 0) alert('กรุณาเพิ่มสินค้า');
    else {
      try {
        const ProcessData = await {
          date,
          dueDate,
          prefix: 'RE' + dayjs(date).format(`BB`) + '-',
          ...data,
          customer: customerData?._id,
          product: data?.productArray,
          allPrice: SumAllPrice(),
          statusTax: productVat,
          statusTaxUni: productVatUni,
        };
        // console.log("dataSubmit", ProcessData);
        // console.log('ProductVat on editReceipt', productVat);

        await dispatch(actions.putReceipt(params.id, ProcessData));
        await dispatch(actions.getAllReceipt({ name: '', page: 1, size: 5 }));
        history.push('/sale/receipt');
      } catch (error) {}
    }
  };
  const tax = (status) => {
    if (!status) {
      return 0;
    } else {
      const price = SumAllPrice(productList);
      const tax = price * 0.03;
      return tax;
    }
  };
  const taxUniversity = () => {
    if (!productVatUni) {
      return 0;
    }
    const priceUniversity = SumAllPrice(productList);
    const taxUniversity = priceUniversity * 0.01;
    return taxUniversity;
  };

  const priceTax = (status) => SumAllPrice(productList) - tax(status);
  if (customer.isloading && product.isloading) {
    return <>error</>;
  }

  // const priceTax = () => SumAllPrice(productList);
  const priceTaxUni = () => SumAllPrice(productList) - taxUniversity();

  const sumPrice = () => {
    if (productList?.length === 0) {
      return 0;
    }
    const sumP = _.sumBy(productList, (each) => each?.price * each?.quantity);
    return sumP;
  };
  const sumDiscount = () => {
    if (productList?.length === 0) {
      return 0;
    }
    const sumP = _.sumBy(productList, (each) => parseInt(each?.discount));
    return sumP;
  };

  const sumAllPrice = (productList) => {
    if (productList?.length === 0) {
      return 0;
    }
    return sumPrice() - sumDiscount();
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (customer.isloading && product.isloading) {
    return <>error</>;
  }
  if (!customer.isloading && !product.isloading) {
    return (
      <div className="min-h-screen">
        <div>
          {renderTitle()}
          <div className="py-2">
            <BackButton />
          </div>
        </div>
        <div>
          <div>
            <div className="flex">
              <Card>
                <div className="p-2">
                  <h1 className="pb-4"> ข้อมูลลูกค้า</h1>
                  <div className="flex w-full">
                    <Controller
                      name="businessname"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          label="ชื่อลูกค้า"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </div>{' '}
                  {/* <div className="pl-4 pt-2">
                      <Controller
                        name="status"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  // checked={checked}
                                  // onChange={handleChange}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label="นิติบุคคล"
                            />
                          </div>
                        )}
                      />
                    </div> */}
                  <Controller
                    name="taxId"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="หมายเลขผู้เสียภาษี"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="tel"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-basic"
                        label="เบอร์โทร"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="address"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-multiline-basic"
                        label="ที่อยู่"
                        variant="outlined"
                        fullWidth
                        multiline
                        margin="normal"
                        rows={4}
                      />
                    )}
                  />
                </div>
              </Card>

              <div className="p-2 w-full">
                <Card>
                  <CardContent>
                    {' '}
                    <form onSubmit={handleSubmit(onSubmitQuotation)}>
                      <div className="flex justify-between">
                        <div className="flex">
                          <h1 className="text-xl pt-8 pr-2">
                            {receipt?.prefix}
                          </h1>
                          <div>
                            <Controller
                              name="running_number"
                              control={control}
                              defaultValue="-"
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  value={field.value}
                                  label="เลขที่เอกสาร"
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                />
                              )}
                            />
                          </div>
                          <div className="p-4 ">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="วันที่"
                                value={date}
                                onChange={(newValue) => {
                                  setDate(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>{' '}
                          </div>
                        </div>
                        <Button
                          variant="contained"
                          type="submit"
                          // onClick={onSubmitQuotation}
                        >
                          บันทึก
                        </Button>
                      </div>
                      <Controller
                        name="sale"
                        control={control}
                        defaultValue="-"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            label="พนักงานขาย"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                      <Controller
                        name="payment"
                        control={control}
                        defaultValue="โอน/เงินสด"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            label="วิธีการชำระเงิน"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />{' '}
                      <Controller
                        name="doc"
                        control={control}
                        defaultValue="-"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            label="เอกสารอ้างอิง"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    </form>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-2">
              {' '}
              <ProductServe
                control={control}
                setProductVat={setProductVat}
                setProductList={setProductList}
                setValue={setValue}
                edit
                watch={watch}
                productVatUni={productVatUni}
                setProductVatUni={setProductVatUni}
                productVat={productVat}
              />
            </div>
            <Card>
              <CardContent>
                <div className="flex w-full ">
                  <div className="w-1/2">
                    {' '}
                    <Controller
                      name="note"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          id="outlined-multiline-basic"
                          label="หมายเหตุ"
                          variant="outlined"
                          fullWidth
                          multiline
                          margin="normal"
                          rows={4}
                        />
                      )}
                    />
                  </div>

                  <div className="flex justify-between  w-1/2 ml-2  ">
                    <div>
                      <div>รวมเงิน </div>
                      <div>ส่วนลดการค้า</div>
                      <div>เงินหลังหักส่วนลด</div>
                      {productVatUni === true ? (
                        <div>หัก ณ ที่จ่าย 1%</div>
                      ) : (
                        <div>หัก ณ ที่จ่าย 3%</div>
                      )}

                      <div className="font-bold ">จำนวนเงินทั้งสิ้น </div>
                    </div>
                    <div>
                      {productVatUni === true ? (
                        <div>
                          <div>
                            {sumPrice(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>

                          <div>
                            {sumDiscount(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>

                          <div>
                            {sumAllPrice(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            <div>
                              {taxUniversity()
                                ?.toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>
                          </div>

                          <div className="font-bold ">
                            {priceTaxUni()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div>
                            {sumPrice()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
                          </div>

                          <div>
                            {sumDiscount()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>

                          <div>
                            {sumAllPrice()
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                          <div>
                            {tax(receipt?.customer?.status)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>

                          <div className="font-bold ">
                            {priceTax(productList)
                              ?.toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  }
  return <>error</>;
}
export default EditRe;
