import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { BackButton } from 'components/Button';
import Loading from 'components/Loading';
import * as actions from '../../redux/action';

import FormGroupEmployee from '../../components/Forms/FormGroupEmployee';

function EditGroupEmployee({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const group = useSelector((state) => state.group);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      group: group?.name,
    },
  });

  useEffect(() => {
    dispatch(actions.groupGet(id));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.groupPut(id, data));
        await dispatch(actions.groupGetAll({}));
        alert('บันทึกสำเร็จ');
        history.goBack();
      } catch (error) {
        console.log('error');
      }
    }
  };

  const renderForm = () => (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroupEmployee
          Controller={Controller}
          control={control}
          group={group}
        />
      </form>
    </div>
  );
  if (group.isLoading || !group.isCompleted) {
    return <Loading isLoading />;
  }
  if (!group.isLoading || group.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="py-2">{renderForm()}</div>
      </div>
    );
  }
}

EditGroupEmployee.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
EditGroupEmployee.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditGroupEmployee;
