import {
  LEAVEDOC_ALL,
  LEAVEDOC_DELETE,
  LEAVEDOC_GET,
  LEAVEDOC_LOADING,
  LEAVEDOC_POST,
  LEAVEDOC_PUT,
} from '../type';

function LeaveDocReducer(state = { Product: null }, action) {
  switch (action.type) {
    case LEAVEDOC_LOADING:
      return { isLoading: true };
    case LEAVEDOC_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LEAVEDOC_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LEAVEDOC_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LEAVEDOC_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case LEAVEDOC_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default LeaveDocReducer;
