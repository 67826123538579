import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Save from '@mui/icons-material/Save';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import LayoutPageAdd from 'components/layout/LayoutPageAdd';
import * as action from '../../redux/action';
import Layout from '../../components/layout/Layout';
import FormCustomer from '../../components/Forms/FormCustomer';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';

function EditCustomer({ title, subtitle }) {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const Customers = useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(action.getOneCustomer(params.id));
  }, []);

  async function onSubmit(data) {
    try {
      await dispatch(
        action.putCustomer(
          params.id,
          data.buName,
          data.taxId,
          data.name,
          data.tel,
          data.address,
          data.status
        )
      );
      await dispatch(action.getAllCustomer({}));
      history.push('/customer');
    } catch (error) {}
  }
  const { handleSubmit, control, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues: Customers,
  });

  useEffect(() => {
    setValue('buName', Customers?.businessname);
    setValue('taxId', Customers?.TaxId);
    setValue('tel', Customers?.tel);
    setValue('name', Customers?.name);
    setValue('address', Customers?.address);
    setValue('status', Customers?.status);
    return () => {};
  }, [Customers]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <div className="py-2">
        <BackButton />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormCustomer
          title="ข้อมูลลูกค้า"
          Controller={Controller}
          control={control}
          watch={watch}
          customer={Customers}
        />
        <div className="pt-2 pl-2 ">
          <Button variant="outlined" startIcon={<Save />} type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
}
export default EditCustomer;
