import axios from 'axios';
import {
  TIMESTAMP_SETTING_ALL,
  TIMESTAMP_SETTING_DELETE,
  TIMESTAMP_SETTING_GET,
  TIMESTAMP_SETTING_LOADING,
  TIMESTAMP_SETTING_PUT,
  TIMESTAMP_SETTING_ERROR,
} from '../type';
import api from '../../utils/functions/api';
export function getAllTimeStampSetting(params = {}) {
  return async function (dispatch) {
    const { status = '' } = params;
    axios
      .get(`${process.env.REACT_APP_API_URL}/timeStampSetting?status=${status}`)
      .then((response) => {
        dispatch({ type: TIMESTAMP_SETTING_ALL, payload: response.data });
        // handle success
      })
      .catch((err) => {
        console.error(err);
      });
  };
}
export function getOneTimeStampSetting(id) {
  return async function (dispatch) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/timeStampSetting/${id}`)
      .then((response) => {
        dispatch({ type: TIMESTAMP_SETTING_GET, payload: response.data });
        // handle success
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export function deleteTimeStampSetting(id) {
  return async function (dispatch) {
    dispatch({ type: TIMESTAMP_SETTING_LOADING });
    axios
      .delete(`${process.env.REACT_APP_API_URL}/timeStampSetting/${id}`)
      .then((resp) => {
        console.log('Response', resp.data);
        dispatch({ type: TIMESTAMP_SETTING_DELETE });
      });
  };
}

export function addTimeStampSetting(payload) {
  return async function (dispatch) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/timeStampSetting/`, payload)
      .then((response) => {
        console.log('response: ', response);
        // do something about response
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export const putTimeStampSetting = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: TIMESTAMP_SETTING_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/timeStampSetting/${id}`,
      payload
    );
    dispatch({ type: TIMESTAMP_SETTING_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: TIMESTAMP_SETTING_ERROR });
  }
};
