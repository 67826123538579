/* eslint-disable import/no-extraneous-dependencies */
import { Button, Card } from '@mui/material';
import React from 'react';
import TextField from '@mui/material/TextField';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function CardDateFilltter({
  dateStart,
  setDateStart,
  dateEnd,
  setDateEnd,
  name,
  setName,
  handlePrintReport,
}) {
  const handleChange = (newValue) => {
    setDateStart(new Date(newValue));
  };
  const handleChangeDateEnd = (newValue) => {
    setDateEnd(new Date(newValue));
  };
  return (
    <div className="w-full ">
      <Card>
        <div className="p-4 lg:flex">
          <div className="w-full">
            <TextField
              id="outlined-basic"
              label="ค้นหาชื่อพนักงาน"
              size="small"
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </div>
          <div className="w-full lg:px-2">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="วันเริ่มต้น"
                inputFormat="dd/MM/yyyy"
                value={dateStart}
                onChange={handleChange}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="w-full ">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="วันสิ้นสุด"
                inputFormat="dd/MM/yyyy"
                value={dateEnd}
                onChange={handleChangeDateEnd}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="px-1">
            <Button
              variant="contained"
              color="success"
              onClick={() => handlePrintReport()}
            >
              {' '}
              พิมพ์
            </Button>
          </div>
        </div>
      </Card>{' '}
    </div>
  );
}
