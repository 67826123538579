export const ReportHeader = (information, renderLogo = true) => ({
  alignment: 'between',
  margin: [20, 4, 20, 0],
  table: {
    widths: [`50%`, `50%`],
    body: [
      [
        {
          image: 'logo',
          width: 190,
          rowSpan: 2,
          margin: [0, 0, 0, 0],
          border: [false, false, false, false],
        },
        {
          margin: [0, 20, 0, 0],
          text: `${information?.name} `,
          style: 'header',
          border: [false, false, false, false],
        },
      ],
      [
        { text: '', border: [false, false, false, false] },
        {
          margin: [0, 0, 0, 0],
          text: `${information?.nameEng}\n ${information?.address}\nเลขประจำตัวผู้เสียภาษี ${information?.taxId} เบอร์โทร ${information?.tel} `,
          style: 'body1',
          border: [false, false, false, false],
        },
      ],
    ],
  },
  styles: {
    header: {
      fontSize: 10,
      bold: true,
      alignment: 'right',
    },
    header1: {
      fontSize: 14,
      bold: true,
    },
  },
});

export default ReportHeader;
