/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Save from '@mui/icons-material/Save';
import { useHistory, useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { Card, Button } from '@mui/material';
import FormInfoBusiness from 'components/Forms/FormInfoBusiness';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import ImageUpload from '../../components/ImageUpload/ImageUpload';
import * as action from '../../redux/action';

export default function EditInfoBusiness() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const information = useSelector((state) => state.information);
  const [selectedImage, setSelectedImage] = useState([]);

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
  });
  useEffect(() => {
    dispatch(action.getOneInformation(params.id));

    return () => {};
  }, []);

  useEffect(() => {
    setValue('name', information?.name);
    setValue('nameEng', information?.nameEng);
    setValue('taxId', information?.taxId);
    setValue('tel', information?.tel);
    setValue('email', information?.email);
    setValue('address', information?.address);
    setValue('webUrl', information?.webUrl);

    return () => {};
  }, [information]);

  const submit = async (data) => {
    try {
      if (!_.isEmpty(selectedImage)) {
        data.image = {
          image: selectedImage[0]?.data_url,
          imageType: 'profile',
          alt: '',
        };
      }
      const dataSubmit = {
        ...data,
      };

      await dispatch(action.putInformation(information?._id, dataSubmit));
      // await dispatch(action.getAllInformation());
      alert('บันทึกข้อมูลสำเร็จ');
      await history.goBack();
    } catch (error) {
      console.error('Error On Information ', error);
    }
  };
  const renderForm = () => (
    <div>
      <FormInfoBusiness Controller={Controller} control={control} />
    </div>
  );

  return (
    <div className="h-screen ">
      <div>
        <ViewTitle title="แก้ไขข้อมูลบริษัท" />
      </div>
      <div className="py-1">
        <BackButton />
      </div>

      <Card>
        <div className=" w-full">
          <div className="p-4 ">
            <div>
              <div>
                <form onSubmit={handleSubmit(submit)}>
                  {renderForm()}
                  <div className="my-auto mx-auto p-4">
                    <div>
                      <ImageUpload
                        images={selectedImage}
                        maxNumber={1}
                        previewSize={500}
                        setImages={setSelectedImage}
                      />
                    </div>
                  </div>
                  <div className="p-4 text-right">
                    <Button
                      variant="contained"
                      startIcon={<Save />}
                      type="submit"
                    >
                      บันทึก
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
