/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { useGeolocated } from 'react-geolocated';

import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import CalDistanceFunction from 'function/CalDistanceFunction';
import Loading from '../../components/Loading';
import dayjs from 'dayjs';
import * as action from '../../redux/action';
import TimeStampCard from '../../components/Card/TimeStampCard';

export default function TimeStamp({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const me = useSelector((state) => state.me);
  const timeStampSetting = useSelector((state) => state.TimeStampSetting);
  const { handleSubmit, control } = useForm({});
  const [selectedImage, setSelectedImage] = useState([]);
  const [place, setPlace] = useState();
  const [imgSrc, setImgSrc] = useState(null);
  const [noteCheckin, setNoteCheckin] = useState();

  useEffect(() => {
    dispatch(action.getAllTimeStampSetting({ status: true }));
    return () => {};
  }, []);
  console.log('noteCheckin', noteCheckin);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  const myLocations = {
    longitude: coords?.longitude,
    latitude: coords?.latitude,
  };
  console.log('coords', coords);
  useEffect(() => {
    setPlace(CalDistanceFunction(timeStampSetting?.arr, myLocations));
    return () => {
      console.log('error ssss');
    };
  }, [timeStampSetting, myLocations]);

  const checkLate = () => {
    const timeSet = dayjs(place?.time_in).format('HH:mm');
    const now = dayjs().format('HH:mm');
    if (now >= timeSet) {
      return true;
    }
    return false;
  };
  // console.log(
  //   _.map(timeStampSetting?.arr, (each) =>
  //     haversine(myLocations, each?.location)
  //   )
  // );
  // console.log('checkLate', checkLate());

  const renderBackButton = () => <BackButton />;
  const onSubmitbill = async (data) => {
    if (place) {
      if (!imgSrc) {
        alert('กรุณาอัปโหลดรูป');
      } else {
        const confirm = window.confirm('ยืนยันบันทึกข้อมูล');
        if (confirm) {
          try {
            const payload = {
              ...data,
              employee: me?.userData?._id,

              image: imgSrc,
              late_status: checkLate(),
              noteCheckin: noteCheckin,
              location: {
                longitude: coords?.longitude,
                latitude: coords?.latitude,
              },
              place_checkIn: place?._id,
            };
            await dispatch(action.CheckInCreate(payload));
            await dispatch(
              action.CheckIngetAll({
                me: me.userData?._id,
                checkOutSatus: false,
              })
            );
            alert('ลงเวลาสำเร็จ');
            await dispatch(
              action.CheckIngetAll({
                me: me.userData?._id,
                checkOutSatus: false,
              })
            );
            history.goBack();
          } catch (error) {
            console.log('error', error);
            alert('บันทึกข้อมูลผิดพลาด');
          }
        }
      }
    } else {
      alert('คุณไม่อยู่ในระยะที่กำหนด');
    }
  };
  const renderCheck = () => (
    <form onSubmit={handleSubmit(onSubmitbill)}>
      <TimeStampCard
        me={me}
        control={control}
        Controller={Controller}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        coords={coords}
        date={new Date()}
        place={place}
        imgSrc={imgSrc}
        setImgSrc={setImgSrc}
        noteCheckin={noteCheckin}
        setNoteCheckin={setNoteCheckin}
      />
    </form>
  );
  if (timeStampSetting.isLoading || !timeStampSetting.isCompleted) {
    return <Loading isLoading />;
    // return <div>404</div>;
  }
  if (timeStampSetting.isLoading || timeStampSetting.isCompleted) {
    return (
      <div className="min-h-screen">
        <div>{renderTitle()}</div>
        <div className="py-1">{renderBackButton()}</div>
        <div>{renderCheck()}</div>
      </div>
    );
  }
}

TimeStamp.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

TimeStamp.defaultProps = {
  title: '',
  subtitle: '',
};
