import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import _ from 'lodash';

export default function SummaryTimestamp({ users }) {
  const callateFunc = (timestamp) => {
    const count = _?.groupBy(timestamp, ['late_status', true]);

    return count;
  };

  return (
    <div>
      <Card>
        <div className="overflow-auto overflow-y-scroll h-64">
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="font-bold">ลำดับ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">รหัสพนักงาน</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">ชื่อ-สกุล</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">แผนก/สังกัด</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">จำนวนวันลงเวลา</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">เข้างานสาย</div>
                  </TableCell>
                  {/* <TableCell>ลางาน</TableCell> */}
                </TableRow>
              </TableHead>

              {_?.isEmpty(users?.arr) ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      ไม่มีข้อมูล
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {_?.map(users?.arr, (each, index) => (
                    <TableRow>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{each?.employeeId}</TableCell>
                      <TableCell>
                        {each?.name} {each?.surname}{' '}
                      </TableCell>
                      <TableCell>
                        <p>
                          <b>{each?.department?.name || '-'}</b>
                          {each?.subBusiness?.name
                            ? ' / ' + each?.subBusiness?.name
                            : ''}
                        </p>
                      </TableCell>
                      <TableCell>{each?.timestamp?.length}</TableCell>
                      <TableCell>
                        {callateFunc(each?.timestamp)?.true?.length || 0}
                      </TableCell>
                      {/* <TableCell>ลางาน</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </Card>
    </div>
  );
}
