import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import * as actions from '../redux/action';
import Loading from 'components/Loading';

export default function PrivateRoute({ children }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    dispatch(actions.getMe())
      .then(() => setIsLoading(false))
      .catch((err) => {
        alert('Catch');
        localStorage.removeItem('userId');
        localStorage.removeItem('Token');
      });
    return () => {};
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loading isLoading />
      </div>
    );
  }

  if (me?.login === false) {
    return (
      <div>
        <Route>
          <Redirect to="/" />
        </Route>
      </div>
    );
  }

  return <div>{children}</div>;
}
