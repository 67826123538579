import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
/// table
import {
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import { ViewTitle } from 'components/ViewTitle';
import * as action from '../../redux/action';

function Product({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const product = useSelector((state) => state.product);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  function getData() {
    dispatch(action.getAllProduct({ page, size }));
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  async function DeleteProduct(id) {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(action.deleteProduct(id));
        await dispatch(action.getAllProduct({}));
      } catch (error) {
        alert('ลบข้อมูลไม่สำเร็จ');
      }
    }
  }
  useEffect(() => {
    getData();
  }, [page, size]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderAddButton = () => (
    <Button
      variant="contained"
      size="large"
      onClick={() => history.push('/product/new')}
    >
      เพิ่ม
    </Button>
  );
  return (
    <div className="">
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="pt-4">{renderAddButton()}</div>
        </div>
        <div className="pt-2 text-center">
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <h1 className="font-bold  "> ชื่อสินค้า</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="font-bold ">จำนวนสินค้า</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="font-bold ">หน่วย</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="font-bold ">ราคาต้นทุน</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="font-bold  ">ราคาขาย</h1>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                {/* {(!product.arr):
               ( <TableBody>
                 <TableRow>
                   <TableCell>ไม่มีสินค้าไอหัวรวย</TableCell>
                 </TableRow>
                </TableBody>)
              } */}
                <TableBody>
                  {product.arr?.map((Products) => (
                    <TableRow key={Products._id}>
                      <TableCell component="th" scope="row">
                        <Link to={`/product/edit/${Products?._id}`}>
                          {Products.productName}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link to={`/product/edit/${Products?._id}`}>
                          {Products.productQuantity}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link to={`/product/edit/${Products?._id}`}>
                          {Products.productUnit}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link to={`/product/edit/${Products?._id}`}>
                          {Products?.cost}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link to={`/product/edit/${Products?._id}`}>
                          {Products.productPrice ? (
                            <div>{Products.productPrice}</div>
                          ) : (
                            <div>ยังไม่กำหนดราคา</div>
                          )}
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        <Stack
                          direction="row"
                          spacing={1}
                          className="justify-center"
                        >
                          <Button
                            color="error"
                            variant="contained"
                            startIcon={<DeleteIcon />}
                            onClick={() => DeleteProduct(Products?._id)}
                          >
                            ลบ
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 100]}
              component="div"
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              count={product?.total || 0}
              rowsPerPage={size}
              page={page - 1}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default Product;
