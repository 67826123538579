/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/self-closing-comp */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import * as actions from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';

export function HomeNavbar({ onMobileNavOpen }) {
  const history = useHistory();

  const information = useSelector((state) => state.information);
  const dispatch = useDispatch();
  const handlePushToHome = () => {
    history.push('/');
  };

  const onSignOut = async () => {
    await localStorage.removeItem('userId');
    localStorage.removeItem('Token');
    history.push('/');
  };
  useEffect(() => {
    dispatch(actions.getAllInformation({}));
    return () => {};
  }, []);

  return (
    <>
      <nav
        className="top-0 fixed z-10 w-full flex flex-wrap items-center justify-between px-2 py-2 navbar-expand-lg shadow bg-gradient-to-r bg-white shadow-xl"
        style={{ background: '#078a48' }}
      >
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between  font-sans">
            <div className="flex">
              <div className="leading-relaxed mr-4 py-2 whitespace-no-wrap flex">
                {/* <img
                  src={information?.arr?.[0]?.logo?.url || PlaceholderLogo}
                  className="h-10 rounded-md"
                /> */}
                <div
                  onClick={() => {
                    handlePushToHome();
                  }}
                >
                  <div className="my-auto pl-4 text-white cursor-pointer  ">
                    {information?.name}
                  </div>
                </div>
              </div>
            </div>
            <div></div>

            <div className=" text-xs py-2 px-1 ">
              <div
                className="bg-gray-300 py-1 px-1 text-green-500 hover:text-green-700 rounded-md  cursor-pointer"
                onClick={() => {
                  onSignOut();
                }}
              >
                ออกจากระบบ
              </div>
            </div>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => onMobileNavOpen()}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}

export default HomeNavbar;
