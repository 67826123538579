import { ME_ERROR, ME_GET, ME_PROMPT, ME_RESET } from '../type';

const initialState = {
  userData: null,
  isLoading: true,
  isCompleted: true,
};

function MeReducer(state = initialState, action) {
  switch (action.type) {
    case ME_GET:
      return {
        userData: { ...action.payload },
        isLoading: false,
        login: true,
      };
    case ME_RESET:
      return {
        ...action.payload,
        isLoading: true,
      };
    case ME_PROMPT:
      return {
        ...action.payload,
        isLoading: true,
        prompt: true,
      };
    case ME_ERROR:
      return {
        ...action.payload,
        login: false,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default MeReducer;
