import { Button } from '@mui/material';
import { ViewTitle } from 'components/ViewTitle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import Loading from 'components/Loading';
import ListTimestampSetting from '../../components/Card/ListTimestampSetting';
import * as actions from '../../redux/action';

export default function TimeStampSetting({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const timeStampSetting = useSelector((state) => state.TimeStampSetting);
  const [select, setSelect] = useState('false');

  useEffect(() => {
    dispatch(actions.getAllTimeStampSetting({}));
    return () => {};
  }, []);

  const prop = {
    handleOnselect: async (each, id) => {
      try {
        await dispatch(actions.putTimeStampSetting(id, { status: each }));
        await dispatch(actions.getAllTimeStampSetting({}));
      } catch (error) {
        alert(error);
      }
    },
    handleDelete: async (id) => {
      const confirm = window.confirm('ยืนยันลบข้อมูล');
      if (confirm) {
        try {
          await dispatch(actions.deleteTimeStampSetting(id));
          await dispatch(actions.getAllTimeStampSetting({}));
        } catch (error) {
          console.log(error);
        }
      }
    },
  };

  const renderList = () => (
    <div>
      <ListTimestampSetting
        timeStampSetting={timeStampSetting}
        select={select}
        setSelect={setSelect}
        {...prop}
      />
    </div>
  );
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderButton = () => (
    <Button
      variant="contained"
      onClick={() => history.push('/work/setting/timestamp-setting/new')}
    >
      เพิ่ม
    </Button>
  );
  if (timeStampSetting.isLoading || !timeStampSetting.isCompleted) {
    return <Loading isLoading={timeStampSetting?.isLoading} />;
  }
  if (!timeStampSetting.isLoading || timeStampSetting.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="py-4">{renderButton()}</div>
        </div>
        {renderList()}
      </div>
    );
  }
  return <div>404</div>;
}
TimeStampSetting.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

TimeStampSetting.defaultProps = {
  title: '',
  subtitle: '',
};
