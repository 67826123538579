import {
  CUSTOMER_ALL,
  CUSTOMER_DELETE,
  CUSTOMER_GET,
  CUSTOMER_POST,
  CUSTOMER_PUT,
  CUSTOMER_LOADING,
} from '../type';
const initialState = {
  isLoading: false,
  isCompleted: true,
};
function CustomerReducer(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_LOADING:
      return { isLoading: false, isCompleted: true };
    case CUSTOMER_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case CUSTOMER_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case CUSTOMER_POST:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case CUSTOMER_PUT:
      return {
        isLoading: false,
        isCompleted: true,
      };
    case CUSTOMER_DELETE:
      return {
        isLoading: false,
        isCompleted: true,
      };
    default:
      return state;
  }
}

export default CustomerReducer;
